import React from "react";
import { useSelector } from "react-redux";
// import { useSelector } from 'react-redux';

const SearchBar = ({
  searchInput,
  placeholder = "Search",
  styles = "",
  inputStyles = "",
  width = "",
}) => {
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  return (
    <div
      className={`pl-3 dark:bg-[#343A40] bg-[#c9c9c9] flex items-center
            w-full md:w-60 dark:text-gray-100 text-gray-900 text-sm rounded-lg ${styles} ${width}`}
    >
      <img
        className="w-4 top-2.5 opacity-70"
        src={`/images/${
          isThemeDark ? "searchIcon_light" : "searchIcon_dark"
        }.svg`}
        alt="searchIcon"
      />
      <input
        className={`dark:bg-[#343A40] bg-[#c9c9c9] p-2 focus:outline-none w-full
                 rounded-lg
                ${inputStyles}
                `}
        type="text"
        placeholder={placeholder}
        onChange={searchInput}
      />
    </div>
  );
};

export default SearchBar;
