import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import Spinner from "../../UI/Spinner/Spinner";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { cn, utcToGermanTime } from "../../../shared/Utility";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import useMobileView from "../../../hooks/useMobileView";

const HdcChart = (props) => {
  const { sensorName, setTimeRange, duration, rawData } = props;
  //language package
  const { t, i18n } = useTranslation("sensors");
  const { width } = useWindowDimensions();
  const isMobileView = useMobileView();
  const [ticks, setTicks] = useState([]);
  const hdcData = useSelector((state) => state.hdc.data);
  const [chartData, setChartData] = useState([]);

  const [dataOneHour, setDataOneHour] = useState(0);
  const [isRaining, setIsRaining] = useState(false);
  const [isCumulative, setIsCumulative] = useState(false);
  const isChartLoading = useSelector((state) => state.chart.isLoading);
  // const [duration, setDuration] = useState(1440)

  useEffect(() => {
    // Get 6 date (as a ticks) dynamically from x-axis data
    // always picks the first data and last data from the data array

    let ticks = [];
    let tickCount = 5;

    if (chartData.length > 0) {
      let firstData = chartData[0].start;
      let lastData = chartData[chartData.length - 1].start;
      ticks.push(firstData);
      let interval = Math.round((chartData.length - 1) / tickCount);
      for (let i = 1; i < tickCount; i++) {
        let index = interval * i;
        let date = chartData[index].start;
        ticks.push(date);
      }
      ticks.push(lastData);
    }
    setTicks(ticks);
  }, [duration, chartData]);

  useEffect(() => {
    if (hdcData && sensorName) {
      // console.log(hdcData);
      hdcData.forEach((position) => {
        if (sensorName === position.name) {
          // console.log("in", sensorName, position.name);
          const differenceInMinutes = Math.floor(
            (new Date() - new Date(position.lastEmission)) / 1000 / 60
          );
          if (differenceInMinutes < 6) {
            setIsRaining(true);
          } else {
            setIsRaining(false);
          }
        }
      });
    }
  }, [hdcData, sensorName]);

  // wiki -> https://spekter.atlassian.net/wiki/spaces/CLOUDBREAK/pages/785842177/Cumulative+Curve+Ganglinie
  useEffect(() => {
    let cumulativeSum = 0;
    if (rawData) {
      const data = rawData.map((item, index) => {
        // const prevData = rawData[index - 1]?.value;

        // pick 1 digit after decimal without rounding by Math.trunc
        const value = Math.trunc(item.value * 10) / 10;

        const sumvalue = cumulativeSum + value;
        cumulativeSum = sumvalue;
        //negative to positive value , like -0.1 to 0.1
        // const absCumulativeData = Math.abs(cumulativeData).toFixed(1);
        const cumulativeValue = Math.trunc(cumulativeSum * 10) / 10;
        if (isCumulative) {
          return {
            start: item.start,
            BarChart: item?.value >= 0 ? value : 0,
            Ganglinie: cumulativeValue,
          };
        } else {
          return {
            start: item.start,
            BarChart: item?.value >= 0 ? value : 0,
          };
        }
      });
      setChartData(data);
      setDataOneHour(data[data.length - 1]?.BarChart);
    }
  }, [rawData, isCumulative]);

  const timeframeContent = (
    <div className="flex gap-2 bg-[#343A40] p-1 rounded-5">
      <div
        onClick={() => setTimeRange(1440)}
        className={`h-[22px] w-[71px] flex justify-center items-center duration-300
     rounded-5 font-[700] text-[11px] cursor-pointer hover:opacity-75
    ${duration === 1440 && "bg-spekterDarkOne"}`}
      >
        {t("oneDaySelection")}
      </div>
      <div
        onClick={() => setTimeRange(1440 * 3)}
        className={`h-[22px] w-[71px] flex justify-center items-center duration-300
     rounded-5 font-[700] text-[11px] cursor-pointer hover:opacity-75
    ${duration === 4320 && "bg-spekterDarkOne"}`}
      >
        {t("threeDaySelection")}
      </div>
    </div>
  );

  const cumulativeSwitch = (
    <div
      data-tip={isMobileView ? "" : t("cumulativeRainfall")}
      data-for="tooltip"
      className={"flex bg-[#343A40] p-1 rounded-5 cursor-pointer"}
      onClick={() => {
        setIsCumulative(!isCumulative);
      }}
    >
      <img
        src="images/chart_modal/icons8-gefülltes-plus-96.svg"
        alt="cumulative chart icon"
        className={cn(
          { "bg-spekterDarkOne rounded-5": isCumulative },
          { "bg-transparent": !isCumulative }
        )}
        height={18}
        width={18}
      />
    </div>
  );
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="flex flex-col text-white bg-[#333333] p-4 text-sm rounded">
          <p>{`${utcToGermanTime(label, "DD.MM HH:mm")}`}</p>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 bg-[#000BF8]"></div>
            <span className="opacity-70">{`Niederschlag: ${payload[0].value}  mm/h`}</span>
          </div>
          {payload[1] && (
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-[#ff7300]"></div>
              <span className="opacity-70">{`Niederschlag Summe: ${payload[1]?.value} mm`}</span>{" "}
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="w-full">
      <div className="flex flex-col items-center sm:items-start sm:flex-row sm:justify-between text-spekterWhite">
        <div className="flex items-start justify-center my-0 mx-2.5 gap-2">
          <img
            src={`/images/${
              isRaining ? "Niederschlag_aktiv.svg" : "Niederschlag_inaktiv.svg"
            }`}
            // src={`/images/${isRaining ? "hdc-circle-active.png" : "hdc-circle-idle.png"
            //   }`}
            alt="hdc active icon"
            className="h-8 m-0"
          />
          <h5 className="mt-1">{sensorName}</h5>
        </div>

        <div className="flex items-center gap-5">
          <section className="flex flex-col items-center sm:items-end">
            <div className="flex flex-row items-center">
              <span>{t("rainAmount")}</span>
              <div className="flex flex-col items-center justify-center mx-2 my-1">
                <h5 className="m-0">{dataOneHour?.toFixed(1)}</h5>
                <span className="m-0 text-[10px] font-semibold">mm</span>
              </div>
              <img
                src="/images/chart_modal/icons8-blur-240-white.svg"
                alt="gfl icon"
                className="h-6"
              />
            </div>
            <div className="flex gap-2 mt-3 mb-1">
              {timeframeContent} {cumulativeSwitch}
            </div>
          </section>
        </div>
      </div>
      {isChartLoading ? (
        <div className="flex items-center justify-center w-full h-52">
          <Spinner />
        </div>
      ) : rawData.length !== 0 ? (
        // <div className="bg-white">
        <ResponsiveContainer width="100%" height={250}>
          <ComposedChart
            width={500}
            height={250}
            data={chartData}
            color="#fff"
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              // left: 20,
            }}
          >
            {/* <CartesianGrid stroke="#f5f5f5" /> */}
            <XAxis
              dataKey="start"
              ticks={ticks}
              fontSize={12}
              tickFormatter={(dateTime) => utcToGermanTime(dateTime, "HH:mm")}
              tick={{ fill: "#fff" }}
              // <YAxis >
            />
            <YAxis
              tickFormatter={(value) => value.toFixed(1)}
              fontSize={12}
              tick={{ fill: "#fff" }}
            />
            <Tooltip content={<CustomTooltip />} />
            {/* <Legend /> */}
            <Bar dataKey="BarChart" barSize={20} fill="#000BF8" />
            <Line
              type="monotone"
              connectNulls
              dataKey="Ganglinie"
              stroke="#ff7300"
              dot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        // </div>
        <div className="flex items-center justify-center pt-20 text-white">
          No data
        </div>
      )}
      <ReactTooltip id="tooltip" place="top" />
    </div>
  );
};

export default HdcChart;
