import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { forgetPassSentOTP } from "../../../redux/actions/index";
import { STEPS } from "./STEPS";
import { useTranslation } from "react-i18next";
import * as toast from "../../UI/Toaster";

const ResendOTP = ({
  email,
  setCurrentStep,
  resendCounter,
  setResendCounter,
}) => {
  //Language package
  const { t, i18n } = useTranslation("settings");

  const dispatch = useDispatch();
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);

  const genResendDiv = (resendCounter, onResendOTP) => {
    if (resendCounter < 1) {
      return (
        <div
          className="flex items-center justify-center gap-2 my-10 cursor-pointer"
          onClick={onResendOTP}
        >
          <div className="dark:text-spekterGreen text-spekterRed">
            {t("resendCodeLongText")}
          </div>
          <img
            className="w-[18px] h-[18px] relative top-[2px]"
            src={
              isThemeDark
                ? "/images/icons8-massen-e-mail-senden-144.png"
                : "/images/icons8-massen-e-mail-senden-144 (1).png"
            }
            alt=""
          />
        </div>
      );
    }

    return (
      // this div should be greyed out, means not clickable
      <div className="flex items-center gap-2 my-10 opacity-50 cursor-wait">
        <div className="text-spekterGreenLight">{t("resendCodeShortText")}</div>
        <img
          className="w-[18px] h-[18px] relative top-[2px] opacity-25"
          src={
            isThemeDark
              ? "/images/icons8-massen-e-mail-senden-144.png"
              : "/images/icons8-massen-e-mail-senden-144 (1).png"
          }
          alt=""
        />
        {resendCounter}
      </div>
    );
  };

  const onResendOTP = () => {
    dispatch(forgetPassSentOTP(email)).then((response) => {
      if (response.error) {
        if (response.error === 404) {
          toast.error(t("emailDoesNotExist"));
        } else {
          toast.error(response?.data?.message || t("somethingWentWrong"));
        }
      } else if (response.data) {
        toast.success(response?.data?.message);
        setResendCounter(response.data.timer);
      }
    });
  };

  return (
    <div
      className={`py-10 rounded-[18px] min-h-[480px] duration-300 p-[39px] sm:p-[47px] w-full lg:w-[500px]
    ${
      isThemeDark
        ? "bg-spekterDarkSix text-gray-100"
        : "text-gray-700 bg-spekterWhite containerShadowLight"
    }  `}
    >
      <p className="text-[21px] sm:text-[24px] pb-2 font-bold sm:text-center lg:text-left">
        {t("resetPassword")}
      </p>
      <p className="text-[15px] sm:text-center lg:text-left">
        {t("receiveOTP")}
        <br />
        <br />
        {t("checkInbox")}
      </p>
      {genResendDiv(resendCounter, onResendOTP)}
      <button
        className="bg-spekterGreenSuccess rounded-[6px] w-full py-[15px] font-bold dark:text-spekterDarkSix text-spekterWhite mt-8 mb-2 cursor-pointer"
        onClick={() => {
          setCurrentStep(STEPS.CODE_VERIFICATION);
        }}
      >
        {t("continueBtn")}
      </button>
      <button
        className="rounded-[4px] w-full h-9 dark:text-spekterGreen mt-2 cursor-pointer"
        onClick={() => {
          setCurrentStep(STEPS.REQUEST_OTP);
        }}
      >
        {t("backBtn")}
      </button>
    </div>
  );
};

export default ResendOTP;
