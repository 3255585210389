const NormalBackButton = ({ handleNormalMapReset, showResetButton }) => {
  return (
    <div
      className={`${
        showResetButton ? "flex" : "hidden"
      } items-center justify-center w-10 h-10 duration-300 rounded cursor-pointer bg-spekterDarkOne hover:bg-gray-600`}
      onClick={handleNormalMapReset}
    >
      <img
        width={20}
        src="/images/icons8-enlarge-100.svg"
        alt="normal back icon"
      />
    </div>
  );
};

export default NormalBackButton;
