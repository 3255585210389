import { useState, useEffect } from "react";

const useMousePosition = () => {
  // State to store mouse coordinates
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  // Event handler to set mouse coordinates
  const updateMousePosition = (ev) => {
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  };

  useEffect(() => {
    // Attach the event listener for mouse movement
    window.addEventListener("mousemove", updateMousePosition);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("mousemove", updateMousePosition);
  }, []);

  return mousePosition;
};

export default useMousePosition;
