import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const AddToHomeScreen = () => {
  const { t, i18n } = useTranslation("addToHomeScreen");
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show banner based on specific conditions
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isInStandaloneMode =
      "standalone" in window.navigator && window.navigator.standalone;

    if (isIOS && !isInStandaloneMode) {
      setTimeout(() => setIsVisible(true), 3000);
    }
  }, []);

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 h-[350px] w-full z-1000 p-2.5 sm:hidden text-white">
      <div className="h-full p-4 bg-black rounded-md">
        <div className="flex justify-end">
          <img
            src="/images/X Icon Bold_c9c9c9.svg"
            alt="close_icon"
            className="p-1"
            onClick={() => setIsVisible(false)}
          />
        </div>
        <div className="text-lg font-bold">{t("installTheApp")}</div>
        <div className="text-sm font-normal">{t("toInstallTheApp")}</div>
        <div className="flex pt-10 text-sm font-bold">
          1.&nbsp;{t("tapOnTheShareIcon")}
          <img src="/images/share.svg" alt="share" className="pl-1" />
        </div>
        <div className="pt-3 text-sm font-bold">
          2.&nbsp;{t("selectAddToHomeScreen")}
          <img
            src="/images/add_to_home.svg"
            alt="add_to_home"
            className="pt-1 pl-3"
          />
        </div>
        <div className="pt-3 text-sm font-bold">3.&nbsp;{t("openTheApp")}</div>
      </div>
    </div>
  );
};

export default AddToHomeScreen;
