import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sensorTypes } from "../../../constants/sensorTypes";
import Info from "../Info/Info";
import SmartRiverSensorsChart from "../SmartRiverSensorsChart/SmartRiverSensorsChart";
import GflSensorsChart from "../GflSensorsChart/GflSensorsChart";
import HdcSensorsChart from "../HdcSensorsChart/HdcSensorsChart";
import SewerChart from "../SewerChart/SewerChart";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useTranslation } from "react-i18next";
import {
  timeStampToGermanTime,
  utcToGermanTime,
} from "../../../shared/Utility";

const AllSensors = ({
  smartriverData,
  gflData,
  hdcData,
  smartsewerData,
  smartRiverButton,
  gflButton,
  hdcButton,
  smartSewerButton,
}) => {
  //Translation package
  const { t, i18n } = useTranslation("sensors");

  // holds all sensor for sensor-manager action
  const [smartRiverSensor, setSmartRiverSensor] = useState([]);
  const [gflSensor, setGflSensor] = useState([]);
  const [hdcSensor, setHdcSensor] = useState([]);
  const [smartSewerSensor, setSmartSewerSensor] = useState([]);

  /* ---------------------------------------
    ---> state for smartRiver and gfl<---
    # those state are holds thresholds of sensor.
    # hdc sensor have not any thresholds.
  ------------------------------------------*/
  const [smartRiverOptionalData, setsmartRiverOptionalData] = useState({});
  const [gflOptionalData, setgflOptionalData] = useState({});
  const [hdcOptionalData, setHdcOptionalData] = useState({});
  const [smartSewerOptionalData, setsmartSewerOptionalData] = useState({});

  //------ only chart state [parsed data]
  // this state will fill up after receiving sensor details info.
  // N.B: those data are parsed from sensor details info.
  const [smartRiverChartData, setSmartRiverChartData] = useState([]);
  const [gflChartData, setGflChartData] = useState([]);
  const [hdcChartData, setHdcChartData] = useState([]);
  const [smartSewerChartData, setSmartSewerChartData] = useState([]);

  useEffect(() => {
    /*-----------------------------
       -->refactore props data<--
      preparing all sensors as one state 
      for sensormanager action
    -------------------------------*/

    if (
      smartriverData !== null &&
      gflData !== null &&
      hdcData !== null &&
      smartsewerData !== null
    ) {
      let newData = [];
      let tempSmartRiverData = [];
      let tempGflData = [];
      let tempHdcData = [];
      let tempSmartSewerData = [];

      let tempSmartRiverOptionalData = {};
      let tempGflOptionalData = {};
      let tempHdcOptionalData = {};
      let tempSmartSewerOptionalData = {};

      smartriverData.forEach((sensor) => {
        const tempSensor = {
          label: sensor.name,
          value: sensor._id,
          sensorType: sensorTypes.SMARTRIVER,
          soleCorrectionValue: sensor.soleCorrectionValue,
        };
        tempSmartRiverData.push(tempSensor);

        const tempSensorName = sensor.name;

        tempSmartRiverOptionalData[tempSensorName] = {
          soleCorrectionValue: sensor.soleCorrectionValue,
          thresholds: sensor.thresholds,
          maintenance: sensor.maintenance,
        };
      });

      gflData.forEach((sensor) => {
        const tempSensor = {
          label: sensor.name,
          value: sensor._id,
          sensorType: sensorTypes.GFL,
        };
        tempGflData.push(tempSensor);

        const tempSensorName = sensor.name;

        tempGflOptionalData[tempSensorName] = {
          thresholds: sensor.thresholds,
          maintenance: sensor.maintenance,
        };
      });

      hdcData.forEach((sensor) => {
        const tempSensor = {
          label: sensor.name,
          value: sensor._id,
          sensorType: sensorTypes.HDC,
        };
        tempHdcData.push(tempSensor);

        const tempSensorName = sensor.name;

        tempHdcOptionalData[tempSensorName] = {
          maintenance: sensor.maintenance,
        };
      });

      smartsewerData.forEach((sensor) => {
        const tempSensor = {
          label: sensor.name,
          value: sensor._id,
          sensorType: sensorTypes.SMARTSEWER,
          soleCorrectionValue: sensor.environment?.soleDistance,
        };
        tempSmartSewerData.push(tempSensor);

        const tempSensorName = sensor.name;

        tempSmartSewerOptionalData[tempSensorName] = {
          soleCorrectionValue: sensor.environment?.soleDistance,
          thresholds: sensor.thresholds,
          maintenance: sensor.maintenance,
        };
      });

      setSmartRiverSensor(tempSmartRiverData);
      setGflSensor(tempGflData);
      setHdcSensor(tempHdcData);
      setSmartSewerSensor(tempSmartSewerData);

      setsmartRiverOptionalData(tempSmartRiverOptionalData);
      setgflOptionalData(tempGflOptionalData);
      setHdcOptionalData(tempHdcOptionalData);
      setsmartSewerOptionalData(tempSmartSewerOptionalData);
    }
  }, [smartriverData, gflData, hdcData, smartsewerData]);

  useEffect(() => {
    /*-----------------------------
      include values
    -------------------------------*/
    if (smartRiverButton && smartRiverSensor) {
      const tempSmartRiverData = [];

      for (let i = 0; i < smartRiverSensor.length; i++) {
        const sensorName = smartRiverSensor[i].label;
        const sensorId = smartRiverSensor[i].value;
        const soleCorrectionValue = smartRiverSensor[i].soleCorrectionValue;

        tempSmartRiverData.push({
          soleCorrectionValue,
          sensorName,
          sensorId,
          values: [],
        });
      }

      if (tempSmartRiverData.length > 0) {
        tempSmartRiverData.forEach((d, i) => {
          d.values =
            smartriverData[i]._id === d.sensorId ? smartriverData[i].data : [];
        });
      }

      let tempSmartRiverSensordata = [];

      tempSmartRiverData.map((sensorData) => {
        let sensorName = sensorData.sensorName;
        let sensorType = sensorTypes.SMARTRIVER;

        tempSmartRiverSensordata.push({
          values: sensorData.values,
          name: sensorName,
          sensorType: sensorType,
        });
      });

      setSmartRiverChartData(tempSmartRiverSensordata);
    }
  }, [smartRiverButton, smartRiverSensor]);

  useEffect(() => {
    if (gflButton && gflSensor) {
      const tempGflData = [];

      for (let i = 0; i < gflSensor.length; i++) {
        const sensorName = gflSensor[i].label;
        const sensorId = gflSensor[i].value;

        tempGflData.push({
          sensorName,
          sensorId,
          values: [],
        });
      }

      if (tempGflData.length > 0) {
        tempGflData.forEach((d, i) => {
          d.values = gflData[i]._id === d.sensorId ? gflData[i].data : [];
        });
      }

      let tempGflSensordata = [];

      tempGflData.map((sensorData) => {
        let sensorName = sensorData.sensorName;
        let sensorType = sensorTypes.GFL;

        tempGflSensordata.push({
          values: sensorData.values,
          name: sensorName,
          sensorType: sensorType,
        });
      });
      setGflChartData(tempGflSensordata);
    }
  }, [gflButton, gflSensor]);

  useEffect(() => {
    if (hdcButton && hdcSensor) {
      const tempHdcData = [];

      for (let i = 0; i < hdcSensor.length; i++) {
        const sensorName = hdcSensor[i].label;
        const sensorId = hdcSensor[i].value;

        tempHdcData.push({
          sensorName,
          sensorId,
          values: [],
        });
      }

      if (tempHdcData.length > 0) {
        tempHdcData.forEach((d, i) => {
          d.values = hdcData[i]._id === d.sensorId ? hdcData[i].data : [];
        });
      }

      let tempHdcSensordata = [];

      tempHdcData.map((sensorData) => {
        let sensorType = sensorTypes.HDC;

        tempHdcSensordata.push({
          ...sensorData,
          sensorType: sensorType,
        });
      });
      setHdcChartData(tempHdcSensordata);
    }
  }, [hdcButton, hdcSensor]);

  useEffect(() => {
    if (smartSewerButton && smartSewerSensor) {
      const tempSmartSewerData = [];

      for (let i = 0; i < smartSewerSensor.length; i++) {
        const sensorName = smartSewerSensor[i].label;
        const sensorId = smartSewerSensor[i].value;
        const soleCorrectionValue = smartSewerSensor[i].soleCorrectionValue;

        tempSmartSewerData.push({
          soleCorrectionValue,
          sensorName,
          sensorId,
          values: [],
        });
      }

      if (tempSmartSewerData.length > 0) {
        tempSmartSewerData.forEach((d, i) => {
          d.values =
            smartsewerData[i]._id === d.sensorId ? smartsewerData[i].data : [];
        });
      }

      let tempSmartSewerSensordata = [];

      tempSmartSewerData.map((sensorData) => {
        let tempdates = [];
        let tempValues = [];
        let sensorName = sensorData.sensorName;
        let sensorType = sensorTypes.SMARTSEWER;

        if (sensorData.values) {
          for (let i = 0; i < sensorData.values.length; i++) {
            tempdates.push(timeStampToGermanTime(sensorData.values[i]._time));
            tempValues.push(sensorData.values[i]._value);
          }
        }

        tempSmartSewerSensordata.push({
          dates: tempdates,
          data: tempValues,
          name: sensorName,
          sensorType: sensorType,
        });
      });

      setSmartSewerChartData(tempSmartSewerSensordata);
    }
  }, [smartSewerButton, smartSewerSensor]);

  // reset our all state when it will unmount
  useEffect(() => {
    return () => {
      setSmartRiverChartData([]);
      setGflChartData([]);
      setHdcChartData([]);
      setSmartSewerChartData([]);

      setSmartRiverSensor([]);
      setGflSensor([]);
      setHdcSensor([]);
      setSmartSewerSensor([]);

      setsmartRiverOptionalData([]);
      setgflOptionalData([]);
      setHdcOptionalData([]);
      setsmartSewerOptionalData([]);
    };
  }, []);

  let chart = (
    <>
      {smartRiverButton &&
        smartRiverChartData.map((sensor, index) => {
          return (
            <div className="p-2.5 md:my-auto max-w-[100%]" key={index}>
              <div className="bg-spekterDarkOne rounded-2xl w-550px max-w-[100%] h-350px min-h-max flex flex-col">
                <SmartRiverSensorsChart
                  data={sensor}
                  optionalData={smartRiverOptionalData[sensor.name]}
                />
              </div>
            </div>
          );
        })}

      {gflButton &&
        gflChartData.map((sensor, index) => {
          return (
            <div className="p-2.5 md:my-auto max-w-[100%]" key={index}>
              <div className="bg-spekterDarkOne rounded-2xl w-550px max-w-[100%] h-350px flex flex-col">
                <GflSensorsChart
                  data={sensor}
                  optionalData={gflOptionalData[sensor.name]}
                />
              </div>
            </div>
          );
        })}

      {hdcButton &&
        hdcChartData.map((sensor, index) => {
          // sum of all values, check if it less than zero
          const data = sensor?.values?.map((data) =>
            data.value > 0 ? data.value : 0
          );
          // sum of all data
          const sum = data.reduce((a, b) => a + b, 0).toFixed(0);

          return (
            <div className="p-2.5 md:my-auto max-w-[100%]" key={index}>
              <div className="bg-spekterDarkOne rounded-2xl w-550px max-w-[100%] h-350px flex flex-col">
                <HdcSensorsChart
                  sensorData={sensor}
                  maintenance={hdcOptionalData[sensor?.sensorName]?.maintenance}
                  sum={sum}
                />
              </div>
            </div>
          );
        })}

      {smartSewerButton &&
        smartSewerChartData.map((sensor, index) => {
          const thresholds = smartSewerOptionalData[sensor.name]?.thresholds;
          return (
            <div className="p-2.5 md:my-auto max-w-[100%]" key={index}>
              <div className="bg-spekterDarkOne rounded-2xl w-550px max-w-[100%] h-350px min-h-max flex flex-col gap-5">
                <div>
                  <Info
                    information={sensor?.name}
                    data={sensor?.data}
                    sensorType={sensor?.sensorType}
                    maintenanceMode={
                      sensor.data.length < 1 ||
                      smartSewerOptionalData[sensor.name]?.maintenance
                    }
                  />
                </div>

                {thresholds && (
                  <div className="flex flex-col items-center gap-3 text-xl">
                    <section className="flex flex-col items-center ">
                      <span className="p-0 m-0 text-spekterWhite">
                        {t("kdwState")}
                      </span>
                      {thresholds[0].overflow === false ? (
                        <span className="p-0 m-0 text-spekterGreenLight">
                          {t("kdwNoOverflow")}
                        </span>
                      ) : (
                        <span className="p-0 m-0 text-spekterRed">
                          {" "}
                          {t("kdwOverflow")}
                        </span>
                      )}
                    </section>
                    <section className="flex flex-col items-center">
                      <span className="p-0 m-0 text-spekterWhite whitespace-nowrap">
                        {t("kdwStateManhole")}
                      </span>
                      {thresholds[1].movement === false ? (
                        <span className="p-0 m-0 text-spekterGreenLight">
                          {" "}
                          {t("kdwClosed")}
                        </span>
                      ) : (
                        <span className="p-0 m-0 text-spekterRed">
                          {t("kdwOpen")}
                        </span>
                      )}
                    </section>
                  </div>
                )}
                {/* {sensor.data.length < 1 ||
                smartSewerOptionalData[sensor.name].maintenance ? (
                  <div className="text-spekterWhite flex justify-center pt-[80px] gap-2 h-full p-4 mt-[30px]"></div>
                ) : (
                  <div></div>
                )} */}
              </div>
            </div>
          );
        })}
    </>
  );

  return <>{chart}</>;
};

export default AllSensors;
