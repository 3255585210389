import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { forgetPassSubmit } from "../../../redux/actions";
import { STEPS } from "./STEPS";
import { useTranslation } from "react-i18next";
import * as toast from "../../UI/Toaster";
import { StringWhitespaceCheck } from "../../../shared/Utility";

const NewCredential = ({ otp, setCurrentStep }) => {
  //Language package
  const { t, i18n } = useTranslation("settings");

  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [errorIdxs, setErrorIdxs] = useState([0, 1, 2, 3]);

  const dispatch = useDispatch();

  const validationMsg = [
    t("criteriaOne"),
    t("criteriaTwo"),
    t("criteriaThree"),
    t("criteriaFour"),
    t("criteriaFive"),
  ];

  const onPasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);

    const invalidDataIndexs = [];
    const upperCaseLettersRgx = /[A-Z]/g;
    const lowerCaseLettersRgx = /[a-z]/g;
    const numbersRgx = /[0-9]/g;
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (!password.match(upperCaseLettersRgx)) {
      invalidDataIndexs.push(0);
    }
    if (!password.match(lowerCaseLettersRgx)) {
      invalidDataIndexs.push(1);
    }
    if (!password.match(numbersRgx)) {
      if (!specialChars.test(password)) {
        invalidDataIndexs.push(2);
      }
    }
    if (password.length < 8) {
      invalidDataIndexs.push(3);
    }
    if (StringWhitespaceCheck(password) === true) {
      invalidDataIndexs.push(4);
    }

    setErrorIdxs(invalidDataIndexs);
    if (invalidDataIndexs.length === 0) {
      if (retypePassword !== "") {
        setRetypePassword("");
      }
    }
  };

  const onRetypePasswordChange = (e) => {
    setRetypePassword(e.target.value);
  };

  const onSubmit = () => {
    dispatch(forgetPassSubmit(otp, newPassword)).then((response) => {
      // console.log(response);
      if (response.error) {
        toast.error(response?.data?.message);
      } else {
        toast.success(response?.data?.message);
        localStorage.removeItem("token");
        setCurrentStep(STEPS.REQUEST_OTP);
      }
    });
  };

  return (
    <div
      className={`py-10 rounded-[18px] min-h-[480px] duration-300 p-[39px] sm:p-[47px] w-full lg:w-[500px]
  ${
    isThemeDark
      ? "bg-spekterDarkSix text-gray-100"
      : "text-gray-700 bg-spekterWhite containerShadowLight"
  }  `}
    >
      <p className="text-[21px] sm:text-[24px] pb-2 font-bold sm:text-center lg:text-left duration-300">
        {t("resetPassword")}
      </p>

      {validationMsg.map((msg, i) => {
        const isWrong = errorIdxs.includes(i);

        return (
          <div className="flex gap-1">
            <img
              className="h-4 w-4 relative top-[3px]"
              src={isWrong ? "/images/wrong.svg" : "/images/right.svg"}
              alt=""
            />
            <p className={`text-[11px] m-1`}>{msg}</p>
          </div>
        );
      })}

      <div className="relative pt-4">
        <div className="py-2 font-bold dark:text-spekterWhite text-[15px]">
          {t("newPw")}
        </div>
        <img
          className="absolute -right-6 top-[75px] w-4 h-4"
          src={errorIdxs.length > 0 ? "/images/wrong.svg" : "/images/right.svg"}
          alt=""
        />
        <input
          className={`rounded-[6px] py-[9px] w-full px-3 text-[15px] border-2 focus:outline-none
          ${errorIdxs.length > 0 ? "border-spekterRed " : "border-transparent"}
          ${
            isThemeDark
              ? "bg-[#333] text-[#818181]"
              : "bg-[#C9C9C9] text-[#333]"
          }`}
          type="password"
          value={newPassword}
          onChange={onPasswordChange}
        />
        <div className="mt-2 py-2 font-bold dark:text-spekterWhite text-[15px]">
          {t("retypePW")}
        </div>
        {newPassword && (
          <img
            className="absolute -right-6 top-[165px] w-4 h-4"
            src={
              newPassword !== retypePassword
                ? "/images/wrong.svg"
                : "/images/right.svg"
            }
            alt=""
          />
        )}
        <input
          className={`rounded-[6px] py-[9px] border-2 w-full px-3 text-[15px] focus:outline-none
          ${
            newPassword
              ? retypePassword === ""
                ? "border-spekterRed"
                : newPassword !== retypePassword
                ? "border-spekterRed"
                : "border-transparent"
              : ""
          }
          ${
            isThemeDark
              ? "bg-[#333] text-[#818181]"
              : "bg-[#C9C9C9] text-[#333]"
          }
          `}
          type="password"
          value={retypePassword}
          disabled={newPassword === ""}
          onChange={onRetypePasswordChange}
        />
        <div className="h-6">
          {newPassword && retypePassword === "" && (
            <p className={`text-[11px] pt-1 text-spekterRed`}>
              {t("requiredField")}{" "}
            </p>
          )}
          {newPassword && retypePassword && newPassword !== retypePassword && (
            <p className={`text-[11px] pt-1 text-spekterRed`}>
              {t("noMatchPw")}{" "}
            </p>
          )}
        </div>
        <button
          className="bg-spekterGreenSuccess rounded-[6px] w-full py-[15px] font-bold dark:text-spekterDarkSix text-spekterWhite mt-4 mb-2 cursor-pointer"
          disabled={
            newPassword && retypePassword === newPassword ? false : true
          }
          onClick={onSubmit}
        >
          {t("submit")}
        </button>
        <button
          className="rounded-[4px] w-full h-9 dark:text-spekterGreen mt-2 cursor-pointer"
          onClick={() => setCurrentStep(STEPS.REQUEST_OTP)}
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );
};

export default NewCredential;
