import React, { useEffect, useState } from "react";
import RescueGroup from "../../components/UserManager/RescueGroup/RescueGroup";
import UserActivation from "../../components/UserManager/UserActivation/UserActivation";
import UserList from "../../components/UserManager/UserList/UserList";
import { useTranslation } from "react-i18next";
import useRole from "../../hooks/useRole";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import useObserver from "../../hooks/useObserver";
import useAgs from "../../hooks/useAgs";

//
const tab = {
  USERLIST: "userList",
  USERACTIVATION: "userActivation",
  RESCUEGROUP: "rescueGroup",
};

const UserManagement = () => {
  //language package
  const { t, i18n } = useTranslation("userManagement");
  const userRole = useRole();
  const isObserver = useObserver();
  const agsList = useAgs();
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const selectedObserverAgs = useSelector(
    (state) => state.observer.selectedAgs
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [ags, setAgs] = useState("");

  const activeModules = useSelector(
    (state) => state.customer?.data?.activeModules
  );
  const [currentTab, setCurrentTab] = useState(
    userRole === "rescue" ? tab.RESCUEGROUP : tab.USERLIST
  );

  const tabOnClick = (clickedTab) => {
    switch (clickedTab) {
      case tab.USERLIST:
        setCurrentTab(tab.USERLIST);
        break;
      case tab.USERACTIVATION:
        setCurrentTab(tab.USERACTIVATION);
        break;
      case tab.RESCUEGROUP:
        setCurrentTab(tab.RESCUEGROUP);
        break;
      case tab.RISKPROFILES:
        setCurrentTab(tab.RISKPROFILES);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let agsValue = "";
    if (searchParams.get("ags")) {
      agsValue = searchParams.get("ags");
    } else {
      agsValue = isObserver ? selectedObserverAgs : agsList[0];
    }
    setAgs(agsValue);
  }, []);

  const infoActiveUser = (
    <div className="px-3 py-2 mt-1.5">
      <span className="text-black dark:text-gray-400">
        {t("activeUserText")}
      </span>
    </div>
  );

  const infoInActiveUser = (
    <div className="px-3 py-2 mt-2">
      <span className="text-black dark:text-gray-400">
        {t("inactiveUserText")}
      </span>
    </div>
  );
  const infoIndivlUser = (
    <div className="px-3 py-2 mt-2">
      <span className="text-black dark:text-gray-400">
        {t("individualUserText")}
      </span>
    </div>
  );

  return (
    <div className="h-full min-h-screen pt-12 text-gray-700 dark:text-gray-100 bg-spekterOffWhite dark:bg-spekterDarkTwo sm:pt-20 sm:pl-36 sm:pr-8 sm:pb-auto">
      <section
        className={`flex flex-col items-center rounded-10 p-3 mt-[25px] h-[85vh] backdrop-blur-sm sm:h-[82vh] 2xl:h-[85vh]
       text-spekterWhite `}
        //   className={`flex flex-col items-center rounded-10 p-3 mt-[25px] h-[85vh] backdrop-blur-sm sm:h-[82vh] 2xl:h-[85vh]
        //  text-spekterWhite sm:shadow-container dark:shadow-slate-700`}
      >
        <p className="relative self-start hidden text-2xl font-bold text-gray-700 bottom-4 sm:block dark:text-gray-100">
          {t("title")}
        </p>
        <div className="w-full px-[12px] sm:px-[0px] flex justify-between gap-3 md:justify-start rounded">
          {/* active */}
          {userRole !== "rescue" && (
            <div
              onClick={() => tabOnClick(tab.USERLIST)}
              className={`flex gap-3 transition duration-300 rounded-lg cursor-pointer float-left lg:mr-3 py-2 px-3 sm:px-4
            ${
              currentTab === tab.USERLIST
                ? "bg-spekterRed"
                : `bg-spekterWhite dark:bg-spekterDarkOne dark:text-gray-100 text-gray-900 buttonShadowLight dark:shadow-none`
            }`}
            >
              <img
                className="w-6"
                alt="user_icon"
                src={`/images/user_manager/active_users_${
                  currentTab === tab.USERLIST
                    ? "light"
                    : isThemeDark
                    ? "light"
                    : "dark"
                }.svg`}
              />
              <button className="hidden focus:outline-none md:block">
                {t("activeUsers")}
              </button>
            </div>
          )}
          {/* inactive */}
          {userRole !== "rescue" && (
            <div
              onClick={() => tabOnClick(tab.USERACTIVATION)}
              className={`flex gap-3 transition duration-300 rounded-lg cursor-pointer float-left lg:mx-3 py-2 px-3 sm:px-4 
            ${
              currentTab === tab.USERACTIVATION
                ? "bg-spekterRed"
                : `bg-spekterWhite dark:bg-spekterDarkOne dark:text-gray-100 text-gray-900 buttonShadowLight dark:shadow-none`
            }`}
            >
              <img
                className="w-6"
                alt="inactiveUserIcon"
                src={`/images/user_manager/inactive_users_${
                  currentTab === tab.USERACTIVATION
                    ? "light"
                    : isThemeDark
                    ? "light"
                    : "dark"
                }.svg`}
              />
              <button className="hidden focus:outline-none md:block">
                {t("inActiveUsers")}
              </button>
            </div>
          )}
          {/* individual user */}
          {activeModules && activeModules["individualUsers"] && (
            <div
              onClick={() => tabOnClick(tab.RESCUEGROUP)}
              className={`flex gap-3 transition duration-300 rounded-lg cursor-pointer float-left lg:mx-3 py-2 px-3 sm:px-4 
            ${
              currentTab === tab.RESCUEGROUP
                ? "bg-spekterRed"
                : `bg-spekterWhite dark:bg-spekterDarkOne dark:text-gray-100 text-gray-900 buttonShadowLight dark:shadow-none`
            }`}
            >
              <img
                className="w-6"
                alt="rescue-icon"
                src={`/images/user_manager/individual_user_${
                  currentTab === tab.RESCUEGROUP
                    ? "light"
                    : isThemeDark
                    ? "light"
                    : "dark"
                }.svg`}
              />
              <button className="hidden focus:outline-none md:block">
                {t("rescue")}
              </button>
            </div>
          )}
          {/* {userRole !== "rescue" &&
            activeModules &&
            activeModules["riskReport"] && (
              <div
                onClick={() => tabOnClick(tab.RISKPROFILES)}
                className={`flex gap-3 transition duration-300 rounded-lg cursor-pointer float-left lg:mx-3 py-2 px-3 sm:px-4 
            ${
              currentTab === tab.RISKPROFILES
                ? "bg-spekterRed"
                : `bg-spekterWhite dark:bg-spekterDarkOne dark:text-gray-100 text-gray-900 buttonShadowLight dark:shadow-none`
            }`}
              >
                <img
                  className="h-6"
                  alt="inactiveUserIcon"
                  src={`/images/user_manager/risk_profiles_${
                    currentTab === tab.RISKPROFILES
                      ? "light"
                      : isThemeDark
                      ? "light"
                      : "dark"
                  }.svg`}
                />
                <button className="hidden focus:outline-none md:block">
                  {t("riskProfile")}
                </button>
              </div>
            )} */}
        </div>
        <main className="w-full h-full md:h-[85%] mx-auto my-0">
          {currentTab === tab.USERLIST ? (
            <>
              {infoActiveUser}
              {ags && <UserList ags={ags} />}
            </>
          ) : currentTab === tab.USERACTIVATION ? (
            <>
              {infoInActiveUser}
              {ags && <UserActivation ags={ags} />}
            </>
          ) : currentTab === tab.RESCUEGROUP ? (
            <>
              {infoIndivlUser}
              {ags && <RescueGroup ags={ags} />}
            </>
          ) : null}
        </main>
      </section>
    </div>
  );
};

export default UserManagement;
