import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  catchment,
  selectObserverAgs,
  selectObserverName,
  smartRiverSensorAction,
} from "../../../redux/actions";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import BarToggle from "../../../components/UI/BarToggle/BarToggle";
import Backdrop from "../../../components/UI/Backdrop/Backdrop";
import Spinner from "../../../components/UI/Spinner/Spinner";
import AlarmInfoSideBar from "../CatchmentAlarmInfo/Sidebar";
import { useTranslation } from "react-i18next";
import useMobileView from "../../../hooks/useMobileView";

const selectPodIcon = (category) => {
  const fileName =
    category === "firefighters"
      ? "Firefighters POD, State=Zoomed Out"
      : category === "administration"
      ? "Administrative Service POD, State=Zoomed Out"
      : category === "police"
      ? "Police POD, State=Zoomed Out"
      : category === "thw"
      ? "THW POD, State=Zoomed Out"
      : "POD Icon_aktiv";
  return `/images/pod/${fileName}.svg`;
};

const ObserverOverview = (props) => {
  const { t } = useTranslation("navigation");
  let color = "#A9A9A9";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sideBar, setSideBar] = useState(false);
  const [address, setAddress] = useState("");
  const [openItem, setOpenItem] = useState("catchment");
  const isMobileView = useMobileView();

  const [isAlarmInfoOpen, setIsAlarmInfoOpen] = useState(false);
  const [alarmData, setAlarmData] = useState(undefined);

  const [searchTerm, setSearchTerm] = useState("");

  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const isLoadingCatment = useSelector((state) => state.catchment.isLoading);
  const selectedAgs = useSelector((state) => state.observer.selectedAgs);
  const selectedObserverName = useSelector(
    (state) => state.observer.selectedName
  );
  const catchmentData = useSelector((state) => state.catchment.data);
  const isLoadingObserver = useSelector((state) => state.observer.isLoading);
  const observerData = useSelector((state) => state.observer.data);
  const hdcData = useSelector((state) => state.hdc.data);
  const smartriverData = useSelector((state) => state.smartriver.data);
  const podsData = useSelector((state) => state.pod.pods.data);
  // To calculate flood level
  const retrieveSmartRiverData = useSelector(
    (state) => state.smartRiverSensor.smartRiverData
  );

  let chevron_right_icon = "/i2/images/icons8-chevron-right-90.svg";
  let chevron_left_icon = "i2/images/icons8-chevron-left-90.svg";

  // Platform check
  const [platform, setPlatform] = useState(null);

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (/Android/i.test(userAgent)) {
      setPlatform("Android");
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setPlatform("iOS");
    } else {
      setPlatform("Unknown");
    }
  }, []);

  // let scrollBar = isThemeDark ? "scrollbar" : "scrollbarLight";
  const [smartRiverSensor, setSmartRiverSensor] = useState([]);
  useEffect(() => {
    if (smartriverData && smartriverData.length > 0) {
      let tempSmartRiverData = [];
      smartriverData.forEach((sensor) => {
        const tempSensor = {
          label: sensor.name,
          value: sensor._id,
          sensorType: "smartriverdata",
          soleCorrectionValue: sensor.soleCorrectionValue,
        };
        tempSmartRiverData.push(tempSensor);
      });
      setSmartRiverSensor(tempSmartRiverData);
    }
  }, [smartriverData]);

  useEffect(() => {
    if (smartRiverSensor) {
      dispatch(smartRiverSensorAction(1440, smartRiverSensor));
    }
  }, [smartRiverSensor]);

  useEffect(() => {
    // console.log(retrieveSmartRiverData);
  }, [retrieveSmartRiverData]);

  const handleChange = (value) => {
    setAddress(value);
  };

  const handleSelect = (value) => {
    setAddress(value);
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => props.setAddressCoordinate(Object.values(latLng)))
      .catch((error) => console.error("Error", error));
  };

  const onCatchmentClick = () => {
    dispatch(catchment(selectedAgs));
  };

  React.useEffect(() => {
    if (selectedAgs && sideBar) {
      const interval = setInterval(() => {
        onCatchmentClick();
      }, 30000); // 30000ms = 30 seconds // 60000ms = 1 minute

      return () => clearInterval(interval);
    }
  }, [selectedAgs, sideBar]);

  const onClickObserver = (item) => {
    dispatch(selectObserverAgs(item?.ags));
    dispatch(selectObserverName(item?.name));
    dispatch(catchment(item?.ags));
  };

  const showSelectedTitleBar = selectedAgs && (
    <>
      <div
        className="flex items-center cursor-pointer"
        onClick={() => {
          dispatch(selectObserverAgs(""));
          dispatch(selectObserverName(""));
          setSearchTerm("");
        }}
      >
        <img
          src={chevron_left_icon}
          alt="observer right icon"
          className="h-5"
        />
        <span className="flex flex-shrink ml-2 text-justify w-72">
          {selectedObserverName}
        </span>
      </div>
      <div className="mt-2 border-b-[1px] border-gray-500"></div>
    </>
  );

  const handleObserverSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredObserverData = observerData?.areas?.filter((area) => {
    return area.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const observerContent = !selectedAgs ? (
    isLoadingObserver ? (
      <div className="flex items-center justify-center mt-4">
        <Spinner />
      </div>
    ) : (
      observerData?.areas && (
        <div className="h-[100%] overflow-y-auto scrollbar border-none">
          <div className="w-[95%] py-2">
            <input
              type="text"
              placeholder={t("searchForObserver")}
              className="w-full h-10 p-2 text-white border-none rounded-md outline-none bg-spekterDarkFour focus:border-none"
              onChange={handleObserverSearch}
            />
          </div>
          {filteredObserverData.map((area, i) => {
            return (
              <div
                className="flex w-full flex-row justify-between items-center p-0.5 pb-3 cursor-pointer"
                key={i}
              >
                <div className="float-left font-semibold text-2xs">
                  {area?.name}
                </div>
                <div
                  className="flex items-center justify-end float-right w-4 h-4 font-bold text-center text-spekterWhite text-2xs"
                  onClick={() => {
                    onClickObserver(area);
                  }}
                >
                  {/* &rarr; */}
                  <img
                    src={chevron_right_icon}
                    alt="observer right icon"
                    // className="h-5"
                  />
                </div>
              </div>
            );
          })}
        </div>
      )
    )
  ) : null;

  const catchmentsContent = selectedAgs && catchmentData && (
    <div className="max-h-[70%] overflow-y-auto scrollbar pb-3">
      {catchmentData.map((element, i) => {
        if (!element.inactive) {
          element.warningLevel === "level1"
            ? (color = "#51FC42")
            : element.warningLevel === "level3"
            ? (color = "#B9FC00")
            : element.warningLevel === "level4"
            ? (color = "#FF0000")
            : element.warningLevel === "level5"
            ? (color = "#8200FF")
            : (color = "#A9A9A");
        } else {
          color = "#A9A9A9";
        }

        return (
          <div
            className="flex w-full flex-row justify-between items-center p-0.5 hover:cursor-pointer hover:opacity-75"
            key={i}
            onClick={() => {
              setIsAlarmInfoOpen(true);
              setAlarmData(element);
              isMobileView && setSideBar(false);
              navigate({
                pathname: "/realtimemap",
                search: `?${createSearchParams({
                  _id: element._id,
                  ags: selectedAgs,
                  notificationType: "rain",
                  catchmentLat: element.coordinatesCenter
                    ? element.coordinatesCenter[0]
                    : null,
                  catchmentLng: element.coordinatesCenter
                    ? element.coordinatesCenter[1]
                    : null,
                })}`,
              });
            }}
          >
            <div className="flex flex-shrink float-left text-xs font-semibold">
              {element.name}
            </div>
            <div
              className="flex items-center justify-center float-right w-16 h-6 text-xs font-bold text-center rounded text-spekterDarkOne"
              style={{ backgroundColor: color }}
            >
              {"lastOneHourMaxPrecipitationSum" in element
                ? element.lastOneHourMaxPrecipitationSum.toFixed(2)
                : 0}
            </div>
          </div>
        );
      })}
    </div>
  );
  // : null;
  const hdcContent = selectedAgs
    ? selectedAgs &&
      // isLoading ? (
      //   <div className="flex items-center justify-center mt-4">
      //     <Spinner />
      //   </div>
      // ) :
      hdcData &&
      hdcData.length > 0 &&
      hdcData.map((element, i) => {
        return (
          <div
            className="flex w-full flex-row justify-between items-center p-0.5"
            key={i}
          >
            <div className="flex items-center gap-2 text-xs font-semibold">
              <img
                className="w-5"
                src="/images/Niederschlag_inaktiv.svg"
                alt="hdc"
              />
              {element.name}
            </div>
            <div
              className="flex items-center justify-center float-right w-16 h-6 text-xs font-bold text-center rounded bg-spekterGreen text-spekterDarkOne"
              // style={{ backgroundColor: color }}
            >
              S0
            </div>
          </div>
        );
      })
    : null;
  const smartriverContent = selectedAgs
    ? selectedAgs &&
      // isLoading ? (
      //   <div className="flex items-center justify-center mt-4">
      //     <Spinner />
      //   </div>
      // ) :
      retrieveSmartRiverData &&
      smartriverData &&
      smartriverData.length > 0 &&
      smartriverData.map((element, i) => {
        const floodLevel =
          retrieveSmartRiverData[i]?.values.length > 0
            ? retrieveSmartRiverData[i]?.soleCorrectionValue -
              retrieveSmartRiverData[i]?.values[
                retrieveSmartRiverData[i]?.values.length - 1
              ]._value
            : "N/A";
        if (floodLevel === "N/A") return null;
        return (
          <div
            onClick={() => {
              isMobileView && setSideBar(false);
              navigate({
                pathname: "/realtimemap",
                search: `?${createSearchParams({
                  _id: element._id,
                  ags: selectedAgs,
                  notificationType: "sensor",
                })}`,
              });
            }}
            className="flex w-full flex-row justify-between items-center p-0.5 cursor-pointer hover:opacity-75 duration-300"
            key={i}
          >
            <div className="flex items-center gap-2 text-xs font-semibold">
              <img
                className="w-5"
                src="/images/Pegel_inaktiv.svg"
                alt="Pegel"
              />
              {element.name}
            </div>
            <div className="flex items-center justify-center float-right w-16 h-6 text-xs font-bold text-center rounded bg-spekterGreen text-spekterDarkOne">
              {isNaN(floodLevel)
                ? floodLevel + " cm"
                : floodLevel + " cm" <= 0
                ? 1
                : floodLevel.toFixed(0) + " cm"}
            </div>
          </div>
        );
      })
    : null;

  const sensorContent = (
    <div className="max-h-[70%] overflow-y-auto scrollbar mt-1">
      {smartriverContent} {hdcContent}
    </div>
  );

  const podsContent = selectedAgs && (
    <div className="max-h-[70%] overflow-y-auto scrollbar mt-1">
      {selectedAgs &&
        podsData &&
        podsData.length > 0 &&
        podsData.map((element, i) => {
          return (
            <div
              onClick={() => {
                dispatch(actions.setShowPods(true));
                isMobileView && setSideBar(false);
                navigate({
                  pathname: "/realtimemap",
                  search: `?${createSearchParams({
                    _id: element._id,
                    notificationType: "pod",
                    catchmentLat: element?.location.lat,
                    catchmentLng: element?.location.lng,
                  })}`,
                });
              }}
              className="flex flex-row items-center justify-between w-full p-1 duration-300 cursor-pointer hover:opacity-75"
              key={i}
            >
              <div className="flex items-center gap-2 text-xs font-semibold">
                <img
                  className="w-5"
                  src={selectPodIcon(element?.category)}
                  alt="pod"
                />
                {element.orderSheet || "(no order sheet)"}
              </div>
            </div>
          );
        })}
      {selectedAgs && (
        <div className=" border-b-[1px] border-gray-500 mt-2"></div>
      )}
    </div>
  );

  const addressSearch = selectedAgs && (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="relative pb-16 md:mt-2 sm:pt-1 sm:pb-0">
          <div className="flex">
            <span>
              <img
                className="absolute pt-2 ml-2"
                height="25"
                width="25"
                src="images/location_search.svg"
                alt="search icon"
              />
            </span>
            <input
              {...getInputProps({
                placeholder: "Adresse suchen...",
                type: "text",
              })}
              className="w-[98%] ml-1 py-2 pl-10 text-white rounded-lg bg-spekterDarkFour focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2"
            />
          </div>
          {suggestions && suggestions.length > 0 && (
            <div className="absolute flex px-1 flex-col justify-end overflow-y-auto h-40 top-[-160px] w-[100%] scrollbar mx-auto">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#2d2d2d",
                  color: "white",
                  cursor: "pointer",
                };
                return (
                  <div
                    className={`p-2`}
                    // className={`p-2 ${
                    //   index + 1 == suggestions.length ? "rounded-b-md" : ""
                    // }`}
                    key={index}
                    {...getSuggestionItemProps(suggestion, { style })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );

  // We are not using reload button now
  // Commented out, but dont delete, we might need this later
  // const reloadButton = selectedAgs && (
  //   <div className="flex items-center justify-center w-40 h-12 mx-auto rounded-md text-spekterWhite bg-boxBG hover:bg-spekterRed">
  //     <img
  //       className="w-4 h-4 mr-2 "
  //       src="images/icons8-reload-58.svg"
  //       alt="reload icon"
  //     />
  //     <button
  //       className=""
  //       onClick={() => {
  //         onCatchmentClick();
  //       }}
  //     >
  //       {t("reload")}
  //     </button>
  //   </div>
  // );

  return (
    <div>
      <BarToggle
        open={sideBar}
        clicked={() => setSideBar(!sideBar)}
        importer="observer"
      />
      <Backdrop
        show={sideBar}
        clicked={() => setSideBar(false)}
        opacity="bg-opacity-0"
      />
      <AlarmInfoSideBar
        open={isAlarmInfoOpen}
        setOpen={setIsAlarmInfoOpen}
        data={alarmData}
      />
      <div
        className={`flex flex-col fixed w-[315px] sm:w-[350px] h-[calc(100vh_-_56px)] overflow-y-auto right-0 top-0 z-200 scrollbar  bg-spekterDarkOne text-spekterWhite py-4 px-4 box-border mt-[3.5rem] transition-transform transform ${
          sideBar ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className={`flex flex-col items-start mb-1 sm:mb-3 relative`}>
          <h5>
            <strong>{t("observer")}</strong>
          </h5>
          <div
            onClick={() => setSideBar(false)}
            className="absolute cursor-pointer bottom-6 -right-3 sm:hidden"
          >
            <img
              className="m-1"
              height="20"
              width="20"
              src="/images/sidebar/close_light.svg"
              alt="cancel icon"
            />
          </div>
        </div>

        {showSelectedTitleBar}

        <div
          className={
            selectedAgs
              ? `${
                  platform === "iOS" ? "h-[70%]" : "h-[70%]"
                } md:h-[calc(100vh-175px)] overflow-y-auto scrollbar`
              : "h-[85%] sm:h-[90%]"
          }
        >
          {observerContent}

          {selectedAgs && (
            <div className="flex justify-end py-2 w-30">
              <div
                onClick={() =>
                  setOpenItem((prev) =>
                    prev === "catchment" ? "" : "catchment"
                  )
                }
                className="sticky top-0 flex items-center w-full gap-3 font-semibold cursor-pointer bg-spekterDarkOne"
              >
                Catchments
                <div
                  className={`${
                    openItem === "catchment"
                      ? "rotate-[270deg]"
                      : "rotate-[90deg]"
                  }`}
                >
                  <img
                    className="w-4"
                    src="/i2/images/icons8-chevron-right-90.svg"
                    alt=""
                  />
                </div>
              </div>
              {openItem === "catchment" && (
                <span className="text-[.7rem] text-center">
                  {t("currentRainAmount")} (mm/h)
                </span>
              )}
            </div>
          )}
          {openItem === "catchment" && (
            <>
              {isLoadingCatment && (
                <div className="flex items-center justify-center h-[70%]">
                  <Spinner />
                </div>
              )}
              {catchmentsContent}
            </>
          )}
          {selectedAgs && (
            <div className=" border-b-[1px] border-gray-500 mb-2"></div>
          )}

          {selectedAgs && (
            <div
              onClick={() =>
                setOpenItem((prev) => (prev === "sensor" ? "" : "sensor"))
              }
              className="sticky top-0 flex w-full gap-3 font-semibold cursor-pointer bg-spekterDarkOne"
            >
              Sensors
              <div
                className={`${
                  openItem === "sensor" ? "rotate-[270deg]" : "rotate-[90deg]"
                }`}
              >
                <img
                  className="w-4"
                  src="/i2/images/icons8-chevron-right-90.svg"
                  alt=""
                />
              </div>
            </div>
          )}
          {openItem === "sensor" && sensorContent}

          {selectedAgs && (
            <div className="border-b-[1px] border-gray-500 my-2"></div>
          )}

          {selectedAgs && (
            <div
              onClick={() =>
                setOpenItem((prev) => (prev === "pod" ? "" : "pod"))
              }
              className="sticky top-0 flex w-full gap-3 font-semibold cursor-pointer bg-spekterDarkOne"
            >
              POD - Points of Danger
              <div
                className={`${
                  openItem === "pod" ? "rotate-[270deg]" : "rotate-[90deg]"
                }`}
              >
                <img
                  className="w-4"
                  src="/i2/images/icons8-chevron-right-90.svg"
                  alt=""
                />
              </div>
            </div>
          )}
          {openItem === "pod" && podsContent}
        </div>
        {/* {addressSearch} */}
        {/* reload button for catchment */}
        {/* Commented out reload button since data ta automatically reloaded in every minutes */}
        {/* {reloadButton} */}
      </div>
    </div>
  );
};

export default ObserverOverview;
