import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../redux/actions/index";
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";
import "react-confirm-alert/src/react-confirm-alert.css";
import Spinner from "../../UI/Spinner/Spinner";
import Pagination from "../../UI/Pagination/Pagination";
import { useTranslation } from "react-i18next";
import EyeButton from "../../UI/EyeButton";
import ShowHideText from "../../UI/ShowHideText";
import SearchBar from "../../UI/SearchBar/SearchBar";
import { debounce } from "../../../shared/Utility";

const UserActivation = ({ ags }) => {
  //language package
  const { t, i18n } = useTranslation("userManagement");
  const userType = "Inactive";
  const isloading = useSelector((state) => state.users.isLoading);
  const isloadingActivateUser = useSelector(
    (state) => state.updateUser.isLoading
  );
  const users = useSelector((state) => state.users.data?.result);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState();
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const [showEmail, setShowEmail] = useState(false);

  // pagination
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const retrivedTotalPage = useSelector((state) => state.users.data?.totalPage);
  const paginate = (number) => setCurrentPage(number);

  useEffect(() => {
    if (!retrivedTotalPage) return;
    setTotalPage(retrivedTotalPage);
  }, [retrivedTotalPage]);

  useEffect(() => {
    dispatch(actions.usersAction(ags, currentPage, userType, searchText));
    // unmount the state
    return () => dispatch(actions.usersReset());
  }, [currentPage, userType, searchText]);

  const searchInput = (event) => {
    setCurrentPage(1);
    setSearchText(event.target.value);
  };
  const debouncedChangeHandler = useCallback(debounce(searchInput, 700), []);

  const modalToggleHandler = (id) => {
    setShowModal(!showModal);
    setUserId(id);
  };

  const modalClosedHandler = () => {
    setShowModal(false);
  };

  const activateUser = (id) => {
    const arg = {
      verify: true,
    };

    const response = dispatch(actions.updateUserAction(ags, id, arg));
    response.then((result) => {
      if (result.type === "UPDATE_USER_SUCCESS") {
        dispatch(actions.usersAction(ags, currentPage, userType, searchText));
      }
    });
  };

  return (
    <div className="relative block mt-3">
      <div className="mb-1">
        <SearchBar
          placeholder={t("search")}
          searchInput={debouncedChangeHandler}
        />
      </div>
      {showModal ? (
        <ConfirmationModal
          ags={ags}
          open={showModal}
          closed={modalClosedHandler}
          userId={userId}
          currentPage={currentPage}
          userType={userType}
          searchText={searchText}
        />
      ) : null}

      {/* inactive users list  */}
      <div className="overflow-y-auto scrollbarLight dark:scrollbar h-[calc(85vh-261px)] 2xl:h-[calc(85vh-226px)]">
        <div className="hidden rounded-xl lg:block">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="sticky top-0 text-xs text-gray-700 uppercase dark:text-gray-400 bg-[#ECECEC] dark:bg-[#1D1E21] z-20">
              <tr>
                <th scope="col" className="px-12 py-3">
                  {t("name")}
                </th>
                <th scope="col" className="px-12 py-3">
                  <div className="flex items-center gap-10">
                    <span> {t("mail")}</span>
                    <EyeButton
                      open={showEmail}
                      onEyeClick={() => setShowEmail((prev) => !prev)}
                    />
                  </div>
                </th>
                <th scope="col" className="hidden px-12 py-3 lg:block">
                  {t("adress")}
                </th>
                <th scope="col" className="px-12 py-3 text-center">
                  {t("options")}
                </th>
              </tr>
            </thead>
            <tbody>
              {isloading || isloadingActivateUser ? (
                <tr>
                  <td className="absolute flex items-center justify-center w-full h-[75%]">
                    <Spinner />
                  </td>
                </tr>
              ) : users && users.length > 0 ? (
                users.map((user, index) => {
                  return (
                    <tr
                      className={`${
                        isThemeDark
                          ? "odd:bg-[#282A2E] w-[100%]"
                          : "odd:bg-[#e3e4e6] w-[100%] "
                      }`}
                      key={user._id}
                    >
                      <td className="px-12 py-4 rounded-l-10 w-[25%] ">
                        {user.profile ? user.profile.username : ""}
                      </td>
                      <td className="px-4 py-4 w-[30%] ">
                        {/* {`${
                          user.emails
                            ? user.emails[0].address
                                .split("@")[0]
                                .slice(0, -2) + "**"
                            : ""
                        }@********`} */}
                        <ShowHideText
                          text={user.emails ? user.emails[0].address : ""}
                          show={showEmail}
                        />
                      </td>
                      <td className="py-4 px-12 hidden lg:block lg:relative top-[5px] w-full ">
                        {user.profile
                          ? user.profile.street +
                            " " +
                            user.profile.streetnumber
                          : ""}
                      </td>
                      <td className="px-12 py-4 rounded-r-10 w-[25%] ">
                        <div className="flex justify-center">
                          <button
                            className="h-8 px-2 py-0 mx-1 text-xs font-bold no-underline border-0 rounded w-28 bg-spekterGreen dark:bg-spekterGreen text-spekterDarkOne dark:text-spekterDarkOne"
                            onClick={() => activateUser(user._id)}
                          >
                            {t("activate")}
                          </button>
                          <button
                            className="h-8 px-2 py-0 mx-1 text-xs font-bold no-underline border-0 rounded w-28 bg-spekterRed text-spekterWhite dark:text-spekterWhite"
                            onClick={() => modalToggleHandler(user._id)}
                          >
                            {t("deactivate")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="relative">
                  <div className="absolute mx-auto mt-40 transform -translate-x-1/2 bg-gray-400 rounded-sm left-[45%] max-w-max">
                    <h5 className="p-10 text-white">{t("verifiedUser")}</h5>
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* mobile inactive users list  */}
        <div className="mb-2 overflow-x-auto lg:hidden">
          <div className="flex justify-end gap-3 pt-3 text-black dark:text-gray-400">
            <div className="flex items-center gap-2">
              <span>{t("mail")}:</span>
              <EyeButton
                open={showEmail}
                onEyeClick={() => setShowEmail((prev) => !prev)}
              />
            </div>
          </div>
          {isloading || isloadingActivateUser ? (
            <div className="absolute flex items-center justify-center w-full h-[90%]">
              <Spinner />
            </div>
          ) : users && users.length > 0 ? (
            users.map((user, index) => (
              <div
                key={user._id}
                className="flex bg-[#e3e4e6] dark:bg-spekterDarkOne text-gray-700 dark:text-gray-400 rounded-5 mt-3 p-3 text-xs"
              >
                <div className=" w-[65%] flex flex-col gap-2 justify-center overflow-y-hidden overflow-x-auto">
                  <div className="flex gap-5">
                    <div className="whitespace-nowrap">{t("name")}:</div>
                    <div>{user.profile ? user.profile.username : ""}</div>
                  </div>
                  <div className="flex gap-[18px]">
                    <div className="whitespace-nowrap">{t("mail")}:</div>
                    <div>
                      {/* {`${
                    user.emails
                      ? user.emails[0].address.split("@")[0].slice(0, -2) + "**"
                      : ""
                  }@********`} */}
                      <ShowHideText
                        text={user.emails ? user.emails[0].address : ""}
                        show={showEmail}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="whitespace-nowrap">{t("adress")}:</div>
                    <div>
                      {user.profile
                        ? user.profile.street + " " + user.profile.streetnumber
                        : ""}
                    </div>
                  </div>
                </div>

                <div className=" w-[35%] flex justify-end items-center">
                  <div className="flex flex-col items-end justify-center gap-2">
                    <button
                      className="w-24 h-8 px-1 py-0 text-xs font-bold no-underline rounded bg-spekterGreenLight dark:bg-spekterGreen text-spekterDarkOne dark:text-gray-900"
                      onClick={() => activateUser(user._id)}
                    >
                      {t("activate")}
                    </button>
                    <button
                      className="w-24 h-8 px-1 py-0 text-xs font-bold no-underline border-0 rounded bg-spekterRed text-spekterWhite dark:text-gray-900"
                      onClick={() => modalToggleHandler(user._id)}
                    >
                      {t("deactivate")}
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="mx-auto mt-40 bg-gray-400 rounded-sm max-w-max">
              <h5 className="p-10 text-white">{t("verifiedUser")}</h5>
            </div>
          )}
        </div>
        <div className="flex justify-center sticky top-[100vh]">
          {users && users.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              paginate={paginate}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserActivation;
