import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import Chart from "react-apexcharts";
import Spinner from "../../UI/Spinner/Spinner";

const SmartsewerChart = ({
  dates,
  sensorName,
  optionalData,
  data,
  setTimeRange,
  duration,
  loading: isLoading,
}) => {
  const { t } = useTranslation("sensors");
  const { thresholds, maintenance } = optionalData || {};

  // const defaultSeries = [
  //   {
  //     name: "",
  //     data: [],
  //   },
  // ];

  // const defaultOptions = {
  //   chart: {
  //     height: 350,
  //     type: "area",
  //     width: "100%",
  //     background: "#141517",
  //     toolbar: {
  //       show: false,
  //       export: {
  //         csv: {
  //           filename: `${2 + 2}`,
  //           columnDelimiter: ",",
  //           headerCategory: "date",
  //           headerValue: "value",
  //           dateFormatter(timestamp) {
  //             return new Date(timestamp).toDateString();
  //           },
  //         },
  //         svg: {
  //           filename: "chart-data",
  //         },
  //         png: {
  //           filename: "chart-data",
  //         },
  //       },
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     // curve: 'smooth' / 'stepline' / 'straight',
  //     curve: "smooth",
  //     width: 2,
  //   },
  //   // background colors...
  //   colors: ["#ffd43b"],
  //   fill: {
  //     // type: 'gradient' / 'solid' / 'pattern' / 'image'
  //     colors: ["#ffd43b", "#f59f00"],
  //     type: ["gradient"],
  //     gradient: {
  //       shade: "dark",
  //       type: "horizontal",
  //       colorStops: [
  //         {
  //           offset: 40,
  //           color: "#ffd43b",
  //           opacity: 0.3,
  //         },
  //         {
  //           offset: 50,
  //           color: "#f59f00",
  //           opacity: 0.3,
  //         },
  //       ],
  //     },
  //   },

  //   // set custom grid
  //   grid: {
  //     show: true,
  //     borderColor: "#343a40",
  //     strokeDashArray: 0,
  //     position: "back",
  //     xaxis: {
  //       lines: {
  //         show: true,
  //       },
  //     },
  //     yaxis: {
  //       lines: {
  //         show: true,
  //       },
  //     },
  //   },

  //   // if chart are not loaded then it will show on chart.
  //   noData: {
  //     text: t("noData"),
  //     align: "center",
  //     verticalAlign: "middle",
  //     offsetX: 0,
  //     offsetY: -10,
  //     style: {
  //       color: "#808080",
  //       fontSize: "14px",
  //       fontFamily: undefined,
  //     },
  //   },

  //   annotations: {
  //     position: "front",
  //     yaxis: [
  //       // warning level 1
  //       {
  //         y: 0,
  //         y2: 0,
  //         strokeDashArray: 0,
  //         borderColor: "#ffd43b",
  //         fillColor: "#ffd43b",
  //         opacity: 1,
  //         offsetX: 0,
  //         offsetY: -3,
  //         width: "100%",
  //         yAxisIndex: 0,
  //         label: {
  //           borderColor: "#c2c2c2",
  //           borderWidth: 0,
  //           borderRadius: 0,
  //           // text:
  //           //   t("warningStageOne") +
  //           //   ` (${optionalData?.thresholds[2]?.level} cm)`,
  //           textAnchor: "start",
  //           position: "right",
  //           offsetX: -185,
  //           offsetY: -8,
  //           style: {
  //             background: "transparent",
  //             color: "#f59f00",
  //             fontSize: "15px",
  //             fontWeight: "bold",
  //             fontFamily: undefined,
  //             cssClass: "apexcharts-yaxis-annotation-label",
  //             padding: {
  //               left: 5,
  //               right: 5,
  //               top: 0,
  //               bottom: 10,
  //             },
  //           },
  //         },
  //       },
  //     ],
  //   },

  //   xaxis: {
  //     opposite: false,
  //     showAlways: false,
  //     // type: "text",
  //     categories: [],
  //     labels: {
  //       show: false,
  //     },
  //     axisTicks: {
  //       show: false,
  //     },
  //     axisBorder: {
  //       show: false,
  //       color: "#78909C",
  //       height: 1,
  //       width: "100%",
  //       offsetX: 0,
  //       offsetY: 0,
  //     },
  //     tooltip: {
  //       enabled: false,
  //     },
  //   },
  //   yaxis: {
  //     min: 0,
  //     max: 0,
  //     opposite: false,
  //     showAlways: false,

  //     labels: {
  //       show: false,
  //       formatter: (value) => value.toFixed(2) + " cm",
  //     },

  //     axisBorder: {
  //       show: false,
  //       color: "#78909C",
  //       offsetX: 0,
  //       offsetY: 0,
  //     },
  //   },
  //   tooltip: {
  //     x: {
  //       format: "dd.MM.yy HH:mm",
  //     },
  //     theme: "dark",
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 450,
  //       options: {
  //         chart: {
  //           height: "250",
  //           width: "95%",
  //           // type: 'bar',
  //         },
  //       },
  //     },
  //   ],
  // };

  // const [options, setOptions] = useState(defaultOptions);
  // const [series, setSeries] = useState(defaultSeries);

  // useEffect(() => {
  //   if (data && dates && thresholds) {
  //     // console.log(data);
  //     // console.log(optionalData);

  //     let validData = [];

  //     let soleDistance = optionalData?.environment?.soleDistance || 0;

  //     data.forEach((value) => {
  //       // convert mm to cm
  //       let cmValue = value / 10;
  //       let data = soleDistance - cmValue;
  //       // check data if it is negative then set it to 0
  //       if (data < 0) {
  //         data = 0;
  //       }
  //       validData.push(data);
  //     });
  //     setSeries([
  //       {
  //         name: sensorName,
  //         data: validData,
  //       },
  //     ]);

  //     // ----set warning stage----
  //     let annotationYaxis = [...defaultOptions.annotations.yaxis];

  //     // warning stage 1
  //     // +-0.5 for warning straight line thickness
  //     annotationYaxis[0].y = thresholds[2]?.level + 0.8;
  //     annotationYaxis[0].y2 = thresholds[2]?.level - 0.5;
  //     annotationYaxis[0].label.text =
  //       t("warningStageOne") + ` (${thresholds[2]?.level} cm)`;

  //     setOptions({
  //       ...defaultOptions,
  //       xaxis: { ...defaultOptions.xaxis, categories: dates },
  //       yaxis: {
  //         ...defaultOptions.yaxis,
  //         max: soleDistance * 1.1, // add 10% to max value
  //       },
  //       annotations: { ...defaultOptions.annotations, yaxis: annotationYaxis },
  //     });
  //   }
  // }, [data, dates, sensorName, thresholds]);

  // const timeframeContent = (
  //   <div className="flex gap-2 bg-[#343A40] p-1 rounded-5">
  //     <div
  //       onClick={() => setTimeRange(1440)}
  //       className={`h-[22px] w-[71px] flex justify-center items-center duration-300
  //   rounded-5 font-[700] text-[11px] cursor-pointer hover:opacity-75
  // ${duration === 1440 && "bg-spekterDarkOne"}`}
  //     >
  //       {t("oneDaySelection")}
  //     </div>
  //     <div
  //       onClick={() => setTimeRange(1440 * 3)}
  //       className={`h-[22px] w-[71px] flex justify-center items-center duration-300
  //   rounded-5 font-[700] text-[11px] cursor-pointer hover:opacity-75
  // ${duration === 4320 && "bg-spekterDarkOne"}`}
  //     >
  //       {t("threeDaySelection")}
  //     </div>
  //     <div
  //       onClick={() => setTimeRange(1440 * 7)}
  //       className={`h-[22px] w-[71px] flex justify-center items-center duration-300
  //   rounded-5 font-[700] text-[11px] cursor-pointer hover:opacity-75
  // ${duration === 10080 && "bg-spekterDarkOne"}`}
  //     >
  //       {t("sevenDaySelection")}
  //     </div>
  //   </div>
  // );

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex flex-col items-center sm:flex-row sm:justify-between text-spekterWhite">
        <div className="flex items-start justify-center my-0 mx-2.5 gap-5">
          <img
            src={`/images/${
              thresholds && thresholds[0]?.overflow
                ? "Kanal_aktiv.svg"
                : "Kanal_inaktiv.svg"
              // ? "sewer-circle-active.png"
              // : "sewer-circle-inactive.png"
            }`}
            alt="gfl icon"
            className="h-8 m-0"
          />
          <h5 className="mt-1">{sensorName}</h5>
        </div>

        {/* TODO: the timeframe content is commented out for now , we might need this future(with chart) , don't remove*/}

        {/* <div className="mt-2">{timeframeContent}</div> */}
      </div>

      <div
        className={`flex flex-col items-center ${
          maintenance ? "justify-center m-5" : "justify-between"
        } p-2 sm:flex-row`}
      >
        {thresholds && (
          <div className="flex flex-col w-full gap-2 pt-10 m-auto text-center sm:gap-10 sm:w-1/2">
            <div className="">
              <h5 className="p-0 m-0 text-spekterWhite">{t("kdwState")}</h5>
              {/* <h4 className="p-0 m-0 text-spekterGreenLight">No Overflow</h4> */}
              {thresholds[0].overflow === false ? (
                <h5 className="p-0 m-0 text-spekterGreenLight">
                  {t("kdwNoOverflow")}
                </h5>
              ) : (
                <h5 className="p-0 m-0 text-spekterRed"> {t("kdwOverflow")}</h5>
              )}
            </div>
            <div className="">
              <h5 className="p-0 m-0 text-spekterWhite whitespace-nowrap">
                {t("kdwStateManhole")}
              </h5>
              {/* <h4 className="p-0 m-0 text-spekterGreenLight">Closed</h4> */}
              {thresholds[1].movement === false ? (
                <h5 className="p-0 m-0 text-spekterGreenLight">
                  {" "}
                  {t("kdwClosed")}
                </h5>
              ) : (
                <h5 className="p-0 m-0 text-spekterRed">{t("kdwOpen")}</h5>
              )}
            </div>
          </div>
        )}

        {/* TODO: the chart is commented out for now , we might need this future , don't remove*/}

        {/* {!maintenance && series[0]?.data.length > 1 && (
          <div className="w-full m-auto text-lg text-center sm:w-3/4 text-spekterWhite sm:block">
            <Chart
              className=""
              options={options}
              series={series}
              type="area"
              height={230}
              width={"100%"}
            />
          </div>
        )} */}

        {/* <div className="hidden w-1/2 m-auto text-lg text-center text-spekterWhite sm:block">
          <p className="p-0 m-0 text-spekterWhite"> {t("kdwStateTextOne")}</p>
          <p className="p-0 m-0 text-spekterWhite"> {t("kdwStateTextTwo")} </p>
          <p className="p-0 m-0 text-spekterWhite"> {t("kdwStateTextThree")}</p>
        </div> */}
      </div>
    </div>
  );
};

export default SmartsewerChart;
