import React from "react";
import { useTranslation } from "react-i18next";
const LogoutModal = ({ setShowLogoutModal, logoutClicked }) => {
  const { t } = useTranslation("modal");
  return (
    <div className="fixed top-0 left-0 z-[501] flex items-center justify-center w-full h-full overflow-auto bg-opacity-30 backdrop-blur-sm">
      <div className="px-12 py-4 shadow-md bg-barBG dark:bg-boxBG rounded-10 max-w-[90%]">
        <p className="font-semibold text-gray-700 select-none dark:text-gray-100 bg-barBG dark:bg-boxBG">
          {t("logOutQuestion")}
        </p>
        <p className="text-gray-700 select-none dark:text-gray-100 text-md">
          {t("logOutMessage")}
        </p>
        <div className="flex w-full gap-16 pt-2">
          <button
            onClick={() => setShowLogoutModal(false)}
            className="border-[1px] border-gray-400 w-24 rounded-5 py-1 text-base text-gray-600 dark:text-gray-400 hover:opacity-75 duration-100"
          >
            {t("logOutButtonCancel")}
          </button>
          <button
            onClick={logoutClicked}
            className="w-24 py-1 text-base duration-100 bg-spekterRed rounded-5 text-spekterWhite dark:text-gray-900 hover:opacity-75"
          >
            {t("logOutButtonConfirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
