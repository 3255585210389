import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DeleteOptionConfirmModal = ({
  setShowDeleteOptionModal,
  deletePodMetaDataHandler,
}) => {
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const { t } = useTranslation("realtimeMap"); // i18n namespace for translation
  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={() => setShowDeleteOptionModal(false)}
    >
      <div
        className={`flex flex-col items-center justify-center w-[400px] gap-4 h-[200px] rounded-[18px] ${
          isThemeDark ? "bg-black/70" : "bg-white"
        } backdrop-blur-[61px]`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="px-4 flex flex-col items-center justify-center gap-5 text-black dark:text-white">
          <span className="text-xl font-bold">{t("deleteOption")}</span>
          <span className="text-[15px] font-normal">
            {t("areYouSureYouWant")}
          </span>
        </div>
        <div className="flex flex-row items-center justify-end w-full gap-5 px-[4%] pt-[6%]">
          <button
            onClick={() => setShowDeleteOptionModal(false)}
            className="px-[23px] py-[6px] font-semibold text-[#fff] rounded-[6px] bg-[#04CF00]"
          >
            {t("cancel")}
          </button>
          <button
            onClick={deletePodMetaDataHandler}
            className="px-[23px] py-[6px] font-semibold text-[#fff] rounded-[6px] bg-[#FF0000]"
          >
            {t("delete")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteOptionConfirmModal;
