import React, { useState, lazy, Suspense } from "react";
import NormalView from "./NormalView/NormalView";
import ObserverView from "./ObserverView/ObserverView";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useSelector } from "react-redux";
import useObserver from "../../hooks/useObserver";
const DangerLevels = lazy(() => import("./DangerLevels/DangerLevels"));

const MainView = () => {
  const showDangerPage = useSelector((state) => state.dangerPage.show);
  const isObserver = useObserver();

  const loader = (
    <div className="flex items-center justify-center w-full h-screen">
      <Spinner />
    </div>
  );

  // return isObserver ? <ObserverView /> : <NormalView />;
  return (
    <>
      {showDangerPage && (
        <Suspense fallback={loader}>
          <DangerLevels />
        </Suspense>
      )}
      {isObserver ? <ObserverView /> : <NormalView />}
    </>
  );
};

export default MainView;
