import React, { useEffect, useState, Suspense, lazy } from "react";
import { useParams } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import * as actions from "../../../redux/actions";
import mapDarkFeature from "../../../constants/mapDarkFeature.json";
import mapLightFeature from "../../../constants/mapLightFeature.json";
import ChartModal from "../../../components/ChartModal/ChartModal";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../Controls/Controls";
import AlarmInfoSideBar from "../CatchmentAlarmInfo/Sidebar";
import ReactTooltip from "react-tooltip";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { sensorTypes } from "../../../constants/sensorTypes";
import { useClickAway } from "@uidotdev/usehooks";
import { useSearchParams } from "react-router-dom";
import { calculateNewLatLng } from "../../../shared/Utility";
import useMobileView from "../../../hooks/useMobileView";
import * as toast from "../../../components/UI/Toaster/index";
import PublicHeader from "../../../components/PublicView/PublicHeader";
import PublicLeftSidebar from "../../../components/PublicView/PublicLeftSidebar";
import styles from "./PublicView.module.css";

const DangerLevels = lazy(() => import("../DangerLevels/DangerLevels"));

// init. colors
const catchmentColors = {
  level1: "#24D160",
  level3: "#F3EA03",
  level4: "#FD1001",
  level5: "#8A03F3",
  inactive: "#A9A9A9",
};

const edgeColors = {
  level1: "#51FC42",
  level3: "#B9FC00",
  level4: "#FF0000",
  level5: "#8200FF",
  inactive: "#A9A9A9",
};

let polygones = [];
let markers = [];

const PublicView = (props) => {
  const { id: ags } = useParams();

  const isMobileView = useMobileView();
  let [searchParams, setSearchParams] = useSearchParams();
  const [chartModalOpen, setChartModalOpen] = useState(false);
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const [isAlarmInfoOpen, setIsAlarmInfoOpen] = useState(false);
  const [alarmData, setAlarmData] = useState(undefined);

  const [map, setMap] = useState();
  const [maps, setMaps] = useState();

  const [latlng, setLatLng] = useState({});
  const [zoom, setZoom] = useState();

  // catchment
  const [onHoverPolygonName, setOnHoverPolygonName] = useState("");
  const [timeRange, setTimeRange] = useState(1440);
  const [sensorAttr, setSensorAttr] = useState(null);
  const dispatch = useDispatch();
  // const { sensorIdFromNotification } = useParams();

  const sensorIdFromNotification = searchParams.get("_id");
  const notificationType = searchParams.get("notificationType");
  const catchmentLat = searchParams.get("catchmentLat");
  const catchmentLng = searchParams.get("catchmentLng");

  const showDangerPage = useSelector((state) => state.dangerPage.show);
  const customerData = useSelector((state) => state.customer.data);
  const customerError = useSelector((state) => state.customer.error);
  const catchmentData = useSelector((state) => state.catchment.data);

  const gflData = useSelector((state) => state.gfl.data);
  const smartriverData = useSelector((state) => state.smartriver.data);
  const smartsewerData = useSelector((state) => state.smartsewer.data);
  const isAddNewPod = useSelector((state) => state.pod.isAddNewPod);

  const [showResetButton, setShowResetButton] = useState(false);

  // marker related states
  const [addressCoordinate, setAddressCoordinate] = useState();
  const [existingMarker, setExistingMarker] = useState();

  // console.log(catchmentLat, catchmentLng);

  // TODO: this will be dynamic
  // const timeframe = "1440";

  const markerClicked = (marker, id, name, sensorType, location) => {
    marker.addListener("click", () => {
      let optionalData = {};
      // write thresholds logic

      // lookup smartRiver data
      if (sensorType === sensorTypes.SMARTRIVER) {
        smartriverData.forEach((data) => {
          if (data["_id"] === id) {
            optionalData.soleCorrectionValue = data["soleCorrectionValue"];
            optionalData.thresholds = data["thresholds"];
            optionalData.maintenance = data["maintenance"];
          }
        });
      }
      // lookup gfl data
      else if (sensorType === sensorTypes.GFL) {
        gflData.forEach((data) => {
          if (data["_id"] === id) {
            optionalData.thresholds = data["thresholds"];
            optionalData.maintenance = data["maintenance"];
          }
        });
      }

      // lookup smart-sewer data
      if (sensorType === sensorTypes.SMARTSEWER) {
        smartsewerData.forEach((data) => {
          if (data["_id"] === id) {
            optionalData.thresholds = data["thresholds"];
            optionalData.maintenance = data["maintenance"];
            optionalData.environment = data["environment"];
          }
        });
      }
      // TODO:here timeframe will be dynamic
      // dispatch(
      //   actions.chartAction(sensorType, id, timeRange, name, optionalData)
      // );
      setSensorAttr({ sensorType, id, name, optionalData });
      setChartModalOpen(true);
      // TODO: set setTimeRange to 1440
      setTimeRange(1440);
    });
  };

  const onGoogleApiLoaded = (map, maps) => {
    setMap(map);
    setMaps(maps);

    // Set maximum and minimum zoom levels
    map.setOptions({
      // maxZoom: 15,
      minZoom: 10,
    });
  };

  useEffect(() => {
    if (sensorAttr) {
      const { sensorType, id, name, optionalData } = sensorAttr;

      dispatch(
        actions.chartAction(sensorType, id, timeRange, name, optionalData)
      );
    }
    // return () => {
    //   // second;
    // };
  }, [timeRange, sensorAttr]);

  //--------------------------
  // Render catchments|polygons
  useEffect(() => {
    if (catchmentData && map && maps) {
      //------remove existing polygon-----
      // MOTO: fix catchment|polygon duplicate issue after catchment overview reload.
      if (polygones) {
        polygones.forEach((p) => {
          p.setMap(null);
        });
        polygones = [];
      }

      catchmentData.forEach((element) => {
        let temptriangleCoords = [];

        element.shapeCoordinates.forEach((element) => {
          let temp = { lat: element[0], lng: element[1] };
          temptriangleCoords.push(temp);
        });

        let polygon = new maps.Polygon({
          paths: temptriangleCoords,
          strokeColor: element.inactive
            ? edgeColors.inactive
            : edgeColors[element.warningLevel],
          strokeOpacity: 1,
          strokeWeight: 3,
          fillColor: element.inactive
            ? catchmentColors.inactive
            : catchmentColors[element.warningLevel],
          fillOpacity: 0.3,
        });

        polygones.push(polygon);

        maps.event.addListener(polygon, "mouseover", function (e) {
          polygon.setOptions({ fillColor: "#ffffff", fillOpacity: 0.5 });
          setOnHoverPolygonName(element?.name);
        });

        maps.event.addListener(polygon, "mouseout", function (e) {
          polygon.setOptions({
            fillColor: element.inactive
              ? catchmentColors.inactive
              : catchmentColors[element.warningLevel],
            fillOpacity: 0.3,
          });
          setOnHoverPolygonName("");
        });

        //onclick
        maps.event.addListener(polygon, "click", function (e) {
          setIsAlarmInfoOpen(true);
          setAlarmData(element);
        });

        polygon.setMap(map);
      });
    }
  }, [catchmentData, map, maps, isAddNewPod]);

  // -----------
  // Render smartRiver marker
  useEffect(() => {
    if (smartriverData && map && maps) {
      const smartriverIconInactive = {
        url: "/images/Pegel_inaktiv.svg",
        scaledSize: new maps.Size(25, 25),
      };
      const smartriverIconActive = {
        url: "/images/Pegel_aktiv.svg",
        scaledSize: new maps.Size(25, 25),
      };
      const smartriverIconWarning = {
        url: "/images/wartung_icon.svg",
        scaledSize: new maps.Size(30, 30),
      };

      Array.isArray(smartriverData) &&
        smartriverData.forEach((position) => {
          let timeDiffHours = 0;

          if (position.measurements?.length > 0) {
            const lastDate = new Date(
              position.measurements[position.measurements.length - 1].timestamp
            );

            const currentDate = new Date();

            const timeDiff = currentDate - lastDate;

            // timeDiffHours = Math.floor(timeDiff / (1000 * 60 * 60));
            timeDiffHours = timeDiff / (1000 * 60 * 60);
          }

          const smartriverMarker = new maps.Marker({
            position: new maps.LatLng(
              position.location.lat,
              position.location.lng
            ),

            // map: map,
            title: position.name ? `${position.name}` : "Smart-River",
            icon:
              position.maintenance || timeDiffHours > 6
                ? smartriverIconWarning
                : position.thresholds[0].active
                ? smartriverIconActive
                : smartriverIconInactive,
          });

          // console.log(position);

          smartriverMarker.setMap(map);
          markers.push(smartriverMarker);
          markerClicked(
            smartriverMarker,
            position._id,
            position.name,
            sensorTypes.SMARTRIVER,
            position.location
          );
        });
    }
  }, [smartriverData, map, maps]);

  // -----------
  // Render gfl marker
  useEffect(() => {
    if (gflData && map && maps) {
      const gflIconInactive = {
        url: "/images/Pegel_inaktiv.svg",
        // url: "/images/floodlevel-circle.png",
        scaledSize: new maps.Size(25, 25),
      };
      const gflIconActive = {
        url: "/images/Pegel_aktiv.svg",
        // url: "/images/floodlevel-circle-active.png",
        scaledSize: new maps.Size(25, 25),
      };
      const gflIconWarning = {
        url: "/images/wartung_icon.svg",
        scaledSize: new maps.Size(30, 30),
      };
      // icons8-loading-circle.gif
      Array.isArray(gflData) &&
        gflData.forEach((position) => {
          const gflMarker = new maps.Marker({
            position: new maps.LatLng(
              position.location.lat,
              position.location.lng
            ),
            // map: map,
            title: position.name ? `${position.name}` : "GFL",
            icon: position.maintenance
              ? gflIconWarning
              : position.thresholds[0].active
              ? gflIconActive
              : gflIconInactive,
          });
          gflMarker.setMap(map);
          markers.push(gflMarker);
          markerClicked(
            gflMarker,
            position._id,
            position.name,
            sensorTypes.GFL,
            position.location
          );
        });
    }
  }, [gflData, map, maps]);

  // customer effect
  useEffect(() => {
    if (customerData != null) {
      setLatLng({
        lat: customerData.coordinatesCenter[0],
        lng: customerData.coordinatesCenter[1],
      });
      setZoom(customerData.coordinatesCenter[2]);
    }
  }, [customerData]);

  // fetch all data
  useEffect(() => {
    if (!ags) return;
    dispatch(actions.customerAction(ags));
    dispatch(actions.catchment(ags));
    dispatch(actions.gfl({ ags }));
    dispatch(actions.smartriver({ ags }));
  }, [dispatch]);

  useEffect(() => {
    if (notificationType === "rain") {
      setLatLng({
        lat: parseFloat(catchmentLat),
        lng: parseFloat(catchmentLng),
      });
      setZoom(14);
      setTimeout(() => {
        setSearchParams({}); // clear search params
      }, 3000);
    }
    // smartriverdata
    if (
      notificationType === "sensor" &&
      smartriverData &&
      smartriverData.length > 0
    ) {
      let selectedSensor;
      let optionalData = {};
      smartriverData &&
        smartriverData?.forEach((data) => {
          // console.log("data", data);
          if (data["_id"] === sensorIdFromNotification) {
            selectedSensor = data;
            optionalData.soleCorrectionValue = data["soleCorrectionValue"];
            optionalData.thresholds = data["thresholds"];
            optionalData.maintenance = data["maintenance"];
          }
        });

      if (selectedSensor) {
        setSensorAttr({
          sensorType: "smartriverdata",
          id: selectedSensor._id,
          name: selectedSensor.name,
          optionalData,
        });
        setTimeout(() => {
          setChartModalOpen(true);
        }, 1000);
        setTimeRange(1440);

        // setting center lat-lng 100 meters down to achieve better ux
        const newLatLng = calculateNewLatLng(
          selectedSensor.location?.lat,
          selectedSensor.location?.lng
        );
        setLatLng({
          lat: newLatLng.lat,
          lng: newLatLng.lng,
        });
        setZoom(17);
        setTimeout(() => {
          setSearchParams({}); // clear search params
        }, 3000);
      }
    }
  }, [notificationType, sensorIdFromNotification, smartriverData]);

  // Render address marker
  useEffect(() => {
    if (addressCoordinate && map && maps) {
      if (existingMarker) {
        markers
          .filter((item) => item.title === "Address-Marker")
          .forEach((marker) => marker.setMap(null));
      }

      const locationIcon = {
        url: "/images/address_marker.svg",
        scaledSize: new maps.Size(30, 30),
      };

      const addressMarker = new maps.Marker({
        position: new maps.LatLng(addressCoordinate[0], addressCoordinate[1]),
        title: "Address-Marker",
        icon: locationIcon,
      });
      addressMarker.setMap(map);
      markers.push(addressMarker);

      setLatLng({
        lat: addressCoordinate[0],
        lng: addressCoordinate[1],
      });
      setZoom(12);
      setExistingMarker(true);
    }
  }, [addressCoordinate, map, maps]);

  const ref = useClickAway(() => {
    setChartModalOpen(false);
  });

  const handleMapChange = (e) => {
    setLatLng({ lat: e.center.lat, lng: e.center.lng });
    setZoom(e.zoom);
    let showMapResetButton;
    if (
      e.zoom !== customerData.coordinatesCenter[2] &&
      e.center.lat !== customerData.coordinatesCenter[0] &&
      e.center.lng !== customerData.coordinatesCenter[1]
    ) {
      showMapResetButton = true;
    } else {
      showMapResetButton = false;
    }

    setShowResetButton(showMapResetButton);
    // setZoom(e.zoom);
    // setLatLng({
    //   lat: e.center.lat,
    //   lng: e.center.lng,
    // });
  };

  const handleNormalMapReset = () => {
    setLatLng({
      lat: customerData.coordinatesCenter[0],
      lng: customerData.coordinatesCenter[1],
    });
    setZoom(customerData.coordinatesCenter[2]);
  };

  const loader = (
    <div className="flex items-center justify-center w-full h-screen">
      <Spinner />
    </div>
  );

  if (customerError) {
    toast.error("AGS Not Found");
  }

  return (
    <div>
      <PublicLeftSidebar />
      <PublicHeader />
      <div className={styles["gm-style"]}>
        {/* <CatchmentsOverview
          items={catchmentData && catchmentData}
          isLoading={isLoading}
        /> */}
        {showDangerPage && (
          <Suspense fallback={loader}>
            <DangerLevels />
          </Suspense>
        )}
        <AlarmInfoSideBar
          open={isAlarmInfoOpen}
          setOpen={setIsAlarmInfoOpen}
          data={alarmData}
        />
        <Controls
          map={map}
          maps={maps}
          handleNormalMapReset={handleNormalMapReset}
          showResetButton={showResetButton}
          setAddressCoordinate={setAddressCoordinate}
        />
        <div
          data-tip=""
          style={{ zIndex: "100", height: "100vh", width: "100vw" }}
        >
          {customerData ? (
            <GoogleMapReact
              onChange={(e) => handleMapChange(e)}
              bootstrapURLKeys={{
                loadingElement: <Spinner />, // Set your custom loader component here
              }}
              options={{
                // styles: isThemeDark ? mapDarkFeature : mapLightFeature,
                styles: mapDarkFeature,
                fullscreenControl: false,
                zoomControl: false,
                gestureHandling: "greedy",
              }}
              // defaultZoom={zoom}
              defaultCenter={{
                lat: 48.56692657664802,
                lng: 13.430500178529469,
              }}
              center={latlng}
              defaultZoom={8}
              zoom={zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) =>
                onGoogleApiLoaded(map, maps)
              }
            ></GoogleMapReact>
          ) : (
            <div className="flex items-center justify-center h-full">
              <Spinner />
            </div>
          )}
          {/* TODO: Will need this for future versions*/}
          {!isMobileView && <ReactTooltip>{onHoverPolygonName}</ReactTooltip>}
        </div>
      </div>
      <div ref={ref}>
        <ChartModal
          setTimeRange={setTimeRange}
          duration={timeRange}
          open={chartModalOpen}
          onCloseModal={() => setChartModalOpen(!chartModalOpen)}
        />
      </div>
    </div>
  );
};

export default PublicView;
