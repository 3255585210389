import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { FaChevronDown } from "react-icons/fa";
import Switch from "../../../components/UI/Switch/Switch";
import * as actions from "../../../redux/actions/index";
import Axios from "../../../services/NetworkService";
import axios from "axios";
import * as toast from "../../../components/UI/Toaster";
import {
  WEBVIEW_COMMENTS_SUCCESS,
  WEBVIEW_COMMENTS_FAIL,
} from "../../../redux/actions/actionTypes";
import { useTranslation } from "react-i18next";

const fileUploadUrl = `/webviewercomments/getUploadUrl`;
// const testToken =
// "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MWZiNjI2ZDZlZGVjMGEzY2YyZWQ0ZmYiLCJpYXQiOjE2NDM4NjQ3MDksImV4cCI6MTY0NDQ2OTUwOX0.BB6dBd18CVszaj0GKVBL7qs9tfWmUFMWkfRl1qGvVYM";

const baseStyle = {
  // flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // height: '100%',
  justifyContent: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#0d0d0f",
  backgroundColor: "#0d0d0f",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  // height: '60px',
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  overflowX: "auto",
  // flexWrap: 'wrap',
  // marginTop: 16
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 4,
  marginRight: 4,
  width: 50,
  height: 50,
  padding: 2,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const Comments = () => {
  //language package
  const { t } = useTranslation("webViewer");
  const showForm = false;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [myFiles, setMyFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const dispatch = useDispatch();
  const showComments = useSelector(
    (state) => state.webviewComments.showComments
  );
  const newCommentStatus = useSelector(
    (state) => state.webviewComments.newCommentStatus
  );
  const olLocalFeature = useSelector((state) => state.localOLFeature.feature);

  const nameChangeHandler = (event) => {
    let value = event.target.value.trim();
    setName(value);
  };

  const descriptionChangeHandler = (event) => {
    let value = event.target.value.trim();
    setDescription(value);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ["image/*", "application/pdf", ".zip"],
    onDrop: (acceptedFiles) => {
      let newImages = myFiles.concat(acceptedFiles);
      if (newImages.length > 5) {
        toast.error(t("maximum5Files"));
      } else {
        setMyFiles(
          newImages.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      }
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAll = () => {
    setMyFiles([]);
  };

  const thumbs = myFiles.map((file) => (
    <div>
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img src={file.preview} style={img} alt="" />
        </div>
      </div>
      <img
        className="w-6 mx-auto mb-4 cursor-pointer"
        src="https://img.icons8.com/cute-clipart/35/000000/delete-forever.png"
        alt="dlt_icon"
        onClick={removeFile(file)}
      />
    </div>
  ));

  const submitMarker = async () => {
    if (name === "") {
      toast.error(t("provideCommentsName"));
    } else if (description === "") {
      toast.error(t("provideDescription"));
    } else {
      setLoading(true);
      // get image upload url
      let fileUrl = [];
      let fileNames = [];

      if (myFiles.length > 0) {
        for (let i = 0; i < myFiles.length; i++) {
          fileNames.push(myFiles[i].name);
        }

        await Axios.post(
          fileUploadUrl,
          {
            fileNames: fileNames,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        ).then(async (response) => {
          for (let i = 0; i < myFiles.length; i++) {
            await axios.put(response.data[i].uploadUrl, myFiles[i]);
            fileUrl.push(response.data[i].fileName);
          }
        });
      }

      // let geoJSONFeature = toGeoJOSN(olLocalFeature);

      const response = dispatch(
        actions.addWebViewComment(name, description, olLocalFeature, fileUrl)
      );
      response.then(function (result) {
        if (result.type === WEBVIEW_COMMENTS_SUCCESS) {
          toast.success(t("markerAddedSuccessfully"));
          setLoading(false);
          resetAllState();
        }

        if (result.type === WEBVIEW_COMMENTS_FAIL) {
          toast.error(t("failedAddMarker"));
          setLoading(false);
        }
      });
    }
  };

  const resetAllState = () => {
    setName("");
    setDescription("");
    setMyFiles([]);
    setLoading(false);
    dispatch(actions.removeLocalOLFeature());
    dispatch(actions.updateNewCmntStatus(false));
  };

  return (
    <div>
      <div className="py-8 px-3.5 mb-5 box-border block text-spekterWhite bg-spekterDarkThree shadow-container rounded-md">
        <div
          className="flex justify-between cursor-pointer hover:text-blue-500"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="font-bold text-lg mr-2.5">Comments</div>
          <div className="mt-1">{<FaChevronDown />}</div>
        </div>

        {expanded && (
          <div>
            <div className="flex flex-col items-center p-3 m-1 opacity-60">
              <img
                src="/images/mouse-double-click-48-white.svg"
                alt="mouse double click"
                height="40px"
                width="40px"
              />
              <span>{t("commentsText")}</span>
            </div>
            <div className="p-2.5">
              <div className={`${showForm ? "hidden" : "block"}`}>
                <ul className="flex items-center gap-8 mb-5">
                  <li className="relative top-1">
                    <Switch
                      value={showComments}
                      bgCheckedColor="checked:bg-spekterGreen"
                      onCheck={() => {
                        dispatch(actions.toggleComments());
                      }}
                    />
                  </li>
                  <li>{t("showComments")}</li>
                </ul>

                <ul>
                  <li className="pb-3 text-lg">{t("addComments")}</li>
                  <li>
                    {!newCommentStatus ? (
                      <button
                        className="flex items-center h-5 p-3 mt-2 font-semibold text-gray-800 rounded bg-spekterGreen"
                        onClick={() => {
                          dispatch(actions.updateNewCmntStatus(true));
                        }}
                      >
                        {t("addCommentsButton")}
                      </button>
                    ) : null}
                    {newCommentStatus ? (
                      <button
                        className="flex items-center h-5 p-3 mt-2 font-semibold text-gray-800 rounded bg-spekterGreen"
                        onClick={() =>
                          dispatch(actions.updateNewCmntStatus(false))
                        }
                      >
                        {t("cancel")}
                      </button>
                    ) : null}
                  </li>
                  <li>
                    {newCommentStatus && !olLocalFeature ? (
                      <div className="items-center p-3 m-1 opacity-60">
                        {t("commentInfo")}
                      </div>
                    ) : null}
                  </li>
                </ul>
                {
                  // title,description and attachment will show based on drawn polygon
                  !olLocalFeature ? null : (
                    <section>
                      <input
                        className="w-full px-3 py-2 my-2 rounded bg-inputBoxBG focus:outline-none"
                        type="text"
                        placeholder="Name"
                        onChange={nameChangeHandler}
                      />
                      <textarea
                        className="w-full px-3 py-2 my-2 rounded bg-inputBoxBG focus:outline-none"
                        placeholder="Description"
                        cols="30"
                        rows="3"
                        onChange={descriptionChangeHandler}
                      />
                      {/* image drop */}
                      <div className="w-full px-3 py-2 border border-dashed rounded border-grey">
                        <br />
                        <div className="">
                          <div className="" {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <p className="hidden my-auto text-sm text-center cursor-pointer md:block">
                              {t("dropFile")}
                            </p>
                          </div>
                          <div>
                            <span
                              className="text-sm text-gray-300"
                              style={{ fontSize: "12px" }}
                            >
                              Accept:{" "}
                            </span>
                            <label
                              className="text-center text-gray-300"
                              style={{ fontSize: "11px" }}
                            >
                              {" "}
                              {t("possibleFileFormats")}
                            </label>
                          </div>
                          <br />
                          <aside
                            className="flex justify-center"
                            style={thumbsContainer}
                          >
                            {thumbs}
                          </aside>
                          <div className="flex justify-center">
                            {thumbs.length > 1 && (
                              <button
                                className="px-4 mt-2 bg-red-500 rounded hover:bg-red-700 text-spekterWhite"
                                onClick={removeAll}
                              >
                                {t("removeAll")}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>

                      <button
                        className="mx-auto w-9/12 block my-3 px-2 py-2.5 font-semibold text-gray-800 rounded bg-spekterGreenLight dark:bg-spekterGreen"
                        onClick={submitMarker}
                      >
                        {loading ? (
                          <div className="flex justify-center">
                            <Loader
                              type="Bars"
                              color="white"
                              height={25}
                              width={25}
                            />
                          </div>
                        ) : (
                          <div>{t("save")}</div>
                        )}
                      </button>
                    </section>
                  )
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Comments;
