import React from "react";
import { useSelector } from "react-redux";
import { CgClose } from "react-icons/cg";
import { useTranslation } from "react-i18next";

const AnalyticsModal = ({ setShowExplanationModal }) => {
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
    //Translation package
    const { t, i18n } = useTranslation("sensorManager");

  return (
    <div className="fixed z-[20000] flex left-0 top-0 w-full h-full overflow-auto backdrop-blur-sm duration-300">
      <div
        className={`relative bg-spekterWhite dark:bg-boxBG m-auto flex flex-col justify-between rounded-10 text-gray-900 dark:text-gray-100
                          ${
                            isThemeDark ? "" : "shadow-modal"
                          } py-3 px-5 max-w-[95%] w-[1174px]`}
      >
        <div
          onClick={() => setShowExplanationModal(false)}
          className="absolute duration-300 cursor-pointer right-2 top-2 hover:opacity-75"
        >
          <CgClose size={24} />
        </div>
        <h3>{t("analytic")}</h3>
        <p className="pt-3">
        {t("analyticOverviewOne")}
        </p>
        <p>{t("analyticOverviewTwo")}:</p>
        <ul>
          <li>
            <strong>{t("drawComparisons")}:</strong> {t("drawComparisonsDescription")}
          </li>
          <li className="pt-1">
            <strong>{t("identifyTrends")}:</strong> {t("identifyTrendsDescription")}
          </li>
          <li className="pt-1">
            <strong>{t("makeInformedDecisions")}:</strong>{t("makeInformedDecisionsDescription")}
          </li>
        </ul>
        <p>
        {t("diveDeepInto")}
        </p>
      </div>
    </div>
  );
};

export default AnalyticsModal;
