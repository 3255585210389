export const customStylesLight = {
  control: (base, state) => ({
    ...base,
    //from tailwind.config.js: spekterDarkOne color is used
    background: "#c9c9c9",
    border: 0,
    boxShadow: "none",
    color: "#333333",
    fontSize: "15px",
    borderRadius: "6px",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    right: 0,
    background: "#333",
  }),
  menuList: (base, state) => ({
    ...base,
    padding: 0,
    background: "#fff",
    color: "gray",
  }),
  loadingIndicator: (base, state) => ({
    ...base,
    color: "#333333",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#333",
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    color: "#333",
  }),
  clearIndicator: (base, state) => ({
    ...base,
    color: "#333",
  }),
};
export const customStylesDark = {
  input: (base, state) => ({
    ...base,
    color: "#fff",
  }),
  singleValue: (base, state) => ({
    ...base,
    color: "#fff",
  }),
  control: (base, state) => ({
    ...base,
    //from tailwind.config.js: spekterDarkOne color is used
    background: "#333",
    border: 0,
    boxShadow: "none",
    color: "red",
    fontSize: "15px",
    borderRadius: "6px",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    right: 0,
    background: "#333",
  }),
  menuList: (base, state) => ({
    ...base,
    padding: 0,
    borderRadius: "6px",
    background: "rgba(0, 0, 0, 0.69)",
    backdropFilter: "blur(4.5px)",
    color: "#818181",
  }),
};
