import React, { useState } from "react";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassSentOTP } from "../../../redux/actions/index";
import { STEPS } from "./STEPS";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import * as toast from "../../UI/Toaster";

const RequestOTP = ({
  setCurrentStep,
  email,
  setEmail,
  setResendCounter,
  setOtp,
}) => {
  //Language package
  const { t, i18n } = useTranslation("settings");
  //Theme selector
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onEmailChange = (e) => {
    setEmail(e.target.value);

    if (validator.isEmail(e.target.value) === false) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  };

  const onSubmitEmail = () => {
    dispatch(forgetPassSentOTP(email)).then((response) => {
      if (response.error) {
        if (response.error === 404) {
          toast.error(t("emailDoesNotExist"));
        } else if (response.data.message.includes(t("requestNewOTP"))) {
          toast.error(response?.data?.message);
          setCurrentStep(STEPS.RESEND_OTP);
          setResendCounter(response.data.timer);
        } else {
          toast.error(response?.data?.message || t("somethingWentWrong"));
        }
      } else if (response.data) {
        toast.success(response?.data?.message);
        setCurrentStep(STEPS.RESEND_OTP);
        setResendCounter(response.data.timer);
        setOtp("");
      }
    });
  };
  return (
    <div
      className={`py-10 rounded-[18px] min-h-[480px] duration-300 p-[39px] sm:p-[47px] w-full lg:w-[500px]
    ${
      isThemeDark
        ? "bg-spekterDarkSix text-gray-100"
        : "text-gray-700 bg-spekterWhite containerShadowLight"
    }  `}
    >
      <p className="text-[21px] sm:text-[24px] pb-2 font-bold sm:text-center lg:text-left duration-300">
        {t("forgotPassword")}
      </p>
      <p className="text-[15px] sm:text-center lg:text-left duration-300">
        {t("resetPwText")}
      </p>

      <div className="relative pt-24">
        <div className="w-20 py-2 font-bold dark:text-spekterWhite text-[15px]">
          {t("mailSolo")}
        </div>
        {email && (
          <img
            className="absolute -right-5 top-[150px] w-4 h-4"
            src={!isEmailValid ? "/images/wrong.svg" : "/images/right.svg"}
            alt=""
          />
        )}
        <input
          className={`rounded-[6px] py-[9px] w-full px-3 text-[15px] focus:outline-none
           ${
             email && !isEmailValid
               ? "border-spekterRed border-2"
               : "border-transparent"
           } ${
            isThemeDark
              ? "bg-[#333] text-[#818181]"
              : "bg-[#C9C9C9] text-[#333]"
          }`}
          type="email"
          value={email}
          onChange={onEmailChange}
        />
        <div className="h-10">
          {!email && (
            <p className={`text-[11px] pt-1`}>{t("registeredEmail")}</p>
          )}
          {email && !isEmailValid && (
            <p className={`text-[11px] pt-1 text-spekterRed`}>
              {t("invalidMail")}
            </p>
          )}
        </div>
        <button
          className="bg-spekterGreenSuccess rounded-[6px] w-full py-[15px] font-bold dark:text-spekterDarkSix text-spekterWhite mt-2 mb-2 cursor-pointer"
          disabled={email && isEmailValid === true ? false : true}
          onClick={onSubmitEmail}
        >
          {t("requestPwLink")}
        </button>
        <div className="flex justify-center pb-2 mt-10">
          <div
            className="justify-center text-[12px] no-underline cursor-pointer text-spekterWhite"
            onClick={() => setCurrentStep(STEPS.CODE_VERIFICATION)}
          >
            {t("alreadyCode")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestOTP;
