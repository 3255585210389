import React from 'react';
// import styles from "./ProfileNotFound.module.css";

const ProfileNotFound = () => {
    return (
        <div className="flex justify-center flex-col items-center h-80 text-gray-500">
            <h3>Profile not found! </h3>
            {/* <img
                height="300px"
                width="300px"
                src="/images/profile_not_found.png"
            /> */}
        </div>
    );
}

export default ProfileNotFound;
