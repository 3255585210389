import React, { useEffect, useState } from "react";
import MessagingOption from "../../UserManager/RescueGroup/MessageOption/MessagingOption";
import Spinner from "../../UI/Spinner/Spinner";
import { userAction } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import CatchmentSelect from "./CatchmentSelect/CatchmentSelect";
import useAgs from "../../../hooks/useAgs";

const Notifications = () => {
  const dispatch = useDispatch();
  const agsList = useAgs();
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const userData = useSelector((state) => state.user.data);
  const isLoading = useSelector((state) => state.user.isLoading);

  useEffect(() => {
    if (!userData) {
      dispatch(userAction());
    }
    return () => {
      dispatch(userAction());
    };
  }, []);

  return (
    <div className="px-6 sm:px-0">
      <div
        className={`${
          isThemeDark
            ? "bg-spekterDarkSix text-spekterWhite"
            : "text-gray-700 bg-spekterWhite containerShadowLight"
        } flex flex-col gap-[69px] mb-4 sm:flex-row justify-between max-w-[100%] w-[1200px]
              p-[30px] 2xl:p-[47px] rounded-[18px] duration-75`}
      >
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-40 ">
            <Spinner />
          </div>
        ) : (
          <MessagingOption
            ags={agsList[0]}
            isInUserSetting={true}
            alertData={userData && userData?.subscriber}
            roles={userData && userData?.roles}
          />
        )}
      </div>
      <div
        className={`${
          isThemeDark
            ? "bg-spekterDarkSix text-spekterWhite"
            : "text-gray-700 bg-spekterWhite containerShadowLight"
        } flex flex-col gap-[69px] mb-4 sm:flex-row justify-between max-w-[100%] w-[1200px]
        p-[39px] 2xl:p-[47px] rounded-[18px] mt-[47px] duration-75`}
      >
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-40">
            <Spinner />
          </div>
        ) : (
          <CatchmentSelect
            ags={agsList[0]}
            isInUserSetting={true}
            alertData={userData && userData?.subscriber}
          />
        )}
      </div>
    </div>
  );
};

export default Notifications;
