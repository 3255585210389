import React, { useEffect, useState } from "react";
import { utcToGermanTime, cn } from "../../../shared/Utility";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import useMobileView from "../../../hooks/useMobileView";

// class SingleChart extends React.Component {
const SingleHdc = ({ sensorData }) => {
  const { t } = useTranslation("sensors");
  const [isCumulative, setIsCumulative] = useState(false);
  const [chartData, setChartData] = useState([]);

  const [ticks, setTicks] = useState([]);
  const isMobileView = useMobileView();

  useEffect(() => {
    // Get 6 date (as a ticks) dynamically from x-axis data
    // always picks the first data and last data from the data array

    let ticks = [];
    let tickCount = 5;

    if (chartData.length > 0) {
      let firstData = chartData[0].start;
      let lastData = chartData[chartData.length - 1].start;
      ticks.push(firstData);
      let interval = Math.round((chartData.length - 1) / tickCount);
      for (let i = 1; i < tickCount; i++) {
        let index = interval * i;
        let date = chartData[index].start;
        ticks.push(date);
      }
      ticks.push(lastData);
    }
    setTicks(ticks);
  }, [chartData]);

  useEffect(() => {
    if (sensorData?.values?.length <= 0) return;

    // Prepare data for chart
    let cumulativeSum = 0;

    const data = sensorData?.values.map((item, index) => {
      // pick 1 digit after decimal without rounding by Math.trunc
      const value = Math.trunc(item.value * 10) / 10;
      const sumvalue = cumulativeSum + value;
      cumulativeSum = sumvalue;
      //negative to positive value , like -0.1 to 0.1
      // const absCumulativeData = Math.abs(cumulativeData).toFixed(1);

      // pick 1 digit after decimal without rounding by Math.trunc
      const cumulativeValue = Math.trunc(cumulativeSum * 10) / 10;
      if (isCumulative) {
        return {
          start: item.start,
          BarChart: item?.value >= 0 ? value : 0,
          Ganglinie: cumulativeValue,
        };
      } else {
        return {
          start: item.start,
          BarChart: item?.value >= 0 ? value : 0,
        };
      }
    });
    setChartData(data);
  }, [sensorData, isCumulative]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="flex flex-col text-white bg-[#333333] p-4 text-sm rounded">
          <p>{`${utcToGermanTime(label, "DD.MM HH:mm")}`}</p>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 bg-[#000BF8]"></div>
            <span className="opacity-70">{`Niederschlag: ${payload[0].value}  mm/h`}</span>
          </div>
          {payload[1] && (
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-[#ff7300]"></div>
              <span className="opacity-70">{`Niederschlag Summe: ${payload[1]?.value} mm`}</span>{" "}
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  const cumulativeSwitch = (
    <div
      data-tip={isMobileView ? "" : t("cumulativeRainfall")}
      data-for="tooltip"
      className={"flex bg-[#343A40] p-1 h-6 rounded-5 cursor-pointer"}
      onClick={() => {
        setIsCumulative(!isCumulative);
      }}
    >
      <img
        src="images/chart_modal/icons8-gefülltes-plus-96.svg"
        alt="cumulative chart icon"
        className={cn(
          { "bg-spekterDarkOne rounded-5": isCumulative },
          { "bg-transparent": !isCumulative }
        )}
        height={18}
        width={18}
      />
    </div>
  );

  return (
    <div>
      <div className="flex justify-between px-4">
        <h5 className="text-gray-200 rounded-xl">{sensorData.sensorName}</h5>
        {cumulativeSwitch}
      </div>
      <ResponsiveContainer width="100%" height={250}>
        <ComposedChart
          width={500}
          height={250}
          data={chartData}
          color="#fff"
          margin={{
            top: 20,
            right: 40,
            bottom: 10,
            // left: 20,
          }}
        >
          {/* <CartesianGrid stroke="#f5f5f5" /> */}
          <XAxis
            dataKey="start"
            // interval={5}
            ticks={ticks}
            fontSize={12}
            tickFormatter={(dateTime) => utcToGermanTime(dateTime, "HH:mm")}
            tick={{ fill: "#fff" }}
            // <YAxis >
          />
          <YAxis
            tickFormatter={(value) => value.toFixed(1)}
            fontSize={12}
            tick={{ fill: "#fff" }}
          />
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Bar dataKey="BarChart" barSize={20} fill="#000BF8" />
          <Line
            type="monotone"
            connectNulls
            dataKey="Ganglinie"
            stroke="#ff7300"
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <ReactTooltip id="tooltip" place="top" />
    </div>
  );
};

export default SingleHdc;
