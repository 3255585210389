import React, { useEffect, useState } from "react";
import { WmsMapType } from "ogc-spekter";
import axios from "axios";
import * as toast from "../../../../components/UI/Toaster/index";
import "./RadarLayer.css";
import ReactTooltip from "react-tooltip";
import useMobileView from "../../../../hooks/useMobileView";
import useObserver from "../../../../hooks/useObserver";
import { useTranslation } from "react-i18next";

const RadarLayer = ({ map }) => {
  //language package
  const { t } = useTranslation("realtimeMap");
  const [sliderValue, setSliderValue] = useState(0);
  const [isRadarON, setIsRadarON] = useState(false);
  const [datesArray, setDatesArray] = useState([]);
  const [isRadarServiceActive, setIsRadarServiceActive] = useState(true);
  const [showSlider, setShowSlider] = useState(false); // disable slider while loading
  const isMobileView = useMobileView();
  const isObserver = useObserver();

  const addZeroBefore = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const checkRadarAvailability = () => {
    const url = "https://wms.geo.admin.ch";
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          if (
            response.data.includes(
              "This service is not available due to technical problems!"
            )
          ) {
            //  GeoServer is not available
            setIsRadarServiceActive(false);
            toast.error(t("radarServiceUnavailable"));
          } else {
            //GeoServer is available
            setIsRadarServiceActive(true);
          }
        } else {
          setIsRadarServiceActive(false);
          toast.error(t("radarServiceUnavailable"));
        }
      })
      .catch((error) => {
        setIsRadarServiceActive(false);
        toast.error(error.message);
      });
  };

  // Init radar layer on map and buffer 24 x 55 minutes
  useEffect(() => {
    if (!map && datesArray.length === 0) return;

    if (!isRadarON) {
      map.overlayMapTypes.clear();
      return;
    }

    const landMapSwiss = WmsMapType({
      // origial link-> https://wms.geo.admin.ch/?VERSION=1.3.0crs=EPSG:4326&dpiMode=7&format=image/png&layers=ch.swisstopo.pixelkarte-farbe-pk100.noscale&styles&url=https://wms.geo.admin.ch/?VERSION%3D1.3.0
      url: "https://wms.geo.admin.ch/VERSION=1.3.0crs=EPSG:4326&dpiMode=7&format=image/png",
      layers: "ch.swisstopo.pixelkarte-farbe-pk100.noscale",
      name: "precipitation",
      opacity: 1.0,
      // time: datesArray[sliderValue], // "2023-03-15T13:30:00.000Z",
    });

    map.overlayMapTypes.setAt("0", landMapSwiss);
  }, [map, isRadarON, datesArray]);

  // disable map zooming while radar is turned on
  useEffect(() => {
    if (!map) return;
    if (isRadarON) {
      map.setOptions({
        minZoom: map.getZoom(),
        maxZoom: map.getZoom(),
      });
    } else {
      map.setOptions({
        minZoom: isObserver ? 5 : 10,
        maxZoom: null,
      });
    }
  }, [map, isRadarON, isObserver]);

  useEffect(() => {
    setShowSlider(false);

    if (isRadarON) {
      setTimeout(() => {
        setShowSlider(true);
      }, 10000);
    }
  }, [isRadarON]);

  // Change opacity of radar layer while sliding
  useEffect(() => {
    if (!map && datesArray.length === 0) return;

    datesArray.forEach((date, index) => {
      map.overlayMapTypes.getAt(index).setOpacity(0.0);
    });

    map.overlayMapTypes.getAt(sliderValue).setOpacity(0.7);
  }, [sliderValue]);

  useEffect(() => {
    if (!map) return;

    let dates = [];
    let date = new Date(); // get current date

    date.setMilliseconds(0);
    date.setSeconds(0);

    //handle initial time
    if (date.getMinutes() % 5 === 0) {
      dates.push(new Date(date).toISOString());
    }

    for (let i = 0; i < 13; i++) {
      //2hours with 5 minutes interval = 24 step
      let minutes = date.getMinutes();

      if (minutes % 5 !== 0) {
        // adjust minutes if not one of 05, 10, 15, 20, 35, etc.
        let diff = minutes % 5;
        date.setMinutes(date.getMinutes() - diff);
      } else {
        date.setMinutes(date.getMinutes() + 5); // increase date by 5 minutes
      }

      dates.push(new Date(date).toISOString()); // save date to array
    }

    setDatesArray(dates);
    // if (isRadarON) checkRadarAvailability();
  }, [map, isRadarON]);

  return (
    <div className="relative flex flex-col">
      <section
        data-tip={
          isMobileView
            ? ""
            : isRadarON
            ?  `${t("disableMapType")}`
            : `${t("changeMapType")}`
        }
        data-for="tooltip"
        className="flex items-center justify-center w-10 h-10 duration-300 rounded cursor-pointer bg-spekterDarkOne hover:bg-gray-600"
        onClick={() => setIsRadarON((prev) => !prev)}
        id="radar-icon"
      >
        <img
          src={
            isRadarON ? "/images/Radar_aktiv.png" : "/images/Radar_inaktiv.png"
          }
          // src={
          //   isRadarON
          //     ? "/i2/images/icons8-radio-tower-150-red.png"
          //     : "/i2/images/icons8-radio-tower-150-white.png"
          // }
          alt="alarm info icon"
          height={22}
          width={22}
          className="m-[11px]"
        />
      </section>
      {/* {isRadarON && isRadarServiceActive && (
        <section className="fixed bottom-0 left-0 flex flex-col items-center justify-center mx-24 my-3 right-10 sm:right-0">
          <div className="p-4 w-72 sm:w-96 bg-spekterDarkOne rounded-10">
            {showSlider ? (
              <div className="flex flex-col items-center justify-center gap-2 sm:flex-row-reverse ">
                <div className="text-center w-28">
                  {datesArray.length !== 0
                    ? `${addZeroBefore(
                        new Date(datesArray[sliderValue]).getHours()
                      )}: ${addZeroBefore(
                        new Date(datesArray[sliderValue]).getMinutes()
                      )} `
                    : "00:00 "}
                  Uhr
                </div>

                <input
                  className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer radarSliderThumb"
                  type="range"
                  min={0}
                  max={12}
                  value={sliderValue}
                  onChange={(e) => {
                    setSliderValue(e.target.value);
                  }}
                />
              </div>
            ) : (
              <div className="">{t("loadingWeatherRadar")} </div>
            )}
            <div className="mt-4">
              <img
                className="mt-0"
                src="/images/FAS_Gradient.svg"
                alt="Radar Intensität"
              />

              <div className="flex w-[350px] max-w-[100%] mt-1 text-sm justify-between sm:text-xs">
                <div className="font-light">Schwach</div>
                <div className="font-light">Extrem</div>
              </div>
            </div>
          </div>
        </section>
      )} */}
      {!isMobileView && <ReactTooltip id="tooltip" place="top" />}
    </div>
  );
};

export default RadarLayer;
