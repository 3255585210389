import React from "react";
import ReactTooltip from "react-tooltip";
import AboutModal from "../../containers/About/AboutModal";
import { useTranslation } from "react-i18next";
import useAboutModal from "../../hooks/useAboutModal";

const PublicLeftSidebar = () => {
  const { t, i18n } = useTranslation("navigation");
  const { showAboutModal, setShowAboutModal } = useAboutModal();

  return (
    <div className="w-full h-full">
      <div className="box-border fixed bottom-0 right-0 z-50 hidden w-20 h-full pt-16 transition-all duration-300 scale-75 sm:block md:scale-100 left-1 md:left-5 2xl:left-10">
        <div className="flex justify-center backdrop-blur-[4.5px] bg-black/[.69] rounded-2xl py-1 h-[90%] mt-10 leftSideBarShadowLight">
          {/* <div className="flex justify-center bg-spekterDarkOne rounded-2xl py-1 h-[80vh] lg:h-[82.25vh] 2xl:h-[85.30vh] mt-10 border-2 border-spekterDarkOne"> */}
          <nav className="relative w-12">
            <div
              data-tip="Realtime Map"
              className="flex flex-col items-center justify-center p-2 my-4 text-center cursor-pointer md:h-12 rounded-xl text-spekterWhite md:px-4 md:py-4 hover:bg-spekterRed bg-spekterRed"
            >
              <div className="p-1">
                <img
                  className="w-full"
                  alt="map"
                  src="/images/menu_icons/map_light.svg"
                />
              </div>
            </div>

            <div
              data-tip={t("about")}
              className={`flex flex-col md:h-12 justify-center items-center rounded-xl text-spekterWhite p-2 md:p-4 md:py-4 text-center cursor-pointer  hover:bg-spekterRed
                   absolute bottom-1`}
              onClick={() => setShowAboutModal(!showAboutModal)}
            >
              <div className="p-1">
                <img
                  className="w-full"
                  alt="sensor"
                  src="/images/menu_icons/about-icon-light.svg"
                />
              </div>
            </div>
          </nav>
        </div>
      </div>
      <ReactTooltip />
      <AboutModal />
    </div>
  );
};

export default PublicLeftSidebar;
