import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import OneSignal from "react-onesignal";
import SwitchIcon from "../UI/Switch/Switch";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const WebPushModal = (props) => {
  const [s1Switch, setS1Switch] = useState(true);
  const [s2Switch, setS2Switch] = useState(true);
  const [s3Switch, setS3Switch] = useState(true);
  const [floodLevelSwitch, setFloodLevelSwitch] = useState(true);
  const [capSwitch, setCapSwitch] = useState(true);
  const { width } = useWindowDimensions();

  const handleS1Switch = () => {
    OneSignal.User.addTags({ s1: !s1Switch ? "1" : "0" });
    localStorage.setItem("s1_notification", (!s1Switch).toString());
    console.log("S1 Switch: " + !s1Switch);
    setS1Switch(!s1Switch);
  };

  const handleS2Switch = () => {
    // OneSignal.User.addTags({ s2_notification: !s2Switch });
    localStorage.setItem("s2_notification", (!s2Switch).toString());
    console.log("S2 Switch: " + s2Switch);
    setS2Switch(!s2Switch);
  };

  const handleS3Switch = () => {
    // OneSignal.User.addTags({ s3_notification: !s3Switch });
    localStorage.setItem("s3_notification", (!s3Switch).toString());

    setS3Switch(!s3Switch);
  };

  const handleFloodLevelSwitch = () => {
    OneSignal.User.addTags({ floodlevel: !floodLevelSwitch ? "1" : "0" });
    localStorage.setItem(
      "floodlevel_notification",
      (!floodLevelSwitch).toString()
    );

    setFloodLevelSwitch(!floodLevelSwitch);
  };

  const handleCapSwitch = () => {
    OneSignal.User.addTags({ cap: !capSwitch ? "1" : "0" });
    localStorage.setItem("cap_notification", (!capSwitch).toString());

    setCapSwitch(!capSwitch);
  };

  useEffect(() => {
    const s1Notification = localStorage.getItem("s1_notification");
    if (s1Notification) {
      setS1Switch(s1Notification === "true");
    }
    const s2Notification = localStorage.getItem("s2_notification");
    if (s2Notification) {
      setS2Switch(s2Notification === "true");
    }
    const s3Notification = localStorage.getItem("s3_notification");
    if (s3Notification) {
      setS3Switch(s3Notification === "true");
    }
    const floodLevelNotification = localStorage.getItem(
      "floodlevel_notification"
    );
    if (floodLevelNotification) {
      setFloodLevelSwitch(floodLevelNotification === "true");
    }
    const capNotification = localStorage.getItem("cap_notification");
    if (capNotification) {
      setCapSwitch(capNotification === "true");
    }
  }, []);

  return (
    <div>
      {props.showModal && (
        <div className="fixed z-[20000] flex left-0 top-0 w-full h-full duration-300">
          <div
            className={`absolute bottom-0 sm:relative m-auto gap-5 headerShadowLight text-white max-h-[85vh] overflow-auto scrollbarLight dark:scrollbar
            w-[830px] max-w-[100%] sm:max-w-[70%] bg-[#151618] rounded-t-2xl sm:rounded-[18px] text-center`}
          >
            <div
              onClick={() => props.setShowModal(false)}
              className="absolute duration-300 cursor-pointer right-3 sm:right-7 top-4 sm:top-7 hover:opacity-75"
            >
              <CgClose size={24} />
            </div>
            <section className="p-[23px] -mt-2 md:-mt-0 md:p-[50px] flex flex-col items-center">
              <div className="text-2xl font-bold">Alarm-Einstellungen</div>
              <div className="mt-4 text-sm font-medium">
                Hier können Sie wählen, über welchen Kanal Sie bei welcher
                Gefahrenstufen alarmiert werden möchten. Die Funktion VoiceCall
                wird nur bei Gefahrenstufe S3 ausgelöst und kann nicht verändert
                werden.
              </div>
              {width > 640 ? (
                <div className="flex justify-center gap-2 pt-10 lg:pt-14 lg:gap-4">
                  <div className="flex flex-col justify-end mb-8 gap-[26px]">
                    <div className="flex items-center w-20 gap-2">Web Push</div>
                  </div>
                  <div className="w-16 py-3 flex flex-col items-center bg-[#F2CB53] rounded-[9px] text-[#FFFFFF]">
                    <p>S1</p>
                    <div>
                      <SwitchIcon
                        value={s1Switch}
                        bgColor="bg-[#BA9C40] opacity-40"
                        bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                        onCheck={handleS1Switch}
                      />
                    </div>
                  </div>
                  <div className="w-16 py-3 flex flex-col items-center bg-[#E41931] rounded-[9px] text-[#FFFFFF]">
                    <p>S2</p>
                    <div>
                      <SwitchIcon
                        value={s2Switch}
                        bgColor="bg-[#b01326] opacity-40"
                        bgCheckedColor="checked:bg-[#b01326] checked:opacity-100"
                        onCheck={handleS2Switch}
                      />
                    </div>
                  </div>
                  <div className="w-16 py-3 flex flex-col items-center bg-[#9F33E5] rounded-[9px] text-[#FFFFFF]">
                    <p>S3</p>
                    <div>
                      <SwitchIcon
                        value={s3Switch}
                        bgColor="bg-[#7a27b0] opacity-40"
                        bgCheckedColor="checked:bg-[#7a27b0] checked:opacity-100"
                        onCheck={handleS3Switch}
                      />
                    </div>
                  </div>
                  <div className="w-28 py-3 flex flex-col items-center bg-[#3733E5] rounded-[9px] text-[#FFFFFF]">
                    <p>Flood Level</p>
                    <div>
                      <SwitchIcon
                        value={floodLevelSwitch}
                        bgColor="bg-[#2a27b0] opacity-40"
                        bgCheckedColor="checked:bg-[#2a27b0] checked:opacity-100"
                        onCheck={handleFloodLevelSwitch}
                      />
                    </div>
                  </div>
                  <div className="w-16 py-3 flex flex-col items-center bg-[#33E590] rounded-[9px] text-[#FFFFFF]">
                    <p>CAP</p>
                    <div>
                      <SwitchIcon
                        value={capSwitch}
                        bgColor="bg-[#27b06e] opacity-40"
                        bgCheckedColor="checked:bg-[#27b06e] checked:opacity-100"
                        onCheck={handleCapSwitch}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-between w-full gap-4">
                  <div className="flex justify-center gap-4 pt-10">
                    Web Push
                  </div>
                  <div className="w-full p-[15px] flex items-center justify-between bg-[#F2CB53] rounded-[9px] text-[#FFFFFF]">
                    <div className="font-bold text-[15px]">S1</div>
                    <div className="flex items-center justify-center">
                      <SwitchIcon
                        value={s1Switch}
                        labelClassName="m-0 pt-1.5"
                        bgColor="bg-[#BA9C40] opacity-40"
                        bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                        onCheck={handleS1Switch}
                      />
                    </div>
                  </div>
                  <div className="w-full p-[15px] flex items-center justify-between bg-[#E41931] rounded-[9px] text-[#FFFFFF]">
                    <div className="font-bold text-[15px]">S2</div>
                    <div className="flex items-center justify-center">
                      <SwitchIcon
                        value={s2Switch}
                        labelClassName="m-0 pt-1.5"
                        bgColor="bg-[#b01326] opacity-40"
                        bgCheckedColor="checked:bg-[#b01326] checked:opacity-100"
                        onCheck={handleS2Switch}
                      />
                    </div>
                  </div>
                  <div className="w-full p-[15px] flex items-center justify-between bg-[#9F33E5] rounded-[9px] text-[#FFFFFF]">
                    <div className="font-bold text-[15px]">S3</div>
                    <div className="flex items-center justify-center">
                      <SwitchIcon
                        value={s3Switch}
                        labelClassName="m-0 pt-1.5"
                        bgColor="bg-[#7a27b0] opacity-40"
                        bgCheckedColor="checked:bg-[#7a27b0] checked:opacity-100"
                        onCheck={handleS3Switch}
                      />
                    </div>
                  </div>
                  <div className="w-full p-[15px] flex items-center justify-between bg-[#3733E5] rounded-[9px] text-[#FFFFFF]">
                    <div className="font-bold text-[15px]">Flood Level</div>
                    <div className="flex items-center justify-center">
                      <SwitchIcon
                        value={floodLevelSwitch}
                        labelClassName="m-0 pt-1.5"
                        bgColor="bg-[#2a27b0] opacity-40"
                        bgCheckedColor="checked:bg-[#2a27b0] checked:opacity-100"
                        onCheck={handleFloodLevelSwitch}
                      />
                    </div>
                  </div>
                  <div className="w-full p-[15px] flex items-center justify-between bg-[#33E590] rounded-[9px] text-[#FFFFFF]">
                    <div className="font-bold text-[15px]">CAP</div>
                    <div className="flex items-center justify-center">
                      <SwitchIcon
                        value={capSwitch}
                        labelClassName="m-0 pt-1.5"
                        bgColor="bg-[#27b06e] opacity-40"
                        bgCheckedColor="checked:bg-[#27b06e] checked:opacity-100"
                        onCheck={handleCapSwitch}
                      />
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebPushModal;
