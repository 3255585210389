import React, { useState } from "react";
import { useSelector } from "react-redux";
import validator from "validator";
// import styles from "./SpecialUserModal.module.css";

const SpecialUserModal = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [voiceCallNumber, setVoiceCallNumber] = useState("");
  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
  const [isVoiceCallNumberValid, setIsVoiceCallNumberValid] = useState(true);
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);

  const closeModal = () => {
    props.closed();
    setName("");
    setEmail("");
    setMobileNumber("");
    setVoiceCallNumber("");
    setIsNameValid(true);
    setIsEmailValid(true);
    setIsMobileNumberValid(true);
    setIsVoiceCallNumberValid(true);
  };

  const nameChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsNameValid(true);
    }
    setName(event.target.value);
  };

  const emailChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsEmailValid(true);
    }
    setEmail(event.target.value);
  };

  const mobileNumberChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsMobileNumberValid(true);
    }
    setMobileNumber(event.target.value);
  };

  const voiceCallNumberChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsVoiceCallNumberValid(true);
    }
    setVoiceCallNumber(event.target.value);
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();

    if (
      name.trim().length === 0 &&
      email.trim().length === 0 &&
      mobileNumber.trim().length === 0 &&
      voiceCallNumber.trim().length === 0
    ) {
      setIsNameValid(false);
      setIsEmailValid(false);
      setIsMobileNumberValid(false);
      setIsVoiceCallNumberValid(false);
      return;
    }

    if (name.trim().length === 0) {
      setIsNameValid(false);
      return;
    }

    if (email.trim().length === 0) {
      setIsEmailValid(false);
      return;
    }

    if (mobileNumber.trim().length === 0) {
      setIsMobileNumberValid(false);
      return;
    }

    if (voiceCallNumber.trim().length === 0) {
      setIsVoiceCallNumberValid(false);
      return;
    }

    if (validator.isEmail(email) === false) {
      setIsEmailValid(false);
      return;
    }
  };

  return (
    <div>
      {props.open ? (
        <div className="block fixed z-10 pt-52 left-0 top-0 w-full h-full overflow-auto">
          <div className={`relative bg-spekterWhite dark:bg-boxBG rounded-xl m-auto p-0 w-11/12 md:w-4/12 ${isThemeDark ? '' : 'shadow-modal'} `}>
            <div className="p-4 bg-barBG rounded-t-xl dark:bg-transparent">
              <h5 className="text-center md:text-left text-gray-700 dark:text-gray-100">Add New Special User</h5>
            </div>
            <div className="py-3 px-4 flex justify-center md:block">
              <input
                className={`bg-spekterWhite dark:bg-inputBoxBG rounded-lg text-gray-600 dark:text-gray-400 h-10 w-full md:w-60 px-3 focus:outline-none 
                ${!isNameValid ? 'border-2 border-spekterRed' : `${isThemeDark ? 'border-0' : 'border-2 border-borderGary'}`}`}
                type="text"
                placeholder="Name"
                value={name}
                onChange={nameChangeHandler}
              />
            </div>
            <div className="py-3 px-4 flex justify-center md:block">
              <input
                className={`bg-spekterWhite dark:bg-inputBoxBG rounded-lg text-gray-600 dark:text-gray-400 h-10 w-full md:w-60 px-3 focus:outline-none 
                ${!isEmailValid ? 'border-2 border-spekterRed' : `${isThemeDark ? 'border-0' : 'border-2 border-borderGary'}`}`}
                type="text"
                placeholder="Email"
                value={email}
                onChange={emailChangeHandler}
              />
            </div>
            <div className="py-3 px-4 flex justify-center md:block">
              <input
                className={`bg-spekterWhite dark:bg-inputBoxBG rounded-lg text-gray-600 dark:text-gray-400 h-10 w-full md:w-60 px-3 focus:outline-none 
                ${!isMobileNumberValid ? 'border-2 border-spekterRed' : `${isThemeDark ? 'border-0' : 'border-2 border-borderGary'}`}`}
                type="text"
                placeholder="Mobile Number"
                value={mobileNumber}
                onChange={mobileNumberChangeHandler}
              />
            </div>
            <div className="py-3 px-4 flex justify-center md:block">
              <input
                className={`bg-spekterWhite dark:bg-inputBoxBG rounded-lg text-gray-600 dark:text-gray-400 h-10 w-full md:w-60 px-3 focus:outline-none 
                ${!isVoiceCallNumberValid ? 'border-2 border-spekterRed' : `${isThemeDark ? 'border-0' : 'border-2 border-borderGary'}`}`}
                type="text"
                placeholder="Voice Call Number"
                value={voiceCallNumber}
                onChange={voiceCallNumberChangeHandler}
              />
            </div>
            <div className="flex justify-end p-4 bg-barBG rounded-b-xl dark:bg-transparent">
              <input
                type="button"
                value="Cancel"
                className="font-semibold bg-gray-400 border-0 py-1 px-4 rounded text-spekterWhite dark:text-gray-900 ml-2"
                onClick={closeModal}
              />
              <input
                type="button"
                value="Add User"
                className="font-semibold bg-spekterGreenLight dark:bg-spekterGreen border-0 py-1 px-4 rounded text-spekterWhite dark:text-gray-900 ml-2"
                onClick={formSubmitHandler}
              />
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default SpecialUserModal;
