import React from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const EyeButton = ({ onEyeClick, open }) => {
  return (
    <button onClick={onEyeClick}>
      {open ? <AiFillEye size={20} /> : <AiFillEyeInvisible size={20} />}
    </button>
  );
};

export default EyeButton;
