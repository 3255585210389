import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SwitchIcon from "../../../components/UI/Switch/Switch";
import * as actions from "../../../redux/actions/index";

const layerInfo = [
  {
    title: "ALKIS Gebäude Daten anzeigen",
    value: "building",
  },
  {
    title: "Häufiges Niederschlagsereignis",
    value: "n30",
  },
  {
    title: "B01",
    value: "b01",
  },
  {
    title: "Seltenes Niederschlagsereignis",
    value: "n100",
  },
  {
    title: "Extremes Niederschlagsereignis",
    value: "n1000",
  },
];

const layersMetaInfo = {
  title: "Wassertiefen Starkregenkarte:",
  data: [
    {
      title: "5 - 10 cm Wassertiefe",
      color: "bg-[#B8EDEF]",
    },
    {
      title: "10 - 50 cm Wassertiefe",
      color: "bg-[#5FA4E4]",
    },
    {
      title: "50 - 100 cm Wassertiefe",
      color: "bg-[#265CC4]",
    },
    {
      title: "> 100 cm Wassertiefe",
      color: "bg-[#0D168E]",
    },
  ],
};

const HazardSwitch = ({ map }) => {
  const { t } = useTranslation("webViewer");
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(true);
  const [sliderValue, setSliderValue] = useState(80);
  const [switchNames, setSwitchNames] = useState({
    building: false,
    n30: false,
    n100: false,
    n1000: false,
  });

  const customerData = useSelector((state) => state.customer.data);
  const preserveState = useSelector((state) => state.webviewer.preserveState);

  //update the switch state when the map is loaded
  useEffect(() => {
    if (!map) return;
    if (preserveState) {
      setSwitchNames({
        building: preserveState.building,
        n30: preserveState.n30,
        n100: preserveState.n100,
        n1000: preserveState.n1000,
      });
    }
  }, [map, preserveState]);

  useEffect(() => {
    if (!customerData || !customerData.mapLayers?.settings) return;
    const { settings } = customerData.mapLayers;
    const updatedSwitchStates = {
      ...switchNames,
      n30: false,
      n100: false,
      n1000: false,
    };

    if (settings.n1000) updatedSwitchStates.n1000 = true;
    else if (settings.n100) updatedSwitchStates.n100 = true;
    else if (settings.n30) updatedSwitchStates.n30 = true;

    setSwitchNames(updatedSwitchStates);
    dispatch(
      actions.updateWebviewerStates({
        ...updatedSwitchStates,
      })
    );
  }, [customerData]);

  useEffect(() => {
    if (!map) return;
    let opacity = sliderValue / 100;

    map.getLayers().forEach((element) => {
      if (element.get("title") === "buildingsShape") {
        element.setVisible(switchNames.building);
        element.setOpacity(opacity);
      } else if (element.get("title") === "starkregenShapesN30") {
        element.setVisible(switchNames.n30);
        element.setOpacity(opacity);
      } else if (element.get("title") === "starkregenShapesN100") {
        element.setVisible(switchNames.n100);
        element.setOpacity(opacity);
      } else if (element.get("title") === "starkregenShapesN1000") {
        element.setVisible(switchNames.n1000);
        element.setOpacity(opacity);
      }
    });
  }, [switchNames, sliderValue, map]);

  const toggleSwitch = (value) => {
    if (value === "building") {
      const updatedData = {
        ...switchNames,
        building: !switchNames[value],
      };

      setSwitchNames({ ...updatedData });
      dispatch(
        actions.updateWebviewerStates({
          ...updatedData,
        })
      );
    } else if (value === "n30") {
      const updatedData = {
        ...switchNames,
        n30: !switchNames[value],
        n100: false,
        n1000: false,
      };

      setSwitchNames({ ...updatedData });
      dispatch(
        actions.updateWebviewerStates({
          ...updatedData,
        })
      );
    } else if (value === "n100") {
      const updatedData = {
        ...switchNames,
        n100: !switchNames[value],
        n30: false,
        n1000: false,
      };

      setSwitchNames({ ...updatedData });
      dispatch(
        actions.updateWebviewerStates({
          ...updatedData,
        })
      );
    } else if (value === "n1000") {
      const updatedData = {
        ...switchNames,
        n1000: !switchNames[value],
        n30: false,
        n100: false,
      };

      setSwitchNames({ ...updatedData });
      dispatch(
        actions.updateWebviewerStates({
          ...updatedData,
        })
      );
    }
  };

  return (
    <div>
      <div className="py-8 px-3.5 mb-5 box-border block text-spekterWhite bg-spekterDarkThree shadow-container rounded-md">
        <div
          className="flex justify-between cursor-pointer hover:text-blue-500"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="font-bold text-lg mr-2.5">
            {t("titleGefahrenkarte")}
          </div>
          <div className="mt-1">{<FaChevronDown />}</div>
        </div>

        {expanded && (
          <div className="p-1.5 sm:p-2.5 mt-3">
            {layerInfo.map((item, index) => {
              if (item.value === "building") {
                return (
                  <div className="flex py-1" key={index}>
                    <SwitchIcon
                      value={switchNames[item.value]}
                      onCheck={() => toggleSwitch(item.value)}
                    />
                    <div className="mt-2 sm:ml-2 text-[13px] sm:text-sm">
                      {item.title}
                    </div>
                  </div>
                );
              } else if (customerData?.mapLayers?.settings?.[item.value]) {
                return (
                  <div className="flex py-1" key={index}>
                    <SwitchIcon
                      value={switchNames[item.value]}
                      onCheck={() => toggleSwitch(item.value)}
                    />
                    <div className="mt-2 sm:ml-2 text-[13px] sm:text-sm">
                      {item.title}
                    </div>
                  </div>
                );
              } else return null;
            })}

            <div className="mt-5 slidecontainer">
              <input
                id="typeinp"
                className="slider"
                type="range"
                min="0"
                max="100"
                value={sliderValue}
                onChange={(e) => setSliderValue(parseFloat(e.target.value))}
                step="5"
              />
              <p className="text-sm sm:text-base">
                Sichtbarkeit: <span>{sliderValue}%</span>
              </p>
            </div>

            {/* additional information about switches */}
            <div className="mt-5 text-sm font-bold sm:text-base">
              {layersMetaInfo.title}
            </div>

            {layersMetaInfo.data.map((item, index) => {
              return (
                <div className="flex mt-2" key={index}>
                  <div className={`${item.color} mt-1 h-5 w-4`}></div>
                  <div className="mt-1 ml-2 text-xs">{item.title}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default HazardSwitch;
