import { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import HTMLParser from "fast-html-parser";
import * as actions from "../../redux/actions";
import Map from "ol/Map";
import View from "ol/View";
import { fromLonLat } from "ol/proj";
import * as Layers from "../../constants/Layers";
import Modal from "../../components/UI/Modal/Modal";
import OLThemeSwitch from "../../components/WebViewer/ThemeSwitch/ThemeSwitch";
import AWS from "aws-sdk";
import "./WebViewer.css";
import { v4 as uuidv4 } from "uuid";
import { useDropzone } from "react-dropzone";
import Axios from "../../services/NetworkService";
import axios from "axios";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Vector from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Spinner from "../../components/UI/Spinner/Spinner";
import OlStyle from "ol/style/Style";
import OlStyleIcon from "ol/style/Icon";
import OlFeature from "ol/Feature";
import OlPoint from "ol/geom/Point";
import { ScaleLine } from "ol/control";
import * as toast from "../../components/UI/Toaster/index";
import { toast as promiseToast } from "sonner";
import SideBar from "./SideBar/SideBar";
import { getFileExtension } from "../../shared/Utility";
import ImageViewerModal from "../../components/UI/ImageViewer/ImageViewerModal";
// import Layout from "../../hoc/Layout/Layout";
import { useTranslation } from "react-i18next";
import useAgs from "../../hooks/useAgs";
import useObserver from "../../hooks/useObserver";
import { useSearchParams } from "react-router-dom";

const fileUploadUrl = `${process.env.REACT_APP_BASE_URL}/webviewercomments/getUploadUrl`;

const isSafariAndIphone = () => {
  return (
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    /iPhone/.test(navigator.userAgent)
  );
};

// Melder Information
let harmReportInfo = [];
let harmNotesInfo = [];
/* webview comments information*/
let commentsInfo = [];

// Comments layer
let commentsLayer = new Vector({
  source: new VectorSource({
    features: [],
    wrapX: false,
  }),
  style: new OlStyle({
    image: new OlStyleIcon({
      anchor: [0.5, 98],
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      scale: 0.2,
      src: "/images/Icon_Flood.png",
    }),
  }),
  zIndex: 10005,
});

// Harm reports layer
let harmReportsLayer = new Vector({
  source: new VectorSource({
    features: [],
    wrapX: false,
  }),
  style: new OlStyle({
    image: new OlStyleIcon({
      anchor: [0.5, 98],
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      src: "/images/harm-reports.svg",
    }),
  }),
  zIndex: 10005,
});

// Harm notes layer
let harmNotesLayer = new Vector({
  source: new VectorSource({
    features: [],
    wrapX: false,
  }),
  style: new OlStyle({
    image: new OlStyleIcon({
      anchor: [0.5, 98],
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      src: "/images/harm-notes.png",
    }),
  }),
  zIndex: 10005,
});

//dropzone style
const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 4,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  overflowX: "auto",
  // flexWrap: 'wrap',
  // marginTop: 16
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 4,
  marginRight: 4,
  width: 50,
  height: 50,
  padding: 2,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

// Config AWS S3 Bucket connection
// -------------------------------
const bucketName = `${process.env.REACT_APP_AWS_BUCKET_NAME}`;
const bucketRegion = `${process.env.REACT_APP_AWS_BUCKET_REGION}`;
const accessKeyId = `${process.env.REACT_APP_AWS_ACCESS_KEY_ID}`;
const secretAccessKey = `${process.env.REACT_APP_AWS_ACCESS_KEY}`;

AWS.config.update({
  accessKeyId: accessKeyId,
  secretAccessKey: secretAccessKey,
  region: bucketRegion,
});

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: bucketName },
  signatureVersion: "v4",
});

const WebViewer = () => {
  //language package
  const { t } = useTranslation("webViewer");

  const [map, setMap] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [open, setOpen] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [ags, setAgs] = useState("");

  const [commentName, setCommentName] = useState("");
  const [description, setDescription] = useState("");
  const [isCommentNameValid, setIsCommentNameValid] = useState(true);
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);
  const [fetchedFiles, setFetchedFiles] = useState([]);
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [selectedFeature, setSelectedFeature] = useState();
  const [editMode, setEditMode] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const mapRef = useRef();

  //Melder
  const [reportLocation, setReportLocation] = useState("");
  const [reportName, setReportName] = useState("");
  const [reportType, setReportType] = useState("");
  const [reportDate, setReportDate] = useState("");
  const [reportDescription, setReportDescription] = useState("");
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [reportFilter, setReportFilter] = useState("");
  const [reportFiles, setReportFiles] = useState();
  const [showImageViewerModal, setShowImageViewerModal] = useState(false);
  const [previewImageLink, setPreviewImageLink] = useState();

  const [noteLocation, setNoteLocation] = useState("");
  const [noteName, setNoteName] = useState("");
  const [noteDescription, setNoteDescription] = useState("");
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [images, setImages] = useState([]);

  const dispatch = useDispatch();
  // const ags = useSelector((state) => state.login.data?.user?.ags);

  const isObserver = useObserver();
  const agsList = useAgs();
  const selectedObserverAgs = useSelector(
    (state) => state.observer.selectedAgs
  );
  const customerData = useSelector((state) => state.customer.data);

  const markerData = useSelector((state) => state.webviewComments.data);
  const dataLoading = useSelector((state) => state.customer.isLoading);
  const showComments = useSelector(
    (state) => state.webviewComments.showComments
  );
  const newCommentStatus = useSelector(
    (state) => state.webviewComments.newCommentStatus
  );
  const olLocalFeature = useSelector((state) => state.localOLFeature.feature);
  const harmReports = useSelector((state) => state.harmReports.data);
  // const showHarmReports = useSelector(
  //   (state) => state.harmReports.showHarmReports
  // );
  const harmNotes = useSelector((state) => state.harmNotes.data);
  // const showHarmNotes = useSelector((state) => state.harmNotes.showHarmNotes);

  // Iterate trough whole bucket cause one page is limited to 1000 keys
  async function getAllKeys(params, allKeys = []) {
    const response = await s3.listObjectsV2(params).promise();
    response.Contents.forEach((obj) => allKeys.push(obj));

    if (response.NextContinuationToken) {
      params.ContinuationToken = response.NextContinuationToken;
      await getAllKeys(params, allKeys);
    }
    return allKeys;
  }

  const getSignedUrl = async (payload) => {
    if (payload.id && payload.ags) {
      let params = {
        Bucket: "cloudbreak-geoserver",
        Prefix: "riskmap-" + payload.ags,
        MaxKeys: 50000,
      };

      let keyData = [];

      // Iterate trough all pages of the bucket to get a list with all keys
      keyData = await getAllKeys(params);

      for (let item of keyData) {
        if (item.Key.split("/")[2]) {
          if (
            item.Key.split("/")[2].split("_")[0].split("R")[1] ===
            payload.id.split(".")[1]
          ) {
            const params = {
              Bucket: "cloudbreak-geoserver",
              Key: item.Key,
              Expires: 86400, // 1 days
            };
            const signedUrl = await s3.getSignedUrl("getObject", params);

            return signedUrl;
          }
        }
      }
    }
  };

  useEffect(() => {
    // create map view object
    const initView = new View({
      // center: olProj.fromLonLat([10.4515, 51.1657]),
      // center: [51.1657, 10.4515],
      //projection: "EPSG:3857",
      //projection: "EPSG:4326",
      center: [0, 0],
      maxZoom: 19,
      zoomWheelEnabled: false,
      enableRotation: false,
    });

    let scaleControl = new ScaleLine({
      units: "metric",
    });

    // initialize openLayer map
    const initialMap = new Map({
      target: mapRef.current,
      // defaultCenter: [10.4515, 51.1657],
      controls: [scaleControl],
      layers: [],
      view: initView,
    });

    setMap(initialMap);

    // let ags = localStorage.getItem("agsValue");
    // if (ags) {
    //   setAgs(ags);
    // }
    // dispatch(actions.customerAction());
    // unmount the map and view properties
    return () => {};
  }, []);

  // modal variables
  var fid;
  var text;
  var risk_number;
  var flood_type;

  const onShowModalHandler = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  const handlerClosePopUp = () => {
    setShowPopup(false);
    setEditMode(false);

    //  RESET
    setSelectedFeature(null);
    setSelectedCommentId(null);
  };

  const closeHarmReportModal = () => {
    setShowReportPopup(false);
  };

  const closeHarmNoteModal = () => {
    setShowNotePopup(false);
  };

  const removeComments = () => {
    if (!selectedFeature || !selectedCommentId) return;
    dispatch(actions.removeWebViewComment(selectedCommentId));
    commentsLayer.getSource().removeFeature(selectedFeature);
    setShowPopup(false);
  };

  // -------------------------
  //comments marker handler
  const onCommentsMarkerHandler = (pixel, coordinate) => {
    map.forEachFeatureAtPixel(pixel, function (f) {
      if (f) {
        setSelectedFeature(f);
        commentsInfo.forEach((comment) => {
          if (comment.markerCoordinates.id === f.getId()) {
            setCommentName(comment.name);
            setDescription(comment.description);
            setSelectedCommentId(comment._id);
            setFetchedFiles(comment.files);
            setShowPopup(true);
          }
        });
      }

      return true;
    });
  };

  // -------------------------
  //Reports marker handler
  const onClickReportsHandler = (pixel) => {
    map.forEachFeatureAtPixel(pixel, function (f) {
      if (f) {
        harmReportInfo.forEach((reportInfo) => {
          if (reportInfo._id === f.getId()) {
            setReportLocation(reportInfo.location.address);
            setReportName(reportInfo.firstname);
            setReportType(reportInfo.harmType[0]);
            setReportDate(
              new Date(reportInfo.date)
                .toLocaleDateString("en-GB")
                .replace(/\//g, ".")
            );
            setReportDescription(reportInfo.harmInfo);
            setReportFiles(reportInfo?.upload);
            setShowReportPopup(true);
          }
        });
      }

      return true;
    });
  };

  // -------------------------
  //Notes marker handler
  const onClickNotesHandler = (pixel) => {
    map.forEachFeatureAtPixel(pixel, function (f) {
      if (f) {
        harmNotesInfo.forEach((noteInfo) => {
          if (noteInfo._id === f.getId()) {
            setNoteLocation(noteInfo.location.address);
            setNoteName(noteInfo.firstname);
            setNoteDescription(noteInfo.harmInfo);
            setImages(noteInfo.upload);
            setShowNotePopup(true);
          }
        });
      }

      return true;
    });
  };

  // map event listerner
  //--------------------
  // listening single-click event

  useEffect(() => {
    if (map) {
      // fetch comments
      initComments();
      // fetchMelderData();

      // listen singleclick map events
      map.on("singleclick", (event) => {
        //donwload riskInformation data
        var viewResolution = map.getView().getResolution();
        var url = Layers.riskShapeImageWMS(
          isObserver ? selectedObserverAgs : agsList[0]
        )
          .getSource()
          .getFeatureInfoUrl(event.coordinate, viewResolution, "EPSG:3857", {
            INFO_FORMAT: "text/html",
          });

        if (url) {
          fetch(url)
            .then(function (response) {
              return response.text();
            })
            .then(function (html) {
              const parsedDataRoot = HTMLParser.parse(html);
              const parsedData =
                parsedDataRoot.childNodes[0].childNodes[5].childNodes[1]
                  .childNodes[5];
              fid = parsedData.childNodes[1].childNodes[0].rawText;
              text = parsedData.childNodes[5].childNodes[0].rawText;
              risk_number = parsedData.childNodes[7].childNodes[0].rawText;
              flood_type = parsedData.childNodes[9].childNodes[0].rawText;

              setModalData({
                fid: fid,
                text: text,
                risk_number: risk_number,
                flood_type: flood_type,
              });
              setShowModal(true);
            })
            .catch(function (error) {
              console.log(error);
            });
        }

        // show the tooltip of comments
        onCommentsMarkerHandler(event.pixel, event.coordinate);
        onClickReportsHandler(event.pixel);
        onClickNotesHandler(event.pixel);
      });
    }
  }, [map]);

  // initialize webview maps
  //------------------------
  useEffect(() => {
    let agsValue;
    if (searchParams.get("ags")) {
      agsValue = searchParams.get("ags");
    } else {
      agsValue = isObserver ? selectedObserverAgs : agsList[0];
    }

    setAgs(agsValue);

    if (map) {
      map.addLayer(commentsLayer);
      map.addLayer(harmReportsLayer);
      map.addLayer(harmNotesLayer);
      // map.addLayer(addressSearchLayer);
      map.addLayer(Layers.buildingsShape(agsValue));
      map.addLayer(Layers.starkregenShapesN30(agsValue));
      map.addLayer(Layers.starkregenShapesN100(agsValue));
      map.addLayer(Layers.starkregenShapesN1000(agsValue));
      map.addLayer(Layers.hinweiskartenShapesN1(agsValue));
      map.addLayer(Layers.hinweiskartenShapesN5(agsValue));
      map.addLayer(Layers.riskShapeImageWMS(agsValue));
      map.addLayer(Layers.riskShapeImageWMS_FT1(agsValue));
      map.addLayer(Layers.riskShapeImageWMS_FT2(agsValue));
      map.addLayer(Layers.riskShapeImageWMS_FT3(agsValue));
    }
  }, [map]);

  useEffect(() => {
    if (customerData && map) {
      map
        .getView()
        .setCenter(
          fromLonLat([
            customerData.coordinatesMaps[1],
            customerData.coordinatesMaps[0],
          ])
        );
      map.getView().setZoom(customerData.coordinatesMaps[2]);
    }
  }, [customerData, map]);

  // Safari check
  useEffect(() => {
    if (isSafariAndIphone()) {
      const scaleLineElement = document.querySelector(".ol-scale-line");
      scaleLineElement.style.bottom = "150px";
    }
  }, []);

  // Initialize comments
  //--------------------

  const initComments = () => {
    return dispatch(actions.fetchWebViewComments());
  };

  // depricated
  // ---------------------
  // show | hide comments marker on map
  useEffect(() => {
    if (!markerData) return;
    commentsInfo = markerData;

    if (showComments) {
      // set polygons as a feature
      if (!markerData) return;
      markerData.forEach((feature) => {
        let coordinate = feature.markerCoordinates.coordinate;
        let id = feature.markerCoordinates.id;

        //create marker object
        let marker = new OlFeature({
          geometry: new OlPoint(coordinate),
          name: "comments marker",
        });

        marker.setId(id);

        // add marker on comments layer.
        commentsLayer.getSource().addFeature(marker);
      });
    } else {
      //-------------------------------------
      // remove all comments marker [feature]
      commentsLayer.getSource().clear();

      //remove local drawn marker and update comment status
      dispatch(actions.removeLocalOLFeature());
      dispatch(actions.updateNewCmntStatus(false));
    }
  }, [showComments, markerData]);

  // Initialize harm reports
  useEffect(() => {
    if (harmReports) harmReportInfo = harmReports;
    if (harmNotes) harmNotesInfo = harmNotes;
  }, [harmReports, harmNotes]);

  // Add comments marker on map
  //---------------------------
  const onAddMarker = (event) => {
    let commentsMarker = new OlFeature({
      // geometry: new OlPoint(fromLonLat([-2, 53])),
      geometry: new OlPoint(event.coordinate),
      name: "comments marker",
    });

    const id = uuidv4();
    const markerObject = { coordinate: event.coordinate, id: id };

    commentsMarker.setId(id);
    commentsLayer.getSource().addFeature(commentsMarker);

    dispatch(actions.addLocalOLFeature(markerObject));
  };

  //remove drawn comments marker from map. when comments cancled by user.
  //------------------------------------------------------------
  const removeDrawnMarker = () => {
    if (olLocalFeature) {
      const feature = commentsLayer
        .getSource()
        .getFeatureById(olLocalFeature.id);
      if (feature) {
        commentsLayer.getSource().removeFeature(feature);
      }
      dispatch(actions.removeLocalOLFeature());
    }
  };

  //---------------------------------
  //Add and remove comments draw mode
  useEffect(() => {
    if (!map) return;

    if (newCommentStatus) {
      map.once("singleclick", onAddMarker);
    } else {
      removeDrawnMarker();
    }
  }, [newCommentStatus, map]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ["image/*", "application/pdf", ".zip"],
    onDrop: (acceptedFiles) => {
      let newImages = myFiles.concat(acceptedFiles);
      if (newImages.length > 5) {
        toast.error(t("maximum5Files"));
      } else {
        setMyFiles(
          newImages.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      }
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAll = () => {
    setMyFiles([]);
  };

  const thumbs = myFiles.map((file) => (
    <div>
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img src={file.preview} style={img} alt="" />
        </div>
      </div>
      <img
        className="w-6 mx-auto mb-4 cursor-pointer"
        src="https://img.icons8.com/cute-clipart/35/000000/delete-forever.png"
        alt="dlt_icon"
        onClick={removeFile(file)}
      />
    </div>
  ));

  const updateModal = async (event) => {
    event.preventDefault();

    if (commentName.trim().length === 0 && description.trim().length === 0) {
      setIsCommentNameValid(false);
      setIsDescriptionValid(false);
      return;
    }

    if (commentName.trim().length === 0) {
      setIsCommentNameValid(false);
      return;
    }

    if (description.trim().length === 0) {
      setIsDescriptionValid(false);
      return;
    }

    setLoading(true);

    let fileUrl = [];
    let fileNames = [];

    for (let i = 0; i < fetchedFiles.length; i++) {
      fileUrl.push(fetchedFiles[i].fileName);
    }

    if (myFiles.length > 0) {
      for (let i = 0; i < myFiles.length; i++) {
        fileNames.push(myFiles[i].name);
      }

      await Axios.post(
        fileUploadUrl,
        {
          fileNames: fileNames,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            // Authorization: testToken,
          },
        }
      ).then(async (response) => {
        for (let i = 0; i < myFiles.length; i++) {
          await axios.put(response.data[i].uploadUrl, myFiles[i]);
          fileUrl.push(response.data[i].fileName);
        }
      });
    }

    const dataBody = {
      name: commentName,
      description: description,
      files: fileUrl,
    };

    const response = dispatch(
      actions.updateWebviewComments(dataBody, selectedCommentId)
    );

    response.then(function (result) {
      if (result.type === "UPDATE_WEBVIEW_COMMENTS_SUCCESS") {
        toast.success(t("commentsUpdatedSuccessfully"));
        setFetchedFiles(result.data.files);
        setLoading(false);
        setEditMode(false);
        setMyFiles([]);
        initComments();
      }

      if (result.type === "UPDATE_WEBVIEW_COMMENTS_FAIL") {
        toast.error(t("failedUpdateComment"));
        setLoading(false);
      }
    });
  };

  const topicChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsCommentNameValid(true);
    }
    setCommentName(event.target.value);
  };

  const descriptionChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsDescriptionValid(true);
    }
    setDescription(event.target.value);
  };

  const deleteAttachment = async (commentId, fileName) => {
    const url = `/webviewercomments/removeFile?id=${commentId}&fileName=${fileName}`;
    try {
      const response = await Axios.delete(url);
      return response;
    } catch (err) {
      throw new Error(err);
    }
  };

  const removeFetchedFile = (commentId, fileName) => {
    promiseToast.promise(deleteAttachment(commentId, fileName), {
      loading: "Loading...",
      success: (data) => {
        initComments();

        // update local file
        const newFiles = fetchedFiles.filter(
          (file) => file.fileName !== fileName
        );
        setFetchedFiles(newFiles);

        return `Attachment has been deleted.`;
      },
      error: "Something went wrong.",
    });
    // const newFiles = [...fetchedFiles];
    // newFiles.splice(newFiles.indexOf(file), 1);
    // setFetchedFiles(newFiles);
  };

  let WebViewCommentsModal = (
    <div className="fixed top-0 left-0 z-10 block w-full h-full pt-40 overflow-auto bg-opacity-30 backdrop-blur">
      <div className="relative w-[95%] md:w-[70%] lg:w-[50%] xl:w-[35%] 2xl:w-[550px] p-0 m-auto bg-white shadow rounded-xl">
        <div className="p-3">
          <div className="flex justify-between align-center">
            <h5 className="text-2xl">Notice</h5>
            <img
              className="w-8 h-8 cursor-pointer"
              src="https://img.icons8.com/color/48/000000/close-window.png"
              alt="Close Window"
              onClick={handlerClosePopUp}
            />
          </div>
          <hr />
          <div className={`${editMode ? "" : "flex"}`}>
            <p className="font-bold">Topic:</p>
            {editMode ? (
              <div className="">
                <input
                  className={`pl-2 rounded-sm h-9 border-2 border-zinc-200 w-full focus:outline-none placeholder-black ${
                    isCommentNameValid ? "" : "border-spekterRed"
                  }`}
                  type="text"
                  placeholder="Topic"
                  value={commentName}
                  onChange={topicChangeHandler}
                />
              </div>
            ) : (
              <p className="pl-1 font-bold">{commentName}</p>
            )}
          </div>

          <div className="">
            <p className="font-bold">Description:</p>
            {editMode ? (
              <textarea
                className={`border-2 p-2 w-full rounded focus:outline-none placeholder-black ${
                  isDescriptionValid ? "" : "border-2 border-spekterRed"
                }`}
                placeholder="Description"
                rows="5"
                value={description}
                onChange={descriptionChangeHandler}
              />
            ) : (
              <p className="">{description}</p>
            )}
          </div>

          <div className="pt-8">
            {editMode ? (
              <div className="border rounded">
                <table className="w-full">
                  <thead className="border-b">
                    <tr>
                      <th className="p-2 bg-gray-100 rounded" colSpan="3">
                        Dateien
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fetchedFiles &&
                      fetchedFiles.map((file) => (
                        <tr>
                          <td className="flex justify-center">
                            <img
                              src="https://img.icons8.com/material-rounded/24/000000/file--v2.png"
                              alt="file img"
                              className="py-2"
                            />
                          </td>
                          <td className="pl-3 border-l">
                            <a
                              href={file.fileUrl}
                              className="text-blue-500 no-underline hover:text-blue-800 visited:text-purple-600"
                            >
                              {file.showName}
                            </a>
                          </td>
                          <td>
                            <div className="flex justify-end pr-2">
                              <input
                                type="button"
                                value="Remove"
                                className="h-8 px-4 ml-3 font-bold border-0 rounded-sm text-spekterWhite bg-spekterRed w-28"
                                onClick={() =>
                                  removeFetchedFile(
                                    selectedCommentId,
                                    file.fileName
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="border rounded">
                <table className="w-full">
                  <thead className="border-b">
                    <tr className="">
                      <th className="px-3 py-2 bg-gray-100 rounded" colSpan="2">
                        Dateien
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fetchedFiles.map((file) => (
                      <tr className="">
                        <td className="flex justify-center">
                          <img
                            src="https://img.icons8.com/material-rounded/24/000000/file--v2.png"
                            alt="file img"
                            className="py-2"
                          />
                        </td>
                        <td className="pl-3 border-l">
                          <a
                            href={file.fileUrl}
                            className="text-blue-500 no-underline hover:text-blue-800"
                          >
                            {file.showName}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          {editMode ? (
            <div className="pt-3">
              <div className="" {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p className="hidden my-auto text-xl md:block">
                  Drop files here
                </p>
                <p className="text-xl md:hidden">Tap here to add photos</p>
              </div>
              <br />
              <aside className="flex justify-center" style={thumbsContainer}>
                {thumbs}
              </aside>
              <div className="flex justify-center">
                {thumbs.length > 1 && (
                  <button
                    className="px-4 mt-2 bg-red-500 rounded text-spekterWhite hover:bg-red-700"
                    onClick={removeAll}
                  >
                    Remove All
                  </button>
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <hr />

          <div className="flex justify-end">
            {editMode ? (
              <div>
                <input
                  type="button"
                  value="Update"
                  className="h-8 px-4 py-1 ml-3 font-bold border-0 rounded-sm text-spekterWhite bg-spekterGreenLight dark:bg-spekterGreen w-28"
                  onClick={updateModal}
                />
                <input
                  type="button"
                  value="Go Back"
                  className="h-8 px-4 ml-3 font-bold border-0 rounded-sm text-spekterWhite bg-spekterRed w-28"
                  onClick={() => setEditMode(false)}
                />
              </div>
            ) : (
              <div>
                <input
                  type="button"
                  value="Edit"
                  className="h-8 px-4 py-1 ml-3 font-bold border-0 rounded-sm text-spekterWhite bg-spekterGreenLight dark:bg-spekterGreen w-28"
                  onClick={() => setEditMode(true)}
                />
                <input
                  type="button"
                  value="Delete"
                  className="h-8 px-4 ml-3 font-bold border-0 rounded-sm text-spekterWhite bg-spekterRed w-28"
                  onClick={removeComments}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) {
    WebViewCommentsModal = (
      <div className="popup">
        <div className="flex items-center justify-center h-full">
          <Spinner />
        </div>
      </div>
    );
  }

  //depriceated
  //Melder
  let harmReportsPopup = (
    <div className="fixed z-10 flex items-center justify-center w-full h-screen overflow-auto bg-opacity-30 backdrop-blur-sm">
      <div className="relative w-[95%] md:w-[70%] lg:w-[50%] xl:w-[35%] 2xl:w-[550px] p-0 m-auto bg-white rounded-lg shadow">
        <div className="p-3">
          <div className="flex justify-between align-center">
            <h5 className="text-2xl">Schadensmeldung</h5>
            <img
              className="w-8 h-8 cursor-pointer"
              src="https://img.icons8.com/color/48/000000/close-window.png"
              alt="Close Window"
              onClick={closeHarmReportModal}
            />
          </div>
          <hr />
          <div className="max-h-[50vh] overflow-y-auto scrollbarLight">
            <div className="font-bold">{reportLocation}</div>
            <div className="flex pt-3">
              <p className="font-bold">Name:</p>
              <p className="pl-1 font-bold">{reportName}</p>
            </div>
            <div className="flex">
              <p className="font-bold">Schadensbild:</p>
              <p className="pl-1 font-bold">{reportType}</p>
            </div>
            <div className="flex">
              <p className="font-bold">Datum Schaden:</p>
              <p className="pl-1 font-bold">{reportDate}</p>
            </div>
            <div className="font-bold">Beschreibung:</div>
            <div className="pt-1">{reportDescription}</div>
            {reportFiles && reportFiles.length > 0 && (
              <>
                <div className="mt-2 font-bold">Dateien:</div>
                <div className="flex flex-wrap gap-4 mt-1 ">
                  {reportFiles.map((file) => (
                    <div className="w-20 h-20 p-1 border-2 cursor-pointer rounded-10">
                      <img
                        onClick={() => {
                          setOpen(false);
                          setTimeout(
                            () => {
                              setPreviewImageLink({
                                link: file?.fileUrl || "",
                                fileName: file?.fileName || "",
                              });
                              setShowImageViewerModal(true);
                            },
                            open ? 500 : 0
                          );
                        }}
                        className="w-[100%] max-h-[100%]"
                        src={
                          getFileExtension(file.fileName) === "pdf"
                            ? "/images/file-dark.svg"
                            : file.fileUrl
                        }
                        // src="/images/file-dark.svg"
                        alt="file"
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {showImageViewerModal && previewImageLink && (
        <ImageViewerModal
          previewImageLink={previewImageLink}
          setShowImageViewerModal={setShowImageViewerModal}
        />
      )}
    </div>
  );

  let harmNotesPopup = (
    <div className="fixed z-10 flex items-center justify-center w-full h-screen bg-opacity-30 backdrop-blur-sm">
      <div className="w-[95%] md:w-[70%] lg:w-[50%] xl:w-[35%] 2xl:w-[550px] bg-white rounded-lg shadow h-3/4 sm:h-4/5">
        <div className="h-full px-3 pb-3">
          <div className="sticky flex justify-between pt-3 bg-white border-b align-center">
            <h5 className="text-2xl">Hinweis</h5>
            <img
              className="w-8 h-8 cursor-pointer"
              src="https://img.icons8.com/color/48/000000/close-window.png"
              alt="Close Window"
              onClick={closeHarmNoteModal}
            />
          </div>
          <div className="h-[93%] overflow-y-auto pt-3">
            <div className="font-bold">{noteLocation}</div>
            <div className="flex pt-3">
              <p className="font-bold">Name:</p>
              <p className="pl-1 font-bold">{noteName}</p>
            </div>
            <div className="font-bold">Beschreibung:</div>
            <div className="pt-1">{noteDescription}</div>
            <div className="pt-2 font-bold">Bilder:</div>
            {images.map((item, index) => {
              return (
                <div className="flex" key={index}>
                  <img src={item} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Modal show={showModal} modalClosed>
        <div>
          <h4>Warning Data</h4>
          <hr />
          <p>
            <strong>Id: </strong>
            {modalData.fid}
          </p>
          <p>
            <strong>Text: </strong>
            {modalData.text}
          </p>
          <p>
            <strong>Risk Number: </strong>
            {modalData.risk_number}
          </p>
          <p>
            <strong>Flood Type: </strong>
            {modalData.flood_type}
          </p>
          <div className="flex justify-end">
            <button
              className="px-3 py-1 bg-gray-500 border-none rounded-sm text-spekterWhite"
              onClick={async () => {
                const signedUrl = await getSignedUrl({
                  id: modalData.fid,
                  ags: isObserver ? selectedObserverAgs : agsList[0],
                });

                window.open(signedUrl, "_blank");
              }}
            >
              Download PDF
            </button>
            <button
              className="px-3 py-1 ml-2 bg-gray-500 border-none rounded-sm text-spekterWhite "
              onClick={(event) => onShowModalHandler(event)}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
      <SideBar map={map} ags={ags} open={open} setOpen={setOpen} />
      {!editMode ? <OLThemeSwitch map={map} /> : null}
      {showPopup ? WebViewCommentsModal : null}
      {showReportPopup ? harmReportsPopup : null}
      {showNotePopup ? harmNotesPopup : null}
      {dataLoading ? (
        <div className="relative w-full h-screen">
          <div className="absolute inset-1/2">
            <Spinner />
          </div>
        </div>
      ) : (
        <div className="w-full h-screen" ref={mapRef}></div>
      )}
    </>
  );
};

export default WebViewer;
