import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PodAddInstruction = ({
  setOpen,
  setAddNewPod,
  setNewMarkerInstruction,
}) => {
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const { t, i18n } = useTranslation("realtimeMap"); //language translation
  return (
    <section className="fixed right-0 flex flex-col items-center justify-center w-full my-3 pointer-events-none bottom-4">
      <div
        className="relative pointer-events-auto flex items-center gap-3 px-[2%] py-[23px] headerShadowLight dark:text-white text-black
          w-[830px] max-w-[90%] md:max-w-[70%] dark:bg-black/70 bg-white/70  backdrop-blur-[61px] rounded-[18px]"
      >
        <span
          onClick={() => {
            setOpen(true);
            setAddNewPod(false);
            setNewMarkerInstruction(false);
          }}
          className="absolute text-2xl cursor-pointer right-4 top-4"
        >
          <img
            src={`/images/pod/X Icon Bold_${
              isThemeDark ? "ffffff" : "000000"
            }.svg`}
            alt="pod icon"
            height={50}
            width={50}
            className="w-3 duration-300 hover:opacity-70"
          />
        </span>
        <div className="self-start">
          <img
            src={`/images/pod/POD Pin Icon_${
              isThemeDark ? "ffffff" : "000000"
            }.svg`}
            alt="pod icon"
            height={50}
            width={50}
            className="h-[33px]"
          />
        </div>
        <div className="flex flex-col gap-4">
          <p className="p-0 m-0 text-[1.5rem] font-bold">{t("addNewPOD")}</p>
          <p className="p-0 m-0 text-sm font-normal">
            {t("selectThePosition")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default PodAddInstruction;
