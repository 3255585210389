import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../../UI/Spinner/Spinner";
import { utcToGermanTime } from "../../../shared/Utility";
import Info from "../Info/Info";
import {
  XAxis,
  YAxis,
  ReferenceLine,
  Tooltip,
  AreaChart,
  Area,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const GflSensorsChart = ({ optionalData, data }) => {
  const { values: rawData, name, sensorType } = data || {};
  const [chartData, setChartData] = useState([]);
  const thresholds = optionalData.thresholds || [];

  const { t } = useTranslation("sensors");

  useEffect(() => {
    if (rawData) {
      const data = rawData.map((item, index) => {
        return {
          date: item.start,
          value: item.value > 0 ? item.value : 0,
        };
      });
      setChartData(data);
    }
  }, [rawData, optionalData]);

  const maintenanceInfo = (
    <div className="text-spekterWhite flex justify-center pt-[80px] gap-2 h-full">
      <img
        className="w-8 h-8"
        src="/images/sensor_manager/icons8-bach-96.png"
        alt="maintenance icon"
      />
      <p className="relative top-[2px] text-[#9CA3AF] font-bold text-lg">
        {t("maintenanceMain")}
      </p>
    </div>
  );
  const WarningLabelOne = (props) => {
    return (
      <g>
        <rect
          x={props.viewBox.x}
          y={props.viewBox.y - 12}
          fill="#343A40"
          width={80}
          height={20}
          rx="5"
          ry="5"
        />
        <text
          x={props.viewBox.x - 20}
          y={props.viewBox.y - 17}
          fill="#fff"
          dy={20}
          dx={30}
          fontSize={10}
        >
          {t("gflWarningStageOne") + " (" + (thresholds[0].level) + "cm)"}
        </text>
      </g>
    );
  };

  const WarningLabelTwo = (props) => {
    return (
      <g>
        <rect
          x={props.viewBox.x + 85}
          y={props.viewBox.y - 12}
          fill="#343A40"
          width={80}
          height={20}
          rx="5"
          ry="5"
        />
        <text
          x={props.viewBox.x + 65}
          y={props.viewBox.y - 18}
          fill="#fff"
          dy={20}
          dx={30}
          fontSize={10}
        >
          {t("gflWarningStageTwo") + " (" + (thresholds[1].level) + "cm)"}
        </text>
      </g>
    );
  };

  const WarningLabelThree = (props) => {
    return (
      <g>
        <rect
          x={props.viewBox.x + 170}
          y={props.viewBox.y - 12}
          fill="#343A40"
          width={80}
          height={20}
          rx="5"
          ry="5"
        />
        <text
          x={props.viewBox.x + 150}
          y={props.viewBox.y - 17}
          fill="#fff"
          dy={20}
          dx={30}
          fontSize={10}
        >
          {t("gflWarningStageThree") + " (" + (thresholds[2].level) + "cm)"}
        </text>
      </g>
    );
  };
  const WarningLabelFour = (props) => {
    return (
      <g>
        <rect
          x={props.viewBox.x + 240}
          y={props.viewBox.y - 11}
          fill="#343A40"
          width={80}
          height={20}
          rx="5"
          ry="5"
        />
        <text
          x={props.viewBox.x + 220}
          y={props.viewBox.y - 16}
          fill="#fff"
          dy={20}
          dx={30}
          fontSize={10}
        >
          {t("gflWarningStageFour") + " (" + (thresholds[3].level) + "cm)"}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="flex flex-col text-white bg-[#333333] p-4 text-sm rounded">
          <p>{`${utcToGermanTime(label, "DD.MM HH:mm")}`}</p>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 bg-[#8200FF]"></div>
            <span className="opacity-70">{`Pegelhöhe : ${payload[0].value} cm`}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  let chart = (
    <ResponsiveContainer width="100%" height={230}>
      <AreaChart
        data={chartData}
        margin={{ top: 20, right: 20, left: -32, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorGfl" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8200FF" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#8200FF" stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          tickFormatter={(dateTime) => utcToGermanTime(dateTime, "HH:mm")}
          interval={5}
          fontSize={12}
          tick={{ fill: "#fff" }}
        />
        <YAxis
          type="number"
          domain={[0, thresholds.length === 4 ? thresholds[3].level * 1.1 : 0]}
          tickFormatter={(value) => value.toFixed(0)}
          fontSize={12}
          tickCount={0}
          tick={{ fill: "#fff" }}
        />
        <CartesianGrid
          strokeDasharray="0 0"
          stroke="#fff"
          strokeWidth={0.4}
          opacity={0.4}
        />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#8200FF"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorGfl)"
        />
        <ReferenceLine
          y={thresholds.length === 4 && thresholds[0].level}
          label={WarningLabelOne}
          stroke="#fff"
          strokeWidth={1}
        />
        <ReferenceLine
          y={thresholds.length === 4 && thresholds[1].level}
          label={WarningLabelTwo}
          stroke="#fff"
          strokeWidth={1}
        />
        <ReferenceLine
          y={thresholds.length === 4 && thresholds[2].level}
          label={WarningLabelThree}
          stroke="#fff"
          strokeWidth={1}
        />
        <ReferenceLine
          y={thresholds.length === 4 && thresholds[3].level}
          label={WarningLabelFour}
          stroke="#fff"
          strokeWidth={1}
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  return (
    <div id="gfl-chart">
      {chartData ? (
        <>
          <Info
            information={name}
            data={chartData[chartData.length - 1]?.value}
            sensorType={sensorType}
            maintenanceMode={optionalData?.maintenance}
          />
          {optionalData?.maintenance ? maintenanceInfo : chart}
        </>
      ) : (
        <div className="flex items-center justify-center mt-20">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default GflSensorsChart;
