import { useEffect, useState, useRef, useMemo } from "react";
import Map from "ol/Map";
import View from "ol/View";
import { fromLonLat } from "ol/proj";
import * as Layers from "../../../../constants/Layers";
import { useDispatch, useSelector } from "react-redux";
import olms from "ol-mapbox-style";
import { chromaticThemeURL } from "../../../../constants/mapboxThemeUrl";
import Vector from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OlStyle from "ol/style/Style";
import OlStyleIcon from "ol/style/Icon";
import OlFeature from "ol/Feature";
import OlPoint from "ol/geom/Point";
import * as actions from "../../../../redux/actions";

const selectPodIcon = (category) => {
  let fileName;

  switch (category) {
    case "firefighters":
      fileName = "Firefighters POD, State=Zoomed Out";
      break;
    case "administration":
      fileName = "Administrative Service POD, State=Zoomed Out";
      break;
    case "police":
      fileName = "Police POD, State=Zoomed Out";
      break;
    case "thw":
      fileName = "THW POD, State=Zoomed Out";
      break;
    default:
      fileName = "POD Icon_aktiv";
      break;
  }

  return `/images/pod/${fileName}.svg`;
};

let pods = [];

const podsLayer = new Vector({
  source: new VectorSource({
    features: [],
    wrapX: false,
  }),
  title: "podLayer",
  style: new OlStyle({
    image: new OlStyleIcon({
      anchor: [0.5, 98],
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      scale: 0.1,
      src: "/images/pod/pod_marker_small.png",
    }),
  }),
  zIndex: 10005,
});

const PodWebviewer = ({ ags, selectedPod }) => {
  const [map, setMap] = useState();
  const [highestLayer, setHighestLayer] = useState();
  const mapRef = useRef();
  const dispatch = useDispatch();

  console.log(highestLayer);

  const customerData = useSelector((state) => state.customer.data);
  const podsData = useSelector((state) => state.pod.pods.data);

  const [tooltipElement, setTooltipElement] = useState(null);

  useEffect(() => {
    // create map view object
    const initView = new View({
      center: [0, 0],
      maxZoom: 19,
      zoomWheelEnabled: false,
      enableRotation: false,
      zoom: 8,
    });

    // initialize openLayer map
    const initialMap = new Map({
      target: mapRef.current,
      controls: [],
      layers: [],
      view: initView,
    });

    //----------------
    //set chromatic theme
    olms(initialMap, chromaticThemeURL).then((map) => {});

    setMap(initialMap);
    return () => {};
  }, []);

  // Map Center
  useEffect(() => {
    if (map) {
      map
        .getView()
        .setCenter(
          fromLonLat([selectedPod.location.lng, selectedPod.location.lat])
        );
      map.getView().setZoom(16);
    }
  }, [map]);

  // Add pods layer
  useEffect(() => {
    if (!map) return;

    map.addLayer(podsLayer);

    dispatch(actions.pods(ags));
  }, [map, ags]);

  useEffect(() => {
    if (!map) return;
    podsLayer.getSource().clear();

    if (!podsData) return;
    pods = podsData;

    if (!podsData) return;

    podsData.forEach((pod) => {
      let coordinate = fromLonLat([pod.location.lng, pod.location.lat]);
      let id = pod._id;

      // Select the appropriate icon based on the pod's category
      let iconPath = selectPodIcon(pod.category);
      let iconStyle = new OlStyle({
        image: new OlStyleIcon({
          anchor: [0.5, 1],
          scale: 0.05,
          src: iconPath,
        }),
      });

      let marker = new OlFeature({
        geometry: new OlPoint(coordinate),
        name: "pod marker",
      });

      // Set the custom style for the marker
      marker.setStyle(iconStyle);
      marker.setId(id);

      // add marker on pods layer.
      podsLayer.getSource().addFeature(marker);
    });
  }, [podsData, map]);

  // Add tooltip on pod marker
  useEffect(() => {
    // Create and configure the tooltip element (runs once on initial render)
    const tooltipDiv = document.createElement("div");
    tooltipDiv.id = "pod-tooltip";

    tooltipDiv.style.cssText = `
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-weight: normal;
    display: none;
  `;

    mapRef.current.appendChild(tooltipDiv);
    setTooltipElement(tooltipDiv);

    return () => {
      tooltipDiv.remove();
    };
  }, []);

  useEffect(() => {
    if (!map) return;

    const onMouseMove = (event) => {
      // Find the map feature under the mouse cursor
      const feature = map.forEachFeatureAtPixel(
        event.pixel,
        (feature) => feature
      );
      if (feature) {
        const pod = pods.find((pod) => pod._id === feature.getId());
        if (pod) {
          tooltipElement.textContent = pod.orderSheet || "(No Order Sheet)";
          tooltipElement.style.display = "block";

          const pixel = map.getPixelFromCoordinate(
            feature.getGeometry().getCoordinates()
          );
          tooltipElement.style.left = `${pixel[0] + 0}px`;
          tooltipElement.style.top = `${pixel[1] + 0}px`;
        } else {
          tooltipElement.style.display = "none";
        }
      } else {
        tooltipElement.style.display = "none";
      }
    };

    map.on("pointermove", onMouseMove);

    return () => map.un("pointermove", onMouseMove);
  }, [map, pods, tooltipElement]);

  // Add layers
  useEffect(() => {
    if (map && customerData) {
      if (customerData?.mapLayers?.strgkMapLayersActive) {
        if (customerData?.mapLayers?.settings?.n1000) {
          map.addLayer(Layers.starkregenShapesN1000(ags));
          setHighestLayer("starkregenShapesN1000");
        } else if (customerData?.mapLayers?.settings?.n100) {
          map.addLayer(Layers.starkregenShapesN100(ags));
          setHighestLayer("starkregenShapesN100");
        } else {
          map.addLayer(Layers.starkregenShapesN30(ags));
          setHighestLayer("starkregenShapesN30");
        }
      } else {
        if (customerData?.mapLayers?.settings?.n5) {
          map.addLayer(Layers.hinweiskartenShapesN5(ags));
          setHighestLayer("hinweiskartenShapesN5");
        } else {
          map.addLayer(Layers.hinweiskartenShapesN1(ags));
          setHighestLayer("hinweiskartenShapesN1");
        }
      }
    }
  }, [customerData, map]);

  // Show highest layer
  useEffect(() => {
    if (highestLayer && map) {
      const layers = map.getLayers().getArray();

      layers.forEach((layer) => {
        if (layer.get("title") === highestLayer) {
          layer.setVisible(true);
        } else {
          layer.setVisible(false);
        }
      });

      const layer = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("title") === "podLayer");

      if (layer) {
        layer.setVisible(true);
      }
    }
  }, [highestLayer, map]);

  return (
    <div className="w-full h-[600px] relative" id="miniMap" ref={mapRef}></div>
  );
};

export default PodWebviewer;
