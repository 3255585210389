import React from "react";

const ShowHideText = ({ text, show }) => {
  return (
    <div className="flex items-center">
      <input
        className="p-0 bg-transparent border-none rounded-none outline-none appearance-none w-[100%]"
        type={show ? "text" : "password"}
        value={text}
        readOnly
      />
    </div>
  );
};

export default ShowHideText;
