import React from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../../redux/actions/index";
import { useTranslation } from "react-i18next";
const ConfirmationModal = ({
  ags,
  open,
  closed,
  userId,
  currentPage,
  userType,
  searchText,
}) => {
  const { t } = useTranslation("modal");
  const dispatch = useDispatch();

  const closeModal = () => {
    closed();
  };

  const modalSubmitHandler = () => {
    const arg = {
      verify: false,
    };

    const response = dispatch(actions.updateUserAction(ags, userId, arg));
    response.then((result) => {
      if (result.type === "UPDATE_USER_SUCCESS") {
        dispatch(actions.usersAction(ags, currentPage, userType, searchText));
      }
    });

    closeModal();
  };

  return (
    <div>
      {open ? (
        <div className="fixed top-0 left-0 z-30 block w-full h-full overflow-auto pt-30vh backdrop-blur-sm">
          <div className="relative p-0 m-auto rounded shadow bg-spekterWhite dark:bg-spekterDarkTwo w-[95%] md:w-[70%] lg:w-[50%] xl:w-[35%] 2xl:w-[550px] h-36">
            <div className="pt-4 pl-4 text-gray-100">
              <p className="text-gray-900 dark:text-gray-100">
                {t("logOutQuestion")}
              </p>
            </div>
            <div className="flex justify-end p-3">
              {/* <input
                type="button"
                value="No"
                className="px-4 py-1 ml-3 text-gray-900 bg-gray-400 border-0 rounded"
                onClick={closeModal}
              /> */}
              <button
                onClick={closeModal}
                className="border-[1px] border-gray-400 w-24 rounded-5 py-1 text-base text-gray-600 dark:text-gray-400"
              >
                Abbrechen
              </button>
              <input
                type="button"
                value="Ja"
                className="w-20 px-4 py-1 ml-3 text-gray-900 border-0 rounded bg-spekterRed"
                onClick={modalSubmitHandler}
              />
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ConfirmationModal;
