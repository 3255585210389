import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useMobileView from "../../../hooks/useMobileView";

const Pagination = ({ currentPage, totalPage, paginate }) => {
  //language package
  const { t, i18n } = useTranslation("pagination");
  const isMobileView = useMobileView();

  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const pageNumbers = [];

  for (let i = 1; i <= totalPage; i++) {
    pageNumbers.push(i);
  }

  const handleNext = () => {
    if (pageNumbers.length !== currentPage) {
      paginate(currentPage + 1);
    }
  };
  const handlePrevious = () => {
    if (currentPage !== 1) {
      paginate(currentPage - 1);
    }
  };

  return (
    <>
      <ul
        className={`items-center py-3 px-3 rounded-md
          ${
            isThemeDark
              ? "bg-spekterDarkOne"
              : "bg-barBG border border-borderGary"
          }
        ${totalPage > 1 || pageNumbers.length > 1 ? "flex" : "hidden"}`}
      >
        <button
          className={`px-2 rounded-sm text-gray-800 dark:text-gray-100 hidden sm:block
          ${currentPage === 1 ? "opacity-25" : "opacity-100"}`}
          onClick={() => paginate(1)}
        >
          {t("first")}
        </button>
        <button
          className={`px-1 sm:px-4 rounded-sm text-gray-800 dark:text-gray-100
          ${currentPage === 1 ? "opacity-25" : "opacity-100"}`}
          onClick={handlePrevious}
        >
          {t("previous")}
        </button>
        {pageNumbers.map((number) => {
          return (
            <li
              className={`${
                number <= currentPage + (isMobileView ? 1 : 3) &&
                number >= currentPage - (isMobileView ? 1 : 3)
                  ? "block"
                  : "hidden"
              } px-1`}
              key={number}
            >
              <button
                className={`${currentPage === number ? "border " : ""} 
                px-2 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-800 rounded-sm`}
                onClick={() => paginate(number)}
              >
                {number}
              </button>
            </li>
          );
        })}
        <button
          className={`px-1 sm:px-4 text-gray-800 dark:text-gray-100 ${
            pageNumbers.length === currentPage ? "opacity-25" : "opacity-100"
          }`}
          onClick={handleNext}
        >
          {t("next")}
        </button>
        <button
          className={`px-2 text-gray-800 dark:text-gray-100 hidden sm:block  ${
            pageNumbers.length === currentPage ? "opacity-25" : "opacity-100"
          }`}
          onClick={() => paginate(pageNumbers.length)}
        >
          {t("last") + ` ( ${pageNumbers.length} )`}
        </button>
      </ul>
    </>
  );
};

export default Pagination;
