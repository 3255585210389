import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as actions from "../../../redux/actions/";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import AboutModal from "../../../containers/About/AboutModal";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import useObserver from "../../../hooks/useObserver";
import { useImmer } from "use-immer";
import useAboutModal from "../../../hooks/useAboutModal";
import useAgs from "../../../hooks/useAgs";
import useRole from "../../../hooks/useRole";
import useRoles from "../../../hooks/useRoles";

const pages = {
  REALTIME_MAP: "realtime_map",
  SENSOR_MANAGER: "senosr_manager",
  WEBVIEWER_MAP: "webviewer_map",
  USERMANAGEMENT: "user_management",
  ANALYTICS: "analytics",
  USER_SETTINGS: "user_settings",
};

const LeftSidebar = (props) => {
  const { t, i18n } = useTranslation("navigation");
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setcurrentPage] = useState(pages.REALTIME_MAP);
  // const [role, setRole] = useState();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { showAboutModal, setShowAboutModal } = useAboutModal();

  const agsList = useAgs();
  const userRole = useRole();
  const userRoles = useRoles();
  const appMode = useObserver() ? "observer" : "non-observer";
  const selectedObserverAgs = useSelector(
    (state) => state.observer.selectedAgs
  );
  const activeModules = useSelector(
    (state) => state.customer?.data?.activeModules
  );

  useEffect(() => {
    if (!pathname) return;
    switch (pathname) {
      case "/realtimemap":
        setcurrentPage(pages.REALTIME_MAP);
        break;
      case "/sensormanager":
        setcurrentPage(pages.SENSOR_MANAGER);
        break;
      case "/usermanagement":
        setcurrentPage(pages.USERMANAGEMENT);
        break;
      case "/analytics":
        setcurrentPage(pages.ANALYTICS);
        break;
      case "/webviewer":
        setcurrentPage(pages.WEBVIEWER_MAP);
        break;
      case "/setting":
        setcurrentPage(pages.USER_SETTINGS);
        break;
      default:
        setcurrentPage(pages.REALTIME_MAP);
    }
  }, [pathname]);

  const pageOnClick = (page) => {
    const ags = selectedObserverAgs || agsList[0];
    switch (page) {
      case pages.REALTIME_MAP:
        dispatch(actions.smartRiverSensorReset());
        dispatch(actions.gflSensorReset());
        dispatch(actions.hdcSensorReset());
        navigate("/");
        break;
      case pages.SENSOR_MANAGER:
        navigate("/sensormanager");
        break;
      case pages.WEBVIEWER_MAP:
        dispatch(actions.smartRiverSensorReset());
        dispatch(actions.gflSensorReset());
        dispatch(actions.hdcSensorReset());
        navigate("/webviewer?ags=" + ags);
        break;
      case pages.USERMANAGEMENT:
        dispatch(actions.smartRiverSensorReset());
        dispatch(actions.gflSensorReset());
        dispatch(actions.hdcSensorReset());
        navigate("/usermanagement?ags=" + ags);
        break;
      case pages.ANALYTICS:
        dispatch(actions.smartRiverSensorReset());
        dispatch(actions.gflSensorReset());
        dispatch(actions.hdcSensorReset());
        navigate("/analytics?ags=" + ags);
        break;
      default:
        break;
    }
  };
  const itemList = [
    {
      title: "Realtime Map",
      imgUrl: "images/menu_icons/map_light.svg",
      imgUrlLightTheme: "images/menu_icons/map_dark.svg",
      allowedUser: ["users", "rescue", "managers", "observer"],
      tooltip: t("map"),
      id: pages.REALTIME_MAP,
      isActive: true,
    },
    {
      title: "Sensor manager",
      imgUrl: "images/menu_icons/sensor_manager_light.svg",
      imgUrlLightTheme: "images/menu_icons/sensor_manager_dark.svg",
      allowedUser: ["rescue", "managers", "observer"],
      tooltip: t("sensorMgmt"),
      id: pages.SENSOR_MANAGER,
      isActive: true,
    },
    {
      title: "User management",
      imgUrl: "images/menu_icons/user_icon_light.svg",
      imgUrlLightTheme: "images/menu_icons/user_icon_dark.svg",
      allowedUser: ["managers", "observer"],
      tooltip: t("userMgmt"),
      id: pages.USERMANAGEMENT,
      isActive: false,
    },
    {
      title: "Analytics",
      imgUrl: "images/menu_icons/analytics_icon_light.svg",
      imgUrlLightTheme: "images/menu_icons/analytics_icon_dark.svg",
      allowedUser: ["managers", "observer"],
      tooltip: t("analytics"),
      id: pages.ANALYTICS,
      isActive: false,
    },
    {
      title: "Webviewer",
      imgUrl: "images/menu_icons/web_view_icon_light.svg",
      imgUrlLightTheme: "images/menu_icons/web_view_icon_dark.svg",
      allowedUser: ["managers", "observer"],
      tooltip: t("webViewer"),
      id: pages.WEBVIEWER_MAP,
      isActive: false,
    },
  ];

  const [menuItems, setMenuItems] = useImmer([]);

  useEffect(() => {
    ReactTooltip.rebuild();
    // if (!userRole) return;
  }, [userRole, menuItems]);

  useEffect(() => {
    setMenuItems(itemList);
    if (!activeModules) return;
    let keys = Object.keys(activeModules);

    setMenuItems((draft) => {
      // swap position of realtime map and webviewer, if realtime map is not active
      if (
        activeModules["realtimeMap"] === false &&
        activeModules["webViewer"] === true
      ) {
        let temp = draft[0];
        draft[0] = draft[4];
        draft[4] = temp;
      }

      keys.forEach((key) => {
        if (key === "realtimeMap") {
          //realtime map
          draft[0].id === pages.REALTIME_MAP
            ? (draft[0].isActive = activeModules[key])
            : (draft[4].isActive = activeModules[key]);
        } else if (key === "sensorManager") {
          //sensor manager
          draft[1].isActive = activeModules[key];
        } else if (key === "analytics") {
          //analytics
          draft[3].isActive = activeModules[key];
        } else if (key === "webViewer") {
          //webviewer
          draft[4].id === pages.WEBVIEWER_MAP
            ? (draft[4].isActive = activeModules[key])
            : (draft[0].isActive = activeModules[key]);
        } else if (key === "userManagement") {
          // user management
          draft[2].isActive = activeModules[key];
        }
      });
      // console.log(menuItems);
    });
  }, [activeModules]);

  return (
    <div className="w-full h-full">
      <div
        id="sidebar"
        className="box-border fixed bottom-0 right-0 z-50 hidden w-20 h-full pt-16 transition-all duration-300 scale-75 sm:block md:scale-100 left-1 md:left-5 2xl:left-10"
      >
        <div className="flex justify-center bg-white/[.69] backdrop-blur-[4.5px] dark:bg-black/[.69] rounded-2xl py-1 h-[90%] mt-10 leftSideBarShadowLight">
          {/* <div className="flex justify-center bg-spekterDarkOne rounded-2xl py-1 h-[80vh] lg:h-[82.25vh] 2xl:h-[85.30vh] mt-10 border-2 border-spekterDarkOne"> */}
          <nav className="relative w-12">
            {menuItems.map((item, index) => {
              if (appMode === "observer") {
                // ---------------
                //No need to check isActive (activeModule) for realtime-map and sensor-manager
                if (
                  item.id === pages.REALTIME_MAP ||
                  item.id === pages.SENSOR_MANAGER
                ) {
                  return (
                    <>
                      {item.allowedUser.includes(appMode) && (
                        <div
                          key={item.id}
                          data-tip={item.tooltip}
                          className={`flex flex-col md:h-12 my-4 justify-center items-center rounded-xl text-spekterWhite p-2 md:px-4 md:py-4 text-center cursor-pointer hover:bg-spekterRed 
                  ${currentPage === item.id ? "bg-spekterRed" : ""}`}
                          onClick={() => pageOnClick(item.id)}
                        >
                          <div className="p-1">
                            <img
                              className="w-full"
                              alt="map"
                              src={
                                currentPage === item.id
                                  ? item.imgUrl
                                  : isThemeDark
                                  ? item.imgUrl
                                  : item.imgUrlLightTheme
                              }
                            />
                          </div>
                        </div>
                      )}
                    </>
                  );
                }

                // -------------------------
                // show Webviewer, if particular observer area is selected
                if (item.id === pages.WEBVIEWER_MAP) {
                  if (!searchParams.get("ags") && !selectedObserverAgs)
                    return null;

                  // check active module to render webviewer
                  return (
                    <>
                      {item.allowedUser.includes(appMode) && item.isActive && (
                        <div
                          key={item.id}
                          data-tip={item.tooltip}
                          className={`flex flex-col md:h-12 my-4 justify-center items-center rounded-xl text-spekterWhite p-2 md:px-4 md:py-4 text-center cursor-pointer hover:bg-spekterRed 
                ${currentPage === item.id ? "bg-spekterRed" : ""}`}
                          onClick={() => pageOnClick(item.id)}
                        >
                          <div className="p-1">
                            <img
                              className="w-full"
                              alt="map"
                              src={
                                currentPage === item.id
                                  ? item.imgUrl
                                  : isThemeDark
                                  ? item.imgUrl
                                  : item.imgUrlLightTheme
                              }
                            />
                          </div>
                        </div>
                      )}
                    </>
                  );
                }

                // render usermanager, analytics based on `manager with active module`
                return (
                  <>
                    {item.allowedUser.includes(appMode) &&
                      selectedObserverAgs &&
                      (userRoles[selectedObserverAgs] || []).includes(
                        "managers"
                      ) && (
                        <div
                          key={item.id}
                          data-tip={item.tooltip}
                          className={`flex flex-col md:h-12 my-4 justify-center items-center rounded-xl text-spekterWhite p-2 md:px-4 md:py-4 text-center cursor-pointer hover:bg-spekterRed 
                ${currentPage === item.id ? "bg-spekterRed" : ""}`}
                          onClick={() => pageOnClick(item.id)}
                        >
                          <div className="p-1">
                            <img
                              className="w-full"
                              alt="map"
                              src={
                                currentPage === item.id
                                  ? item.imgUrl
                                  : isThemeDark
                                  ? item.imgUrl
                                  : item.imgUrlLightTheme
                              }
                            />
                          </div>
                        </div>
                      )}
                  </>
                );
              } else {
                return (
                  <>
                    {item.allowedUser.includes(userRole) && item.isActive && (
                      <div
                        key={item.id}
                        data-tip={item.tooltip}
                        className={`flex flex-col md:h-12 my-4 justify-center items-center rounded-xl text-spekterWhite p-2 md:px-4 md:py-4 text-center cursor-pointer hover:bg-spekterRed
                  ${currentPage === item.id ? "bg-spekterRed" : ""}`}
                        onClick={() => pageOnClick(item.id)}
                      >
                        <div className="p-[3px]">
                          <img
                            className="w-full"
                            alt="map"
                            src={
                              currentPage === item.id
                                ? item.imgUrl
                                : isThemeDark
                                ? item.imgUrl
                                : item.imgUrlLightTheme
                            }
                          />
                        </div>
                      </div>
                    )}
                    {menuItems[0].id === pages.REALTIME_MAP
                      ? index === 2 && (
                          <hr
                            className={`my-4 border-b ${
                              !isThemeDark
                                ? "border-[#C9C9C9]"
                                : "border-gray-700"
                            }`}
                          />
                        )
                      : index === 1 && (
                          <hr
                            className={`my-4 border-b ${
                              !isThemeDark
                                ? "border-[#C9C9C9]"
                                : "border-gray-700"
                            }`}
                          />
                        )}
                  </>
                );
              }
            })}

            <div
              data-tip={t("about")}
              className={`flex flex-col md:h-12 justify-center items-center rounded-xl text-spekterWhite p-2 md:p-4 md:py-4 text-center cursor-pointer  hover:bg-spekterRed
                   absolute bottom-1`}
              onClick={() => setShowAboutModal(!showAboutModal)}
            >
              <div className="p-1">
                <img
                  className="w-full"
                  alt="sensor"
                  src={`images/menu_icons/about-icon-${
                    isThemeDark ? "light" : "dark"
                  }.svg`}
                />
              </div>
            </div>
          </nav>
        </div>
      </div>
      <ReactTooltip />
      <AboutModal />
    </div>
  );
};

export default LeftSidebar;
