import React, { useEffect, useState } from "react";
import Account from "../../components/UserSettings/Account/Account";
import Password from "../../components/UserSettings/Password/Password";
import Notifications from "../../components/UserSettings/Notifications/Notifications";
import { useSelector } from "react-redux";
import PasswordNew from "../../components/UserSettings/Password/PasswordNew";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const tab = {
  ACCOUNT: "account",
  PASSWORD: "password",
  NOTIFICATIONS: "notifications",
};

const UserSettings = (props) => {
  //language package
  const { t, i18n } = useTranslation("userSettings");
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const [searchParams, setSearchParams] = useSearchParams();
  let currentTab = searchParams.get("tabName");
  // console.log(currentTab);
  useEffect(() => {
    if (!currentTab) return;
    switch (currentTab) {
      case tab.ACCOUNT:
        setSearchParams(`tabName=${tab.ACCOUNT}`);
        break;

      case tab.PASSWORD:
        setSearchParams(`tabName=${tab.PASSWORD}`);
        break;
      case tab.NOTIFICATIONS:
        setSearchParams(`tabName=${tab.NOTIFICATIONS}`);
        break;
      default:
        setSearchParams(`tabName=${tab.NOTIFICATIONS}`);
        break;
    }
  }, [currentTab, setSearchParams]);

  const tabOnClick = (clickedTab) => {
    switch (clickedTab) {
      case tab.ACCOUNT:
        // setCurrentTab(tab.ACCOUNT);
        setSearchParams(`tabName=${tab.ACCOUNT}`);
        break;
      case tab.PASSWORD:
        setSearchParams(`tabName=${tab.PASSWORD}`);
        break;
      case tab.NOTIFICATIONS:
        // setCurrentTab(tab.NOTIFICATIONS);
        setSearchParams(`tabName=${tab.NOTIFICATIONS}`);
        break;
      default:
        break;
    }
  };

  return (
    <div className="min-h-screen text-gray-700 dark:text-gray-100 dark:bg-[#151515] pt-[56px] sm:pl-[130px]">
      <div
        className={`flex flex-col items-center overflow-x-auto pt-[20px] sm:pt-[44px] text-spekterWhite h-[calc(100vh_-_56px)]
       dark:sm:scrollbar`}
      >
        <div className="self-start text-[21px] sm:text-2xl font-bold text-gray-800 px-6 dark:text-gray-100">
          {t("title")}
        </div>
        <section className={`h-full w-full text-spekterWhite text-[15px]`}>
          <div className="flex justify-between px-6 py-4 overflow-x-auto rounded-sm gap-x-4 sm:justify-start sm:gap-x-8">
            <div
              className={`flex justify-center gap-[9px] sm:gap-[15px] items-center transition duration-300 rounded-[9px] cursor-pointer
               py-[12px] sm:py-[18px] px-[23px] sm:px-[33px] active:outline-none focus:outline-none 
            ${
              currentTab === tab.ACCOUNT
                ? "bg-spekterRed"
                : `${isThemeDark ? "" : "bg-spekterWhite"} 
            dark:bg-spekterDarkSix  buttonShadowLight dark:text-spekterWhite text-spekterDarkSix`
            }`}
              onClick={() => tabOnClick(tab.ACCOUNT)}
            >
              <img
                className="w-[23px] h-[23px]"
                src={`/images/user_settings/user_${
                  currentTab === tab.ACCOUNT
                    ? "light"
                    : isThemeDark
                    ? "light"
                    : "dark"
                }.svg`}
                alt="avatar"
              />
              <span className="font-[700]">User</span>
            </div>
            <div
              className={`flex justify-center gap-[9px] sm:gap-[15px] items-center transition duration-300 rounded-[9px] cursor-pointer
              py-[12px] sm:py-[18px] px-[23px] sm:px-[33px] active:outline-none focus:outline-none 
            ${
              currentTab === tab.PASSWORD
                ? "bg-spekterRed"
                : `${isThemeDark ? "" : "bg-spekterWhite"} 
            dark:bg-spekterDarkSix  buttonShadowLight dark:text-gray-100 text-gray-700`
            }`}
              onClick={() => tabOnClick(tab.PASSWORD)}
            >
              <img
                className="w-[23px] h-[23px]"
                src={`/images/user_settings/key_${
                  currentTab === tab.PASSWORD
                    ? "light"
                    : isThemeDark
                    ? "light"
                    : "dark"
                }.svg`}
                alt="avatar"
              />
              <span className="font-[700]">{t("pass")}</span>
            </div>

            <div
              className={`flex justify-center gap-[9px] sm:gap-[15px] items-center transition duration-300 rounded-[9px] cursor-pointer
              py-[12px] sm:py-[18px] px-[23px] sm:px-[33px] active:outline-none focus:outline-none
            ${
              currentTab === tab.NOTIFICATIONS
                ? "bg-spekterRed"
                : `${isThemeDark ? "" : "bg-spekterWhite"} 
            dark:bg-spekterDarkSix  buttonShadowLight dark:text-gray-100 text-gray-700`
            }`}
              onClick={() => tabOnClick(tab.NOTIFICATIONS)}
            >
              <img
                className="w-[23px] h-[23px]"
                src={`/images/user_settings/bell_${
                  currentTab === tab.NOTIFICATIONS
                    ? "light"
                    : isThemeDark
                    ? "light"
                    : "dark"
                }.svg`}
                alt="avatar"
              />
              <span className="font-[700]">{t("notify")}</span>
            </div>
          </div>
          <main className=" w-full mx-auto my-0 py-[36px] sm:px-6">
            {currentTab === tab.ACCOUNT ? (
              <Account />
            ) : currentTab === tab.PASSWORD ? (
              <PasswordNew />
            ) : (
              <Notifications />
            )}
          </main>
        </section>
      </div>
    </div>
  );
};

export default UserSettings;
