import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions";
import MunicipalInputField from "../../components/Auth/Register/MunicipalInputField/MunicipalInputField";
import AddressInputField from "../../components/Auth/Register/AddressInputField/AddressInputField";
import { Navigate } from "react-router-dom";
import { toast } from "sonner";
import { StringWhitespaceCheck } from "../../shared/Utility";
import validator from "validator";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/UI/Spinner/Spinner";
import DownloadSection from "../../components/Auth/DownloadSection/DownloadSection";

// test values
// const tempToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6ImFjY2VzcyJ9.eyJpYXQiOjE2MjU3MjUyNjIsImV4cCI6MTYzMzUwMTI2MiwiYXVkIjoiaHR0cHM6Ly95b3VyZG9tYWluLmNvbSIsImlzcyI6ImZlYXRoZXJzIiwic3ViIjoicW9OdDVOZlliWlpQWWlMbVkiLCJqdGkiOiJhNmMzZGZkNi02Yzk0LTRkNjAtYTRjYi01Mzk0YzFiNDdjZTcifQ.L40E7p_HdGzWJC9SN0uuvrfqif1QDHfX9dA_qbBLt14';
// const ags = '09262000';

const Registration = (props) => {
  //language package
  const { t } = useTranslation("settings");

  // form states
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [mobile, setMobile] = useState("");

  const [municipalityAGS, setMunicipalityAGS] = useState();
  const [address, setAddress] = useState("");
  const [checkboxFlag, setCheckboxFlag] = useState(false);

  //error states
  const [isMunicipalityAGSValid, setIsMunicipalityAGSValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isConfirmPassValid, setIsConfirmPassValid] = useState(true);
  const [errorCheckboxFlag, setErrorCheckboxFlag] = useState(true);
  const [redirect, setRedirect] = useState(false);

  // placeholder states
  const [municipalityPlaceholder, setMunicipalityPlaceholder] =
    useState("Kommune auswählen");
  const [namePlaceholder, setNamePlaceholder] = useState("Vorname / Nachname");
  const [adressePlaceholder, setAdressePlaceholder] = useState("Adresse");
  const [phonePlaceholder, setPhonePlaceholder] = useState(
    "Tel. Nr. (Optional)"
  );
  const [mailPlaceholder, setMailPlaceholder] = useState("Email");
  const [passwordPlaceholder, setPasswordPlaceholder] = useState("Passwort");
  const [confirmPasswordPlaceholder, setConfirmPasswordPlaceholder] = useState(
    "Passwort bestätigen"
  );
  const [showMunicipalityTitle, setShowMunicipalityTitle] = useState(false);
  const [showNameTitle, setShowNameTitle] = useState(false);
  const [showAdresseTitle, setShowAdresseTitle] = useState(false);
  const [showPhoneTitle, setShowPhoneTitle] = useState(false);
  const [showMailTitle, setShowMailTitle] = useState(false);
  const [showPasswordTitle, setShowPasswordTitle] = useState(false);
  const [showConfirmPasswordTitle, setShowConfirmPasswordTitle] =
    useState(false);

  // mouse event
  const [isHovered, setIsHovered] = useState(false);

  const dispatch = useDispatch();

  const isLoadingMunicipality = useSelector(
    (state) => state.municipality.loading
  );
  const municipalityData = useSelector((state) => state.municipality.data);

  // Carousel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "/images/fas_slide_1.svg",
    "/images/fas_slide_2.svg",
    "/images/fas_slide_3.svg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const currentImage = images[currentImageIndex];

  useEffect(() => {
    dispatch(actions.municipality());
  }, [dispatch]);

  // mouse event
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // error handler for all input field
  const onErrorHandler = () => {
    console.log("MunicipalityAGS", municipalityAGS);
    let isValid = true;
    if (!municipalityAGS) {
      console.log("HEre");
      setIsMunicipalityAGSValid(false);
      isValid = false;
    } else {
      setIsMunicipalityAGSValid(true);
    }

    if (name.trim().length < 1) {
      setIsNameValid(false);
      isValid = false;
    } else {
      setIsNameValid(true);
    }

    if (address.trim().length < 1) {
      setIsAddressValid(false);
      isValid = false;
    } else {
      setIsAddressValid(true);
    }

    // FIXME:Keeping this code if we want to make mobile number required field

    // if (mobile && (isValidPhoneNumber(mobile) || mobile.length === 0)) {
    //   isValid = true;
    //   setIsMobileValid(true);
    // } else {
    //   setIsMobileValid(false);
    //   isValid = false;
    // }

    // TODO: New code where we make mobile number optional
    if (!mobile || isValidPhoneNumber(mobile)) {
      isValid = true;
      setIsMobileValid(true);
    } else {
      setIsMobileValid(false);
      isValid = false;
    }

    if (email.trim().length < 1) {
      setIsEmailValid(false);
      isValid = false;
    } else if (validator.isEmail(email) === false) {
      setIsEmailValid(false);
      isValid = false;
    } else {
      setIsEmailValid(true);
    }

    if (password === "") {
      setIsPasswordValid(false);
      isValid = false;
    } else if (StringWhitespaceCheck(password) === true) {
      setIsPasswordValid(false);
      toast.error(t("firstOrLastCharacter"));
      isValid = false;
    } else {
      setIsPasswordValid(true);
    }

    if (confirmPass === "") {
      setIsConfirmPassValid(false);
      isValid = false;
      return (isValid = false);
    } else if (confirmPass !== password) {
      toast.error(t("passDidNotMatch"));
      setIsConfirmPassValid(false);
      return (isValid = false);
    } else {
      setIsConfirmPassValid(true);
    }

    if (!checkboxFlag) {
      toast.error(t("markTheCheckbox"));
      return (isValid = false);
    }

    return isValid;
  };

  // submit form
  const onSubmit = (event) => {
    event.preventDefault();
    const isValid = onErrorHandler();

    if (isValid) {
      dispatch(
        actions.reg(
          municipalityAGS.ags,
          email.trim().toLowerCase(),
          name,
          address,
          mobile ? mobile : "",
          password
        )
      ).then((response) => {
        if (response.type === "REG_SUCCESS") {
          toast.success(t("registrationSuccessful"));
          setTimeout(() => setRedirect(true), 3000);
        }

        if (response.type === "REG_FAIL") {
          toast.error(response?.error?.response?.data?.message);
        }
      });
    }
  };

  const removeMunicipalityPlaceholder = () => {
    setMunicipalityPlaceholder("");
    setShowMunicipalityTitle(true);
  };

  const removeNamePlaceholder = () => {
    setNamePlaceholder("");
    setShowNameTitle(true);
  };

  const removeAdressePlaceholder = () => {
    setAdressePlaceholder("");
    setShowAdresseTitle(true);
  };

  const removePhonePlaceholder = () => {
    setPhonePlaceholder("");
    setShowPhoneTitle(true);
  };

  const removeMailPlaceholder = () => {
    setMailPlaceholder("");
    setShowMailTitle(true);
  };

  const removePasswordPlaceholder = () => {
    setPasswordPlaceholder("");
    setShowPasswordTitle(true);
  };

  const removeConfirmPasswordPlaceholder = () => {
    setConfirmPasswordPlaceholder("");
    setShowConfirmPasswordTitle(true);
  };

  const handleMunicipalityBlur = () => {
    if (!municipalityAGS) {
      setMunicipalityPlaceholder("Kommune auswählen");
      setShowMunicipalityTitle(false);
    }
  };

  const handleNameBlur = () => {
    if (name === "") {
      setNamePlaceholder("Vorname / Nachname");
      setShowNameTitle(false);
    }
  };

  const handleAdresseBlur = () => {
    if (address === "") {
      setAdressePlaceholder("Adresse");
      setShowAdresseTitle(false);
    }
  };

  const handlePhoneBlur = () => {
    if (mobile === "") {
      setPhonePlaceholder("Tel. Nr. (Optional)");
      setShowPhoneTitle(false);
    }
  };

  const handleMailBlur = () => {
    if (email === "") {
      setMailPlaceholder("Email");
      setShowMailTitle(false);
    }
  };

  const handlePasswordBlur = () => {
    if (password === "") {
      setPasswordPlaceholder("Passwort");
      setShowPasswordTitle(false);
    }
  };

  const handleConfirmPasswordBlur = () => {
    if (confirmPass === "") {
      setConfirmPasswordPlaceholder("Passwort bestätigen");
      setShowConfirmPasswordTitle(false);
    }
  };

  let form = (
    <form
      onSubmit={onSubmit}
      className="bg-boxBG w-full sm:w-[50%] xl:w-[40%] h-full overflow-y-auto bg-opacity-95 scrollbar pb-16"
    >
      <div className="mx-[20px] sm:mx-[30px] xl:mx-[47px]">
        <div className="flex justify-start">
          <img
            src="/images/Logo_Swisscom_Light.svg"
            alt="fas"
            className="h-16 mt-2"
          />
        </div>
        <div className="flex pt-[60px] sm:pt-[80px] flex-col duration-300 h-full">
          <div className="flex items-center text-[29px] font-bold w-full">
            <span className="mr-4 text-white cursor-pointer">
              Registrierung
            </span>
          </div>
          <span className="text-white text-[15px] w-full mt-2 mb-[35px] sm:mb-[47px]">
            Hier können sie sich in ihren FAS-Account einloggen.
          </span>

          <div
            className={`${
              showMunicipalityTitle ? "opacity-100" : "opacity-0"
            } text-white w-full font-bold pl-[12px]`}
          >
            Kommune auswählen
          </div>
          {isLoadingMunicipality && (
            <div className="w-full xl:w-[425px] flex items-center justify-center">
              <Spinner />
            </div>
          )}
          {municipalityData && (
            <div
              className={`w-full xl:w-[425px] border-2 ${
                isMunicipalityAGSValid ? "border-boxBG" : "border-red-600"
              }`}
            >
              <MunicipalInputField
                agsName={municipalityAGS}
                setAGS={setMunicipalityAGS}
                municipalityData={municipalityData.data}
                municipalityPlaceholder={municipalityPlaceholder}
                removeMunicipalityPlaceholder={removeMunicipalityPlaceholder}
                handleMunicipalityBlur={handleMunicipalityBlur}
              />
            </div>
          )}

          <div
            className={`${
              showNameTitle ? "opacity-100" : "opacity-0"
            } text-white w-full font-bold pl-[12px] mt-[18px]`}
          >
            Vorname / Nachname
          </div>
          <input
            type="text"
            placeholder={namePlaceholder}
            className={`w-full xl:w-[425px] h-[41px] bg-[#333333] border-2 rounded-md text-spekterWhite
             py-3.5 px-3 focus:outline-none placeholder:capitalize ${
               isNameValid ? "border-boxBG" : "border-red-600"
             }`}
            onChange={(event) => setName(event.target.value)}
            value={name}
            onFocus={removeNamePlaceholder}
            onBlur={handleNameBlur}
          />

          {/* address input field */}
          <div
            className={`${
              showAdresseTitle ? "opacity-100" : "opacity-0"
            } text-white w-full font-bold pl-[12px] mt-[18px]`}
          >
            Adresse
          </div>
          <div
            className={`w-full xl:w-[425px] border-2 ${
              isAddressValid ? "border-boxBG" : "border-red-600"
            }`}
          >
            <AddressInputField
              address={address}
              setAddress={setAddress}
              adressePlaceholder={adressePlaceholder}
              removeAdressePlaceholder={removeAdressePlaceholder}
              handleAdresseBlur={handleAdresseBlur}
            />
          </div>

          {/* Phone number input field */}
          <div
            className={`${
              showPhoneTitle ? "opacity-100" : "opacity-0"
            } text-white w-full font-bold pl-[12px] mt-[18px]`}
          >
            Tel. Nr.
          </div>
          <div
            className={`flex w-full xl:w-[425px] h-[41px] pl-6 rounded bg-[#333] text-[#818181] border-2 ${
              isMobileValid ? "border-boxBG" : "border-red-600"
            }`}
          >
            <PhoneInput
              className="w-full phoneInputDark"
              placeholder={phonePlaceholder}
              onChange={setMobile}
              value={mobile}
              international
              countryCallingCodeEditable={true}
              country="DE"
              countries={["DE", "AT", "CH", "FR", "IT", "LU"]}
              onFocus={removePhonePlaceholder}
              onBlur={handlePhoneBlur}
            />
          </div>

          <div
            className={`${
              showMailTitle ? "opacity-100" : "opacity-0"
            } text-white w-full font-bold pl-[12px] mt-[18px]`}
          >
            Email
          </div>
          <input
            type="email"
            placeholder={mailPlaceholder}
            className={`w-full xl:w-[425px] h-[41px] bg-[#333333] border-2 rounded-md text-spekterWhite
             py-3.5 px-3 focus:outline-none placeholder:capitalize ${
               isEmailValid ? "border-boxBG" : "border-red-600"
             }`}
            onChange={(event) => setEmail(event.target.value.trim())}
            value={email}
            onFocus={removeMailPlaceholder}
            onBlur={handleMailBlur}
          />

          <div
            className={`${
              showPasswordTitle ? "opacity-100" : "opacity-0"
            } text-white w-full font-bold pl-[12px] mt-[18px]`}
          >
            Passwort
          </div>
          <input
            type="password"
            placeholder={passwordPlaceholder}
            className={`w-full xl:w-[425px] h-[41px] border-2 bg-[#333333] rounded-md text-spekterWhite py-3.5 px-3 focus:outline-none ${
              isPasswordValid ? "border-boxBG" : "border-red-600"
            }`}
            onChange={(event) => setPassword(event.target.value)}
            value={password}
            onFocus={removePasswordPlaceholder}
            onBlur={handlePasswordBlur}
          />

          <div
            className={`${
              showConfirmPasswordTitle ? "opacity-100" : "opacity-0"
            } text-white w-full font-bold pl-[12px] mt-[18px]`}
          >
            Passwort bestätigen
          </div>
          <input
            type="password"
            placeholder={confirmPasswordPlaceholder}
            className={`w-full xl:w-[425px] h-[41px] border-2 bg-[#333333] rounded-md text-spekterWhite py-3.5 px-3 focus:outline-none ${
              isConfirmPassValid ? "border-boxBG" : "border-red-600"
            }`}
            onChange={(event) => setConfirmPass(event.target.value)}
            value={confirmPass}
            onFocus={removeConfirmPasswordPlaceholder}
            onBlur={handleConfirmPasswordBlur}
          />

          {/* terms and condition checkbox */}
          <div className="flex flex-row mt-[18px] items-center w-full xl:w-[425px]">
            <div
              className="relative flex bg-[#333333] h-[23px] w-[23px] items-center rounded-md cursor-pointer"
              onClick={() => setCheckboxFlag(!checkboxFlag)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {/* <input
                className="appearance-none h-[23px] w-[23px] cursor-pointer checked:border-transparent rounded-md"
                value="checkbox"
                type="checkbox"
                checked={checkboxFlag}
                onChange={() => setCheckboxFlag(!checkboxFlag)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              /> */}
              {isHovered && !checkboxFlag && (
                <img
                  src="/images/hover_x.svg"
                  alt="check"
                  className="h-[15px] w-[15px] absolute cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              )}
              {checkboxFlag && !isHovered && (
                <img
                  src="/images/hover_not_click_x.svg"
                  alt="check"
                  className="h-[15px] w-[15px] absolute cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              )}
              {checkboxFlag && isHovered && (
                <img
                  src="/images/hover_n_click_x.svg"
                  alt="check"
                  className="h-[15px] w-[15px] absolute cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              )}
            </div>
            <div className="pl-[12px] w-[95%] text-spekterWhite text-sm">
              Hiermit erteile ich meine Einwilligung zur Datenverarbeitung gemäß
              dem&nbsp;
              <a
                className="text-white underline"
                target="_blank"
                rel="noreferrer"
                href="https://www.spekter.de/datenschutz.html"
              >
                Datenschutzhinweis.
              </a>
            </div>
          </div>

          <button
            className="sm:mb-10 w-full xl:w-[425px] mt-4 h-[53px] text-base text-center no-underline border-0 cursor-pointer bg-[#04CF00] shadow-btn text-spekterWhite dark:text-gray-800 rounded-5 hover:opacity-80 duration-300"
            type="submit"
          >
            {props.loading ? (
              <div className="flex items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <div className="font-semibold text-[#000000]">
                Jetzt Registrieren
              </div>
            )}
          </button>
        </div>
      </div>
    </form>
  );

  if (redirect) {
    return <Navigate replace to="/" />;
  }

  return (
    <div
      className="overflow-hidden bg-center bg-cover sm:h-screen"
      style={{ backgroundImage: `url(${currentImage})` }}
    >
      <div className="flex flex-col items-center justify-center w-full h-full sm:flex-row">
        {form}
        <DownloadSection
          images={images}
          currentImageIndex={currentImageIndex}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.register.loading,
    redirectToHome: state.register.regRedirectPath,
    error: state.register.error,
  };
};

export default connect(mapStateToProps)(Registration);
