import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectObserverAgs,
  selectObserverName,
} from "../../../../redux/actions";

const ObserverBackButton = () => {
  const dispatch = useDispatch();
  const selectedAgs = useSelector((state) => state.observer.selectedAgs);

  const handleBackButton = () => {
    dispatch(selectObserverAgs(""));
    dispatch(selectObserverName(""));
  };
  return (
    <div
      className={`${
        selectedAgs ? "flex" : "hidden"
      }  items-center justify-center w-10 h-10 duration-300 rounded cursor-pointer bg-spekterDarkOne hover:bg-gray-600`}
      onClick={handleBackButton}
    >
      <img
        // src="i2/images/icons8-chevron-left-90.png"
        width={20}
        src="images/icons8-enlarge-100.svg"
        alt="observer back icon"
      />
    </div>
  );
};

export default ObserverBackButton;
