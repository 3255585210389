import React, { useState, useEffect, useCallback, useRef } from "react";
import DownloadSection from "../../components/Auth/DownloadSection/DownloadSection";
import * as actions from "../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import useMobileView from "../../hooks/useMobileView";
import { set } from "date-fns";
import debounce from "lodash.debounce";

const DefaultScreen = () => {
  const isMobileView = useMobileView();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.allCommunities.data);
  const isLoading = useSelector((state) => state.allCommunities.loading);

  const [kommunePlaceholder, setKommunePlaceholder] = useState(
    "Bitte wählen Sie Ihre Kommune"
  );
  const [showKommuneTitle, setShowKommuneTitle] = useState(false);

  const [value, setValue] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedMunicipality, setSelectedMuncipality] = useState();
  const [showDropdown, setShowdropdown] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);

  const elementRef = useRef(null);

  // Carousel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "/images/fas_slide_1.svg",
    "/images/fas_slide_2.svg",
    "/images/fas_slide_3.svg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  const currentImage = images[currentImageIndex];

  useEffect(() => {
    if (searchKeyword) {
      dispatch(actions.allCommunitiesAction(searchKeyword));
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (elementRef.current && isSelecting) {
      console.log("scrolling");
      elementRef.current.scrollIntoView({ top: -10, behavior: "smooth" });
    }
  }, [isSelecting, isMobileView]);

  const removeKommunePlaceholder = () => {
    setKommunePlaceholder("Suchen...");
    setShowKommuneTitle(true);
    if (isMobileView) {
      setIsSelecting(true);
    }
  };

  const handleKommuneBlur = () => {
    if (!selectedMunicipality) {
      setKommunePlaceholder("Bitte wählen Sie Ihre Kommune");
      setShowKommuneTitle(false);
      if (isMobileView) {
        setIsSelecting(false);
      }
    }
  };

  const publicFormHandler = (e) => {
    e.preventDefault();
    navigate(`/ags/${selectedMunicipality.ags}`);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    debouncer(e.target.value);
    setShowdropdown(true);
    setSelectedMuncipality(false);
  };

  const debouncer = useCallback(
    debounce((value) => {
      setSearchKeyword(value);
    }, 500),
    []
  );

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  const dropdown = (
    <div
      id="dropdownHover"
      className={`z-10 ${
        showDropdown && !isLoading && searchKeyword ? "block" : "hidden"
      } bg-[#333333] divide-gray-700 rounded-lg shadow w-full absolute mt-1 overflow-hidden`}
    >
      <div className="text-sm text-gray-300">
        {data
          ? data?.data?.map((info) => (
              <div
                key={info._id}
                onClick={() => {
                  setValue(`${info.administrationUnit} ${info.municipality}`);
                  setSelectedMuncipality(info);
                  setShowdropdown(false);
                }}
                onTouchStart={(e) => e.preventDefault()}
                onTouchEnd={() => {
                  // Changed from onClick to onTouchEnd
                  setValue(`${info.administrationUnit} ${info.municipality}`);
                  setSelectedMuncipality(info);
                  setShowdropdown(false);
                }}
                className="block px-4 py-3 duration-100 border-b cursor-pointer select-none border-gray-700/50 hover:bg-gray-700"
              >
                {`${info.administrationUnit} ${info.municipality}`}
              </div>
            ))
          : null}
      </div>
    </div>
  );

  const isIOS = /iPhone/.test(navigator.userAgent);

  const handleRescueWorkersLogin = () => {
    navigate("/login");
  };

  let form = (
    <form
      onSubmit={(e) => publicFormHandler(e)}
      className="relative bg-boxBG w-full sm:w-[50%] xl:w-[40%] h-full bg-opacity-95"
      id="form"
    >
      <div className="mx-[20px] sm:mx-[30px] xl:mx-[47px] h-[78%] md:h-auto flex flex-col justify-between">
        <div>
          <div className="mx-[20px] sm:mx-[30px] xl:mx-[47px] h-full">
            <div
              className={`flex justify-start ${
                isIOS && isMobileView ? "mt-8" : ""
              }`}
            >
              <img
                src="/images/Logo_Swisscom_Light.svg"
                alt="fas"
                className="h-20 mt-4 mb-3"
              />
            </div>
            <div className="flex pt-[5vh] sm:pt-[10vh] 2xl:pt-[20vh] flex-col duration-300 h-full">
              <div id="welcomeSection">
                <div
                  id="welcomeText"
                  className="mr-4 font-bold text-[18px] sm:text-[29px] text-white duration-300 cursor-pointer"
                >
                  Willkommen
                </div>
                <div
                  id="subtitle"
                  className="w-full mt-2 mb-5 text-white duration-300"
                >
                  Hier können sie sich in ihren FAS-Account einloggen.
                </div>
              </div>
              <div id="selectSection">
                <div
                  className={`${
                    showKommuneTitle ? "opacity-100" : "opacity-0"
                  } text-white w-full font-bold mt-3`}
                >
                  Bitte wählen Sie Ihre Kommune:
                </div>
                <div
                  ref={elementRef}
                  className="relative w-full xl:w-[425px] mb-1"
                >
                  <input
                    onChange={handleChange}
                    onFocus={removeKommunePlaceholder}
                    onBlur={handleKommuneBlur}
                    value={value}
                    className={`rounded-[5px] bg-[#333333] border-[#333333] h-[50px] w-full px-3 focus:outline-none 
                    text-white border-2 ${
                      selectedMunicipality &&
                      selectedMunicipality.customer &&
                      "border-spekterGreen"
                    } ${
                      selectedMunicipality &&
                      !selectedMunicipality.customer &&
                      "border-red-600"
                    }`}
                    type="text"
                    placeholder={kommunePlaceholder}
                  />
                  {isLoading && searchKeyword && (
                    <div className="absolute inline pt-2 h-[25px] w-[25px] sm:h-[50px] sm:w-[50px] top-[2px] right-5 sm:right-0">
                      <Spinner />
                    </div>
                  )}
                  {!isLoading &&
                    selectedMunicipality &&
                    selectedMunicipality.customer && (
                      <div className="absolute w-5 right-4 top-[15px] cursor-pointer">
                        <img src="/images/right.svg" alt="right" />
                      </div>
                    )}
                  {!isLoading &&
                    selectedMunicipality &&
                    !selectedMunicipality.customer && (
                      <div
                        onClick={() => {
                          setValue("");
                          setSelectedMuncipality(null);
                        }}
                        className="absolute w-5 right-4 top-[15px] cursor-pointer"
                      >
                        <img src="/images/wrong.svg" alt="wrong" />
                      </div>
                    )}

                  {selectedMunicipality && !selectedMunicipality.customer && (
                    <div className="text-[12px] sm:text-[15px] font-[400] text-[#FF0000] absolute sm:right-2 w-full text-end">
                      Diese Kommune ist nicht verfügbar.
                    </div>
                  )}
                  {data?.data && data.data.length > 0 && dropdown}
                </div>

                <button
                  className="w-full xl:w-[425px] disabled:bg-slate-500 mt-4 h-[53px] text-base text-center no-underline border-0 cursor-pointer bg-[#04CF00] shadow-btn text-spekterWhite dark:text-gray-800 rounded-5 hover:opacity-80 duration-300"
                  type="submit"
                  disabled={
                    !selectedMunicipality || !selectedMunicipality.customer
                  }
                >
                  <div className="font-semibold text-white">
                    Zum Frühalarmsystem
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          id="loginButton"
          className="relative sm:absolute -bottom-6 md:bottom-0 left-0 w-full px-[20px] sm:px-[30px] xl:px-[47px] py-4"
        >
          <div className="ml-[10px] sm:mx-[30px] xl:mx-[47px] pb-4 text-center">
            <span
              onClick={handleRescueWorkersLogin}
              className="font-semibold text-[#04CF00] hover:opacity-80 cursor-pointer"
            >
              Login Rettungskräfte
            </span>
          </div>
        </div>
      </div>
    </form>
  );

  return (
    <div
      className="h-screen overflow-hidden bg-center bg-cover"
      style={{ backgroundImage: `url(${currentImage})` }}
    >
      <div className="flex flex-col items-center justify-center w-full h-full sm:flex-row">
        {form}
        <DownloadSection
          images={images}
          currentImageIndex={currentImageIndex}
          handleDotClick={handleDotClick}
        />
      </div>
    </div>
  );
};

export default DefaultScreen;
