import * as actionTypes from '../actionTypes';
import Axios from '../../../services/NetworkService';

export const regPending = () => {
    return {
        type: actionTypes.REG_PENDING
    };
}

export const regSuccess = (data) => {
    return {
        type: actionTypes.REG_SUCCESS,
        data: data
    };
}

export const regFail = (error) => {
    return {
        type: actionTypes.REG_FAIL,
        error: error
    };
}

export const reg = (ags, email, username, street, mobile, password) => {
    let data = {
        "strategy": "local",
        "ags": ags,
        "email": email,
        "username": username,
        "street": street,
        "mobile": mobile,
        "password": password
    };

    return async dispatch => {
        try {
            dispatch(regPending());
            const response = await Axios.post("/users", data);
            return dispatch(regSuccess(response.data));
        } catch (error) {
            return dispatch(regFail(error));
        }
    };
}