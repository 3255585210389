import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import AllSensors from "../../components/SensorManager/AllSensors/AllSensors";
import * as actions from "../../redux/actions/index";
import { useTranslation } from "react-i18next";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Spinner from "../../components/UI/Spinner/Spinner";
import SensorManagerModal from "../../components/SensorManager/Modal/SensorManagerModal";
import Pagination from "../../components/UI/Pagination/Pagination";
import { debounce, getStartEndDateByTimeFrame } from "../../shared/Utility";
import useAgs from "../../hooks/useAgs";
import useObserver from "../../hooks/useObserver";

const SensorManager = () => {
  //Language package
  const { t, i18n } = useTranslation("sensorManager");
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);

  const agsList = useAgs();
  // search
  const [searchText, setSearchText] = useState("");
  const isObserver = useObserver();

  // showButton
  const [showSmartRiverBtn, setShowSmartRiverBtn] = useState(null);
  const [showGflBtn, setShowGflBtn] = useState(null);
  const [showHdcBtn, setShowHdcBtn] = useState(null);
  const [showSmartSewerBtn, setShowSmartSewerBtn] = useState(null);

  // filter control state
  const [smartRiverButton, setSmartRiverButton] = useState(false);
  const [gflButton, setGflButton] = useState(false);
  const [hdcButton, setHdcButton] = useState(false);
  const [smartSewerButton, setSmartSewerButton] = useState(false);

  const [showExplanationModal, setShowExplanationModal] = useState(false);

  //redux states
  const selectedAgs = useSelector((state) => state.observer.selectedAgs);
  const smartriverData = useSelector((state) => state.smartriver.data?.result);
  const hdcData = useSelector((state) => state.hdc.data?.result);
  const gflData = useSelector((state) => state.gfl.data?.result);
  const smartsewerData = useSelector((state) => state.smartsewer.data?.result);
  const smartRiverTotalPage = useSelector(
    (state) => state.smartriver.data?.totalPage
  );
  const hdcTotalPage = useSelector((state) => state.hdc.data?.totalPage);
  const gflTotalPage = useSelector((state) => state.gfl.data?.totalPage);
  const smartSewerTotalPage = useSelector(
    (state) => state.smartsewer.data?.totalPage
  );
  const isLoadingSmartRiver = useSelector(
    (state) => state.smartriver.isLoading
  );
  const isLoadingHdc = useSelector((state) => state.hdc.isLoading);
  const isLoadingGfl = useSelector((state) => state.gfl.isLoading);
  const isLoadingSmartSewer = useSelector(
    (state) => state.smartsewer.isLoading
  );

  // pagination
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (number) => setCurrentPage(number);

  const dispatch = useDispatch();

  let decentralized_icon = isThemeDark
    ? "i2/images/icons8-decentralized-network-250.png"
    : "i2/images/icons8-decentralized-network-250-dark.png";

  useEffect(() => {
    if (!smartRiverTotalPage) return;
    if (smartRiverButton) {
      setTotalPage(smartRiverTotalPage);
    }
  }, [smartRiverTotalPage, smartRiverButton]);

  useEffect(() => {
    if (!hdcTotalPage) return;
    if (hdcButton) {
      setTotalPage(hdcTotalPage);
    }
  }, [hdcTotalPage, hdcButton]);

  useEffect(() => {
    if (!gflTotalPage) return;
    if (gflButton) {
      setTotalPage(gflTotalPage);
    }
  }, [gflTotalPage, gflButton]);

  useEffect(() => {
    if (!smartSewerTotalPage) return;
    if (smartSewerButton) {
      setTotalPage(smartSewerTotalPage);
    }
  }, [smartSewerTotalPage, smartSewerButton]);

  useEffect(() => {
    if (
      smartriverData &&
      smartriverData.length > 0 &&
      showSmartRiverBtn === null
    ) {
      setShowSmartRiverBtn(true);
    }

    if (hdcData && hdcData.length > 0 && showHdcBtn === null) {
      setShowHdcBtn(true);
    }

    if (gflData && gflData.length > 0 && showGflBtn === null) {
      setShowGflBtn(true);
    }

    if (
      smartsewerData &&
      smartsewerData.length > 0 &&
      showSmartSewerBtn === null
    ) {
      setShowSmartSewerBtn(true);
    }
  }, [smartriverData, hdcData, gflData, smartsewerData]);

  // toggling filter button
  const smartRiverButtonClick = () => {
    setSmartRiverButton(!smartRiverButton);
    setGflButton(false);
    setHdcButton(false);
    setSmartSewerButton(false);
    setCurrentPage(1);
  };

  const gflButtonClick = () => {
    setGflButton(!gflButton);
    setSmartRiverButton(false);
    setHdcButton(false);
    setSmartSewerButton(false);
    setCurrentPage(1);
  };

  const hdcButtonClick = () => {
    setHdcButton(!hdcButton);
    setSmartRiverButton(false);
    setGflButton(false);
    setSmartSewerButton(false);
    setCurrentPage(1);
  };

  const sewerButtonClick = () => {
    setSmartSewerButton(!smartSewerButton);
    setSmartRiverButton(false);
    setGflButton(false);
    setHdcButton(false);
    setCurrentPage(1);
  };

  const searchInput = (event) => {
    setCurrentPage(1);
    setSearchText(event.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(searchInput, 500), []);

  // mount sensors
  useEffect(() => {
    const ags = agsList.toString();
    const { start, end } = getStartEndDateByTimeFrame(1440);
    const query = {
      ags: ags,
      page: currentPage,
      limit: 10,
      sort: "alphabetical",
      withData: true,
      // timeFrame: 1440,
      start: start.toISOString(),
      end: end.toISOString(),
      filter: true,
    };

    if (selectedAgs) {
      query.ags = selectedAgs;
    }

    if (searchText) {
      query.searchTerm = searchText;
    }

    dispatch(actions.smartriver({ ...query }));
    dispatch(actions.gfl({ ...query, resolution: 60 }));
    dispatch(actions.hdc({ ...query, resolution: 60 }));

    // turn off smartsewer for observer users
    if (!isObserver) {
      dispatch(
        actions.smartsewer({
          ...query,
          timeFrame: 1440,
          start: null,
          end: null,
        })
      );
    }

    return () => {
      // dispatch(actions.selectObserverAgs(""));
      dispatch(actions.catchmentSuccess([]));
      dispatch(actions.smartriverSuccess([]));
      dispatch(actions.hdcSuccess([]));
      dispatch(actions.gflSuccess([]));
      dispatch(actions.smartsewerSuccess([]));
    };
  }, [currentPage, searchText]);

  const darkInfoIcon = "images/menu_icons/about-icon-dark.svg";
  const lightInfoIcon = "images/menu_icons/about-icon-light.svg";

  const shouldRenderPagination =
    (smartRiverButton && smartriverData && smartriverData.length > 0) ||
    (hdcButton && hdcData && hdcData.length > 0) ||
    (gflButton && gflData && gflData.length > 0) ||
    (smartSewerButton && smartsewerData && smartsewerData.length > 0);

  // Sensor Manager
  return (
    <div className="h-full min-h-screen pt-12 text-gray-100 dark:bg-spekterDarkTwo md:pt-20 md:pl-36 sm:pr-8 md:pb-auto">
      <div
        className={`flex flex-col items-center rounded-10 mt-[25px] h-full sm:h-[82vh] 2xl:h-[85vh] sm:shadow-container dark:shadow-slate-700`}
      >
        <div className="flex md:self-start">
          <p className="text-gray-700 dark:text-gray-100 font-bold pl-[26px] pr-[15px] py-[6px] sm:py-[24px] text-2xl">
            {t("title")}
          </p>
          <img
            onClick={() => setShowExplanationModal(!showExplanationModal)}
            className="h-6 mt-[11px] cursor-pointer md:mt-[29px]"
            alt="sensor"
            src={isThemeDark ? lightInfoIcon : darkInfoIcon}
          />
        </div>
        <div className="mb-4">
          <input
            className={`bg-spekterDarkOne rounded-lg text-gray-200 h-10 sm:h-12 w-60 sm:w-80 px-3
                    focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2`}
            type="text"
            placeholder={t("searchField")}
            onChange={debouncedChangeHandler}
          />
        </div>
        {showSmartRiverBtn || showHdcBtn || showGflBtn || showSmartSewerBtn ? (
          <div className="flex h-24 sm:h-12 flex-row items-center justify-center w-[96%] my-0 text-xl text-gray-500 rounded-md sm:px-3 md:max-w-max bg-spekterDarkOne py-7">
            <div className="hidden text-lg text-gray-200 lg:block">
              <strong>{t("select")}&nbsp; &nbsp;</strong>
            </div>
            <div className="flex flex-wrap">
              {showSmartRiverBtn && (
                <input
                  type="button"
                  value={t("flood")}
                  className={`w-[45%] sm:w-auto text-sm sm:text-lg mx-1 border-none py-1 px-2 rounded-sm ml-2
            ${
              smartRiverButton
                ? "bg-[#343A40] text-gray-100 "
                : "bg-[#1D1E21] text-gray-400"
            }`}
                  onClick={smartRiverButtonClick}
                />
              )}

              {showGflBtn && (
                <input
                  type="button"
                  value={t("gfl")}
                  className={`w-[45%] sm:w-auto text-sm sm:text-lg mx-1 border-none py-1 px-2 rounded-sm text-gray-200 ml-2
            ${
              gflButton
                ? "bg-[#343A40] text-gray-100 "
                : "bg-[#1D1E21] text-gray-400"
            }`}
                  onClick={gflButtonClick}
                />
              )}

              {showHdcBtn && (
                <input
                  type="button"
                  value={t("hdcShort")}
                  className={`mt-1.5 sm:mt-0 w-[45%] sm:w-auto text-sm sm:text-lg mx-1 border-none py-1 px-2 rounded-sm text-gray-200 ml-2
            ${
              hdcButton
                ? "bg-[#343A40] text-gray-100 "
                : "bg-[#1D1E21] text-gray-400"
            }`}
                  onClick={hdcButtonClick}
                />
              )}

              {showSmartSewerBtn && (
                <input
                  type="button"
                  value={t("sewer")}
                  className={`mt-1.5 sm:mt-0 w-[45%] sm:w-auto text-sm sm:text-lg mx-1 border-none py-1 px-2 rounded-sm text-gray-200 ml-2
            ${
              smartSewerButton
                ? "bg-[#343A40] text-gray-100 "
                : "bg-[#1D1E21] text-gray-400"
            }`}
                  onClick={sewerButtonClick}
                />
              )}
            </div>
          </div>
        ) : (
          <Spinner />
        )}
        <div className="pt-3 flex flex-wrap justify-center min-h-[50%] w-full overflow-x-auto scrollbarLight dark:scrollbar">
          {!smartRiverButton &&
          !gflButton &&
          !hdcButton &&
          !smartSewerButton ? (
            <div className="pt-3 opacity-5">
              <img
                className="h-[200px] w-[200px]"
                src={decentralized_icon}
                alt="network icon"
              />
            </div>
          ) : // <div className="m-auto border-2 rounded-sm border-spekterGreenLight dark:border-spekterGreen bg-spekterGreenLight dark:bg-spekterGreen">
          //   <h5 className="p-10 sensor-text-color">
          //     {t("informationTextSensorManager")}
          //   </h5>
          // </div>
          isLoadingSmartRiver ||
            isLoadingHdc ||
            isLoadingGfl ||
            isLoadingSmartSewer ? (
            <Spinner />
          ) : (
            <AllSensors
              smartriverData={smartriverData || []}
              gflData={gflData || []}
              hdcData={hdcData || []}
              smartsewerData={smartsewerData || []}
              smartRiverButton={smartRiverButton}
              gflButton={gflButton}
              hdcButton={hdcButton}
              smartSewerButton={smartSewerButton}
            />
          )}
        </div>
        <div className="mt-1 flex justify-center sticky top-[100vh]">
          {shouldRenderPagination && (
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              paginate={paginate}
            />
          )}
        </div>
        {showExplanationModal && (
          <SensorManagerModal
            setShowExplanationModal={setShowExplanationModal}
          />
        )}
      </div>
    </div>
  );
};

export default SensorManager;
