import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WebPushModal from "./WebPushModal";

const PublicHeader = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, []);

  const navigateToHome = () => {
    navigate("/", { replace: true });
    window.location.reload(); // reload the page to reset the app
  };

  return (
    <div className="background-layer-dark headerShadowLight w-full h-14 fixed top-0 z-1000 py-1 px-[3%] min-w-max">
      <div className="flex items-center justify-between h-full gap-2">
        <div>
          <img
            className="h-10 duration-300 sm:h-14"
            src="/images/Logo_Swisscom_Light.svg"
            alt="Fas_Icon"
          />
        </div>
        <div id="public_header_logout">
          <img
            className="cursor-pointer h-7"
            src="/images/logout.svg"
            alt="notification"
            onClick={navigateToHome}
          />
        </div>
        {/* <div>
          <img
            className="cursor-pointer h-7"
            src="/images/notification_inactive.png"
            alt="notification"
            onClick={() => setShowModal(true)}
          />
        </div> */}
      </div>
      <WebPushModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default PublicHeader;
