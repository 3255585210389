import React, { useEffect, useState } from "react";
import BarToggle from "../../../components/UI/BarToggle/BarToggle";
import { useTranslation } from "react-i18next";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import useMobileView from "../../../hooks/useMobileView";
import { useNavigate, createSearchParams } from "react-router-dom";
import AlarmInfoSideBar from "../CatchmentAlarmInfo/Sidebar";
import { smartRiverSensorAction } from "../../../redux/actions";
import * as actions from "../../../redux/actions";

const selectPodIcon = (category) => {
  const fileName =
    category === "firefighters"
      ? "Firefighters POD, State=Zoomed Out"
      : category === "administration"
      ? "Administrative Service POD, State=Zoomed Out"
      : category === "police"
      ? "Police POD, State=Zoomed Out"
      : category === "thw"
      ? "THW POD, State=Zoomed Out"
      : "POD Icon_aktiv";
  return `/images/pod/${fileName}.svg`;
};

const NormalOverview = ({ userAgs }) => {
  let color = "#A9A9A9";
  const { t } = useTranslation("navigation");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileView = useMobileView();
  const [sideBar, setSideBar] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [openItem, setOpenItem] = useState("catchment");
  const [isAlarmInfoOpen, setIsAlarmInfoOpen] = useState(false);
  const [alarmData, setAlarmData] = useState(undefined);
  const [smartRiverSensor, setSmartRiverSensor] = useState([]);

  const isLoadingCatchment = useSelector((state) => state.catchment.isLoading);
  const catchmentData = useSelector((state) => state.catchment.data);
  const smartriverData = useSelector((state) => state.smartriver.data);
  const hdcData = useSelector((state) => state.hdc.data);
  const podsData = useSelector((state) => state.pod.pods.data);
  const retrieveSmartRiverData = useSelector(
    (state) => state.smartRiverSensor.smartRiverData
  );
  const customerName = useSelector((state) => state.customer.data?.name);

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (/Android/i.test(userAgent)) {
      setPlatform("Android");
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setPlatform("iOS");
    } else {
      setPlatform("Unknown");
    }
  }, []);

  // Get smart river sensors
  useEffect(() => {
    if (smartriverData && smartriverData.length > 0) {
      let tempSmartRiverData = [];
      smartriverData.forEach((sensor) => {
        const tempSensor = {
          label: sensor.name,
          value: sensor._id,
          sensorType: "smartriverdata",
          soleCorrectionValue: sensor.soleCorrectionValue,
        };
        tempSmartRiverData.push(tempSensor);
      });
      setSmartRiverSensor(tempSmartRiverData);
    }
  }, [smartriverData]);

  useEffect(() => {
    if (smartRiverSensor) {
      dispatch(smartRiverSensorAction(1440, smartRiverSensor));
    }
  }, [smartRiverSensor]);

  const catchmentsContent = catchmentData && (
    <div className="max-h-[70%] overflow-y-auto scrollbar pb-3">
      {catchmentData.map((element, i) => {
        if (!element.inactive) {
          element.warningLevel === "level1"
            ? (color = "#51FC42")
            : element.warningLevel === "level3"
            ? (color = "#B9FC00")
            : element.warningLevel === "level4"
            ? (color = "#FF0000")
            : element.warningLevel === "level5"
            ? (color = "#8200FF")
            : (color = "#A9A9A");
        } else {
          color = "#A9A9A9";
        }

        return (
          <div
            className="flex w-full flex-row justify-between items-center p-0.5 hover:cursor-pointer hover:opacity-75"
            key={i}
            onClick={() => {
              setIsAlarmInfoOpen(true);
              setAlarmData(element);
              isMobileView && setSideBar(false);
              navigate({
                pathname: "/realtimemap",
                search: `?${createSearchParams({
                  _id: element._id,
                  ags: userAgs,
                  notificationType: "rain",
                  catchmentLat: element.coordinatesCenter
                    ? element.coordinatesCenter[0]
                    : null,
                  catchmentLng: element.coordinatesCenter
                    ? element.coordinatesCenter[1]
                    : null,
                })}`,
              });
            }}
          >
            <div className="flex flex-shrink float-left text-xs font-semibold">
              {element.name}
            </div>
            <div
              className="flex items-center justify-center float-right w-16 h-6 text-xs font-bold text-center rounded text-spekterDarkOne"
              style={{ backgroundColor: color }}
            >
              {"lastOneHourMaxPrecipitationSum" in element
                ? element.lastOneHourMaxPrecipitationSum.toFixed(2)
                : 0}
            </div>
          </div>
        );
      })}
    </div>
  );

  const smartriverContent =
    retrieveSmartRiverData &&
    smartriverData &&
    smartriverData.length > 0 &&
    smartriverData.map((element, i) => {
      const floodLevel =
        retrieveSmartRiverData[i]?.values.length > 0
          ? retrieveSmartRiverData[i]?.soleCorrectionValue -
            retrieveSmartRiverData[i]?.values[
              retrieveSmartRiverData[i]?.values.length - 1
            ]._value
          : "N/A";
      if (floodLevel === "N/A") return null;
      return (
        <div
          onClick={() => {
            isMobileView && setSideBar(false);
            navigate({
              pathname: "/realtimemap",
              search: `?${createSearchParams({
                _id: element._id,
                ags: userAgs,
                notificationType: "sensor",
              })}`,
            });
          }}
          className="flex w-full flex-row justify-between items-center p-0.5 cursor-pointer hover:opacity-75 duration-300"
          key={i}
        >
          <div className="flex items-center gap-2 text-xs font-semibold">
            <img className="w-5" src="/images/Pegel_inaktiv.svg" alt="Pegel" />
            {element.name}
          </div>
          <div className="flex items-center justify-center float-right w-16 h-6 text-xs font-bold text-center rounded bg-spekterGreen text-spekterDarkOne">
            {isNaN(floodLevel)
              ? floodLevel + " cm"
              : floodLevel + " cm" <= 0
              ? 1
              : floodLevel.toFixed(0) + " cm"}
          </div>
        </div>
      );
    });

  const hdcContent =
    hdcData &&
    hdcData.length > 0 &&
    hdcData.map((element, i) => {
      return (
        <div
          className="flex w-full flex-row justify-between items-center p-0.5"
          key={i}
        >
          <div className="flex items-center gap-2 text-xs font-semibold">
            <img
              className="w-5"
              src="/images/Niederschlag_inaktiv.svg"
              alt="hdc"
            />
            {element.name}
          </div>
          <div
            className="flex items-center justify-center float-right w-16 h-6 text-xs font-bold text-center rounded bg-spekterGreen text-spekterDarkOne"
            // style={{ backgroundColor: color }}
          >
            S0
          </div>
        </div>
      );
    });

  const sensorContent = (
    <div className="max-h-[70%] overflow-y-auto scrollbar mt-1">
      {smartriverContent} {hdcContent}
    </div>
  );

  const podsContent = (
    <div className="max-h-[70%] overflow-y-auto scrollbar mt-1">
      {podsData &&
        podsData.length > 0 &&
        podsData.map((element, i) => {
          return (
            <div
              onClick={() => {
                dispatch(actions.setShowPods(true));
                isMobileView && setSideBar(false);
                navigate({
                  pathname: "/realtimemap",
                  search: `?${createSearchParams({
                    _id: element._id,
                    notificationType: "pod",
                    catchmentLat: element?.location.lat,
                    catchmentLng: element?.location.lng,
                  })}`,
                });
              }}
              className="flex flex-row items-center justify-between w-full p-1 duration-300 cursor-pointer hover:opacity-75"
              key={i}
            >
              <div className="flex items-center gap-2 text-xs font-semibold">
                <img
                  className="w-5"
                  src={selectPodIcon(element?.category)}
                  alt="pod"
                />
                {element.orderSheet || "(no order sheet)"}
              </div>
            </div>
          );
        })}

      <div className=" border-b-[1px] border-gray-500 mt-2"></div>
    </div>
  );

  return (
    <div>
      <BarToggle
        open={sideBar}
        clicked={() => setSideBar(!sideBar)}
        importer="observer"
      />

      <AlarmInfoSideBar
        open={isAlarmInfoOpen}
        setOpen={setIsAlarmInfoOpen}
        data={alarmData}
      />

      <div
        className={`flex flex-col fixed w-[315px] sm:w-[350px] h-[calc(100vh_-_56px)] overflow-y-auto right-0 top-0 z-200 scrollbar  bg-spekterDarkOne text-spekterWhite py-4 px-4 box-border mt-[3.5rem] transition-transform transform ${
          sideBar ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className={`flex flex-col items-start mb-1 sm:mb-3 relative`}>
          <h5>
            <strong>{t("observer")}</strong>
          </h5>
          <div
            onClick={() => setSideBar(false)}
            className="absolute cursor-pointer bottom-6 -right-3 sm:hidden"
          >
            <img
              className="m-1"
              height="20"
              width="20"
              src="/images/sidebar/close_light.svg"
              alt="cancel icon"
            />
          </div>
        </div>

        <div className="flex items-center cursor-pointer">
          <span className="flex flex-shrink text-justify w-72">
            {customerName}
          </span>
        </div>
        <div className="mt-2 border-b-[1px] border-gray-500"></div>

        <div
          className={`${
            platform === "iOS" ? "h-[70%]" : "h-[70%]"
          } md:h-[calc(100vh-175px)] overflow-y-auto scrollbar`}
        >
          <div className="flex justify-end py-2 w-30">
            <div
              className="sticky top-0 flex items-center w-full gap-3 font-semibold cursor-pointer bg-spekterDarkOne"
              onClick={() =>
                setOpenItem((prev) => (prev === "catchment" ? "" : "catchment"))
              }
            >
              Catchments
              <div
                className={`${
                  openItem === "catchment"
                    ? "rotate-[270deg]"
                    : "rotate-[90deg]"
                }`}
              >
                <img
                  className="w-4"
                  src="/i2/images/icons8-chevron-right-90.svg"
                  alt=""
                />
              </div>
            </div>
            {openItem === "catchment" && (
              <span className="text-[.7rem] text-center">
                {t("currentRainAmount")} (mm/h)
              </span>
            )}
          </div>

          {openItem === "catchment" && (
            <>
              {isLoadingCatchment && (
                <div className="flex items-center justify-center h-[70%]">
                  <Spinner />
                </div>
              )}
              {catchmentsContent}
            </>
          )}

          <div className=" border-b-[1px] border-gray-500 mb-2"></div>

          <div
            onClick={() =>
              setOpenItem((prev) => (prev === "sensor" ? "" : "sensor"))
            }
            className="sticky top-0 flex w-full gap-3 font-semibold cursor-pointer bg-spekterDarkOne"
          >
            Sensors
            <div
              className={`${
                openItem === "sensor" ? "rotate-[270deg]" : "rotate-[90deg]"
              }`}
            >
              <img
                className="w-4"
                src="/i2/images/icons8-chevron-right-90.svg"
                alt=""
              />
            </div>
          </div>

          {openItem === "sensor" && sensorContent}

          <div className="border-b-[1px] border-gray-500 my-2"></div>

          <div
            onClick={() => setOpenItem((prev) => (prev === "pod" ? "" : "pod"))}
            className="sticky top-0 flex w-full gap-3 font-semibold cursor-pointer bg-spekterDarkOne"
          >
            POD - Points of Danger
            <div
              className={`${
                openItem === "pod" ? "rotate-[270deg]" : "rotate-[90deg]"
              }`}
            >
              <img
                className="w-4"
                src="/i2/images/icons8-chevron-right-90.svg"
                alt=""
              />
            </div>
          </div>

          {openItem === "pod" && podsContent}
        </div>
      </div>
    </div>
  );
};

export default NormalOverview;
