import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import Login from "../Login/Login";
import RegisterScreen from "../../containers/Register/Register";
import NotFoundPage from "../../containers/404/404NotFound";
import PasswordNewPublic from "../UserSettings/Password/PasswordNewPublic";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import RealtimeMap from "../../containers/RealtimeMap/index";
import AppLayout from "../../hoc/AppLayout/AppLayout";
import useAuth from "../../hooks/useAuth";
import Analytics from "../../containers/Analytics/Analytics";
import SensorManager from "../../containers/SensorManager/SensorManager";
import UserSettings from "../../containers/UserSettings/UserSettings";
import WebViewer from "../../containers/WebViewer/WebViewer";
import UserManagement from "../../containers/UserManager/UserManager";
import useRole from "../../hooks/useRole";
import useObserver from "../../hooks/useObserver";
import useAgs from "../../hooks/useAgs";
import { useImmer } from "use-immer";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions";
import Spinner from "../UI/Spinner/Spinner";
import ChangeEmail from "../../containers/ChangeEmail/ChangeEmail";
import PublicView from "../../containers/RealtimeMap/PublicView/PublicView";
import DefaultScreen from "../../containers/DefaultScreen/DefaultScreen";
import useRoles from "../../hooks/useRoles";

//======== all users type ==========
// "users", "rescue", "managers", "observer", "non-observer"

const AppRouter = () => {
  const dispatch = useDispatch();
  const isAuth = useAuth();
  const userRole = useRole();
  const userRoles = useRoles();
  const agsList = useAgs();
  const isObserver = useObserver();
  let appMode = useObserver() ? "observer" : "non-observer";
  const [searchParams, setSearchParams] = useSearchParams();
  const [isActiveModuleLoaded, setIsActiveModuleLoaded] = useState(true);

  const activeModules = useSelector(
    (state) => state.customer?.data?.activeModules
  );
  const selectedObserverAgs = useSelector(
    (state) => state.observer.selectedAgs
  );

  const [privateRoutes, setPrivateRoutes] = useImmer([
    {
      path: "/realtimemap",
      // path: "/realtimemap/:sensorIdFromNotification?",
      components: <RealtimeMap />,
      allowedUser: ["users", "rescue", "managers", "observer"],
      isActive: false,
    },
    {
      path: "/sensormanager",
      components: <SensorManager />,
      allowedUser: ["rescue", "managers", "observer"],
      isActive: false,
    },
    {
      path: "/usermanagement",
      components: <UserManagement />,
      allowedUser: ["managers", "observer"],
      isActive: true,
    },
    {
      path: "/analytics",
      components: <Analytics />,
      allowedUser: ["managers", "observer"],
      isActive: true,
    },
    {
      path: "/webviewer",
      components: <WebViewer />,
      allowedUser: ["managers", "observer"],
      isActive: true,
    },
    {
      path: "/setting",
      components: <UserSettings />,
      allowedUser: ["users", "rescue", "managers"],
      isActive: false,
    },
  ]);

  const appLoader = (
    <div className="absolute z-10 flex flex-col items-center justify-center left-1/2 top-1/2">
      <div className="flex items-center justify-center h-full">
        <Spinner />
      </div>
    </div>
  );

  let publicRoutes = [
    {
      path: "/",
      components: <DefaultScreen />,
    },
    {
      path: "/login",
      components: <Login />,
    },
    {
      path: "/register",
      components: <RegisterScreen />,
    },
    {
      path: "/forgetpassword",
      components: <PasswordNewPublic />,
    },
    {
      path: "/ags/:id",
      components: <PublicView />,
    },
  ];

  useEffect(() => {
    if (!activeModules) return;
    let keys = Object.keys(activeModules);
    setPrivateRoutes((draft) => {
      keys.forEach((key) => {
        // console.log(key);
        if (key === "realtimeMap") {
          //realtime map
          draft[0].isActive = activeModules[key];
        } else if (key === "sensorManager") {
          //sensor manager
          draft[1].isActive = activeModules[key];
        } else if (key === "userManagement") {
          // user management
          draft[2].isActive = activeModules[key];
        } else if (key === "analytics") {
          //analytics
          draft[3].isActive = activeModules[key];
        } else if (key === "webViewer") {
          //webviewer
          draft[4].isActive = activeModules[key];
        } else if (key === "userSettings") {
          // user settings
          draft[5].isActive = activeModules[key];
        } // } else if (key === "riskReport") {
        //   // riskReport profile
        //   draft[5].isActive = activeModules[key];
        // }
      });
      setIsActiveModuleLoaded(false);
    });
  }, [activeModules]);

  useEffect(() => {
    if (!isAuth) return;
    if (!activeModules) {
      let ags;
      if (searchParams.get("ags")) {
        ags = searchParams.get("ags");
      } else {
        ags = selectedObserverAgs || agsList[0];
      }
      // console.log("ags is", ags);
      dispatch(actions.customerAction(ags));
    }
  }, [isAuth, dispatch, selectedObserverAgs]);

  return (
    <>
      <AppLayout isAuthenticated={isAuth} />
      <Routes>
        {/* Home page */}
        {activeModules && (
          <Route
            key="/"
            path="/"
            element={
              <Navigate
                replace
                to={
                  isAuth
                    ? activeModules["realtimeMap"]
                      ? "/realtimemap"
                      : "/webviewer"
                    : "/"
                }
              />
            }
          />
        )}

        {/*==================
          private routes 
        ===================*/}
        {privateRoutes.map((route) => {
          if (appMode === "observer") {
            // Allowed Webviewer, if particular observer area is selected
            // if (route.path === "/webviewer") {
            //   if (!selectedObserverAgs) return null;
            // }

            if (
              route.path === "/usermanagement" ||
              route.path === "/analytics"
            ) {
              return (
                (userRoles[selectedObserverAgs] || []).includes("managers") && (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<PrivateRoute>{route.components}</PrivateRoute>}
                  />
                )
              );
            }

            // ---------------
            //No need to check activeModule for realtime-map, sensor-manager and webviewer , because these are always active for observer

            return (
              route.allowedUser.includes(appMode) && (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<PrivateRoute>{route.components}</PrivateRoute>}
                />
              )
            );
          } else {
            return (
              route.allowedUser.includes(userRole) &&
              route.isActive && (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<PrivateRoute>{route.components}</PrivateRoute>}
                />
              )
            );
          }
        })}

        {/*==================
          public routes 
        ===================*/}
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<PublicRoute>{route.components}</PublicRoute>}
            />
          );
        })}

        {/* Default routes */}
        {/* <Route key="/risk" path="/risk" element={<RiskForm />} /> */}
        <Route
          key="/change-email"
          path="/change-email/:token"
          element={<ChangeEmail />}
        />

        {/* 
          # Handle unknown path
          ---------------------
          1. Authenticated user -> Show 404 page
          2. Unauthenticated user -> Redirect to /login page > 
        */}
        <Route
          key="/*"
          path="/*"
          element={
            isAuth ? (
              isActiveModuleLoaded ? (
                appLoader
              ) : (
                <NotFoundPage />
              )
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
      </Routes>
    </>
  );
};

export default AppRouter;
