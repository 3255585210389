import React, { useEffect, useState } from "react";
import { STEPS } from "./STEPS";
import { useTranslation } from "react-i18next";
import DownloadSection from "../../Auth/DownloadSection/DownloadSection";
import { useNavigate } from "react-router";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import {
  forgetPassSentOTP,
  forgetPassSubmit,
} from "../../../redux/actions/index";
import { verifyOTP } from "../../../redux/actions/auth/forgetPassword";
import * as toast from "../../UI/Toaster";
import Spinner from "../../UI/Spinner/Spinner";
import OtpInputField from "./OtpInput";
import { StringWhitespaceCheck } from "../../../shared/Utility";

const PasswordNewPublic = () => {
  //Language package
  const { t, i18n } = useTranslation("settings");

  const [currentStep, setCurrentStep] = useState(STEPS.REQUEST_OTP);
  // Request OTP
  const [email, setEmail] = useState("");
  const [mailPlaceholder, setMailPlaceholder] = useState("Email");
  const [showMailTitle, setShowMailTitle] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  // Code verify
  const [otp, setOtp] = useState("");
  const [resendCounter, setResendCounter] = useState(0);
  const [isOtpInvalid, setIsOtpInvalid] = useState(otp.length < 6);
  const [loadingVerification, setLoadingVerification] = useState(false);

  // New Pass
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isConfirmPassValid, setIsConfirmPassValid] = useState(true);
  const [passwordPlaceholder, setPasswordPlaceholder] =
    useState("New Passwort");
  const [confirmPasswordPlaceholder, setConfirmPasswordPlaceholder] =
    useState("Re-type Passwort");
  const [showPasswordTitle, setShowPasswordTitle] = useState(false);
  const [showConfirmPasswordTitle, setShowConfirmPasswordTitle] =
    useState(false);
  const [errorIdxs, setErrorIdxs] = useState([0, 1, 2, 3]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.forgetPass.isLoading);

  useEffect(() => {
    resendCounter > 0 &&
      setTimeout(() => setResendCounter(resendCounter - 1), 1000);
  }, [resendCounter]);

  // Carousel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "/images/fas_slide_1.svg",
    "/images/fas_slide_2.svg",
    "/images/fas_slide_3.svg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  const currentImage = images[currentImageIndex];

  const removeMailPlaceholder = () => {
    setMailPlaceholder("");
    setShowMailTitle(true);
  };

  const handleMailBlur = () => {
    if (email === "") {
      setMailPlaceholder("Email");
      setShowMailTitle(false);
    }
  };

  const removePasswordPlaceholder = () => {
    setPasswordPlaceholder("");
    setShowPasswordTitle(true);
  };

  const removeConfirmPasswordPlaceholder = () => {
    setConfirmPasswordPlaceholder("");
    setShowConfirmPasswordTitle(true);
  };

  const handlePasswordBlur = () => {
    if (password === "") {
      setPasswordPlaceholder("New Passwort");
      setShowPasswordTitle(false);
    }
  };

  const handleConfirmPasswordBlur = () => {
    if (confirmPass === "") {
      setConfirmPasswordPlaceholder("Re-type Passwort");
      setShowConfirmPasswordTitle(false);
    }
  };

  const validationMsg = [
    t("criteriaOne"),
    t("criteriaTwo"),
    t("criteriaThree"),
    t("criteriaFour"),
    t("criteriaFive"),
  ];

  const onPasswordChange = (e) => {
    const password = e.target.value;
    setPassword(password);

    const invalidDataIndexs = [];
    const upperCaseLettersRgx = /[A-Z]/g;
    const lowerCaseLettersRgx = /[a-z]/g;
    const numbersRgx = /[0-9]/g;
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (!password.match(upperCaseLettersRgx)) {
      invalidDataIndexs.push(0);
    }
    if (!password.match(lowerCaseLettersRgx)) {
      invalidDataIndexs.push(1);
    }
    if (!password.match(numbersRgx)) {
      if (!specialChars.test(password)) {
        invalidDataIndexs.push(2);
      }
    }
    if (password.length < 8) {
      invalidDataIndexs.push(3);
    }
    if (StringWhitespaceCheck(password) === true) {
      invalidDataIndexs.push(4);
    }

    setErrorIdxs(invalidDataIndexs);

    if (invalidDataIndexs.length === 0) {
      if (confirmPass !== "") {
        setConfirmPass("");
      }
    }
  };

  const onSubmitEmail = () => {
    if (email.trim().length < 1) {
      setIsEmailValid(false);
      return;
    } else if (validator.isEmail(email) === false) {
      setIsEmailValid(false);
      return;
    } else {
      setIsEmailValid(true);
    }

    dispatch(forgetPassSentOTP(email)).then((response) => {
      if (response.error) {
        if (response.error === 404) {
          toast.error(t("emailDoesNotExist"));
        } else if (response.data.message.includes(t("requestNewOTP"))) {
          toast.error(response?.data?.message);
          setCurrentStep(STEPS.RESEND_OTP);
          setResendCounter(response.data.timer);
        } else {
          toast.error(response?.data?.message || t("somethingWentWrong"));
        }
      } else if (response.data) {
        toast.success(response?.data?.message);
        setCurrentStep(STEPS.RESEND_OTP);
        setResendCounter(response.data.timer);
        setOtp("");
      }
    });
  };

  const onVerifyCode = () => {
    setLoadingVerification(true);
    dispatch(verifyOTP(otp)).then((response) => {
      if (response.error) {
        toast.error(response?.data?.message || t("somethingWentWrong"));
        setLoadingVerification(false);
      } else if (response.data) {
        toast.success(response?.data?.message);
        setCurrentStep(STEPS.NEW_CREDENTIAL);
        setLoadingVerification(false);
      }
    });
  };

  const onResendOTP = () => {
    dispatch(forgetPassSentOTP(email)).then((response) => {
      if (response.error) {
        if (response.error === 404) {
          toast.error(t("emailDoesNotExist"));
        } else {
          toast.error(response?.data?.message || t("somethingWentWrong"));
        }
      } else if (response.data) {
        toast.success(response?.data?.message);
        setResendCounter(response.data.timer);
      }
    });
  };

  const onSubmitNewCredentials = () => {
    if (password === "") {
      setIsPasswordValid(false);
      return;
    } else {
      setIsPasswordValid(true);
    }

    if (confirmPass === "") {
      setIsConfirmPassValid(false);
      return;
    } else {
      setIsConfirmPassValid(true);
    }

    if (errorIdxs.length > 0) {
      toast.error(t("Please match the password criteria"));
      return;
    }

    if (password !== confirmPass) {
      toast.error(t("noMatchPw"));
      return;
    }

    dispatch(forgetPassSubmit(otp, password)).then((response) => {
      if (response.error) {
        toast.error(response?.data?.message);
      } else {
        toast.success(response?.data?.message);
        localStorage.removeItem("token");
        setCurrentStep(STEPS.PASSWORD_UPDATED);
      }
    });
  };

  const genResendDiv = (resendCounter, onResendOTP) => {
    console.log("Resend counter");
    console.log(resendCounter);
    if (resendCounter < 1) {
      return (
        <div
          className="flex items-center justify-center gap-2 my-10 cursor-pointer"
          onClick={onResendOTP}
        >
          <div className="dark:text-spekterGreen text-spekterRed">
            {t("resendCodeLongText")}
          </div>
          <img
            className="w-[18px] h-[18px] relative top-[2px]"
            src="/images/icons8-massen-e-mail-senden-144.png"
            alt=""
          />
        </div>
      );
    }

    return (
      // this div should be greyed out, means not clickable
      <div className="flex items-center gap-2 my-10 text-white opacity-50 cursor-wait">
        <div className="text-spekterGreenLight">{t("resendCodeShortText")}</div>
        <img
          className="w-[18px] h-[18px] relative top-[2px] opacity-25"
          src="/images/icons8-massen-e-mail-senden-144.png"
          alt=""
        />
        {resendCounter}
      </div>
    );
  };

  let form = (
    <div className="bg-boxBG w-full sm:w-[50%] xl:w-[40%] h-full bg-opacity-95">
      <div className="mx-[20px] sm:mx-[30px] xl:mx-[47px] h-full">
        <div className={`flex justify-start`}>
          <img src="/images/fas.png" alt="fas" className="h-8 mt-4 mb-3" />
        </div>
        <div className="flex pt-[60px] sm:pt-[80px] flex-col duration-300 h-full">
          {/* Request for OTP */}
          {currentStep === STEPS.REQUEST_OTP && (
            <>
              <div className="flex items-center text-[29px] font-bold w-full">
                <span className="mr-4 text-white cursor-pointer">
                  {t("forgotPassword")}
                </span>
              </div>

              <span className="text-white text-[15px] w-full mt-2 mb-[35px] sm:mb-[47px]">
                {t("resetPwText")}
              </span>

              <div
                className={`${
                  showMailTitle ? "opacity-100" : "opacity-0"
                } text-white w-full font-bold pl-[12px] mt-[18px]`}
              >
                Email
              </div>

              <input
                type="email"
                placeholder={mailPlaceholder}
                className={`w-full xl:w-[425px] h-[41px] bg-[#333333] border-2 rounded-md text-spekterWhite
             py-3.5 px-3 focus:outline-none placeholder:capitalize ${
               isEmailValid ? "border-boxBG" : "border-red-600"
             }`}
                onChange={(event) => setEmail(event.target.value.trim())}
                value={email}
                onFocus={removeMailPlaceholder}
                onBlur={handleMailBlur}
              />

              <button
                className="mb-4 sm:mb-10 w-full xl:w-[425px] mt-8 h-[53px] text-base text-center no-underline border-0 cursor-pointer bg-[#04CF00] shadow-btn text-spekterWhite dark:text-gray-800 rounded-5 hover:opacity-80 duration-300"
                onClick={onSubmitEmail}
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <div className="font-semibold text-[#000000]">
                    {t("requestPwLink")}
                  </div>
                )}
              </button>

              <div className="w-full xl:w-[425px] text-white text-[14px] flex justify-center">
                <span
                  className="cursor-pointer"
                  onClick={() => setCurrentStep(STEPS.CODE_VERIFICATION)}
                >
                  {t("alreadyCode")}
                </span>
              </div>
              <div className="flex justify-center mt-15">
                <img
                  className="h-4 mt-1 mr-1"
                  src="/images/arrow_left.svg"
                  alt=""
                />
                <div
                  className="no-underline cursor-pointer text-md text-spekterWhite"
                  onClick={() => navigate("/login")}
                >
                  {t("backToLogin")}
                </div>
              </div>
            </>
          )}

          {/* Resend OTP */}
          {currentStep === STEPS.RESEND_OTP && (
            <>
              <div className="flex items-center text-[29px] font-bold w-full">
                <span className="mr-4 text-white cursor-pointer">
                  {t("resetPassword")}
                </span>
              </div>

              <span className="text-white text-[15px] w-full my-2">
                {t("receiveOTP")}
              </span>

              <span className="text-white text-[15px] w-full mt-2 mb-[35px] sm:mb-[47px]">
                {t("checkInbox")}
              </span>

              {genResendDiv(resendCounter, onResendOTP)}

              <button
                className="mb-2 sm:mb-4 w-full xl:w-[425px] h-[53px] text-base text-center no-underline border-0 cursor-pointer bg-[#04CF00] shadow-btn text-spekterWhite dark:text-gray-800 rounded-5 hover:opacity-80 duration-300"
                onClick={() => {
                  setCurrentStep(STEPS.CODE_VERIFICATION);
                }}
              >
                <div className="font-semibold text-[#000000]">
                  {t("continueBtn")}
                </div>
              </button>

              <button
                className="rounded-[4px] w-full h-9 dark:text-spekterGreen cursor-pointer"
                onClick={() => {
                  setCurrentStep(STEPS.REQUEST_OTP);
                }}
              >
                {t("backBtn")}
              </button>
            </>
          )}

          {/* Code Verification */}
          {currentStep === STEPS.CODE_VERIFICATION && (
            <>
              <div className="flex items-center text-[29px] font-bold w-full">
                <span className="mr-4 text-white cursor-pointer">
                  {t("codeVerification")}
                </span>
              </div>

              <span className="text-white text-[15px] w-full mt-2 mb-[35px] sm:mb-[47px]">
                {t("enterOTP")}
                {email}
              </span>

              <OtpInputField
                otp={otp}
                setOtp={setOtp}
                isOtpInvalid={isOtpInvalid}
                setIsOtpInvalid={setIsOtpInvalid}
                isThemeDark={true}
                isPublic={true}
              />

              <button
                className={`mb-2 sm:mb-4 w-full xl:w-[425px] mt-8 h-[53px] text-base text-center no-underline border-0 ${
                  isOtpInvalid ? "cursor-not-allowed" : "cursor-pointer"
                } bg-[#04CF00] shadow-btn text-spekterWhite dark:text-gray-800 rounded-5 hover:opacity-80 duration-300`}
                disabled={isOtpInvalid}
                onClick={onVerifyCode}
              >
                {loadingVerification ? (
                  <div className="flex items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <div className="font-semibold text-[#000000]">
                    {t("verifyCode")}
                  </div>
                )}
              </button>
              <button
                className="rounded-[4px] w-full h-9 dark:text-spekterGreen cursor-pointer"
                disabled={resendCounter > 0}
                onClick={onResendOTP}
              >
                {t("resendCodeShortText")}
                {resendCounter > 0 && resendCounter}
              </button>
            </>
          )}

          {/* New Credentials */}
          {currentStep === STEPS.NEW_CREDENTIAL && (
            <>
              <div className="flex items-center text-[29px] font-bold w-full mb-4">
                <span className="mr-4 text-white cursor-pointer">
                  {t("resetPassword")}
                </span>
              </div>

              {validationMsg.map((msg, i) => {
                const isWrong = errorIdxs.includes(i);
                return (
                  <div className="flex gap-1">
                    <img
                      className="h-4 w-4 relative top-[3px]"
                      src={isWrong ? "/images/wrong.svg" : "/images/right.svg"}
                      alt=""
                    />
                    <p className={`text-[11px] m-1 text-white`}>{msg}</p>
                  </div>
                );
              })}

              <div
                className={`${
                  showPasswordTitle ? "opacity-100" : "opacity-0"
                } text-white w-full font-bold pl-[12px] mt-[18px]`}
              >
                New Passwort
              </div>

              <input
                type="password"
                placeholder={passwordPlaceholder}
                className={`w-full xl:w-[425px] h-[41px] bg-[#333333] border-2 rounded-md text-spekterWhite
             py-3.5 px-3 focus:outline-none placeholder:capitalize ${
               isPasswordValid ? "border-boxBG" : "border-red-600"
             }`}
                onChange={onPasswordChange}
                value={password}
                onFocus={removePasswordPlaceholder}
                onBlur={handlePasswordBlur}
              />

              <div
                className={`${
                  showConfirmPasswordTitle ? "opacity-100" : "opacity-0"
                } text-white w-full font-bold pl-[12px] mt-[18px]`}
              >
                Re-type Passwort
              </div>

              <input
                type="password"
                placeholder={confirmPasswordPlaceholder}
                className={`w-full xl:w-[425px] h-[41px] bg-[#333333] border-2 rounded-md text-spekterWhite
             py-3.5 px-3 focus:outline-none placeholder:capitalize ${
               isConfirmPassValid ? "border-boxBG" : "border-red-600"
             }`}
                onChange={(e) => setConfirmPass(e.target.value)}
                value={confirmPass}
                onFocus={removeConfirmPasswordPlaceholder}
                onBlur={handleConfirmPasswordBlur}
              />

              <button
                className="mb-2 sm:mb-4 w-full xl:w-[425px] mt-8 h-[53px] text-base text-center no-underline border-0 bg-[#04CF00] shadow-btn text-spekterWhite dark:text-gray-800 rounded-5 hover:opacity-80 duration-300 cursor-pointer"
                onClick={onSubmitNewCredentials}
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <div className="font-semibold text-[#000000]">
                    {t("submit")}
                  </div>
                )}
              </button>

              <button
                className="rounded-[4px] w-full h-9 dark:text-spekterGreen cursor-pointer"
                onClick={() => setCurrentStep(STEPS.REQUEST_OTP)}
              >
                {t("cancel")}
              </button>
            </>
          )}

          {/* Password Updated */}
          {currentStep === STEPS.PASSWORD_UPDATED && (
            <>
              <div className="flex items-center text-[29px] font-bold w-full">
                <span className="mr-4 text-white cursor-pointer">
                  {t("updatedPW")}
                </span>
              </div>

              <span className="text-white text-[15px] w-full mt-2 mb-[35px] sm:mb-[47px]">
                {t("updatedPWText")}
              </span>

              <button
                className="mb-4 sm:mb-10 w-full xl:w-[425px] mt-8 h-[53px] text-base text-center no-underline border-0 cursor-pointer bg-[#04CF00] shadow-btn text-spekterWhite dark:text-gray-800 rounded-5 hover:opacity-80 duration-300"
                onClick={() => navigate("/login")}
              >
                <div className="font-semibold text-[#000000]">
                  {t("loginText")}
                </div>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="h-screen bg-center bg-cover sm:overflow-hidden"
      style={{ backgroundImage: `url(${currentImage})` }}
    >
      <div className="flex flex-col items-center justify-center w-full h-full sm:flex-row">
        {form}
        <DownloadSection
          images={images}
          currentImageIndex={currentImageIndex}
        />
      </div>
    </div>
  );
};

export default PasswordNewPublic;
