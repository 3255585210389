import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { utcToGermanTime } from "../../../shared/Utility";
import Spinner from "../../UI/Spinner/Spinner";
import Info from "../Info/Info";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const HdcSensorsChart = ({ sensorData, sum, maintenance }) => {
  const { t } = useTranslation(["sensors"]);

  // hdc cumulative swithc
  const [isCumulative, setIsCumulative] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [ticks, setTicks] = useState([]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="flex flex-col text-white bg-[#333333] p-4 text-sm rounded">
          <p>{`${utcToGermanTime(label, "DD.MM HH:mm")}`}</p>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 bg-[#000BF8]"></div>
            <span className="opacity-70">{`Niederschlag: ${payload[0].value}  mm/h`}</span>
          </div>
          {payload[1] && (
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 bg-[#ff7300]"></div>
              <span className="opacity-70">{`Niederschlag Summe: ${payload[1]?.value} mm`}</span>{" "}
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    // Get 6 date (as a ticks) dynamically from x-axis data
    // always picks the first data and last data from the data array

    let ticks = [];
    let tickCount = 5;

    if (chartData.length > 0) {
      let firstData = chartData[0].start;
      let lastData = chartData[chartData.length - 1].start;
      ticks.push(firstData);
      let interval = Math.round((chartData.length - 1) / tickCount);
      for (let i = 1; i < tickCount; i++) {
        let index = interval * i;
        let date = chartData[index].start;
        ticks.push(date);
      }
      ticks.push(lastData);
    }
    setTicks(ticks);
  }, [chartData]);

  useEffect(() => {
    let cumulativeSum = 0;
    if (sensorData) {
      const data = sensorData?.values.map((item, index) => {
        // pick 1 digit after decimal without rounding by Math.trunc
        const value = Math.trunc(item.value * 10) / 10;
        const sumvalue = cumulativeSum + value;
        cumulativeSum = sumvalue;
        //negative to positive value , like -0.1 to 0.1
        // const absCumulativeData = Math.abs(cumulativeData).toFixed(1);

        // pick 1 digit after decimal without rounding by Math.trunc
        const cumulativeValue = Math.trunc(cumulativeSum * 10) / 10;
        if (isCumulative) {
          return {
            start: item.start,
            BarChart: item?.value >= 0 ? value : 0,
            Ganglinie: cumulativeValue,
          };
        } else {
          return {
            start: item.start,
            BarChart: item?.value >= 0 ? value : 0,
          };
        }
      });
      setChartData(data);
    }
  }, [sensorData, isCumulative]);

  const chart = (
    <ResponsiveContainer width="100%" height={250}>
      <ComposedChart
        width={500}
        height={250}
        data={chartData}
        color="#fff"
        margin={{
          top: 20,
          right: 30,
          bottom: 20,
          // left: 20,
        }}
      >
        {/* <CartesianGrid stroke="#f5f5f5" /> */}
        <XAxis
          dataKey="start"
          fontSize={12}
          ticks={ticks}
          tickFormatter={(dateTime) => utcToGermanTime(dateTime, "HH:mm")}
          tick={{ fill: "#fff" }}
          // <YAxis >
        />
        <YAxis
          tickFormatter={(value) => value.toFixed(1)}
          fontSize={12}
          tick={{ fill: "#fff" }}
        />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Bar dataKey="BarChart" barSize={20} fill="#000BF8" />
        <Line
          type="monotone"
          connectNulls
          dataKey="Ganglinie"
          stroke="#ff7300"
          dot={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );

  return (
    <div id="chart">
      {sensorData ? (
        <>
          <Info
            information={sensorData?.sensorName}
            data={sum}
            sensorType={sensorData?.sensorType}
            maintenanceMode={maintenance}
            isCumulative={isCumulative}
            setIsCumulative={setIsCumulative}
          />

          {maintenance ? (
            <div className="text-spekterWhite flex justify-center items-center h-full p-4 mt-[30px]">
              <img
                className="w-4 h-4"
                src="/images/sensor_manager/icons8-bach-96.png"
                alt="maintenance icon"
              />
              <p className="relative top-[2px] text-[#9CA3AF] font-bold text-lg">
                {t("maintenanceMain")}
              </p>
            </div>
          ) : (
            chart
          )}
        </>
      ) : (
        <div className="flex items-center justify-center mt-20">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default HdcSensorsChart;
