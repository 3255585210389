// style fo react-joyride
export const wizardCustomStylesLight = {
  options: {
    backgroundColor: "#ffffff",
    overlayColor: "rgba(0, 0, 0, 0.5)",
    primaryColor: "#000000",
    textColor: "#333333",
    zIndex: 1000,
  },
  buttonNext: {
    backgroundColor: "#BD2734",
    color: "#ffffff",
    outline: "none",
  },
  buttonBack: {
    color: "#000000",
    outline: "none",
  },
  buttonClose: {
    color: "#ffffff",
    outline: "none",
  },
};
export const wizardCustomStylesDark = {
  options: {
    arrowColor: "#000",
    backgroundColor: "#000000",
    overlayColor: "rgba(0, 0, 0, 0.5)",
    primaryColor: "#fff",
    textColor: "#fff",
    zIndex: 1000,
  },
  buttonNext: {
    backgroundColor: "#BD2734",
    outline: "none",
    color: "#ffffff",
  },
  buttonBack: {
    color: "#ffffff",
    outline: "none",
  },
  buttonClose: {
    color: "#ffffff",
    outline: "none",
  },
};
