import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronDown } from "react-icons/fa";
import SwitchIcon from "../../../components/UI/Switch/Switch";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions/index";

const switchInfo = [
  {
    title: "Risk Areas",
    value: "riskarea",
  },
  {
    title: "Senken & Mulden",
    value: "floodtype1",
  },
  {
    title: "Überflutung wild abfließendes Wasser",
    value: "floodtype2",
  },
  {
    title: "Überflutung Fließgewässer",
    value: "floodtype3",
  },
];

const switchMetaInfo = {
  title: "Risikogefahrenkarte:",
  data: [
    {
      title: "Senken & Mulden",
      color: "bg-[#464646]",
    },
    {
      title: "Überflutung wild abfließendes Wasser",
      color: "bg-[#FFC524]",
    },
    {
      title: "Überflutung Fließgewässer",
      color: "bg-[#FF2222]",
    },
  ],
};

const RiskSwitch = ({ map }) => {
  const { t } = useTranslation("webViewer");
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [sliderValue, setSliderValue] = useState(80);
  const [switchStates, setSwitchStates] = useState({
    riskarea: false,
    floodtype1: false,
    floodtype2: false,
    floodtype3: false,
  });

  const preserveState = useSelector((state) => state.webviewer.preserveState);

  //update the switch state when the map is loaded
  useEffect(() => {
    if (!map) return;
    if (preserveState) {
      setSwitchStates({
        riskarea: preserveState.riskarea,
        floodtype1: preserveState.floodtype1,
        floodtype2: preserveState.floodtype2,
        floodtype3: preserveState.floodtype3,
      });
    }
  }, [map, preserveState]);

  const toggleSwitch = (value) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [value]: !prevState[value],
    }));

    dispatch(
      actions.updateWebviewerStates({
        ...switchStates,
        [value]: !switchStates[value],
      })
    );
  };

  useEffect(() => {
    if (!map) return;
    let opacity = sliderValue / 100;

    map.getLayers().forEach((element) => {
      if (element.get("title") === "riskShapeImageWMS") {
        element.setVisible(switchStates.riskarea);
        element.setOpacity(opacity);
      } else if (element.get("title") === "riskShapeImageWMS_FT1") {
        element.setVisible(switchStates.floodtype1);
        element.setOpacity(opacity);
      } else if (element.get("title") === "riskShapeImageWMS_FT2") {
        element.setVisible(switchStates.floodtype2);
        element.setOpacity(opacity);
      } else if (element.get("title") === "riskShapeImageWMS_FT3") {
        element.setVisible(switchStates.floodtype3);
        element.setOpacity(opacity);
      }
    });
  }, [switchStates, sliderValue, map]);

  return (
    <div>
      <div className="py-8 px-3.5 mb-5 box-border block text-spekterWhite bg-spekterDarkThree shadow-container rounded-md">
        <div
          className="flex justify-between cursor-pointer hover:text-blue-500"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="font-bold text-lg mr-2.5">
            Starkregen Risikokarten
          </div>
          <div className="mt-1">{<FaChevronDown />}</div>
        </div>

        {expanded && (
          <div className="p-1.5 sm:p-2.5 mt-3">
            {switchInfo.map((item, index) => {
              return (
                <div className="flex py-1" key={index}>
                  <SwitchIcon
                    value={switchStates[item.value]}
                    onCheck={() => toggleSwitch(item.value)}
                  />
                  <div className="mt-2 sm:ml-2 text-[13px] sm:text-sm">
                    {item.title}
                  </div>
                </div>
              );
            })}

            <div className="mt-5 slidecontainer">
              <input
                id="typeinp"
                className="slider"
                type="range"
                min="0"
                max="100"
                value={sliderValue}
                onChange={(e) => setSliderValue(parseFloat(e.target.value))}
                step="5"
              />
              <p className="text-sm sm:text-base">
                {t("visibility")} <span>{sliderValue}%</span>
              </p>
            </div>

            {/* additional information about switches */}
            <div className="mt-5 text-sm font-bold sm:text-base">
              {switchMetaInfo.title}
            </div>

            {switchMetaInfo.data.map((item, index) => {
              return (
                <div className="flex mt-2" key={index}>
                  <div className={`${item.color} mt-1 h-5 w-4`}></div>
                  <div className="mt-1 ml-2 text-xs">{item.title}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default RiskSwitch;
