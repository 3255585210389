import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OneSignal from "react-onesignal";
import * as actions from "../../../../redux/actions/index";
import SwitchIcon from "../../../UI/Switch/Switch";
import * as toast from "../../../UI/Toaster";
import SpecialUserModal from "../Modals/SpecialUserModal/SpecialUserModal";

import { useTranslation } from "react-i18next";
import { use } from "i18next";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const MessagingOption = ({ ags, alertData, isInUserSetting, roles }) => {
  //language package
  const { t, i18n } = useTranslation("userSettings");

  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const { width } = useWindowDimensions();
  // waring stage s1
  const [s1smsSwitch, sets1smsSwitch] = useState(false);
  const [s1mailSwitch, sets1mailSwitch] = useState(false);
  const [s1voiceCallSwitch, sets1VoiceCallSwitch] = useState(false);
  const [s1WebPush, setS1WebPush] = useState(true);

  // waring stage s2
  const [s2smsSwitch, sets2smsSwitch] = useState(false);
  const [s2mailSwitch, sets2mailSwitch] = useState(false);
  const [s2voiceCallSwitch, sets2VoiceCallSwitch] = useState(false);
  const [s2WebPush, setS2WebPush] = useState(true);

  // waring stage s3
  const [s3smsSwitch, sets3smsSwitch] = useState(false);
  const [s3mailSwitch, sets3mailSwitch] = useState(false);
  const [s3voiceCallSwitch, sets3VoiceCallSwitch] = useState(true); // default true
  const [s3WebPush, setS3WebPush] = useState(true);

  const [floodLevelWebPush, setFloodLevelWebPush] = useState(true);
  const [capWebPush, setCapWebPush] = useState(true);

  //const showModal = false;
  const [showSpecialUserModal, setShowSpecialUserModal] = useState(false);
  const [userRole, setUserRole] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (alertData) {
      // setup s1 warning
      sets1smsSwitch(alertData.alertSettings.s1.sms);
      sets1VoiceCallSwitch(alertData.alertSettings.s1.voice);
      sets1mailSwitch(alertData.alertSettings.s1.email);

      // setup s2 warning
      sets2smsSwitch(alertData.alertSettings.s2.sms);
      sets2VoiceCallSwitch(alertData.alertSettings.s2.voice);
      sets2mailSwitch(alertData.alertSettings.s2.email);

      // setup s3 warning
      sets3smsSwitch(alertData.alertSettings.s3.sms);
      sets3VoiceCallSwitch(alertData.alertSettings.s3.voice);
      sets3mailSwitch(alertData.alertSettings.s3.email);
    }
  }, [alertData]);

  useEffect(() => {
    if (roles) {
      if (Object.keys(roles).length > 1) {
        setUserRole("users");
      } else if (roles[Object.keys(roles)].length > 1) {
        setUserRole(roles[Object.keys(roles)][1]);
      } else {
        setUserRole(roles[Object.keys(roles)][0]);
      }
    }
  }, [roles]);

  // const specialUserModalToggleHandler = () => {
  //   setShowSpecialUserModal(!showModal);
  // };

  const specialUserModalClosedHandler = () => {
    setShowSpecialUserModal(false);
  };

  // Web push handler
  const handleS1WebPush = () => {
    OneSignal.User.addTags({ s1: !s1WebPush ? "1" : "0" });
    localStorage.setItem("s1_notification", (!s1WebPush).toString());
    setS1WebPush(!s1WebPush);
  };

  const handleS2WebPush = () => {
    // OneSignal.User.addTags({ s2: !s2WebPush ? "1" : "0" });
    localStorage.setItem("s2_notification", (!s2WebPush).toString());
    setS2WebPush(!s2WebPush);
  };

  const handleS3WebPush = () => {
    // OneSignal.User.addTags({ s3: !s3WebPush ? "1" : "0" });
    localStorage.setItem("s3_notification", (!s3WebPush).toString());
    setS3WebPush(!s3WebPush);
  };

  const handleFloodLevelWebPush = () => {
    OneSignal.User.addTags({ floodlevel: !floodLevelWebPush ? "1" : "0" });
    localStorage.setItem(
      "floodlevel_notification",
      (!floodLevelWebPush).toString()
    );
    setFloodLevelWebPush(!floodLevelWebPush);
  };

  const handleCapWebPush = () => {
    OneSignal.User.addTags({ cap: !capWebPush ? "1" : "0" });
    localStorage.setItem("cap_notification", (!capWebPush).toString());
    setCapWebPush(!capWebPush);
  };

  useEffect(() => {
    const s1Notification = localStorage.getItem("s1_notification");
    if (s1Notification) {
      setS1WebPush(s1Notification === "true");
    }
    const s2Notification = localStorage.getItem("s2_notification");
    if (s2Notification) {
      setS2WebPush(s2Notification === "true");
    }
    const s3Notification = localStorage.getItem("s3_notification");
    if (s3Notification) {
      setS3WebPush(s3Notification === "true");
    }
    const floodLevelNotification = localStorage.getItem(
      "floodlevel_notification"
    );
    if (floodLevelNotification) {
      setFloodLevelWebPush(floodLevelNotification === "true");
    }
    const capNotification = localStorage.getItem("cap_notification");
    if (capNotification) {
      setCapWebPush(capNotification === "true");
    }
  }, []);

  const updateSubscriber = (updatedAlertSettings, setSwitchValue) => {
    const payload = { alertSettings: updatedAlertSettings };

    dispatch(actions.updateSubsciberAction(ags, alertData._id, payload)).then(
      (result) => {
        if (result.type === "SUBSCRIBER_SUCCESS") {
          toast.success(t("warningStageUpdated"));
        } else if (result.type === "SUBSCRIBER_FAIL") {
          toast.error(t("failedToUpdate"));
          setSwitchValue &&
            setSwitchValue((switchValue) => {
              return !switchValue;
            });
        }
      }
    );
  };

  return (
    <div
      className={`flex w-full p-2 rounded-xl lg:p-6 ${
        isThemeDark
          ? "bg-spekterDarkSix text-spekterWhite"
          : "text-spekterDarkSix bg-spekterWhite"
      }`}
    >
      <div className="flex flex-col gap-x-[60px] justify-between items-center w-full lg:flex-row">
        {!isInUserSetting && (
          <p className="font-[700] text-[21px] sm:text-2xl">
            {t("notifyTitle")}
          </p>
        )}
        {isInUserSetting && (
          <div className="lg:w-[35%] h-full">
            <p className="font-[700] break-keep text-[21px] sm:text-[24px]">
              Alarm-Einstellungen
            </p>
            <p className="font-normal text-[15px]">
              Hier können Sie wählen, über welchen Kanal Sie bei welcher
              Gefahrenstufen alarmiert werden möchten. Die Funktion VoiceCall
              wird nur bei Gefahrenstufe S3 ausgelöst und kann nicht verändert
              werden.
            </p>
          </div>
        )}

        {alertData ? (
          width > 640 ? (
            <div
              className={`flex justify-center lg:w-[65%] gap-2 pt-5 lg:gap-4 duration-200`}
            >
              <div className="flex flex-col justify-end mb-8 gap-[26px] pr-2">
                {/* <div className="flex items-center gap-2 w-28 br">
                  <span className="text-sm text-gray-800 dark:text-gray-100">
                    Web Push
                  </span>
                </div> */}
                <div className="flex items-center gap-2 w-28">
                  <img
                    src={
                      isThemeDark
                        ? "/images/sms_light.svg"
                        : "/images/sms_dark.svg"
                    }
                    alt="sms"
                    className="hidden h-4 sm:block"
                  />
                  <span className="text-sm text-gray-800 dark:text-gray-100">
                    {t("SMS")}
                  </span>
                </div>
                <div className="flex items-center gap-2 w-28">
                  <img
                    src={
                      isThemeDark
                        ? "/images/mail_light.svg"
                        : "/images/mail_dark.svg"
                    }
                    alt="mail"
                    className="hidden h-[14px] sm:block"
                  />
                  <span className="text-sm text-gray-800 dark:text-gray-100">
                    {t("mail")}
                  </span>
                </div>

                <div className="flex items-center gap-2 w-28">
                  <img
                    src={
                      isThemeDark
                        ? "/images/phone_light.svg"
                        : "/images/phone_dark.svg"
                    }
                    alt="PhoneIcon"
                    className="hidden h-4 sm:block"
                  />
                  <span className="text-sm text-gray-800 dark:text-gray-100 whitespace-nowrap">
                    {t("voiceCall")}
                  </span>
                </div>
              </div>
              <div className="w-20 py-3 flex flex-col items-center bg-[#F2CB53] rounded-[9px] text-[#FFFFFF]">
                <p>{t("S1")}</p>
                {/* <div>
                  <SwitchIcon
                    value={false}
                    bgColor="bg-[#BA9C40]"
                    onCheck={handleS1WebPush}
                  />
                </div> */}
                <div>
                  <SwitchIcon
                    value={s1smsSwitch}
                    bgColor="bg-[#BA9C40]"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s1: {
                          sms: !s1smsSwitch,
                        },
                      };
                      updateSubscriber(updatedAlertSetting, sets1smsSwitch);
                      sets1smsSwitch(!s1smsSwitch);
                    }}
                    disabled={userRole === "users" && true}
                  />
                </div>
                <div>
                  <SwitchIcon
                    value={s1mailSwitch}
                    bgColor="bg-[#BA9C40]"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s1: {
                          email: !s1mailSwitch,
                        },
                      };
                      updateSubscriber(updatedAlertSetting, sets1mailSwitch);
                      sets1mailSwitch(!s1mailSwitch);
                    }}
                  />
                </div>
                {userRole !== "users" && (
                  <div>
                    <SwitchIcon
                      value={s1voiceCallSwitch}
                      bgColor="bg-[#BA9C40]"
                      onCheck={() => {
                        const updatedAlertSetting = {
                          s1: {
                            voice: !s1voiceCallSwitch,
                          },
                        };
                        updateSubscriber(
                          updatedAlertSetting,
                          sets1VoiceCallSwitch
                        );
                        sets1VoiceCallSwitch(!s1voiceCallSwitch);
                      }}
                      disabled={userRole === "managers" || ("rescue" && true)}
                    />
                  </div>
                )}
              </div>
              <div className="w-20 py-3 flex flex-col items-center bg-[#E41931] rounded-[9px] text-[#FFFFFF]">
                <p>{t("S2")}</p>
                {/* <div>
                  <SwitchIcon
                    value={false}
                    bgColor="bg-[#b01326]"
                    onCheck={handleS2WebPush}
                  />
                </div> */}
                <div>
                  <SwitchIcon
                    value={s2smsSwitch}
                    bgColor="bg-[#b01326]"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s2: {
                          sms: !s2smsSwitch,
                        },
                      };
                      updateSubscriber(updatedAlertSetting, sets2smsSwitch);
                      sets2smsSwitch(!s2smsSwitch);
                    }}
                  />
                </div>

                <div>
                  <SwitchIcon
                    value={s2mailSwitch}
                    bgColor="bg-[#b01326]"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s2: {
                          email: !s2mailSwitch,
                        },
                      };
                      updateSubscriber(updatedAlertSetting, sets2mailSwitch);
                      sets2mailSwitch(!s2mailSwitch);
                    }}
                  />
                </div>
                {userRole !== "users" && (
                  <div>
                    <SwitchIcon
                      value={s2voiceCallSwitch}
                      bgColor="bg-[#b01326]"
                      onCheck={() => {
                        const updatedAlertSetting = {
                          s2: {
                            voice: !s2voiceCallSwitch,
                          },
                        };
                        updateSubscriber(
                          updatedAlertSetting,
                          sets2VoiceCallSwitch
                        );
                        sets2VoiceCallSwitch(!s2voiceCallSwitch);
                      }}
                      disabled={userRole === "managers" || ("rescue" && true)}
                    />
                  </div>
                )}
              </div>
              <div className="w-20 py-3 flex flex-col items-center bg-[#9F33E5] rounded-[9px] text-[#FFFFFF]">
                <p>{t("S3")}</p>
                {/* <div>
                  <SwitchIcon
                    value={false}
                    bgColor="bg-[#7a27b0]"
                    onCheck={handleS3WebPush}
                  />
                </div> */}
                <div>
                  <SwitchIcon
                    value={s3smsSwitch}
                    bgColor="bg-[#7a27b0]"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s3: {
                          sms: !s3smsSwitch,
                        },
                      };
                      updateSubscriber(updatedAlertSetting, sets3smsSwitch);
                      sets3smsSwitch(!s3smsSwitch);
                    }}
                  />
                </div>
                <div>
                  <SwitchIcon
                    value={s3mailSwitch}
                    bgColor="bg-[#7a27b0]"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s3: {
                          email: !s3mailSwitch,
                        },
                      };
                      updateSubscriber(updatedAlertSetting, sets3mailSwitch);
                      sets3mailSwitch(!s3mailSwitch);
                    }}
                  />
                </div>
                {/* {userRole !== "users" && ( */}
                <div>
                  <SwitchIcon
                    value={s3voiceCallSwitch}
                    bgColor="bg-[#7a27b0]"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s3: {
                          voice: !s3voiceCallSwitch,
                        },
                      };
                      updateSubscriber(
                        updatedAlertSetting,
                        sets3VoiceCallSwitch
                      );
                      sets3VoiceCallSwitch(!s3voiceCallSwitch);
                    }}
                  />
                </div>
              </div>
              {/* <div className="w-24 py-3 flex flex-col items-center bg-[#3733E5] rounded-[9px] text-[#FFFFFF]">
                <p className="text-center">Flood Level</p>
                <div>
                  <SwitchIcon
                    value={false}
                    bgColor="bg-[#2a27b0]"
                    onCheck={handleFloodLevelWebPush}
                  />
                </div>
              </div>
              <div className="w-20 py-3 flex flex-col items-center bg-[#33E590] rounded-[9px] text-[#FFFFFF]">
                <p>CAP</p>
                <div>
                  <SwitchIcon
                    value={false}
                    bgColor="bg-[#27b06e]"
                    onCheck={handleCapWebPush}
                  />
                </div>
              </div> */}
            </div>
          ) : (
            // mobile ui
            <div
              className={`flex flex-col justify-between w-full lg:w-[60%] gap-2 sm:gap-10 duration-200`}
            >
              <div className="flex justify-between p-[15px]">
                <div className="flex items-center w-2"></div>
                <div className="flex items-center justify-center">
                  <img
                    src={
                      isThemeDark
                        ? "/images/sms_light.svg"
                        : "/images/sms_dark.svg"
                    }
                    alt="sms"
                    className="h-4 sm:block"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <img
                    src={
                      isThemeDark
                        ? "/images/mail_light.svg"
                        : "/images/mail_dark.svg"
                    }
                    alt="mail"
                    className=" h-[14px] sm:block"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <img
                    src={
                      isThemeDark
                        ? "/images/phone_light.svg"
                        : "/images/phone_dark.svg"
                    }
                    alt="phone"
                    className="h-4 sm:block"
                  />
                </div>
                {/* <div className="flex items-center justify-center">WP</div> */}
              </div>
              <div className="w-full p-[15px] flex items-center justify-between bg-[#F2CB53] rounded-[9px] text-[#FFFFFF]">
                <div className="font-bold text-[15px]">{t("S1")}</div>
                <div className="flex items-center justify-center">
                  <SwitchIcon
                    value={s1smsSwitch}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s1: {
                          sms: !s1smsSwitch,
                        },
                      };
                      updateSubscriber(updatedAlertSetting, sets1smsSwitch);
                      sets1smsSwitch(!s1smsSwitch);
                    }}
                    disabled={userRole === "users" && true}
                  />
                </div>
                <div>
                  <SwitchIcon
                    value={s1mailSwitch}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s1: {
                          email: !s1mailSwitch,
                        },
                      };
                      updateSubscriber(updatedAlertSetting, sets1mailSwitch);
                      sets1mailSwitch(!s1mailSwitch);
                    }}
                  />
                </div>
                {/* dummy switch for ui alignment */}
                <div className="flex items-center justify-center invisible">
                  <SwitchIcon
                    value={false}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {}}
                  />
                </div>
                {/* switch for webpush */}
                {/* <div className="flex items-center justify-center">
                  <SwitchIcon
                    value={false}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {}}
                  />
                </div> */}
              </div>
              <div className="w-full p-[15px] flex items-center justify-between bg-[#E41931] rounded-[9px] text-[#FFFFFF]">
                {/* <div className="w-20 py-3 flex flex-col items-center bg-[#E41931] rounded-[9px] text-[#FFFFFF]"> */}
                <div className="font-bold text-[15px]">{t("S2")}</div>
                <div>
                  <SwitchIcon
                    value={s2smsSwitch}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#b01326] opacity-40"
                    bgCheckedColor="checked:bg-[#b01326] checked:opacity-100"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s2: {
                          sms: !s2smsSwitch,
                        },
                      };
                      updateSubscriber(updatedAlertSetting, sets2smsSwitch);
                      sets2smsSwitch(!s2smsSwitch);
                    }}
                  />
                </div>

                <div>
                  <SwitchIcon
                    value={s2mailSwitch}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#b01326] opacity-40"
                    bgCheckedColor="checked:bg-[#b01326] checked:opacity-100"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s2: {
                          email: !s2mailSwitch,
                        },
                      };
                      updateSubscriber(updatedAlertSetting, sets2mailSwitch);
                      sets2mailSwitch(!s2mailSwitch);
                    }}
                  />
                </div>
                {/* dummy switch for ui alignment */}
                <div className="flex items-center justify-center invisible">
                  <SwitchIcon
                    value={s1smsSwitch}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {}}
                  />
                </div>
                {/* <div className="flex items-center justify-center">
                  <SwitchIcon
                    value={false}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {}}
                  />
                </div> */}
                {/* )} */}
              </div>
              <div className="w-full p-[15px] flex items-center justify-between bg-[#9F33E5] rounded-[9px] text-[#FFFFFF]">
                {/* <div className="w-20 py-3 flex flex-col items-center bg-[#9F33E5] rounded-[9px] text-[#FFFFFF]"> */}
                <div className="font-bold text-[15px]">{t("S3")}</div>
                <div>
                  <SwitchIcon
                    value={s3smsSwitch}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#7a27b0] opacity-40"
                    bgCheckedColor="checked:bg-[#7a27b0] checked:opacity-100"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s3: {
                          sms: !s3smsSwitch,
                        },
                      };
                      updateSubscriber(updatedAlertSetting, sets3smsSwitch);
                      sets3smsSwitch(!s3smsSwitch);
                    }}
                  />
                </div>
                <div>
                  <SwitchIcon
                    value={s3mailSwitch}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#7a27b0] opacity-40"
                    bgCheckedColor="checked:bg-[#7a27b0] checked:opacity-100"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s3: {
                          email: !s3mailSwitch,
                        },
                      };
                      updateSubscriber(updatedAlertSetting, sets3mailSwitch);
                      sets3mailSwitch(!s3mailSwitch);
                    }}
                  />
                </div>
                <div>
                  <SwitchIcon
                    value={s3voiceCallSwitch}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#7a27b0] opacity-40"
                    bgCheckedColor="checked:bg-[#7a27b0] checked:opacity-100"
                    onCheck={() => {
                      const updatedAlertSetting = {
                        s3: {
                          voice: !s3voiceCallSwitch,
                        },
                      };
                      updateSubscriber(
                        updatedAlertSetting,
                        sets3VoiceCallSwitch
                      );
                      sets3VoiceCallSwitch(!s3voiceCallSwitch);
                    }}
                  />
                </div>
                {/* <div className="flex items-center justify-center">
                  <SwitchIcon
                    value={false}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {}}
                  />
                </div> */}
              </div>
              {/* <div className="w-full p-[15px] flex items-center justify-between bg-[#3733E5] rounded-[9px] text-[#FFFFFF]">
                <div className="font-bold text-[15px]">Flood Level</div>

                <div>
                  <SwitchIcon
                    value={false}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {}}
                  />
                </div>
              </div>
              <div className="w-full p-[15px] flex items-center justify-between bg-[#33E590] rounded-[9px] text-[#FFFFFF]">
                <div className="font-bold text-[15px]">CAP</div>

                <div>
                  <SwitchIcon
                    value={false}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {}}
                  />
                </div>
              </div> */}
            </div>
          )
        ) : (
          <div className="text-[#FF0000] text-2xl pt-4 text-center font-bold">
            {t("noSubscriber")}
          </div>
        )}
      </div>
      <SpecialUserModal
        open={showSpecialUserModal}
        closed={specialUserModalClosedHandler}
      />
      <div className="flex justify-end flex-1 w-full">
        {/* <button
          onClick={specialUserModalToggleHandler}
          className="h-10 mt-8 mr-3 rounded-lg md:px-10 2xl:px-20 bg-hdcBlue shadow-btn">
          Add New Pool User
        </button> */}
      </div>
    </div>
  );
};

export default MessagingOption;
