import { CgClose, CgArrowLeft } from "react-icons/cg";

function FeedbackModal({ setShowFeedback, setMainModal, setShowAboutModal }) {
  return (
    <div
      className="absolute sm:relative bottom-0 m-auto headerShadowLight dark:text-white text-black max-h-[80vh]
            w-[830px] max-w-[100%] sm:max-w-[70%] dark:bg-black/70 bg-white/70 backdrop-blur-[61.5px] rounded-t-2xl sm:rounded-[18px] p-8"
    >
      <section className="text-center">
        <div
          onClick={() => {
            setShowFeedback(false);
            setShowAboutModal(false);
            setMainModal(true);
          }}
          className="absolute cursor-pointer top-8 right-8"
        >
          <CgClose size={24} />
        </div>
        <div
          onClick={() => {
            setShowFeedback(false);
            setMainModal(true);
          }}
          className="absolute flex gap-1 cursor-pointer top-8 left-8"
        >
          <CgArrowLeft size={25} />
          <label className="hidden cursor-pointer md:block">Zurück</label>
        </div>

        <p className="text-xl md:text-[28px] pb-2 font-[700]">Feedback</p>
      </section>
      <section className="max-h-[60vh] overflow-y-auto break-words w-full scrollbarLight dark:scrollbar">
        <p>
          <br />
          Die vorliegenden Informationen sind ein Service Ihrer Kommune und der
          Firma SPEKTER GmbH. <br />
          SPEKTER nimmt gerne Ihr Feedback zu Anregungen oder Verbesserungen
          auf. <br />
          Bitte berücksichtigen Sie, dass hierbei keine Fragen beantworten
          werden können. <br />{" "}
        </p>
        <br />
        <p>E-Mail-Adresse für Ihr Feedback:</p>
        <a href="mailto: feedback@spekter.de" target="_blank ">
          <u>
            <b>feedback@spekter.de</b>
          </u>
        </a>
        <br />
        <br />
        <p className="pt-8 font-bold"> Haftungsausschluss</p>
        <p>
          <br />
          Das Starkregen-Auskunft-System ist kostenloser Service Ihrer Kommune
          zur Unterstützung Ihrer Aktivitäten zum Schutz vor Überflutung. Die
          Informationen erheben keinen Anspruch auf Vollständigkeit und
          Richtigkeit. Jegliche Haftung ist ausgeschlossen. Die gezeigten
          Informationen sind ausschließlich für Ihre angefragte Liegenschaft zur
          verwenden.
          <br />{" "}
        </p>
      </section>
    </div>
  );
}

export default FeedbackModal;

// test change
