import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import * as actions from "../../../redux/actions";
import mapDarkFeature from "../../../constants/mapDarkFeature.json";
import mapLightFeature from "../../../constants/mapLightFeature.json";
import ChartModal from "../../../components/ChartModal/ChartModal";
import { useDispatch, useSelector } from "react-redux";
import ObserverOverview from "./ObserverOverview";
import Controls from "../Controls/Controls";
import AlarmInfoSideBar from "../CatchmentAlarmInfo/Sidebar";
import ReactTooltip from "react-tooltip";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { useClickAway } from "@uidotdev/usehooks";
import { useSearchParams } from "react-router-dom";
import { calculateNewLatLng } from "../../../shared/Utility";
import useMobileView from "../../../hooks/useMobileView";
import { sensorTypes } from "../../../constants/sensorTypes";
import { useSetState } from "react-use";
import Joyride, { STATUS } from "react-joyride";
import {
  wizardCustomStylesDark,
  wizardCustomStylesLight,
} from "../wizardCustomStyle";
import { mapWizardSteps } from "../mapWizardSteps";
import styles from "./ObserverView.module.css";

// colors for polygons and edges
const polygonColors = {
  level1: "#24D160",
  level3: "#F3EA03",
  level4: "#FD1001",
  level5: "#8A03F3",
  inactive: "#A9A9A9",
};

const edgeColors = {
  level1: "#51FC42",
  level3: "#B9FC00",
  level4: "#FF0000",
  level5: "#8200FF",
  inactive: "#A9A9A9",
};

const riverscapeColors = {
  inactive: "#46BCEC",
  active: "#BD2734",
  inactiveDarkMode: "#4EC8D8",
  hoveredPolyline: "#FFFFFF",
};

// states
let polygones = [];
let markers = [];
let locationMarker;
let riverscapeMarkers = [];

const ObserverView = () => {
  const isMobileView = useMobileView();
  let [searchParams, setSearchParams] = useSearchParams();
  // appearance related
  const [chartModalOpen, setChartModalOpen] = useState(false);
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const [isAlarmInfoOpen, setIsAlarmInfoOpen] = useState(false);
  const [alarmData, setAlarmData] = useState(undefined);
  const [addressCoordinate, setAddressCoordinate] = useState();

  //maps related
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [center, setCenter] = useState({});
  const [zoom, setZoom] = useState();

  // catchment related
  const [timeRange, setTimeRange] = useState(1440);
  const [sensorAttr, setSensorAttr] = useState(null);

  const sensorIdFromNotification = searchParams.get("_id");
  const sensorAgsFromNotification = searchParams.get("ags");
  const notificationType = searchParams.get("notificationType");
  const catchmentLat = searchParams.get("catchmentLat");
  const catchmentLng = searchParams.get("catchmentLng");

  // observer states
  // const [isObserver, setIsObserver] = useState(true);
  const [onHoverPolygonName, setOnHoverPolygonName] = useState("");
  const [onHoverPolylineName, setOnHoverPolylineName] = useState("");

  const dispatch = useDispatch();

  // redux states
  const selectedAgs = useSelector((state) => state.observer.selectedAgs);
  const currentMapPositon = useSelector(
    (state) => state.observer.currentMapPosition
  );
  const observerData = useSelector((state) => state.observer.data);
  const customerData = useSelector((state) => state.customer.data);
  const catchmentData = useSelector((state) => state.catchment.data);
  const riverscapeData = useSelector((state) => state.riverscape.data);
  const isLoading = useSelector((state) => state.catchment.isLoading);
  const hdcData = useSelector((state) => state.hdc.data);
  const gflData = useSelector((state) => state.gfl.data);
  const smartriverData = useSelector((state) => state.smartriver.data);
  const isSmartriverDataLoading = useSelector(
    (state) => state.smartriver.isLoading
  );
  const smartsewerData = useSelector((state) => state.smartsewer.data);
  const [selectedSensor, setSelectedSensor] = useState();

  const [showOnBoardWizard, setShowOnBoardWizard] = useState(false);

  const [{ run, steps }, setState] = useSetState({
    run: true,
    steps: mapWizardSteps,
  });

  const markerClicked = (marker, id, name, sensorType, location) => {
    marker.addListener("click", () => {
      let optionalData = {};
      // write thresholds logic

      // lookup smartRiver data
      if (sensorType === "smartriverdata" && smartriverData.length > 0) {
        smartriverData.forEach((data) => {
          if (data["_id"] === id) {
            optionalData.soleCorrectionValue = data["soleCorrectionValue"];
            optionalData.thresholds = data["thresholds"];
            optionalData.maintenance = data["maintenance"];
          }
        });
      }
      // lookup gfl data
      else if (sensorType === "gfldata") {
        // lookup smartRiver data

        gflData.forEach((data) => {
          if (data["_id"] === id) {
            optionalData.thresholds = data["thresholds"];
            optionalData.maintenance = data["maintenance"];
          }
        });
      }

      // lookup smartRiver data
      if (sensorType === "smartsewerdata") {
        smartsewerData.forEach((data) => {
          if (data["_id"] === id) {
            optionalData.thresholds = data["thresholds"];
            optionalData.maintenance = data["maintenance"];
            optionalData.environment = data["environment"];
          }
        });
      }
      // TODO:here timeframe will be dynamic
      // dispatch(
      //   actions.chartAction(sensorType, id, timeRange, name, optionalData)
      // );`
      setSensorAttr({ sensorType, id, name, optionalData });
      setChartModalOpen(true);
      // TODO: set setTimeRange to 1440
      setTimeRange(1440);
    });
  };

  const onGoogleApiLoaded = (map, maps) => {
    setMap(map);
    setMaps(maps);

    // Set maximum and minimum zoom levels
    map.setOptions({
      // maxZoom: 15,
      minZoom: 5,
    });
  };

  useEffect(() => {
    if (sensorAttr) {
      const { sensorType, id, name, optionalData } = sensorAttr;

      dispatch(
        actions.chartAction(sensorType, id, timeRange, name, optionalData)
      );
    }
  }, [timeRange, sensorAttr, dispatch]);

  //--------------------------
  // Render catchment polygons
  useEffect(() => {
    if (!selectedAgs) return;
    if (catchmentData && map && maps) {
      removeCatchmetPolygons();

      let showPods = false;
      catchmentData.forEach((element) => {
        let temptriangleCoords = [];

        element.shapeCoordinates.forEach((element) => {
          let temp = { lat: element[0], lng: element[1] };
          temptriangleCoords.push(temp);
        });

        let polygon = new maps.Polygon({
          paths: temptriangleCoords,
          strokeColor: element.inactive
            ? edgeColors.inactive
            : edgeColors[element.warningLevel],
          strokeOpacity: 1,
          strokeWeight: 3,
          fillColor: element.inactive
            ? polygonColors.inactive
            : polygonColors[element.warningLevel],
          fillOpacity: 0.3,
        });

        if (!element.inactive) {
          if (element.warningLevel !== "level1") {
            showPods = true;
          }
        }

        maps.event.addListener(polygon, "mouseover", function () {
          polygon.setOptions({ fillColor: "#ffffff", fillOpacity: 0.5 });
          setOnHoverPolygonName(element?.name);
        });

        maps.event.addListener(polygon, "mouseout", function () {
          polygon.setOptions({
            fillColor: element.inactive
              ? polygonColors.inactive
              : polygonColors[element.warningLevel],
            fillOpacity: 0.3,
          });
          setOnHoverPolygonName("");
        });

        //onclick
        maps.event.addListener(polygon, "click", function (e) {
          setIsAlarmInfoOpen(true);
          setAlarmData(element);
        });

        polygones.push(polygon);
        polygon.setMap(map);
      });
      if (showPods) {
        dispatch(actions.setShowPods(true));
      }
    }
  }, [catchmentData, map, maps, selectedAgs]);

  // -----------
  // Render hdc marker
  useEffect(() => {
    if (hdcData && hdcData.length > 0 && map && maps) {
      const hdcIconInactive = {
        url: "/images/Niederschlag_inaktiv.svg",
        // url: "/images/hdc-circle-idle.png",
        scaledSize: new maps.Size(25, 25),
      };
      const hdcIconActive = {
        url: "/images/Niederschlag_aktiv.svg",
        // url: "/images/hdc-circle-active.png",
        scaledSize: new maps.Size(25, 25),
      };

      hdcData.forEach((position) => {
        let isRaining;
        const differenceInMinutes = Math.floor(
          (new Date() - new Date(position.lastEmission)) / 1000 / 60
        );

        if (differenceInMinutes < 6) {
          isRaining = true;
        } else {
          isRaining = false;
        }

        const hdcMarker = new maps.Marker({
          position: new maps.LatLng(
            position.location.lat,
            position.location.lng
          ),
          // map: map,
          title: position.name ? `${position.name}` : "HDC",
          icon: isRaining ? hdcIconActive : hdcIconInactive,
        });
        markers.push(hdcMarker);
        markerClicked(hdcMarker, position._id, position.name, "hdcdata");

        hdcMarker.setMap(map);
      });
    }
  }, [hdcData, map, maps]);

  // -----------
  // Render smartRiver marker
  useEffect(() => {
    if (smartriverData && smartriverData.length > 0 && map && maps) {
      const smartriverIconInactive = {
        url: "/images/Pegel_inaktiv.svg",
        // url: "/images/floodlevel-circle.png",
        scaledSize: new maps.Size(25, 25),
      };

      const smartriverIconActive = {
        url: "/images/Pegel_aktiv.svg",
        scaledSize: new maps.Size(25, 25),
      };
      const smartriverIconWarning = {
        url: "/images/wartung_icon.svg",
        scaledSize: new maps.Size(30, 30),
      };

      smartriverData.forEach((position) => {
        let timeDiffHours = 0;

        if (position.measurements?.length > 0) {
          const lastDate = new Date(
            position.measurements[position.measurements.length - 1].timestamp
          );

          const currentDate = new Date();

          const timeDiff = currentDate - lastDate;

          // timeDiffHours = Math.floor(timeDiff / (1000 * 60 * 60));
          timeDiffHours = timeDiff / (1000 * 60 * 60);
        }

        const smartriverMarker = new maps.Marker({
          position: new maps.LatLng(
            position.location.lat,
            position.location.lng
          ),
          // map: map,
          title: position.name ? `${position.name}` : "Smart-River",
          icon:
            position.maintenance || timeDiffHours > 6
              ? smartriverIconWarning
              : position.thresholds[0].active
              ? smartriverIconActive
              : smartriverIconInactive,
        });
        smartriverMarker.setMap(map);
        markers.push(smartriverMarker);
        markerClicked(
          smartriverMarker,
          position._id,
          position.name,
          "smartriverdata",
          position.location
        );
      });
    }
  }, [smartriverData, map, maps]);

  // -----------
  // Render gfl marker
  useEffect(() => {
    if (gflData && gflData.length > 0 && map && maps) {
      const gflIconInactive = {
        url: "/images/Pegel_inaktiv.svg",
        // url: "/images/floodlevel-circle.png",
        scaledSize: new maps.Size(25, 25),
      };
      const gflIconActive = {
        url: "/images/Pegel_aktiv.svg",
        // url: "/images/floodlevel-circle-active.png",
        scaledSize: new maps.Size(25, 25),
      };

      gflData.forEach((position) => {
        const gflMarker = new maps.Marker({
          position: new maps.LatLng(
            position.location.lat,
            position.location.lng
          ),
          // map: map,
          title: position.name ? `${position.name}` : "GFL",
          icon: position.thresholds[0].active ? gflIconActive : gflIconInactive,
        });
        gflMarker.setMap(map);
        markers.push(gflMarker);
        markerClicked(gflMarker, position._id, position.name, "gfldata");
      });
    }
  }, [gflData, map, maps]);

  // -----------
  // Render smartSewer marker
  useEffect(() => {
    if (smartsewerData && smartsewerData.length && map && maps) {
      const smartsewerIconInactive = {
        url: "/images/Kanal_inaktiv.svg",
        // url: "/images/sewer-circle-inactive.png",
        scaledSize: new maps.Size(25, 25),
      };
      const smartsewerIconActive = {
        url: "/images/Kanal_aktiv.svg",
        // url: "/images/sewer-circle-active.png",
        scaledSize: new maps.Size(25, 25),
      };

      smartsewerData.forEach((position) => {
        const smartsewerMarker = new maps.Marker({
          position: new maps.LatLng(
            position.location.lat,
            position.location.lng
          ),
          // map: map,
          title: position.name ? `${position.name}` : "Smart-Sewer",
          icon: position.thresholds[0].overflow
            ? smartsewerIconActive
            : smartsewerIconInactive,
        });
        smartsewerMarker.setMap(map);
        markers.push(smartsewerMarker);
        markerClicked(
          smartsewerMarker,
          position._id,
          position.name,
          "smartsewerdata"
        );
      });
    }
  }, [smartsewerData, map, maps]);

  // Render address marker
  useEffect(() => {
    if (addressCoordinate && map && maps) {
      if (locationMarker) {
        locationMarker.setMap(null);
        locationMarker = undefined;
      }

      const locationIcon = {
        url: "/images/address_marker.svg",
        scaledSize: new maps.Size(30, 30),
      };

      const addressMarker = new maps.Marker({
        position: new maps.LatLng(addressCoordinate[0], addressCoordinate[1]),
        title: "Address-Marker",
        icon: locationIcon,
      });
      addressMarker.setMap(map);
      // markers.push(addressMarker);
      locationMarker = addressMarker;

      setCenter({
        lat: addressCoordinate[0],
        lng: addressCoordinate[1],
      });
      setZoom(12);
    }
  }, [addressCoordinate, map, maps]);

  // render riverscape markers.
  useEffect(() => {
    if (
      riverscapeData &&
      riverscapeData.length > 0 &&
      map &&
      maps &&
      !selectedAgs
    ) {
      console.log("zoom", zoom);
      if (zoom < 10) {
        removeRiverscapeMarkers();
      } else if (
        zoom >= 10 &&
        riverscapeData &&
        riverscapeData.length > 0 &&
        !selectedAgs
      ) {
        renderRiverscapeMarkers();
      }
    }
  }, [riverscapeData, selectedAgs, map, maps, zoom]);

  //remove polygons
  const removeCatchmetPolygons = () => {
    if (polygones) {
      polygones.forEach((pg) => pg.setMap(null));
      polygones = [];
    }
  };

  // remove riverscape markers
  const removeRiverscapeMarkers = () => {
    if (riverscapeMarkers && riverscapeMarkers.length > 0) {
      riverscapeMarkers.forEach((marker) => marker.setMap(null));
      riverscapeMarkers = [];
    }
  };

  //remove markers except the latest address marker
  const removeMarkers = () => {
    markers.forEach((marker) => marker.setMap(null));
  };
  // set Center and zoom
  useEffect(() => {
    if (customerData != null) {
      setCenter({
        lat: customerData.coordinatesCenter[0],
        lng: customerData.coordinatesCenter[1],
      });
      setZoom(customerData.coordinatesCenter[2]);
    }
  }, [customerData]);

  // render observer polygons
  const renderObserverPolygons = () => {
    observerData?.areas.forEach((area) => {
      let tempCoordinates = [];

      area?.polygonCoordinates?.forEach((coordinate) => {
        let temp = { lat: coordinate[0], lng: coordinate[1] };
        tempCoordinates.push(temp);
      });

      let polygon = new maps.Polygon({
        paths: tempCoordinates,
        strokeColor: edgeColors[area?.maxWarningLevel],
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: polygonColors[area?.maxWarningLevel],
        fillOpacity: 0.6,
      });

      polygones.push(polygon);

      // mouse over listener
      maps.event.addListener(polygon, "mouseover", function () {
        polygon.setOptions({ fillColor: "#ffffff", fillOpacity: 0.5 });
        setOnHoverPolygonName(area.customer?.name);
      });

      // mouse out listener
      maps.event.addListener(polygon, "mouseout", function () {
        polygon.setOptions({
          fillColor: polygonColors[area?.maxWarningLevel],
          fillOpacity: 0.6,
        });
        setOnHoverPolygonName("");
      });

      maps.event.addListener(polygon, "click", function () {
        dispatch(actions.selectObserverAgs(area?.ags));
        dispatch(actions.selectObserverName(area?.name));
      });

      polygon.setMap(map);
    });
  };

  // render observer riverscape
  const renderObserverRiverscape = () => {
    riverscapeData?.forEach((area) => {
      // if (!area?.alarmActive) return;
      area?.riverPolygon?.forEach((subpolygon) => {
        let tempCoordinates = [];

        subpolygon.forEach((coordinate) => {
          let temp = { lat: coordinate[0], lng: coordinate[1] };
          tempCoordinates.push(temp);
        });
        let polyline = new maps.Polyline({
          path: tempCoordinates,
          strokeColor: area?.alarmActive
            ? riverscapeColors.active
            : isThemeDark
            ? riverscapeColors.inactiveDarkMode
            : riverscapeColors.inactive,
          strokeOpacity: 1,
          strokeWeight: 3,
          zIndex: 999,
        });
        polygones.push(polyline);
        // on mouse over and out effet
        // mouse over listener
        maps.event.addListener(polyline, "mouseover", function () {
          polyline.setOptions({
            strokeWeight: 7,
            strokeColor: riverscapeColors.hoveredPolyline,
          });
          setOnHoverPolylineName(area?.riverName || "Riverscape"); // Set the tooltip content
        });

        // mouse out listener
        maps.event.addListener(polyline, "mouseout", function () {
          polyline.setOptions({
            strokeWeight: 3,
            strokeColor: area?.alarmActive
              ? riverscapeColors.active
              : isThemeDark
              ? riverscapeColors.inactiveDarkMode
              : riverscapeColors.inactive,
          });
          setOnHoverPolylineName(""); // Clear the tooltip content
        });
        polyline.setMap(map);
      });
    });
  };

  // render riverscape markers.
  const renderRiverscapeMarkers = () => {
    if (zoom < 10 || riverscapeMarkers.length !== 0) return;
    const riverscapeIconInactive = {
      url: "/images/Pegel_inaktiv.svg",
      // url: "/images/floodlevel-circle.png",
      scaledSize: new maps.Size(20, 20),
    };

    const riverscapeIconActive = {
      url: "/images/Pegel_aktiv.svg",
      scaledSize: new maps.Size(20, 20),
    };

    riverscapeData?.forEach((area) => {
      // if (!area?.alarmActive) return;
      area.sensorGroup?.forEach((sensor) => {
        const riverscapeMarker = new maps.Marker({
          position: new maps.LatLng(sensor.location.lat, sensor.location.lng),
          title: sensor.name ? `${sensor.name}` : "RIVERSCAPE",
          icon: area?.alarmActive
            ? riverscapeIconActive
            : riverscapeIconInactive,
        });

        riverscapeMarker.addListener("click", () => {
          // only smart-river and gfl allowed
          if (sensor.type !== "smartriver" && sensor.type !== "gfl") return;

          const sensorName = sensor.name;
          const id = sensor._id;
          const sensorType =
            sensor.type === "smartriver"
              ? sensorTypes.SMARTRIVER
              : sensorTypes.GFL;
          const optionalData = {
            soleCorrectionValue: sensor["soleCorrectionValue"],
            thresholds: sensor["thresholds"],
            maintenance: sensor["maintenance"],
          };

          setSensorAttr({ sensorType, id, name: sensorName, optionalData });
          setChartModalOpen(true);
          setTimeRange(1440);
        });

        riverscapeMarker.setMap(map);
        riverscapeMarkers.push(riverscapeMarker);
      });
    });
  };

  const loadCatchmentsAndSensorsData = () => {
    const query = {
      ags: selectedAgs,
    };
    dispatch(actions.customerAction(selectedAgs));
    dispatch(actions.catchment(selectedAgs));
    dispatch(actions.hdc(query));
    dispatch(actions.gfl(query));
    dispatch(actions.smartriver(query));

    // sewer not need for observer
    // dispatch(actions.smartsewer(query));
  };

  //render observer polygons
  useEffect(() => {
    if (!map && !maps) return;

    if (selectedAgs) {
      removeCatchmetPolygons();
      loadCatchmentsAndSensorsData();
      removeRiverscapeMarkers();
    } else {
      if (observerData) {
        //------remove existing polygon-----
        // FIX: catchment|polygon duplicate issue after catchment overview reload.
        removeCatchmetPolygons();
        removeMarkers();
        renderObserverPolygons();
        // calling function to render riverscape
        renderObserverRiverscape();

        if (currentMapPositon) {
          setCenter(currentMapPositon?.center);
          setZoom(currentMapPositon?.zoom);
        } else if (observerData?.coordinatesCenter) {
          const coordinatesCenter = { ...observerData.coordinatesCenter };
          setCenter({ lat: coordinatesCenter[0], lng: coordinatesCenter[1] });
          setZoom(coordinatesCenter[2]);
        }
      }
    }
  }, [
    observerData,
    map,
    maps,
    selectedAgs,
    currentMapPositon,
    riverscapeData,
    isThemeDark,
  ]);

  const onKeepMapPosition = () => {
    if (!selectedAgs) {
      const position = {
        zoom: map.getZoom(),
        center: {
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng(),
        },
      };

      dispatch(actions.currentMapPosition(position));
    }
  };

  useEffect(() => {
    // dispatch(actions.customerAction());
    if (selectedAgs) {
      loadCatchmentsAndSensorsData();
    } else {
      dispatch(actions.observer());
      dispatch(actions.riverscape());
    }
  }, [dispatch]);

  // set observer ags and name from notification
  useEffect(() => {
    let observerAreaName;
    if (sensorAgsFromNotification) {
      observerData?.areas.forEach((area) => {
        if (area.ags === sensorAgsFromNotification) {
          observerAreaName = area.name;
        }
      });
      dispatch(actions.selectObserverAgs(sensorAgsFromNotification));
      dispatch(actions.selectObserverName(observerAreaName));
    }
  }, [sensorAgsFromNotification]);

  // set center and zoom from notification for catchment
  useEffect(() => {
    if (
      selectedAgs == sensorAgsFromNotification &&
      notificationType === "rain"
    ) {
      setCenter({
        lat: parseFloat(catchmentLat),
        lng: parseFloat(catchmentLng),
      });
      setZoom(14);
      setTimeout(() => {
        setSearchParams({}); // clear search params
      }, 5000);
    }
  }, [selectedAgs, sensorAgsFromNotification, customerData]);

  // selecting sensor from notification for chat modal and map center
  useEffect(() => {
    if (
      notificationType === "sensor" &&
      selectedAgs === sensorAgsFromNotification &&
      isSmartriverDataLoading === false &&
      smartriverData &&
      smartriverData.length > 0
    ) {
      smartriverData &&
        smartriverData.forEach((data) => {
          if (data["_id"] === sensorIdFromNotification) {
            setSelectedSensor(data);
          }
        });
    }
  }, [smartriverData, sensorIdFromNotification]);

  // opening chart modal and setting center and zoom
  useEffect(() => {
    if (
      selectedAgs === sensorAgsFromNotification &&
      !isSmartriverDataLoading &&
      smartriverData &&
      selectedSensor &&
      notificationType === "sensor"
    ) {
      // let optionalData = {};
      // smartriverData &&
      //   smartriverData.forEach((data) => {
      //     if (data["_id"] === sensorIdFromNotification) {
      //       optionalData.soleCorrectionValue = data["soleCorrectionValue"];
      //       optionalData.thresholds = data["thresholds"];
      //       optionalData.maintenance = data["maintenance"];
      //     }
      //   });
      // setSensorAttr({
      //   sensorType: "smartriverdata",
      //   id: selectedSensor._id,
      //   name: selectedSensor.name,
      //   optionalData,
      // });
      // setTimeout(() => {
      //   setChartModalOpen(true);
      // }, 1000);
      // setTimeRange(1440);

      if (selectedSensor.location?.lat) {
        // setting center lat-lng 100 meters down to achieve better ux
        // const newLatLng = calculateNewLatLng(
        //   selectedSensor.location?.lat,
        //   selectedSensor.location?.lng
        // );
        setCenter({
          lat: selectedSensor.location?.lat,
          lng: selectedSensor.location?.lng,
        });
        setZoom(17);
        setTimeout(() => {
          setSearchParams({}); // clear search params
          setSelectedSensor();
        }, 5000);
      }
    }
  }, [selectedSensor]);

  // set center and zoom from sidebar for pod
  useEffect(() => {
    if (notificationType === "pod") {
      setCenter({
        lat: parseFloat(catchmentLat),
        lng: parseFloat(catchmentLng),
      });
      setZoom(16);
      setTimeout(() => {
        setSearchParams({}); // clear search params
      }, 5000);
    }
  }, [notificationType, catchmentLat, catchmentLng]);

  const ref = useClickAway(() => {
    setChartModalOpen(false);
  });

  const handleMapChange = (e) => {
    setZoom(e.zoom);
    setCenter({
      lat: e.center.lat,
      lng: e.center.lng,
    });
  };

  // reset zoom and center for [pods only]
  const resetZoomAndCenter = () => {
    setCenter({
      lat: customerData.coordinatesCenter[0],
      lng: customerData.coordinatesCenter[1],
    });
    setZoom(customerData.coordinatesCenter[2]);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  // onboarding wizard logic
  useEffect(() => {
    const haveShowWizardFlag = localStorage.getItem("showOnBoardWizard");
    if (!haveShowWizardFlag) {
      setShowOnBoardWizard(true);
    }
    return () => {
      localStorage.setItem("showOnBoardWizard", "no");
    };
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
      localStorage.setItem("showOnBoardWizard", "no");
    }
  };

  return (
    <div id="map" className={styles["gm-style"]}>
      {showOnBoardWizard && selectedAgs && (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={run}
          showProgress={false}
          showSkipButton
          locale={{ back: "Zurück", last: "Letzte", next: "Weiter" }}
          steps={steps}
          styles={
            isThemeDark ? wizardCustomStylesDark : wizardCustomStylesLight
          }
        />
      )}
      <div>
        <ObserverOverview setAddressCoordinate={setAddressCoordinate} />
        <AlarmInfoSideBar
          open={isAlarmInfoOpen}
          setOpen={setIsAlarmInfoOpen}
          data={alarmData}
        />
        <Controls
          map={map}
          maps={maps}
          handleNormalMapReset={resetZoomAndCenter}
          setAddressCoordinate={setAddressCoordinate}
        />
        <div
          data-tip=""
          style={{ zIndex: "100", height: "100vh", width: "100%" }}
        >
          {observerData ? (
            <GoogleMapReact
              onChange={(e) => handleMapChange(e)}
              options={{
                styles: isThemeDark ? mapDarkFeature : mapLightFeature,
                fullscreenControl: false,
                zoomControl: false,
                gestureHandling: "greedy",
              }}
              // defaultZoom={zoom}
              defaultCenter={{
                lat: 48.56692657664802,
                lng: 13.430500178529469,
              }}
              center={center}
              defaultZoom={8}
              zoom={zoom}
              onDragEnd={onKeepMapPosition}
              onZoomAnimationEnd={onKeepMapPosition}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) =>
                onGoogleApiLoaded(map, maps)
              }
            ></GoogleMapReact>
          ) : (
            <div className="flex items-center justify-center h-full">
              <Spinner />
            </div>
          )}
          {!isMobileView && <ReactTooltip>{onHoverPolygonName}</ReactTooltip>}
          {!isMobileView && <ReactTooltip>{onHoverPolylineName}</ReactTooltip>}
        </div>
      </div>
      <div ref={ref}>
        <ChartModal
          setTimeRange={setTimeRange}
          duration={timeRange}
          open={chartModalOpen}
          onCloseModal={() => setChartModalOpen(!chartModalOpen)}
        />
      </div>
    </div>
  );
};

export default ObserverView;
