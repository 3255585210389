import { useState } from "react";

export const CustomOption = ({
  innerProps,
  label,
  data,
  setShowDeleteOptionModal,
  setOptionData,
  metadataname,
  isThemeDark,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const hoverStyle = isHovered
    ? { background: isThemeDark ? "#ccc" : "lightblue", color: "black" }
    : {};
  return (
    <div
      {...innerProps}
      className="duration-200 flex items-center justify-between w-full h-[40px] py-[5px] px-[10px] my-[5px] rounded-[6px] cursor-pointer"
      style={{
        ...hoverStyle,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span>{label}</span>
      <button
        onClick={(e) => {
          e.stopPropagation();

          setOptionData({
            label: label,
            metadataname: metadataname,
          });
          setShowDeleteOptionModal(true);
        }}
      >
        &times;
      </button>
    </div>
  );
};

export const CustomCategoryOption = ({
  innerProps,
  label,
  data,
  isThemeDark,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const hoverStyle = isHovered
    ? { background: isThemeDark ? "#ccc" : "lightblue", color: "black" }
    : {};
  return (
    <div
      {...innerProps}
      className="duration-200 flex items-center gap-5 w-full h-[40px] py-[5px] px-[10px] my-[5px] rounded-[6px] cursor-pointer"
      style={{
        ...hoverStyle,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{ background: data?.color }}
        className="w-5 h-5 rounded-full"
      ></div>
      <span>{label}</span>
    </div>
  );
};

export const CustomMenu = (props) => {
  const { children, innerProps } = props;
  return (
    <div
      {...innerProps}
      className="absolute w-full bg-opacity-69 backdrop-blur-[60px] rounded-6 z-50"
    >
      {children}
    </div>
  );
};
