import React from "react";
import * as actions from "../../../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CatchmentItem = ({
  id,
  warningLevel,
  name,
  data,
  onDismissCard,
  top,
  zIndex,
  opacity,
  handleNotificationClick,
}) => {
  const { t, i18n } = useTranslation("catchmentItem"); //language package
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const dispatch = useDispatch();

  const floodLevel =
    warningLevel === "level5"
      ? "S3"
      : warningLevel === "level4"
      ? "S2"
      : warningLevel === "level3"
      ? "S1"
      : warningLevel === "level1"
      ? "S0"
      : "N/A";

  return (
    <div
      onClick={() => handleNotificationClick(data)}
      style={{
        top: top,
        zIndex: zIndex,
        // opacity: opacity,
      }}
      className={`max-w-full h-[90px] p-2.5 backdrop-blur-[61.5px]  mt-2 rounded-[15px] hover:cursor-pointer transition-all duration-500
             mb-2 sm:ml-2 relative ${
               floodLevel === "S1"
                 ? "catchmentSensorBgS1-light dark:catchmentSensorBgS1"
                 : floodLevel === "S2"
                 ? "catchmentSensorBgS2-light dark:catchmentSensorBgS2"
                 : floodLevel === "S3"
                 ? "catchmentSensorBgS3-light dark:catchmentSensorBgS3"
                 : ""
             }`}
    >
      <div className="flex justify-between w-full">
        <div className="w-[35px] h-[35px]">
          {floodLevel === "S1" || floodLevel === "S2" || floodLevel === "S3" ? (
            <img
              className="w-full rounded-full catchmentIconShadow-light dark:catchmentIconShadow"
              src={`/images/S${
                floodLevel === "S1"
                  ? "1"
                  : floodLevel === "S2"
                  ? "2"
                  : floodLevel === "S3"
                  ? "3"
                  : ""
              } Icon.svg`}
              alt="sensor status icon"
            />
          ) : null}
        </div>
        <div className="w-[95%] px-3 text-[15px] font-bold relative top-[4px] dark:text-spekterWhite text-black whitespace-nowrap overflow-hidden text-ellipsis">
          {`Warning Level ${floodLevel}`}
        </div>
        <img
          className="h-3 duration-300 cursor-pointer hover:opacity-80"
          onClick={() => {
            dispatch(actions.hideNotification(id));
            onDismissCard();
          }}
          src={`/images/notification/${
            isThemeDark ? "X Icon Bold.svg" : "X Icon Bold_000000.svg"
          }`}
          alt="cancel icon"
        />
      </div>
      <div className="relative pl-12 overflow-y-hidden text-xs text-black dark:text-gray-300 bottom-1">
        <p className="w-[260px] line-clamp-2">{`${t("The")} ${name} ${t(
          "hasThe"
        )} ${floodLevel} ${t("reached")}`}</p>
      </div>
    </div>
  );
};
export default CatchmentItem;
