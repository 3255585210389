import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// local dependencies
import { debounce } from "../../../shared/Utility";
import * as actions from "../../../redux/actions/index";
import SearchBar from "../../UI/SearchBar/SearchBar";
import Spinner from "../../UI/Spinner/Spinner";
// import Pagination from "../../UI/Pagination/Pagination";

import EditUserModal from "./EditUserModal/EditUserModal";
import Pagination from "../../UI/Pagination/Pagination";
import { useTranslation } from "react-i18next";
import ShowHideText from "../../UI/ShowHideText";
import EyeButton from "../../UI/EyeButton";

const UserList = ({ ags }) => {
  //language package
  const { t, i18n } = useTranslation("userManagement");
  const userType = "Active";
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.data?.result);
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const isloading = useSelector((state) => state.users.isLoading);
  const isLoadingUpdateUser = useSelector(
    (state) => state.updateUser.isLoading
  );

  // const [ags, setAgs] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [userData, setUserData] = useState(null);
  const [searchText, setSearchText] = useState("");

  // pagination
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // const ags = useSelector((state) => state.login.data?.user?.ags);
  const retrivedTotalPage = useSelector((state) => state.users.data?.totalPage);
  const paginate = (number) => setCurrentPage(number);

  useEffect(() => {
    if (!retrivedTotalPage) return;
    setTotalPage(retrivedTotalPage);
  }, [retrivedTotalPage]);

  useEffect(() => {
    dispatch(actions.usersAction(ags, currentPage, userType, searchText));
  }, [isLoadingUpdateUser, currentPage, searchText, ags]);

  const searchInput = (event) => {
    setCurrentPage(1);
    setSearchText(event.target.value);
  };
  const debouncedChangeHandler = useCallback(debounce(searchInput, 700), []);

  const modalToggleHandler = (data) => {
    setShowModal(!showModal);
    setUserData(data);
  };

  const modalClosedHandler = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // unmount the state
    return () => dispatch(actions.usersReset());
  }, []);

  return (
    <div className="relative block h-[70%] xl:h-[75%]">
      {/* <div className="relative block mt-4 md:mt-12 h-[70%] xl:h-[75%]"> */}
      <div className="mt-3 mb-2">
        <SearchBar
          placeholder={t("search")}
          searchInput={debouncedChangeHandler}
        />
      </div>
      {showModal ? (
        <EditUserModal
          ags={ags}
          open={showModal}
          closed={modalClosedHandler}
          userdata={userData}
          currentPage={currentPage}
          userType={userType}
          searchText={searchText}
        />
      ) : null}

      <div className="overflow-y-auto h-[calc(85vh-270px)] 2xl:h-[calc(85vh-245px)] scrollbarLight dark:scrollbar">
        {/* <div className="overflow-y-auto h-[90%] scrollbarLight dark:scrollbar"> */}
        <div className="hidden rounded-xl xl:block">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="sticky top-0 text-xs text-gray-700 uppercase dark:text-gray-400 bg-[#ECECEC] dark:bg-[#1D1E21] z-20">
              <tr>
                <th scope="col" className="px-12 py-3">
                  {t("name")}
                </th>
                <th scope="col" className="hidden px-8 py-3 lg:block">
                  <div className="flex items-center gap-10">
                    <span> {t("mail")}</span>
                    <EyeButton
                      open={showEmail}
                      onEyeClick={() => setShowEmail((prev) => !prev)}
                    />
                  </div>
                </th>
                <th scope="col" className="px-6 py-3">
                  <div className="flex items-center gap-10">
                    <span> {t("mobile")}</span>
                    <EyeButton
                      open={showPhoneNumber}
                      onEyeClick={() => setShowPhoneNumber((prev) => !prev)}
                    />
                  </div>
                </th>
                <th scope="col" className="px-12 py-3">
                  Address
                </th>
                <th scope="col" className="px-12 py-3 text-center">
                  {t("options")}
                </th>
              </tr>
            </thead>
            <tbody>
              {isloading || isLoadingUpdateUser ? (
                <tr>
                  <td className="absolute flex items-center justify-center w-full h-[75%]">
                    <Spinner />
                  </td>
                </tr>
              ) : users && users.length > 0 ? (
                users.map((user, index) => {
                  return (
                    // <tr className={`${isThemeDark ? "" : "odd:text-gray-400"} odd:bg-[#282A2E] `}>
                    <tr
                      className={`${
                        isThemeDark
                          ? "odd:bg-[#282A2E] w-[100%] "
                          : "odd:bg-[#e3e4e6] w-[100%] "
                      }`}
                      key={user._id}
                    >
                      <td className="px-12 py-4 rounded-l-10 w-[15%]">
                        {user.name ? user.name : ""}
                      </td>

                      <td className="py-4 hidden lg:block lg:relative top-[5px] w-full">
                        {/* {`${
                        user.email
                          ? user.email.split("@")[0].slice(0, -2) + "**"
                          : ""

                      }@********`} */}
                        <ShowHideText
                          text={user.email || ""}
                          show={showEmail}
                        />
                        {/* veryverybigemailtetxtestetst@gmail.com */}
                      </td>

                      <td className="px-4 py-4 w-[18%] ">
                        {/* {`${
                        user.mobile
                          ? user.mobile.slice(0, 6) + "*****"
                          : ""
                      }`} */}
                        <ShowHideText
                          text={user.mobile || ""}
                          show={showPhoneNumber}
                        />
                      </td>
                      <td className="px-12 py-4 w-[22%] ">
                        {user.address ? user.address : ""}
                      </td>
                      <td
                        onClick={() => modalToggleHandler(user)}
                        className="px-12 py-4 cursor-pointer rounded-r-10 w-[10%]"
                      >
                        <div className="flex justify-center">
                          <button className="w-20 h-8 px-2 py-0 mx-1 text-xs font-bold no-underline border-0 rounded bg-spekterGreen dark:bg-spekterGreen text-spekterDarkOne dark:text-gray-900">
                            {t("edit")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="relative">
                  <div className="absolute mx-auto mt-40 transform -translate-x-1/2 bg-gray-400 rounded-sm left-[45%] max-w-max">
                    <h5 className="p-10 text-white">
                      {t("noActiveUsersFound")}
                    </h5>
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* mobile users list  */}
        <div className="mb-2 overflow-x-auto rounded-xl xl:hidden">
          <div className="flex justify-end gap-3 text-black dark:text-gray-400">
            <div className="flex items-center gap-2">
              <span>{t("mail")}:</span>
              <EyeButton
                open={showEmail}
                onEyeClick={() => setShowEmail((prev) => !prev)}
              />
            </div>
            <div className="flex items-center gap-2">
              <span>Phone:</span>
              <EyeButton
                open={showPhoneNumber}
                onEyeClick={() => setShowPhoneNumber((prev) => !prev)}
              />
            </div>
          </div>
          {isloading || isLoadingUpdateUser ? (
            <div className="absolute flex items-center justify-center w-full h-[72%]">
              <Spinner />
            </div>
          ) : users && users.length > 0 ? (
            users.map((user) => {
              return (
                <div
                  key={user._id}
                  className="flex break-before-auto bg-[#e3e4e6] dark:bg-spekterDarkOne text-gray-700 dark:text-gray-400 rounded-5 mt-3 p-3 text-xs"
                >
                  <div className=" w-[75%] flex flex-col gap-2 min-h-max">
                    <div className="flex gap-5">
                      <div className="whitespace-nowrap">{t("name")}:</div>
                      <div>{user.name ? user.name : ""}</div>
                    </div>
                    <div className="flex gap-2">
                      <div className="whitespace-nowrap">Address:</div>
                      <div>{user.address ? user.address : ""}</div>
                    </div>
                    <div className="flex gap-4">
                      <div className="whitespace-nowrap">Mobile:</div>
                      <div>
                        {/* {`${
                      user.mobile ? user.mobile.slice(0, 6) + "*****" : ""
                    }`} */}
                        <ShowHideText
                          text={user.mobile || ""}
                          show={showPhoneNumber}
                        />
                      </div>
                    </div>
                    <div className="flex items-center gap-[19px]">
                      <div className="whitespace-nowrap">{t("mail")}:</div>
                      <div className="text-[10px] w-full">
                        {/* {`${
                        user.email
                          ? user.email.split("@")[0].slice(0, -2) + "**"
                          : ""
                      }@********`} */}
                        <ShowHideText
                          text={user.email || ""}
                          show={showEmail}
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" w-[25%] flex justify-end items-center">
                    <button
                      onClick={() => modalToggleHandler(user)}
                      className="w-20 h-8 px-2 py-0 text-xs font-bold text-gray-900 no-underline rounded bg-spekterGreenLight dark:bg-spekterGreen"
                    >
                      {t("edit")}
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="mx-auto mt-40 bg-gray-400 rounded-sm max-w-max">
              <h5 className="p-10 text-white">{t("noActiveUsersFound")}</h5>
            </div>
          )}
        </div>

        <div className="flex justify-center sticky top-[100vh]">
          {users && users.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              paginate={paginate}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserList;
