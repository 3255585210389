import React from "react";
import { useSelector } from "react-redux";
import Backdrop from "../../../../../components/UI/Backdrop/Backdrop";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showDangerPagePopUp } from "../../../../../redux/actions/index";

const Sidebar = ({ open, setOpen }) => {
  const { t } = useTranslation("legend");
  const dispatch = useDispatch();
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);

  let bgColor = isThemeDark ? "bg-spekterDarkOne" : "bg-white";
  let textColor = isThemeDark ? "text-spekterWhite" : "text-spekterDarkOne";
  let cancel_icon = isThemeDark
    ? "/i2/images/icons8-cancel-32.png"
    : "/i2/images/icons8-cancel-24-dark.png";

  const floodLevels = [
    {
      name: t("s0"),
      color: "bg-spekterGreen",
    },
    {
      name: t("s1"),
      color: "bg-spekterYellow",
    },
    {
      name: t("s2"),
      color: "bg-spekterRed",
    },
    {
      name: t("s3"),
      color: "bg-spekterPurple",
    },
    {
      name: t("pod"),
      color: "bg-[#C84F5A]",
    },
  ];
  const sensorsInfo = [
    {
      imgUrl: "/images/Niederschlag_inaktiv.svg",
      // imgUrl: "images/hdc-circle-idle.png",
      name: t("hdcInactive"),
    },
    {
      imgUrl: "/images/Niederschlag_aktiv.svg",
      // imgUrl: "images/hdc-circle-active.png",
      name: t("hdcActive"),
    },
    {
      imgUrl: "/images/Pegel_inaktiv.svg",
      // imgUrl: "images/floodlevel-circle.png",
      name: t("smartriverInactive"),
    },
    {
      imgUrl: "/images/Pegel_aktiv.svg",
      // imgUrl: "images/floodlevel-circle-active.png",
      name: t("smartriverActive"),
    },
    {
      imgUrl: "/images/Kanal_inaktiv.svg",
      // imgUrl: "images/sewer-circle-inactive.png",
      name: t("sewerInactive"),
    },
    {
      imgUrl: "/images/Kanal_aktiv.svg",
      // imgUrl: "images/sewer-circle-active.png",
      name: t("sewerActive"),
    },
  ];

  const onShowDangerPage = () => {
    dispatch(showDangerPagePopUp(true));
  };
  return (
    <div>
      <Backdrop
        show={open}
        clicked={() => setOpen(false)}
        opacity="bg-opacity-0"
      />
      <div
        className={`fixed w-[350px] h-[calc(100vh_-_56px)] right-0 top-0 ${bgColor} ${textColor} pt-3 sm:py-8 px-4 box-border mt-14 transition-transform transform overflow-y-auto scrollbarLight dark:scrollbar ${
          open ? "translate-x-0" : "translate-x-full"
        }`}
        style={{ zIndex: 500 }}
      >
        <div className="flex flex-row items-center justify-between sm:mb-1 sm:pt-3">
          <h4>{t("title")}</h4>
          <img
            className="cursor-pointer"
            height="20"
            width="20"
            src={cancel_icon}
            alt="cancel icon"
            onClick={() => setOpen(false)}
          />
        </div>
        <main className="flex flex-col justify-between">
          {/* <main className="flex flex-col h-[90%] justify-between bb"> */}
          <div>
            <section className="mt-4">
              <h5 className="font-bold">{t("titleSensor")}</h5>
              {sensorsInfo.map((sensor, index) => (
                <span className="flex flex-row items-center m-3" key={index}>
                  <img
                    src={sensor.imgUrl}
                    alt="sensor-icons"
                    height="30"
                    width="30"
                  />
                  <p className="m-0 ml-3 text-xs">{sensor.name}</p>
                </span>
              ))}
            </section>
            <section className="mt-5">
              <h5 className="font-bold">{t("titleSLevels")}</h5>
              {floodLevels.map((level, index) => (
                <span className="flex flex-row items-center m-3" key={index}>
                  <div
                    className={`h-[30px] w-[30px] rounded ${level.color}`}
                  ></div>
                  <p className="m-0 ml-3 text-xs">{level?.name}</p>
                </span>
              ))}
            </section>
            <section className="mt-5 mb-28">
              <h5 className="font-bold">Radar Intensität</h5>
              <img
                className="mt-3"
                src="/images/FAS_Gradient.svg"
                alt="Radar Intensität"
              />

              <div className="flex w-[350px] max-w-[100%] mt-1 text-sm justify-between sm:text-xs">
                <div className="font-light">Schwach</div>
                <div className="font-light">Extrem</div>
              </div>
            </section>
            <section className="flex items-center justify-center mt-4 mb-28 sm:mb-0 pb-4">
              <button
                className="flex items-center justify-center h-10 text-gray-800 rounded cursor-pointer w-60 bg-neutral-200"
                onClick={() => onShowDangerPage()}
              >
                <img
                  className="w-5 h-5"
                  src="/images/menu_icons/about-icon-dark.svg"
                  alt="info icon"
                />
                <p className="m-0 ml-2 text-xs text-gray-800">
                  {t("infoWarningStage")}
                </p>
              </button>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Sidebar;
