import React from "react";

const DownloadSection = (props) => {
  return (
    <div className="relative w-full sm:w-[50%] xl:w-[60%] h-full bg-boxBG bg-opacity-95 sm:bg-opacity-60 px-[20px] sm:px-10 xl:px-20 hidden sm:flex items-center">
      <div className="mx-auto flex flex-col py-3 items-start sm:items-center sm:bg-[#000000] min-h-[100px] w-full xl:max-w-[690px] rounded-xl duration-300 mt-auto sm:mb-[80px]">
        <span className="text-[1.5rem] mb-3 text-white font-bold">
          Starkregen App
        </span>
        <span className="text-[.80rem] mb-3 text-start sm:text-center text-white w-full sm:max-w-[90%]">
          Installieren Sie die Starkregen App auf Ihrem Smartphone um
          rechtzeitig vor Starkregen, Überflutung und Hochwasser alarmiert zu
          werden.
        </span>
        <div className="flex justify-start sm:justify-center max-w-full gap-5 my-2">
          <a
            href="https://apps.apple.com/de/app/starkregen-app/id1528339521?l=de"
            target="_blank"
            rel="noopener noreferrer"
            className="w-[40%] sm:w-[30%]"
          >
            <img
              src="/images/appstore.png"
              alt="appstore"
              className="shadow-modal"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.spekter.cloudbreakapp"
            target="_blank"
            rel="noopener noreferrer"
            className="w-[40%] sm:w-[30%]"
          >
            <img
              src="/images/google-play.png"
              alt="google-play"
              className=" shadow-modal"
            />
          </a>
        </div>
      </div>
      <div className="hidden sm:flex left-1/2 transform -translate-x-1/2 absolute bottom-10 space-x-2">
        {props.images.map((_, index) => (
          // <button
          //   key={index}
          //   className={`h-3 w-3 bg-white rounded-full ${
          //     index === props.currentImageIndex ? "opacity-100" : "opacity-50"
          //   }`}
          //   onClick={() => props.handleDotClick(index)}
          // />
          <div
            key={index}
            className={`h-3 w-3 bg-white rounded-full cursor-pointer ${
              index === props.currentImageIndex ? "opacity-100" : "opacity-50"
            }`}
            onClick={() => props.handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default DownloadSection;
