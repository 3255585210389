import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import ImageWMS from "ol/source/ImageWMS";
import ImageLayer from "ol/layer/Image";
import { get as getProjection } from "ol/proj";
import { getWidth } from "ol/extent";
import Vector from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OlStyle from "ol/style/Style";
import OlStyleIcon from "ol/style/Icon";

// export const osmBaseLayer = new TileLayer({
//     visible: true,
//     opacity: 0.8,
//     title: "osmBaseLayer",
//     source: new TileWMS({
//         url: "https://ows.terrestris.de/osm-gray/service?&layers=OSM-WMS",
//         //url: 'https://geoserver.starkregenalarm.com/geoserver/wms',
//         params: {
//             FORMAT: "image/png",
//             VERSION: "1.1.0",
//             tiled: true,

//             //'LAYERS': 'osm-grey-roads,osm-grey-landuse,osm-grey-placenames,osm-grey-water',
//         },
//     }),
// });

const projection = getProjection("EPSG:3857");
const projectionExtent = projection.getExtent();
const size = getWidth(projectionExtent) / 256;
const resolutions = new Array(14);
const matrixIds = new Array(14);

for (let z = 0; z < 14; ++z) {
  // generate resolutions and matrixIds arrays for this WMTS
  resolutions[z] = size / Math.pow(2, z);
  matrixIds[z] = z;
}

export const osmBaseLayer = new TileLayer({
  visible: true,
  opacity: 0.7,
  title: "osmBaseLayer",
  source: new TileWMS({
    url: "https://api.mapbox.com/styles/v1/spektergmbh/ckttwtjta0qvz18pmgzjxczvk/wmts?access_token=pk.eyJ1Ijoic3Bla3RlcmdtYmgiLCJhIjoiY2s2dGd5bnZiMHBhZTNsbWw1eGVhaWx3dCJ9.jpamQ8Yl4Wr-xisbkw1a1A",
    //url: 'https://geoserver.starkregenalarm.com/geoserver/wms',
    params: {
      FORMAT: "image/png",
      VERSION: "1.1.0",
      tiled: true,
      //'LAYERS': 'osm-grey-roads,osm-grey-landuse,osm-grey-placenames,osm-grey-water',
    },
  }),
});

// Get building layers from Cloudbreak Geoserver
export const buildingsShape = (ags) =>
  new TileLayer({
    visible: false,
    opacity: 0.8,
    title: "buildingsShape",
    source: new TileWMS({
      url: `http://greyscale-v2.wms.geofabrik.de/74cd432deb99495d85597dad4479f35c?crs=EPSG:4326&dpiMode=7&format=image/png&layers=buildings&styles&url=http://greyscale-v2.wms.geofabrik.de/74cd432deb99495d85597dad4479f35c&username=${process.env.REACT_APP_ALKIS_USER}&password=${process.env.REACT_APP_ALKIS_PASSWORD}`,
      params: {
        FORMAT: "image/png",
        VERSION: "1.1.0",
        tiled: true,
        LAYERS: ags + "-" + "buildings",
      },
    }),
    zIndex: 899,
  });

// Get flood layers from Cloudbreak Geoserver
export const starkregenShapesN30 = (ags) =>
  new TileLayer({
    visible: false,
    opacity: 0.8,
    title: "starkregenShapesN30",
    source: new TileWMS({
      url: "https://geoserver.starkregenalarm.com/geoserver/wms",
      params: {
        FORMAT: "image/png",
        VERSION: "1.1.0",
        tiled: true,
        LAYERS: ags + "-" + "n30",
        exceptions: "application/vnd.ogc.se_inimage",
        tilesOrigin: 4420898.150000001 + "," + 5507747.5200000005,
      },
    }),
    zIndex: 999,
  });

export const starkregenShapesN100 = (ags) =>
  new TileLayer({
    visible: false,
    opacity: 0.8,
    title: "starkregenShapesN100",
    source: new TileWMS({
      url: "https://geoserver.starkregenalarm.com/geoserver/wms",
      params: {
        FORMAT: "image/png",
        VERSION: "1.1.0",
        tiled: true,
        LAYERS: ags + "-" + "n100",
        exceptions: "application/vnd.ogc.se_inimage",
        tilesOrigin: 4420898.150000001 + "," + 5507747.5200000005,
      },
    }),
    zIndex: 1000,
  });

export const starkregenShapesN1000 = (ags) =>
  new TileLayer({
    visible: false,
    opacity: 0.8,
    title: "starkregenShapesN1000",
    source: new TileWMS({
      url: "https://geoserver.starkregenalarm.com/geoserver/wms",
      params: {
        FORMAT: "image/png",
        VERSION: "1.1.0",
        tiled: true,
        LAYERS: ags + "-" + "n1000",
        exceptions: "application/vnd.ogc.se_inimage",
        tilesOrigin: 4420898.150000001 + "," + 5507747.5200000005,
      },
    }),
    zIndex: 10000,
  });

const username = `${process.env.REACT_APP_GEO_SERVER_USER}`;
const password = `${process.env.REACT_APP_GEO_SERVER_PASSWORD}`;
const encodedCredentials = btoa(`${username}:${password}`);

function customLoader(image, src) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", src);
  xhr.setRequestHeader("Authorization", "Basic " + encodedCredentials);
  xhr.responseType = "blob";
  xhr.onload = function () {
    if (this.status === 200) {
      var blob = this.response;
      image.getImage().src = URL.createObjectURL(blob);
    }
  };
  xhr.send();
}

// Get flood layers from new Cloudbreak Geoserver
export const hinweiskartenShapesN1 = (ags) =>
  new TileLayer({
    visible: false,
    opacity: 0.8,
    title: "hinweiskartenShapesN1",
    source: new TileWMS({
      url: "https://geoserver.hydroio.cloud/geoserver/wms",
      tileLoadFunction: customLoader,
      params: {
        FORMAT: "image/png",
        VERSION: "1.1.0",
        tiled: true,
        LAYERS: ags + "_n1",
        exceptions: "application/vnd.ogc.se_inimage",
        tilesOrigin: 4420898.150000001 + "," + 5507747.5200000005,
      },
    }),
    zIndex: 999,
  });

export const hinweiskartenShapesN5 = (ags) =>
  new TileLayer({
    visible: false,
    opacity: 0.8,
    title: "hinweiskartenShapesN5",
    source: new TileWMS({
      url: "https://geoserver.hydroio.cloud/geoserver/wms",
      tileLoadFunction: customLoader,
      params: {
        FORMAT: "image/png",
        VERSION: "1.1.0",
        tiled: true,
        LAYERS: ags + "_n5",
        exceptions: "application/vnd.ogc.se_inimage",
        tilesOrigin: 4420898.150000001 + "," + 5507747.5200000005,
      },
    }),
    zIndex: 999,
  });

export const riskShapeImageWMS = (ags) =>
  new ImageLayer({
    visible: false,
    opacity: 0.8,
    title: "riskShapeImageWMS",
    source: new ImageWMS({
      url: "https://geoserver.starkregenalarm.com/geoserver/wms",
      params: {
        FORMAT: "image/png",
        VERSION: "1.1.0",
        tiled: true,
        LAYERS: "riskmap-" + ags + "-riskareas",
        exceptions: "application/vnd.ogc.se_inimage",
        tilesOrigin: 4420898.150000001 + "," + 5507747.5200000005,
      },
    }),
    zIndex: 10001,
  });

export const riskShapeImageWMS_FT1 = (ags) =>
  new ImageLayer({
    visible: false,
    opacity: 0.8,
    title: "riskShapeImageWMS_FT1",
    source: new ImageWMS({
      url: "https://geoserver.starkregenalarm.com/geoserver/wms",
      params: {
        FORMAT: "image/png",
        VERSION: "1.1.0",
        tiled: true,
        LAYERS: "riskmap-" + ags + "-floodtype-1",
        exceptions: "application/vnd.ogc.se_inimage",
        tilesOrigin: 4420898.150000001 + "," + 5507747.5200000005,
      },
    }),
    zIndex: 10002,
  });

export const riskShapeImageWMS_FT2 = (ags) =>
  new ImageLayer({
    visible: false,
    opacity: 0.8,
    title: "riskShapeImageWMS_FT2",
    source: new ImageWMS({
      url: "https://geoserver.starkregenalarm.com/geoserver/wms",
      params: {
        FORMAT: "image/png",
        VERSION: "1.1.0",
        tiled: true,
        LAYERS: "riskmap-" + ags + "-floodtype-2",
        exceptions: "application/vnd.ogc.se_inimage",
        tilesOrigin: 4420898.150000001 + "," + 5507747.5200000005,
      },
    }),
    zIndex: 10003,
  });

export const riskShapeImageWMS_FT3 = (ags) =>
  new ImageLayer({
    visible: false,
    opacity: 0.8,
    title: "riskShapeImageWMS_FT3",
    source: new ImageWMS({
      url: "https://geoserver.starkregenalarm.com/geoserver/wms",
      params: {
        FORMAT: "image/png",
        VERSION: "1.1.0",
        tiled: true,
        LAYERS: "riskmap-" + ags + "-floodtype-3",
        exceptions: "application/vnd.ogc.se_inimage",
        tilesOrigin: 4420898.150000001 + "," + 5507747.5200000005,
      },
    }),
    zIndex: 10004,
  });
