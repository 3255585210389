import React, { useEffect, useState } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { FaChevronDown } from "react-icons/fa";
import Switch from "../../../../components/UI/Switch/Switch";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Vector from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OlStyle from "ol/style/Style";
import OlStyleIcon from "ol/style/Icon";
import OlFeature from "ol/Feature";
import OlPoint from "ol/geom/Point";
import useAgs from "../../../../hooks/useAgs";
import useObserver from "../../../../hooks/useObserver";
import * as actions from "../../../../redux/actions/index";
import { fromLonLat, toLonLat } from "ol/proj";

const selectPodIcon = (zoomValue, category) => {
  const fileName =
    zoomValue >= 15
      ? category === "firefighters"
        ? "Firefighters POD, State=Zoomed In"
        : category === "administration"
        ? "Administrative Service POD, State=Zoomed In"
        : category === "police"
        ? "Police POD, State=Zoomed In"
        : category === "thw"
        ? "THW POD, State=Zoomed In"
        : "POD Icon_aktiv"
      : category === "firefighters"
      ? "Firefighters POD, State=Zoomed Out"
      : category === "administration"
      ? "Administrative Service POD, State=Zoomed Out"
      : category === "police"
      ? "Police POD, State=Zoomed Out"
      : category === "thw"
      ? "THW POD, State=Zoomed Out"
      : "POD Icon_aktiv";
  return `/images/pod/${fileName}.svg`;
};

/* repick is used to prevent multiple event listeners
 without repick, event listener is added multiple times after each click
*/
let repick = true;
let addPodActive = false;

// pods is used to store all pods
//
let pods = [];

// dummy pod is used to show a marker on map when user clicks on map to add a new pod
let dummyPod = null;
const podsLayer = new Vector({
  source: new VectorSource({
    features: [],
    wrapX: false,
  }),
  style: new OlStyle({
    image: new OlStyleIcon({
      anchor: [0.5, 98],
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      scale: 0.1,
      src: "/images/pod/pod_marker_small.png",
    }),
  }),
  zIndex: 10005,
});

const Pods = ({
  map,
  ags,
  addNewPod,
  setAddNewPod,
  setShowPodViewModal,
  setShowCreateAndEditModal,
  setSelectedPod,
  setNewLatLng,
  newMarkerInstruction,
  setNewMarkerInstruction,
}) => {
  const { t } = useTranslation("realtimeMap"); // i18n namespace for translation
  const dispatch = useDispatch();
  const [currentZoom, setCurrentZoom] = useState(0);
  const showForm = false;
  const [showPods, setShowPods] = useState(true);
  const [expanded, setExpanded] = useState(true);

  const podsData = useSelector((state) => state.pod.pods.data);

  const [tooltipElement, setTooltipElement] = useState(null);

  useEffect(() => {
    if (!map) return;
    podsLayer.getSource().clear();
    if (!podsData) return;
    pods = podsData;

    if (showPods) {
      if (!podsData) return;

      podsData.forEach((pod) => {
        let coordinate = fromLonLat([pod.location.lng, pod.location.lat]);
        let id = pod._id;

        // Select the appropriate icon based on the pod's category
        let iconPath = selectPodIcon(currentZoom, pod?.category);
        let iconStyle = new OlStyle({
          image: new OlStyleIcon({
            anchor: [0.5, 1],
            scale: 0.05,
            src: iconPath,
          }),
        });

        let marker = new OlFeature({
          geometry: new OlPoint(coordinate),
          name: "pod marker",
        });

        // Set the custom style for the marker
        marker.setStyle(iconStyle);
        marker.setId(id);

        // add marker on pods layer.
        podsLayer.getSource().addFeature(marker);
      });
    } else {
      //--------------------------------
      // remove existing pods from layer
      podsLayer.getSource().clear();
    }
  }, [showPods, podsData, currentZoom, map]);

  const cleanDummyPod = () => {
    if (dummyPod) {
      podsLayer.getSource().removeFeature(dummyPod);
      dummyPod = null;
    }
  };

  // Add tooltip on pod marker
  useEffect(() => {
    // Create and configure the tooltip element (runs once on initial render)
    const tooltipDiv = document.createElement("div");
    tooltipDiv.id = "pod-tooltip";

    tooltipDiv.style.cssText = `
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-weight: normal;
    display: none;
  `;

    document.body.appendChild(tooltipDiv);
    setTooltipElement(tooltipDiv);

    return () => document.body.removeChild(tooltipDiv);
  }, []);

  useEffect(() => {
    if (!map) return;
    if (pods && pods.length === 0) return;
    if (!tooltipElement.style) return;

    const onMouseMove = (event) => {
      // Find the map feature under the mouse cursor
      const feature = map.forEachFeatureAtPixel(
        event.pixel,
        (feature) => feature
      );
      if (feature) {
        const pod = pods.find((pod) => pod._id === feature.getId());
        if (pod) {
          // console.log(tooltipElement);
          tooltipElement.textContent = pod.orderSheet || "(No Order Sheet)";
          tooltipElement.style.display = "block";

          const pixel = map.getPixelFromCoordinate(
            feature.getGeometry().getCoordinates()
          );
          tooltipElement.style.left = `${pixel[0] + 0}px`;
          tooltipElement.style.top = `${pixel[1] - 0}px`;
        } else {
          tooltipElement.style.display = "none";
        }
      } else {
        tooltipElement.style.display = "none";
      }
    };

    map.on("pointermove", onMouseMove);

    return () => map.un("pointermove", onMouseMove);
  }, [map, pods, tooltipElement]);

  //add new pod
  useEffect(() => {
    if (!map) return;

    const onAddMarker = (event) => {
      cleanDummyPod();
      if (!repick) return;
      const lnglat = toLonLat(event.coordinate);
      setNewLatLng({ lng: lnglat[0], lat: lnglat[1] });

      let marker = new OlFeature({
        geometry: new OlPoint(event.coordinate),
        name: "pod marker",
      });

      let id = uuidv4();
      marker.setId(id);

      setShowCreateAndEditModal(true);
      setNewMarkerInstruction(false);

      // add marker on pods layer.
      podsLayer.getSource().addFeature(marker);
      dummyPod = marker;
    };

    if (addNewPod) {
      map.once("singleclick", onAddMarker);
      repick = true;
    } else {
      cleanDummyPod();
      repick = false;
    }
  }, [addNewPod, newMarkerInstruction, map]);

  // disable pod viewer listener while addNewPod is active
  useEffect(() => {
    addPodActive = addNewPod;
  }, [addNewPod]);

  useEffect(() => {
    if (!map) return;
    map.addLayer(podsLayer);

    // if (agsList.length === 0) return;
    // const ags = isObserver ? selectedObserverAgs : agsList[0];
    dispatch(actions.pods(ags));

    map.on("singleclick", (event) => {
      // find pod at clicked location

      if (addPodActive) return;
      map.forEachFeatureAtPixel(event.pixel, function (f) {
        if (f) {
          // setSelectedFeature(f);
          pods.forEach((pod) => {
            if (pod._id === f.getId()) {
              setSelectedPod(pod);
              setShowPodViewModal(true);
            }
          });
        }

        return true;
      });
    });

    // get current zoom value
    map.on("moveend", () => {
      const zoomValue = map.getView().getZoom();
      setCurrentZoom(zoomValue);
      if (zoomValue >= 15) {
        setCurrentZoom(zoomValue);
        console.log(zoomValue);
      }
    });
  }, [map, ags]);

  return (
    <div className="">
      <div className="py-8 px-3.5 mb-5 box-border block text-spekterWhite bg-spekterDarkThree shadow-container rounded-md">
        <div
          className="flex items-center justify-between cursor-pointer hover:text-blue-500"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="flex items-center justify-center">
            <img
              src="/images/pod/POD Icon_ffffff.svg"
              alt="pod icon"
              className="w-10 h-10 "
              id="pod-icon"
            />
            <div className="font-bold text-lg mr-2.5">PODs</div>
          </div>
          <div className="mt-1">{<FaChevronDown />}</div>
        </div>

        {expanded && (
          <div>
            <span className="flex flex-col items-center p-3 m-1 opacity-60">
              {t("inCaseOfHazzard")}
            </span>

            <div className="p-2.5">
              <div className={`${showForm ? "hidden" : "block"}`}>
                <ul className="flex items-center gap-8 mb-5">
                  <li className="relative top-1">
                    <Switch
                      value={showPods}
                      bgCheckedColor="checked:bg-[#04CF00]"
                      onCheck={() => {
                        setShowPods(!showPods);
                      }}
                    />
                  </li>
                  <li>PODs anzeigen</li>
                </ul>
                <ul>
                  <li>
                    {!addNewPod ? (
                      <button
                        className="flex items-center gap-2 p-[10px] ml-2 font-semibold text-black rounded bg-[#04CF00]"
                        onClick={() => {
                          setAddNewPod(true);
                          setNewMarkerInstruction(true);
                        }}
                      >
                        <img
                          src="/images/pod/Plus_icon_bold_000000.svg"
                          height={20}
                          width={20}
                          alt="plus icon"
                          className="opacity-75"
                          id="plus icon "
                        />
                        <span className="opacity-75 ">
                          Neuen Pod hinzufügen
                        </span>
                      </button>
                    ) : (
                      <button
                        className="flex items-center gap-2 ml-3 p-[10px] font-semibold text-black rounded bg-[#04CF00]"
                        onClick={() => {
                          setAddNewPod(false);
                          setNewMarkerInstruction(false);
                        }}
                      >
                        {t("cancel")}
                      </button>
                    )}
                  </li>
                  <li>
                    {addNewPod && (
                      <div className="items-center p-3 m-1 opacity-60">
                        {/* {t("commentInfo")} */}
                        {t("pleaseClickOnTheMap")}
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pods;
