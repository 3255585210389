import React from "react";
import Legend from "./Legend/Legend";
import ObserverBackButton from "./ObserverBackButton/ObserverBackButton";
import RadarLayer from "./RadarLayer/RadarLayer";
import ZoomControls from "./ZoomControls/ZoomControls";
import Clock from "./Clock/Clock";
import POD from "./POD/POD";
import useObserver from "../../../hooks/useObserver";
import { useSelector } from "react-redux";
import NormalBackButton from "./NormalBackButton/NormalBackButton";
import useRole from "../../../hooks/useRole";
import useAuth from "../../../hooks/useAuth";
import PlaceSearch from "./PlaceSearch/PlaceSearch";

const Controls = ({
  map,
  maps,
  handleNormalMapReset,
  showResetButton,
  setAddressCoordinate,
}) => {
  const isAuth = useAuth();
  const userRole = useRole();
  const observer = useObserver();
  const activeModules = useSelector(
    (state) => state.customer?.data?.activeModules
  );
  const selectedAgs = useSelector((state) => state.observer.selectedAgs);

  return (
    <div className="flex flex-col justify-between items-end pointer-events-none fixed top-20 bottom-5 right-5 text-spekterWhite font-bold z-[100] xl:z-[101]">
      <section className="flex flex-col items-end gap-2 pointer-events-auto">
        <Clock />
        {!observer ? (
          activeModules && activeModules["radar"] && <RadarLayer map={map} />
        ) : (
          <RadarLayer map={map} />
        )}

        {isAuth && (
          <>
            {/*  ===reder on normal view===
                revoke POD access for users */}
            {!observer &&
              activeModules &&
              activeModules["pod"] &&
              userRole !== "users" && <POD map={map} maps={maps} />}

            {/* render on observer 2nd layer
               disabled by class hidden for pod persist issue while back on observer from 2nd layer
             */}
            {observer && (
              <POD
                className={selectedAgs ? "flex" : "hidden"}
                map={map}
                maps={maps}
              />
            )}
          </>
        )}
      </section>
      <div className="h-1"></div>
      <section className="pointer-events-auto ">
        <ObserverBackButton />
        <NormalBackButton
          handleNormalMapReset={handleNormalMapReset}
          showResetButton={showResetButton}
        />
        {/* {!observer ? (
          <PlaceSearch setAddressCoordinate={setAddressCoordinate} />
        ) : (
          showAddressSearchIcon && (
            <PlaceSearch setAddressCoordinate={setAddressCoordinate} />
          )
        )} */}
        <PlaceSearch setAddressCoordinate={setAddressCoordinate} />
        <div className="h-1"></div>
        <Legend />
        <div className="h-4"></div>
        <ZoomControls map={map} />
      </section>
    </div>
  );
};

export default Controls;
