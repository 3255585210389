import React, { useEffect, useState } from "react";

const Clock = () => {
  const [time, setTime] = useState("00:00");

  // add zero to single digit numbers
  const addZero = (num) => {
    return num < 10 ? `0${num}` : num;
  };

  const getTime = () => {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const time = `${addZero(hours)}:${addZero(minutes)}`;
    return time;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = getTime();
      setTime(currentTime);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex items-center justify-center w-20 h-10 duration-300 rounded cursor-pointer bg-spekterDarkOne hover:bg-gray-600">
      <span className="text-lg font-semibold">{time}</span>
    </div>
  );
};

export default Clock;
