import React from "react";
import Spinner from "../../../UI/Spinner/Spinner";
import { useTranslation } from "react-i18next";

const ConfirmDeleteModal = ({
  setShowDeleteModal,
  confirmClicked,
  isDeleteLoading,
}) => {
  const { t } = useTranslation("userSettings");
  return (
    <div className="fixed top-0 left-0 z-10 flex items-center justify-center w-full h-full overflow-auto bg-opacity-30 backdrop-blur-sm">
      {isDeleteLoading ? (
        <Spinner />
      ) : (
        <div className="px-12 py-4 shadow-md bg-barBG dark:bg-boxBG rounded-10 max-w-[90%]">
          <p className="font-semibold text-gray-700 select-none dark:text-gray-100 bg-barBG dark:bg-boxBG">
            {t("deletionText")}
          </p>
          <p className="select-none text-spekterRed dark:text-gray-100 text-md">
            {t("deletionMsg")}
          </p>
          <div className="flex w-full gap-16 pt-2">
            <button
              onClick={() => setShowDeleteModal(false)}
              className="border-[1px] border-gray-400 w-24 rounded-5 py-1 text-base text-gray-600 dark:text-gray-400 hover:opacity-75 duration-100"
            >
              {t("cancelBtnText")}
            </button>
            <button
              onClick={confirmClicked}
              className="w-24 py-1 text-base duration-100 bg-spekterRed rounded-5 text-spekterWhite dark:text-gray-900 hover:opacity-75"
            >
              {t("deleteBtnText")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmDeleteModal;
