import React from "react";

const BarToggle = ({ open, importer, clicked }) => {
  let close_menu_icon = "/i2/images/icons8-chevron-right-90.svg";

  let attachedClass = null;

  if (open) {
    attachedClass = `cursor-pointer rounded fixed right-0 inset-y-1/2 z-200 ${
      importer === "webviewer" ? "w-[88%] sm:w-[480px]" : "sm:w-[380px]"
    } bg-spekterDarkOne box-border py-10`;
    // "cursor-pointer rounded fixed right-0 inset-y-1/2 z-200 sm:w-[380px] bg-spekterDarkOne box-border py-10 bg";
  } else {
    attachedClass =
      "cursor-pointer rounded fixed right-0 inset-y-1/2 z-100 box-border bg-spekterDarkOne py-10 px-3";
  }

  return (
    <div className={attachedClass} onClick={clicked}>
      {open ? (
        <img
          className="absolute top-7 left-1"
          alt="close menu"
          width="20px"
          src={close_menu_icon}
        />
      ) : (
        <img
          className="absolute top-7 right-1"
          alt="open menu"
          width="20px"
          src="images/open_menu.png"
        />
      )}
    </div>
  );
};

export default BarToggle;
