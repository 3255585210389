import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Spinner from "../../UI/Spinner/Spinner";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { utcToGermanTime } from "../../../shared/Utility";
import {
  XAxis,
  YAxis,
  ReferenceLine,
  Tooltip,
  AreaChart,
  Area,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const GflChart = ({
  rawData,
  optionalData,
  sensorName,
  duration,
  setTimeRange,
}) => {
  //language package
  const { t } = useTranslation("sensors");

  const [currentValue, setCurrentValue] = useState("");
  const { width } = useWindowDimensions();
  const [tickInterval, setTickInterval] = useState(5);
  const [chartData, setChartData] = useState([]);
  const isChartLoading = useSelector((state) => state.chart.isLoading);

  const thresholds = optionalData.thresholds || [];
  const isMaintenanceMode = optionalData?.maintenance;

  useEffect(() => {
    let tickHour = 6;
    if (duration <= 1440) {
      tickHour = 6;
    } else {
      if (width <= 640) {
        tickHour = 6;
      } else {
        tickHour = 12;
      }
    }

    let hour = duration / 60;
    let interval = Math.round(hour / tickHour);

    setTickInterval(interval);
  }, [width, duration]);

  useEffect(() => {
    if (rawData) {
      const data = rawData.map((item, index) => {
        return {
          date: item.start,
          value: item.value > 0 ? item.value : 0,
        };
      });
      setCurrentValue(data[data.length - 1]?.value.toString().split(".")[0]);
      setChartData(data);
    }
  }, [rawData, optionalData]);

  const timeframeContent = (
    <div className="flex gap-2 bg-[#343A40] p-1 rounded-5">
      <div
        onClick={() => setTimeRange(1440)}
        className={`h-[22px] w-[71px] flex justify-center items-center duration-300
    rounded-5 font-[700] text-[11px] cursor-pointer hover:opacity-75
  ${duration === 1440 && "bg-spekterDarkOne"}`}
      >
        {t("oneDaySelection")}
      </div>
      <div
        onClick={() => setTimeRange(1440 * 3)}
        className={`h-[22px] w-[71px] flex justify-center items-center duration-300
    rounded-5 font-[700] text-[11px] cursor-pointer hover:opacity-75
  ${duration === 4320 && "bg-spekterDarkOne"}`}
      >
        {t("threeDaySelection")}
      </div>
      <div
        onClick={() => setTimeRange(1440 * 7)}
        className={`h-[22px] w-[71px] flex justify-center items-center duration-300
    rounded-5 font-[700] text-[11px] cursor-pointer hover:opacity-75
  ${duration === 10080 && "bg-spekterDarkOne"}`}
      >
        {t("sevenDaySelection")}
      </div>
    </div>
  );

  if (isChartLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner />
      </div>
    );
  }

  const WarningLabelOne = (props) => {
    return (
      <g>
        <rect
          x={props.viewBox.x + 5}
          y={props.viewBox.y - 10}
          fill="#343A40"
          width={80}
          height={15}
          rx="5"
          ry="5"
        />
        <text
          x={props.viewBox.x - 15}
          y={props.viewBox.y - 18}
          fill="#fff"
          dy={20}
          dx={30}
          fontSize={10}
        >
          {t("gflWarningStageOne") + " (" + thresholds[0].level + "cm)"}
        </text>
      </g>
    );
  };

  const WarningLabelTwo = (props) => {
    return (
      <g>
        <rect
          x={props.viewBox.x + 90}
          y={props.viewBox.y - 10}
          fill="#343A40"
          width={80}
          height={15}
          rx="5"
          ry="5"
        />
        <text
          x={props.viewBox.x + 70}
          y={props.viewBox.y - 18}
          fill="#fff"
          dy={20}
          dx={30}
          fontSize={10}
        >
          {t("gflWarningStageTwo") + " (" + thresholds[1].level + "cm)"}
        </text>
      </g>
    );
  };

  const WarningLabelThree = (props) => {
    return (
      <g>
        <rect
          x={props.viewBox.x + 175}
          y={props.viewBox.y - 10}
          fill="#343A40"
          width={80}
          height={15}
          rx="5"
          ry="5"
        />
        <text
          x={props.viewBox.x + 155}
          y={props.viewBox.y - 18}
          fill="#fff"
          dy={20}
          dx={30}
          fontSize={10}
        >
          {t("gflWarningStageThree") + " (" + thresholds[2].level + "cm)"}
        </text>
      </g>
    );
  };
  const WarningLabelFour = (props) => {
    return (
      <g>
        <rect
          x={props.viewBox.x + 260}
          y={props.viewBox.y - 10}
          fill="#343A40"
          width={80}
          height={15}
          rx="5"
          ry="5"
        />
        <text
          x={props.viewBox.x + 240}
          y={props.viewBox.y - 18}
          fill="#fff"
          dy={20}
          dx={30}
          fontSize={10}
        >
          {t("gflWarningStageFour") + " (" + thresholds[3].level + "cm)"}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="flex flex-col text-white bg-[#333333] p-4 text-sm rounded">
          <p>{`${utcToGermanTime(label, "DD.MM HH:mm")}`}</p>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 bg-[#8200FF]"></div>
            <span className="opacity-70">{`Pegelhöhe : ${payload[0].value} cm`}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  let chart = (
    <ResponsiveContainer width="100%" height={250}>
      <AreaChart
        data={chartData}
        margin={{ top: 20, right: 20, left: -20, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorGfl" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8200FF" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#8200FF" stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          tickFormatter={(dateTime) => utcToGermanTime(dateTime, "HH:mm")}
          interval={tickInterval}
          fontSize={12}
          tick={{ fill: "#fff" }}
        />
        <YAxis
          type="number"
          domain={[0, thresholds.length === 4 ? thresholds[3].level * 1.1 : 0]}
          tickFormatter={(value) => value.toFixed(0)}
          fontSize={12}
          tickCount={0}
          tick={{ fill: "#fff" }}
        />
        <CartesianGrid
          strokeDasharray="0 0"
          stroke="#fff"
          strokeWidth={0.4}
          opacity={0.4}
        />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#8200FF"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorGfl)"
        />
        <ReferenceLine
          y={thresholds.length === 4 && thresholds[0].level}
          label={WarningLabelOne}
          stroke="#ffffff"
          strokeWidth={1}
        />
        <ReferenceLine
          y={thresholds.length === 4 && thresholds[1].level}
          label={WarningLabelTwo}
          stroke="#ffffff"
          strokeWidth={1}
        />
        <ReferenceLine
          y={thresholds.length === 4 && thresholds[2].level}
          label={WarningLabelThree}
          stroke="#ffffff"
          strokeWidth={1}
        />
        <ReferenceLine
          y={thresholds.length === 4 && thresholds[3].level}
          label={WarningLabelFour}
          stroke="#ffffff"
          strokeWidth={1}
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  return (
    <div className="w-full h-full">
      <div className="flex flex-col items-center sm:items-start sm:flex-row sm:justify-between text-spekterWhite">
        <div className="flex items-start justify-center my-0 mx-2.5 gap-2">
          <img
            src={`/images/${
              isMaintenanceMode || chartData.length < 1
                ? "wartung_icon.svg"
                : thresholds.length === 2 && thresholds[0].active
                ? "Pegel_aktiv.svg"
                : "Pegel_inaktiv.svg"
            }`}
            alt="gfl icon"
            className="h-8 m-0"
          />
          <h5 className="mt-1">{sensorName}</h5>
        </div>
        {!isMaintenanceMode && chartData.length > 1 && (
          <section className="flex flex-col items-end ">
            <div className="flex flex-row items-center">
              <span>{t("floodLevelMeasurment")}:</span>
              <div className="flex flex-col items-center justify-center mx-2 my-1">
                <h5 className="m-0">
                  {isMaintenanceMode ? "--" : `${currentValue}`}
                </h5>
                <span className="m-0 text-[10px] font-semibold">CM</span>
              </div>
              <img
                src="/images/chart_modal/icons8-futures-240.svg"
                alt="gfl icon"
                className="h-6"
              />
            </div>
            <div className="mt-3"> {timeframeContent}</div>
          </section>
        )}
      </div>
      <div>
        {isMaintenanceMode || chartData.length < 1 ? (
          <div className="text-spekterWhite flex justify-center pt-[80px] gap-2 h-full p-4 mt-[30px]">
            <img
              className="w-8 h-8"
              src="/images/sensor_manager/icons8-bach-96.png"
              alt="maintenance icon"
            />
            <p className="relative top-[2px] text-[#9CA3AF] font-bold text-lg">
              {t("maintainanceFoulingGfl")}
            </p>
          </div>
        ) : (
          chart
        )}
      </div>
    </div>
  );
};

export default GflChart;
