import * as actionTypes from "../../actions/actionTypes";
const initialState = {
  isThemeDark:
    localStorage.getItem("isThemeDark") === null
      ? false
      : JSON.parse(localStorage.getItem("isThemeDark")),
};

const toggleTheme = (state) => {
  localStorage.setItem("isThemeDark", !state.isThemeDark);
  return { ...state, isThemeDark: !state.isThemeDark };
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.THEME_TYPE:
      return toggleTheme(state);
    default:
      return state;
  }
};

export default reducer;
