import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeEmailAccount } from "../../redux/actions/auth/changeEmail";
import Spinner from "../../components/UI/Spinner/Spinner";
import { toast } from "sonner";

const ChangeEmail = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = params;

  const isLoading = useSelector((state) => state.changeEmail.isLoading);

  useEffect(() => {
    if (token) {
      dispatch(changeEmailAccount(token)).then((response) => {
        if (response.type === "CHANGE_EMAIL_SUCCESS") {
          toast.success("Email changed successfully");
          setTimeout(() => navigate("/realtimemap"), 3000);
          setLoading(false);
        }

        if (response.type === "CHANGE_EMAIL_FAIL") {
          toast.error(response?.error?.response?.data?.message);
          setLoading(false);
        }
      });
    }
  }, []);

  return (
    <div
      className="box-border overflow-hidden bg-fixed bg-center bg-no-repeat bg-cover"
      style={{ backgroundImage: "url(/images/bg-fas.png)" }}
    >
      <div className="flex items-center flex-col gap-10 justify-center h-screen mx-2 max-w-[100%]">
        {(loading || isLoading) && (
          <div className="text-center bg-boxBG w-[500px] min-h-[350px] rounded-xl duration-300 max-w-[100%]">
            <div className="flex items-center justify-center h-full">
              <Spinner />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChangeEmail;
