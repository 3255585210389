import React, { useEffect, useState } from "react";
import { STEPS } from "./STEPS";
import RequestOTP from "./RequestOTP";
import ResendOTP from "./ResendOTP";
import CodeVerification from "./CodeVerification";
import NewCredential from "./NewCredential";
import { useTranslation } from "react-i18next";

const PasswordNew = () => {
  // const [currentStep, setCurrentStep] = useState(STEPS.PASSWORD_UPDATED);
  // const [currentStep, setCurrentStep] = useState(STEPS.NEW_CREDENTIAL);
  // const [currentStep, setCurrentStep] = useState(STEPS.CODE_VERIFICATION);
  const [currentStep, setCurrentStep] = useState(STEPS.RESEND_OTP);
  // const [currentStep, setCurrentStep] = useState(STEPS.REQUEST_OTP);
  const [email, setEmail] = useState("");
  const [resendCounter, setResendCounter] = useState(0);
  const [otp, setOtp] = useState("");

  //Language package
  const { t, i18n } = useTranslation("settings");

  useEffect(() => {
    resendCounter > 0 &&
      setTimeout(() => setResendCounter(resendCounter - 1), 1000);
  }, [resendCounter]);

  return (
    <div className="mt-0 px-6 sm:px-0 flex flex-wrap">
      {currentStep === STEPS.REQUEST_OTP && (
        <RequestOTP
          setCurrentStep={setCurrentStep}
          email={email}
          setEmail={setEmail}
          setResendCounter={setResendCounter}
          setOtp={setOtp}
        />
      )}

      {currentStep === STEPS.RESEND_OTP && (
        <ResendOTP
          email={email}
          setCurrentStep={setCurrentStep}
          resendCounter={resendCounter}
          setResendCounter={setResendCounter}
        />
      )}

      {currentStep === STEPS.CODE_VERIFICATION && (
        <CodeVerification
          email={email}
          otp={otp}
          setOtp={setOtp}
          setCurrentStep={setCurrentStep}
          resendCounter={resendCounter}
          setResendCounter={setResendCounter}
        />
      )}

      {currentStep === STEPS.NEW_CREDENTIAL && (
        <NewCredential otp={otp} setCurrentStep={setCurrentStep} />
      )}
    </div>
  );
};

export default PasswordNew;
