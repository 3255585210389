import { useEffect, useState } from "react";
import styles from "./ModalGeneric.module.css";
const ImageViewerModal = ({ setShowImageViewerModal, previewImageLink }) => {
  const [fileExtension, setFileExtension] = useState(null);
  useEffect(() => {
    const fileExtension = previewImageLink?.fileName?.split(".").pop();
    setFileExtension(fileExtension);
  }, [previewImageLink]);

  const handleDownloadClick = () => {
    const fileUrl = previewImageLink?.link;
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = previewImageLink?.link;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  return (
    <div
      // style={{
      //   background: "black",
      // }}
      className={styles.modalRoot}
    >
      <div
        style={{ width: "70vw", height: "80vh", position: "relative" }}
        className={styles.modal}
      >
        <div
          onClick={() => setShowImageViewerModal(false)}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "15px",
            right: "15px",
          }}
        >
          <img
            style={{ width: "18px" }}
            src="/images/notification/X Icon Bold.svg"
            alt="x"
          />
        </div>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {fileExtension === "jpg" ||
          fileExtension === "jpeg" ||
          fileExtension === "gif" ||
          fileExtension === "png" ? (
            <img
              style={{
                width: "75%",
                maxHeight: "90%",
                objectFit: "contain",
              }}
              src={previewImageLink?.link}
              alt="previewImage"
            />
          ) : (
            <div
              style={{
                padding: "15px 30px",
                borderRadius: "5px",
                background: "#525252",
                lineHeight: "2",
                color: "#fff",
              }}
            >
              <span>
                Für diesen Dateityp ist keine Vorschau verfügbar.
                <br />
                Doch kannst du{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "#03ff5b",
                    fontWeight: "700",
                  }}
                  onClick={handleDownloadClick}
                >
                  Herunterladen
                </span>{" "}
                die Datei.
              </span>
            </div>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            right: "20px",
          }}
          onClick={handleDownloadClick}
          className="px-10 py-2 font-semibold border-0 rounded-md cursor-pointer bg-spekterGreenLight dark:bg-spekterGreen shadow-btn text-spekterDarkOne dark:text-gray-800"
        >
          <span
            style={{
              fontSize: "16px",
            }}
          >
            Download
          </span>
        </div>
      </div>
    </div>
  );
};

export default ImageViewerModal;

{
  /* TODO: have to convert in tailwind*/
}
