import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const DwdItem = ({
  data,
  onDismissCard,
  top,
  zIndex,
  opacity,
  expandNotifications,
  setExpandNotifications,
  notificationFlag,
}) => {
  const [showFull, setShowFull] = useState(false);
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);

  useEffect(() => {
    setShowFull(false);
  }, [notificationFlag]);

  const handleDwdClick = () => {
    if (!expandNotifications) {
      setExpandNotifications(true);
    } else {
      setShowFull(!showFull);
    }
  };

  //ref: https://github.com/spekterio/cloudbreakapp/blob/c8435ac9d5ef29daf131373991ff3679604b1210/lib/screens/cap_message/cap_message.dart#LL87C36-L87C36

  return (
    <div
      onClick={handleDwdClick}
      style={{
        top: top,
        zIndex: zIndex,
        // opacity: opacity,
      }}
      className={` ${
        showFull ? "max-h-max" : "h-[90px]"
      } p-2.5  mt-2 rounded-[15px] backdrop-blur-[61.5px]
         hover:cursor-pointer mb-2 sm:ml-2 relative transition-all duration-500 notificationBg-light dark:notificationBg 
         overflow-y-hidden`}
    >
      <div className="flex justify-between w-full">
        <div className="w-[50px]">
          <img
            className="w-full"
            src="/images/DWD Notification Icon_light.svg"
            alt="dwd"
          />
        </div>
        <div
          className={`w-full px-3 text-[15px] font-semibold relative top-[2px] text-black dark:text-spekterWhite 
        ${showFull ? "" : "truncate"} `}
        >
          {data?.info?.headline}
        </div>
        <img
          className="h-3 duration-300 cursor-pointer hover:opacity-80"
          onClick={() => onDismissCard()}
          src={`/images/notification/${
            isThemeDark ? "X Icon Bold.svg" : "X Icon Bold_000000.svg"
          }`}
          alt="cancel icon"
        />
        <div
          onClick={() => setShowFull(!showFull)}
          className="absolute duration-300 text-spekterWhite hover:text-gray-300 right-2 bottom-2 hover:scale-125"
        >
          <img
            className={`w-4 ${showFull ? "rotate-180" : ""} duration-500`}
            src={`/images/notification/Opened Triangle Arrow for Infomenu_Bold_${
              isThemeDark ? "ffffff" : "000000"
            }.svg`}
            alt=""
          />
        </div>
      </div>
      <div className="pt-2.5 pl-[57px] pr-6 text-[12px] text-black dark:text-gray-200">
        <p className={`${showFull ? "" : "line-clamp-2"}  w-[260px]`}>
          {data?.info?.description}
        </p>

        <p
          className={`${
            showFull ? "" : "line-clamp-1 hidden"
          }  w-[270px] font-semibold`}
        >
          Gültig bis:{" "}
          {`${new moment(data?.info?.expires).format("DD.MM.YYYY HH:mm")}`}
        </p>
      </div>
    </div>
  );
};
export default DwdItem;
