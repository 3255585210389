/*
 * Title: Municipals input field
 * Description: Municipals input field with searchable dropdown
 * Author: Sohag
 * Date: 28/06/2021
 */

// dependencies
import Select from "react-select";

const MunicipalInputField = (props) => {
  const options = props.municipalityData;
  const dot = () => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: "green",
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#333333",
      border: 0,
      outline: 0,
      boxShadow: "none",
      height: "41px",
    }),
    option: (styles) => {
      return {
        ...styles,
        backgroundColor: "#333333",
        color: "#818181",
        cursor: "pointer",

        ":active": {
          ...styles[":active"],
          backgroundColor: "#0056A4",
          color: "red",
        },
        "&:hover": {
          ...styles,
          cursor: "pointer",
          color: "white",
          backgroundColor: "#C9C9C9",
        },
      };
    },
    menu: (styles) => ({
      ...styles,
      marginTop: 0,
    }),
    menuList: (styles) => ({
      ...styles,
      padding: 0,
      "::-webkit-scrollbar": {
        width: "9px",
      },
      "::-webkit-scrollbar-track": {
        background: "#333333",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#333333",
        borderRadius: "15px",
        border: "2px solid #818181",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#333333",
      },
    }),
    input: (styles) => ({ ...styles, color: "white" }),
    singleValue: (styles) => ({ ...styles, color: "white" }),
  };

  return (
    <div className="w-full">
      {/* leading icon */}
      {/* <FontAwesomeIcon icon={props.icon} className="relative mx-2 text-spekterWhite left-3 top-8" /> */}

      {/* main search */}
      <Select
        className="scrollbar h-[41px]"
        options={options}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => `${option}`}
        value={props.agsName}
        onChange={(value) => props.setAGS(value)}
        placeholder={
          <div className=" text-[#818181]">{props.municipalityPlaceholder}</div>
        }
        styles={colourStyles}
        onFocus={props.removeMunicipalityPlaceholder}
        onBlur={props.handleMunicipalityBlur}
        isSearchable={true}
      />

      {/* error text */}
      {props.error ? (
        <span className="text-sm text-red-600">{props.error}</span>
      ) : null}
    </div>
  );
};

export default MunicipalInputField;
