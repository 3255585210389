import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SwitchIcon from "../../../UI/Switch/Switch";
import {
  editCustomSubscriberAction,
  userAction,
} from "../../../../redux/actions";
import * as toast from "../../../UI/Toaster";
import useMobileView from "../../../../hooks/useMobileView";

const CatchmentSelect = ({ ags, alertData, isInUserSetting }) => {
  const { t, i18n } = useTranslation("userSettings");
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const isMobileView = useMobileView();

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data);
  const [catchments, setCatchments] = useState(null);

  useEffect(() => {
    if (!userData) {
      dispatch(userAction());
    }
    if (userData && catchments === null) {
      setCatchments(userData.catchments);
    }
  }, [userData, catchments, dispatch]);
  return (
    <div
      className={`flex w-full p-0 rounded-xl lg:p-6 ${
        isThemeDark
          ? "bg-spekterDarkSix text-spekterWhite"
          : "text-spekterDarkSix bg-spekterWhite"
      }`}
    >
      <div
        className={`flex ${
          alertData
            ? "flex-col items-center"
            : "flex-col lg:flex-row justify-between items-center w-full"
        } w-full gap-5`}
      >
        <div className={`max-w-[100%] ${alertData ? "" : "lg:w-[35%]"}`}>
          {/* <div className=" max-w-[100%]"> */}
          <p className="text-[21px] sm:text-[24px] font-bold">
            Alarm-Einzugsgebiete
          </p>
          <p className="font-normal text-[15px]">
            Bitte wählen Sie eines odere mehrer Einzugsgebiete aus in denen Sie
            alarmiert werden möchten. Wenn Sie KEIN Gebiet wählen, werden Sie
            NICHT alarmiert!
          </p>
        </div>
        {alertData ? (
          <div className="flex flex-col-reverse w-full lg:flex-row">
            <div className="w-full hidden lg:flex lg:w-[40%] justify-center items-center">
              <img
                className="h-[80%]"
                src={
                  isThemeDark
                    ? "/images/Catchment Selection_dark.svg"
                    : "/images/Catchment Selection_light.svg"
                }
                alt=""
              />
            </div>
            <div
              className={`flex flex-col items-center w-full lg:w-[60%] h-64 p-2
         overflow-y-auto text-black rounded-md dark:text-gray-200
          scrollbarLight dark:scrollbar bg-spekterWhite dark:bg-spekterDarkSix
          `}
            >
              {catchments &&
                catchments.map((catchment, index) => (
                  <div
                    key={index}
                    className="flex items-center w-[300px] max-w-full h-16 gap-1"
                  >
                    <div className={`w-[30%]`}>
                      <SwitchIcon
                        bgColor="bg-[#ccc]"
                        bgCheckedColor="checked:bg-spekterGreenLight checked:opacity-100"
                        value={catchment.alert}
                        onCheck={() => {
                          const newCatchments = [...catchments];
                          catchments[index].alert = !newCatchments[index].alert;
                          setCatchments(newCatchments);
                          const catchmentsWithAlert = newCatchments
                            .filter((catchment) => catchment.alert)
                            .map((catchment) => catchment.catchmentNumber);
                          const editResponse = dispatch(
                            editCustomSubscriberAction(
                              ags,
                              userData?.subscriber?._id,
                              {
                                alertCatchments: catchmentsWithAlert,
                              }
                            )
                          );
                          editResponse.then(function (result) {
                            if (
                              result.type === "EDIT_CUSTOM_SUBSCRIBER_SUCCESS"
                            ) {
                              toast.success(
                                `${catchment.name} updated successfully`
                              );
                            }

                            if (
                              result.error &&
                              result.error.message === "Network Error"
                            ) {
                              const newCatchments = [...catchments];
                              catchments[index].alert =
                                !newCatchments[index].alert;
                              setCatchments(newCatchments);
                              toast.error(
                                "Server not responding, please try later"
                              );
                            } else if (
                              result.type === "EDIT_CUSTOM_SUBSCRIBER_FAIL"
                            ) {
                              const newCatchments = [...catchments];
                              catchments[index].alert =
                                !newCatchments[index].alert;
                              setCatchments(newCatchments);
                              toast.error("Failed");
                            }
                          });
                        }}
                      />
                    </div>
                    <div className="relative bottom-[3px] font-[700] text-sm w-full md:w-[70%]">
                      {catchment.name}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div
            className={`${
              alertData
                ? "text-center"
                : `lg:w-[65%]  ${isMobileView ? "text-center" : "text-end"}`
            } text-[#FF0000] text-2xl pt-4 font-bold`}
          >
            {t("noSubscriber")}
          </div>
        )}
      </div>
    </div>
  );
};

export default CatchmentSelect;
