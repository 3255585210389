import React, { useState } from "react";
import OtpInput from "react-otp-input";

const OtpInputField = ({
  otp,
  setOtp,
  isOtpInvalid,
  setIsOtpInvalid,
  isThemeDark,
  isPublic,
}) => {
  const handleChange = (inputOtp) => {
    if (inputOtp.length < 6) {
      setIsOtpInvalid(true);
    } else {
      setIsOtpInvalid(false);
    }
    setOtp(inputOtp);
  };

  return (
    <div>
      <OtpInput
        containerStyle="mb-2 mt-4 flex justify-center scale-90 sm:scale-100"
        inputStyle={`mr-2 rounded otpBoxStyle ${
          otp && isOtpInvalid ? "border-2 border-red-600" : ""
        }
          ${
            isPublic || isThemeDark
              ? "bg-[#272727]"
              : "bg-gray-200 otpInputColorLight"
          }`}
        numInputs={6}
        isInputNum={true}
        value={otp}
        onChange={handleChange}
        hasErrored={otp && isOtpInvalid}
      />
    </div>
  );
};

export default OtpInputField;
