import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Spinner from "../../UI/Spinner/Spinner";
import { utcToGermanTime } from "../../../shared/Utility";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

import {
  XAxis,
  YAxis,
  ReferenceLine,
  Tooltip,
  AreaChart,
  Area,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const SmartRiverChart = ({
  rawData,
  optionalData,
  sensorName,
  duration,
  setTimeRange,
}) => {
  //language package
  const { t } = useTranslation("sensors");

  const [currentValue, setCurrentValue] = useState("");
  const [ticks, setTicks] = useState([]);
  const [chartData, setChartData] = useState([]);
  const isChartLoading = useSelector((state) => state.chart.isLoading);

  const thresholds = optionalData.thresholds || [];
  const isMaintenanceMode = optionalData?.maintenance;

  useEffect(() => {
    // Get 6 | 12 date (as a ticks) dynamically from x-axis data
    // always picks the first data and last data from the data array

    let ticks = [];
    let tickCount = 5;

    if (duration > 1440) {
      tickCount = 12;
    }

    if (chartData.length > 0) {
      let firstData = chartData[0].date;
      let lastData = chartData[chartData.length - 1].date;
      ticks.push(firstData);
      let interval = Math.round((chartData.length - 1) / tickCount);
      for (let i = 1; i < tickCount; i++) {
        let index = interval * i;
        let date = chartData[index]?.date;
        ticks.push(date);
      }
      ticks.push(lastData);
    }
    // console.log("chartData" , chartData);
    setTicks(ticks);
  }, [duration, chartData]);

  // FIXME: This is the old smoothing, which is not working properly
  // useEffect(() => {
  //   if (rawData) {
  //     let data = [];
  //     // check if all values are zero then set the chartData value to zero
  //     const isAllValuesZero = rawData.every((item) => item._value === 0);
  //     if (isAllValuesZero) {
  //       data = rawData.map((item, index) => {
  //         return {
  //           date: item._time,
  //           value: 0,
  //         };
  //       });
  //     } else {
  //       let beginningValue = 0;
  //       for (let i = 0; i < rawData.length; i++) {
  //         if (rawData[i]._value < optionalData?.soleCorrectionValue) {
  //           beginningValue =
  //             optionalData?.soleCorrectionValue - rawData[i]._value;
  //           break;
  //         }
  //       }

  //       for (let i = 0; i < rawData.length; i++) {
  //         const item = rawData[i];
  //         let value = optionalData?.soleCorrectionValue - item?._value;
  //         if (value < 1) {
  //           if (i === 0) {
  //             value = beginningValue;
  //           } else {
  //             value = data[data.length - 1]._value;
  //           }
  //         }
  //         data.push({
  //           date: item._time,
  //           value,
  //         });
  //       }
  //     }

  //     setCurrentValue(data[data.length - 1]?.value?.toString().split(".")[0]);
  //     setChartData(data);
  //   }
  // }, [rawData, optionalData]);

  useEffect(() => {
    if (rawData) {
      let data = [];
      // check if all values are zero then set the chartData value to zero
      const isAllValuesZero = rawData.every((item) => item._value === 0);
      if (isAllValuesZero) {
        data = rawData.map((item, index) => {
          return {
            date: item._time,
            value: 0,
          };
        });
      } else {
        data = rawData.map((item, index) => {
          const value = optionalData?.soleCorrectionValue - item?._value;
          return {
            date: item._time,
            value: value > 0 ? value : 0,
          };
        });
      }
      setCurrentValue(data[data.length - 1]?.value.toString().split(".")[0]);
      setChartData(data);
    }
  }, [rawData, optionalData]);

  const timeframeContent = (
    <div className="flex gap-2 bg-[#343A40] p-1 rounded-5">
      <div
        onClick={() => setTimeRange(1440)}
        className={`h-[22px] w-[71px] flex justify-center items-center duration-300
    rounded-5 font-[700] text-[11px] cursor-pointer hover:opacity-75
  ${duration === 1440 && "bg-spekterDarkOne"}`}
      >
        {t("oneDaySelection")}
      </div>
      <div
        onClick={() => setTimeRange(1440 * 3)}
        className={`h-[22px] w-[71px] flex justify-center items-center duration-300
    rounded-5 font-[700] text-[11px] cursor-pointer hover:opacity-75
  ${duration === 4320 && "bg-spekterDarkOne"}`}
      >
        {t("threeDaySelection")}
      </div>
      <div
        onClick={() => setTimeRange(1440 * 7)}
        className={`h-[22px] w-[71px] flex justify-center items-center duration-300
    rounded-5 font-[700] text-[11px] cursor-pointer hover:opacity-75
  ${duration === 10080 && "bg-spekterDarkOne"}`}
      >
        {t("sevenDaySelection")}
      </div>
    </div>
  );

  if (isChartLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner />
      </div>
    );
  }

  const WarningLabelOne = (props) => {
    return (
      <text
        x={props.viewBox.width - 170}
        y={props.viewBox.y - 30}
        fill="#02d39a"
        dy={20}
        dx={30}
      >
        {t("warningStageOne") + ` (${optionalData?.thresholds[0].level} cm)`}
      </text>
    );
  };

  const WarningLabelTwo = (props) => {
    return (
      <text
        x={props.viewBox.width - 170}
        y={props.viewBox.y - 30}
        fill="#02d39a"
        dy={20}
        dx={30}
      >
        {t("warningStageTwo") + ` (${optionalData?.thresholds[1].level} cm)`}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="flex flex-col text-white bg-[#333333] p-4 text-sm rounded">
          <p>{`${utcToGermanTime(label, "DD.MM HH:mm")}`}</p>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 bg-[#02d39a]"></div>
            <span className="opacity-70">{`Pegelhöhe : ${payload[0].value} cm`}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  let chart = (
    <ResponsiveContainer width="100%" height={250}>
      <AreaChart
        data={chartData}
        margin={{ top: 20, right: 20, left: -20, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorSmartRiver" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#02d39a" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#02d39a" stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          tickFormatter={(dateTime) => utcToGermanTime(dateTime, "HH:mm")}
          ticks={ticks}
          fontSize={12}
          tick={{ fill: "#fff" }}
        />
        <YAxis
          type="number"
          domain={[0, thresholds.length !== 0 ? thresholds[1].level * 1.1 : 0]}
          tickFormatter={(value) => value.toFixed(0)}
          fontSize={12}
          tickCount={0}
          tick={{ fill: "#fff" }}
        />
        <CartesianGrid
          strokeDasharray="0 0"
          stroke="#fff"
          strokeWidth={0.4}
          opacity={0.4}
        />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="basis"
          dataKey="value"
          stroke="#02d39a"
          strokeWidth={2}
          strokeLinejoin="round"
          fillOpacity={1}
          fill="url(#colorSmartRiver)"
        />
        <ReferenceLine
          y={thresholds.length === 2 && thresholds[0].level}
          label={WarningLabelOne}
          stroke="#02d39a"
          strokeWidth={2}
        />
        <ReferenceLine
          y={thresholds.length === 2 && thresholds[1].level}
          label={WarningLabelTwo}
          stroke="#02d39a"
          strokeWidth={2}
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  return (
    <div className="w-full h-full">
      <div className="flex flex-col items-center sm:items-start sm:flex-row sm:justify-between text-spekterWhite">
        <div className="flex items-start justify-center my-0 mx-2.5 gap-2">
          <img
            src={`/images/${
              isMaintenanceMode || chartData.length < 1
                ? "wartung_icon.svg"
                : thresholds.length === 2 && thresholds[0].active
                ? "Pegel_aktiv.svg"
                : "Pegel_inaktiv.svg"
            }`}
            alt="gfl icon"
            className="h-8 m-0"
          />
          <h5 className="mt-1">{sensorName}</h5>
        </div>
        {!isMaintenanceMode && chartData.length > 1 && (
          <section className="flex flex-col items-end ">
            <div className="flex flex-row items-center">
              <span>{t("floodLevelMeasurment")}:</span>
              <div className="flex flex-col items-center justify-center mx-2 my-1">
                <h5 className="m-0">
                  {isMaintenanceMode ? "--" : `${currentValue}`}
                </h5>
                <span className="m-0 text-[10px] font-semibold">CM</span>
              </div>
              <img
                src="/images/chart_modal/icons8-futures-240.svg"
                alt="gfl icon"
                className="h-6"
              />
            </div>
            <div className="mt-3"> {timeframeContent}</div>
          </section>
        )}
      </div>
      <div>
        {isMaintenanceMode || chartData.length < 1 ? (
          <div className="text-spekterWhite flex justify-center pt-[80px] gap-2 h-full p-4 mt-[30px]">
            <img
              className="w-8 h-8"
              src="/images/sensor_manager/icons8-bach-96.png"
              alt="maintenance icon"
            />
            <p className="relative top-[2px] text-[#9CA3AF] font-bold text-lg">
              {t("maintainanceFouling")}
            </p>
          </div>
        ) : (
          chart
        )}
      </div>
    </div>
  );
};

export default SmartRiverChart;
