import React, { useState } from "react";
import { toast } from "sonner";
import * as actions from "../../../../redux/actions";
import Axios from "../../../../services/NetworkService";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "./ConfirmationDialog";
import usePodAgs from "../../../../hooks/usePodAgs";
import { useLocation, useNavigate } from "react-router";
import { getFileExtension } from "../../../../shared/Utility";
import ImageViewerModal from "../../../../components/UI/ImageViewer/ImageViewerModal";
import { useTranslation } from "react-i18next";
import PodWebviewer from "./PodWebviewer";

const DESCRIPTION_TEXT =
  "Bei Auslösung des o.g. Alarm- und Einsatzplans erledigen die o.g. Einheiten/Organisationen folgende Aufträge:";

function PodViewModal({
  setShow,
  selectedPod,
  setSelectedPod,
  setShowPodsModal,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("realtimeMap"); // i18n namespace for translation
  const { pathname } = useLocation();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showImageViewerModal, setShowImageViewerModal] = useState(false);
  const [previewImageLink, setPreviewImageLink] = useState();
  const activeModules = useSelector(
    (state) => state.customer?.data?.activeModules
  );

  const podAgs = usePodAgs();

  const deletePod = async (id) => {
    try {
      const response = await Axios.delete(`/pods/remove/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const deletePodHandler = (id) => {
    toast.promise(deletePod(id), {
      loading: `${t("loading")}`,
      success: (data) => {
        dispatch(actions.pods(podAgs));
        setShowPodsModal(false);
        setSelectedPod(null);
        setShow(false);
        setSelectedPod(null);
        return `${t("PODDeletedSuccessfully")}`;
      },
      error: `${t("somethingWentWrong")}`,
    });
  };

  return (
    <div className="fixed top-[145px] md:top-auto right-0 z-10 flex flex-col items-center justify-center w-full h-[calc(100vh_-_320px)]">
      {showDeleteConfirmModal && (
        <ConfirmationDialog
          setShowDeleteConfirmModal={setShowDeleteConfirmModal}
          onDelete={() => deletePodHandler(selectedPod._id)}
          selectedPod={selectedPod}
        />
      )}
      <section
        className="relative flex flex-col gap-5 p-[18px] md:p-[36px] headerShadowLight text-black max-h-[80vh] md:max-h-[90vh]
          w-[900px] max-w-[95%] md:max-w-[70%] bg-white"
      >
        <div className="flex justify-end w-full">
          <img
            onClick={() => {
              setShow(false);
              setSelectedPod(null);
            }}
            src={`/images/pod/X Icon Bold_${"000000"}.svg`}
            alt="pod icon"
            className="self-start w-3 duration-300 cursor-pointer hover:opacity-70"
          />
        </div>
        <form className="flex flex-col w-full gap-8 h-[calc(100%_-_36px)]">
          <div className="h-full overflow-y-auto scrollbarLight">
            <div className="flex flex-col items-start justify-center gap-3">
              <div className="text-[18px] font-bold border-[3px] border-[#000] w-full p-3">
                {selectedPod?.aepName}
              </div>
              <div className="text-[18px] break-words font-bold border-[3px] border-[#000] w-full p-3">
                {selectedPod?.type}
              </div>
              <div className="min-h-[100px] border-[3px] border-[#000] w-full p-3">
                <p className="text-[18px] font-normal">
                  <span className="font-bold">
                    {t("commandAndAssemblyPoint")}
                  </span>{" "}
                  <br />
                  {selectedPod?.commandAssemblyPoint} <br />
                  {selectedPod?.address?.street +
                    " " +
                    selectedPod?.address?.streetNr}
                  <br />
                  {selectedPod?.address?.zipCode +
                    " " +
                    selectedPod?.address?.municipality}
                  <br />
                  {selectedPod?.contact?.phoneNumber
                    ? `${t("telNr")} ${selectedPod?.contact?.phoneNumber}`
                    : null}
                  <br />
                  {selectedPod?.contact?.faxNumber
                    ? `${t("faxNr")} ${selectedPod?.contact?.faxNumber}`
                    : null}
                </p>
              </div>
              <div className="min-h-[100px] border-[3px] border-[#000] w-full p-3 flex flex-col gap-2 sm:flex-row">
                <div className="w-full sm:w-1/2">
                  <p className="text-[18px] break-words font-bold">
                    {`${t("orderSheet")}:`}{" "}
                    <span className="font-normal">
                      {selectedPod?.orderSheet || ""}
                    </span>
                  </p>
                </div>
                <div className="w-full sm:w-1/2">
                  <p className="text-[18px] break-words font-bold">
                    {`${t("for")}:`}{" "}
                    <span className="font-normal">
                      {selectedPod?.category === "administration"
                        ? t("administrationalService")
                        : selectedPod?.category === "firefighters"
                        ? t("firefighers")
                        : selectedPod?.category === "police"
                        ? t("police")
                        : selectedPod?.category === "thw"
                        ? t("thw")
                        : ""}
                    </span>
                  </p>
                </div>
              </div>
              <div className="min-h-[200px] border-[3px] border-[#000] w-full p-3">
                <p className="text-[18px] font-bold">
                  {selectedPod?.descriptionText ? DESCRIPTION_TEXT : ""}
                </p>
                <p className="text-[18px] break-words font-normal">
                  {selectedPod?.descriptionText}
                </p>
              </div>
              <div className="min-h-[100px] border-[3px] border-[#000] w-full p-3">
                <p className="text-[18px] font-bold">{t("imagesDocuments")}:</p>
                {selectedPod && selectedPod?.files?.length <= 0 && (
                  <p className="font-normal opacity-50">
                    Dateien: Keine Dateien vorhanden
                  </p>
                )}
                {selectedPod && selectedPod?.files?.length !== 0 && (
                  <div className="w-full">
                    <div className="w-full">
                      <div className="flex flex-wrap gap-3">
                        {selectedPod &&
                          selectedPod?.files?.map((file, index) => (
                            <div
                              key={index}
                              className="relative w-20 h-20 p-1 mb-5 border-2 cursor-pointer rounded-10"
                            >
                              <img
                                onClick={() => {
                                  setPreviewImageLink({
                                    link: file?.fileUrl || "",
                                    fileName: file?.fileName || "",
                                  });
                                  setShowImageViewerModal(true);
                                }}
                                className="w-[100%] max-h-[100%]"
                                src={
                                  getFileExtension(file.fileName) === "pdf"
                                    ? "/images/file-dark.svg"
                                    : file.fileUrl
                                }
                                // src="/images/file-dark.svg"
                                alt="file"
                              />
                              <div className="absolute font-normal truncate w-20 text-[12px] text-center -left-1 -bottom-5">
                                {file?.showName}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {pathname !== "/webviewer" &&
                activeModules &&
                activeModules["webViewer"] && (
                  <>
                    <div className="min-h-[600px] border-[3px] border-[#000] w-full p-3">
                      <PodWebviewer ags={podAgs} selectedPod={selectedPod} />
                    </div>

                    <button
                      onClick={() => navigate("/webviewer?ags=" + podAgs)}
                      className="px-[23px] py-[6px] font-semibold text-[#000000] rounded-[5px] bg-[#46BCEC]"
                    >
                      Webviewer
                    </button>
                  </>
                )}
            </div>
          </div>
          {pathname === "/webviewer" && (
            <div
              className={`flex flex-row items-center justify-end gap-5 w-full`}
            >
              {selectedPod && (
                <button
                  type="button"
                  onClick={() => {
                    setShowDeleteConfirmModal(true);
                  }}
                  className="my-1 text-red-500 text-[15px] font-bold disabled:bg-gray-500"
                >
                  {t("deleteThisPOD")}
                </button>
              )}
              <button
                onClick={() => {
                  setShowPodsModal(true);
                  setShow(false);
                }}
                className=" disabled:bg-gray-500 px-[23px] py-[6px] self-center sm:self-end font-semibold
               text-[#fff] rounded-[6px] bg-[#04CF00]"
              >
                {t("edit")}
              </button>
            </div>
          )}
        </form>
        {/* <DevTool control={control} /> */}
      </section>
      {showImageViewerModal && previewImageLink && (
        <ImageViewerModal
          previewImageLink={previewImageLink}
          setShowImageViewerModal={setShowImageViewerModal}
        />
      )}
    </div>
  );
}

export default PodViewModal;
