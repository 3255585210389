import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const dwdPending = () => {
    return {
        type: actionTypes.DWD_PENDING,
    };
};

export const dwdSuccess = (data) => {
    return {
        type: actionTypes.DWD_SUCCESS,
        data: data,
    };
};

export const dwdFail = (error) => {
    return {
        type: actionTypes.DWD_FAIL,
        error: error,
    };
};

export const dwdAction = () => {
    let urlEndPoint = "/dwdcap";

    return async (dispatch) => {
        dispatch(dwdPending());
        Axios.get(urlEndPoint, {
            headers: {
                // Authorization: token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                dispatch(dwdSuccess(response.data.data));
            })
            .catch((error) => {
                dispatch(dwdFail(error));
            });
    };
};
