import React from "react";

const backdrop = ({ show, opacity, clicked }) =>
  show && (
    <div
      className={`h-screen w-screen fixed left-0 top-0 z-100 bg-spekterDarkOne ${
        opacity ? opacity : "bg-opacity-50"
      }`}
      onClick={clicked}
    ></div>
  );

export default backdrop;
