/*
 * Title: Address input field
 * Description: Address input field with google place api
 * Author: Sohag
 * Date: 28/06/2021
 */

// denpendencies
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

const AddressInputField = (props) => {
  const handleSelect = async (value) => {
    props.setAddress(value);
  };

  return (
    <div>
      <PlacesAutocomplete
        value={props.address}
        onChange={props.setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="relative">
            <div className="relative">
              <input
                {...getInputProps({
                  type: "text",
                })}
                className="w-full h-[41px] pl-6 rounded-lg text-spekterWhite bg-[#333333] focus:outline-none"
                onFocus={props.removeAdressePlaceholder}
                onBlur={props.handleAdresseBlur}
                placeholder={props.adressePlaceholder}
              />
            </div>
            {suggestions && suggestions.length > 0 && (
              <div
                className="absolute flex px-1 flex-col 
              justify-end overflow-y-auto w-[100%] scrollbar mx-auto"
              >
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                  const style = {
                    backgroundColor: suggestion.active ? "#41b6e6" : "#2d2d2d",
                    color: "white",
                    cursor: "pointer",
                  };
                  return (
                    <div
                      className={`p-2`}
                      key={index}
                      {...getSuggestionItemProps(suggestion, { style })}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          // <div>
          //   {/* leading icon */}
          //   {/* <FontAwesomeIcon icon={props.icon} className="relative text-spekterWhite top-8 left-5" /> */}

          //   {/* input */}
          //   <input {...getInputProps({ placeholder: "Adresse", type: "text" })} className="w-full h-12 pl-6 mr-3 placeholder-white border-0 rounded bg-inputBoxLightBG bb text-spekterWhite focus:outline-none" />
          //   {props.error ? <span className="text-sm text-red-600">{props.error}</span> : null}

          //   <div>

          //     {/* loading text */}
          //     {loading ? <div className="flex justify-center w-11/12 m-auto text-spekterWhite">Loading...</div> : null}

          //     {/* place suggestion container */}
          //     {suggestions.map(suggestion => {
          //       const style = {
          //         backgroundColor: suggestion.active ? "#41b6e6" : "#2d2d2d",
          //         color: 'white',
          //       };
          //       return (
          //         <div
          //           className="p-2 text-center rounded-sm bg-inputBoxLightBG "
          //           {...getSuggestionItemProps(suggestion, { style })}>
          //           {suggestion.description}
          //         </div>
          //       );
          //     })}
          //   </div>
          // </div>
        )}
      </PlacesAutocomplete>

      {/* error text */}
      {props.error ? (
        <span className="text-sm text-red-600">{props.error}</span>
      ) : null}
    </div>
  );
};

export default AddressInputField;
