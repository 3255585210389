import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  userAction,
  updateSubsciberAction,
  deleteAccouont,
} from "../../../redux/actions/index";
import * as actionTypes from "../../../redux/actions/actionTypes";
import Spinner from "../../UI/Spinner/Spinner";
import ProfileNotFound from "../../Common/ProfileNotFound";
import { useTranslation } from "react-i18next";
import * as toast from "../../UI/Toaster";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import ConfirmDeleteModal from "./Modal/ConfirmDeleteModal";
import validator from "validator";
import { customErrorMessages } from "../../../shared/locales/CustomErrorMessages";

const Account = () => {
  //language package
  const { t, i18n } = useTranslation("userSettings");

  const [subscriberId, setSubscriberId] = useState("");
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [voiceCall, setVoiceCall] = useState("");
  const [address, setAddress] = useState("");
  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
  const [isVoiceCallValid, setIsVoiceCallValid] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const userData = useSelector((state) => state.user.data);
  const userDataLoading = useSelector((state) => state.user.isLoading);
  const isDeleteLoading = useSelector((state) => state.deleteAccount.isLoading);

  let navigate = useNavigate();

  // const errorToast = (notifyText) =>
  //   toast.error(<div>{notifyText}</div>, {
  //     position: "top-center",
  //     style: { background: "#FF0000" },
  //     autoClose: 3000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: 0,
  //   });

  // const successToast = (notifyText) =>
  //   toast.success(<div>{notifyText}</div>, {
  //     position: "top-center",
  //     style: { background: "#07bc0c" },
  //     autoClose: 3000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: 0,
  //   });

  // TODO: add optional chaining here
  useEffect(() => {
    if (userData) {
      setName(userData?.subscriber?.name);
      setMobileNumber(userData?.subscriber?.mobile);
      setAddress(userData?.subscriber?.address);
      setVoiceCall(userData?.subscriber?.voicecall);
      setEmail(userData?.emails[0].address);
      setSubscriberId(userData?.subscriber?._id);
    }
  }, [userData]);

  useEffect(() => {
    dispatch(userAction());
  }, [dispatch]);

  const nameChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsNameValid(true);
    }
    setName(event.target.value);
  };

  const addressChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsAddressValid(true);
    }
    setAddress(event.target.value);
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();

    if (
      name &&
      name.trim().length === 0 &&
      mobileNumber &&
      mobileNumber.trim().length === 0 &&
      email &&
      email.trim().length === 0 &&
      address &&
      address.trim().length === 0 &&
      voiceCall &&
      voiceCall.trim().length === 0
    ) {
      setIsNameValid(false);
      setIsMobileNumberValid(false);
      setIsEmailValid(false);
      setIsAddressValid(false);
      setIsVoiceCallValid(false);
      return;
    }

    if (name && name.trim().length === 0) {
      setIsNameValid(false);
      return;
    }

    if (
      mobileNumber &&
      mobileNumber.length > 0 &&
      isValidPhoneNumber(mobileNumber)
    ) {
      setIsMobileNumberValid(true);
    } else {
      toast.error(t("enterPhoneNumber"));
      setIsMobileNumberValid(false);
      return;
    }

    if (email && email.trim().length === 0) {
      setIsEmailValid(false);
      return;
    } else if (validator.isEmail(email) === false) {
      toast.error("Please enter a valid email");
      setIsEmailValid(false);
      return;
    }

    if (address && address.trim().length === 0) {
      setIsAddressValid(false);
      return;
    }

    if (voiceCall && voiceCall.length > 0 && isValidPhoneNumber(voiceCall)) {
      setIsVoiceCallValid(true);
    } else {
      toast.error(t("enterCellNumber"));
      setIsVoiceCallValid(false);
      return;
    }

    const arg = {
      name,
      email,
      mobile: mobileNumber,
      address,
      voicecall: voiceCall,
    };

    dispatch(updateSubsciberAction(subscriberId, arg)).then((result) => {
      if (result.type === "SUBSCRIBER_SUCCESS") {
        // toast.success(result?.data?.message);
        toast.success(customErrorMessages[result?.data?.message] ?? result?.data?.message);
      } else if (result.type === "SUBSCRIBER_FAIL") {
        toast.error(t("failedUpdateInformation"));
      }
    });
  };

  const confirmDeleteAccount = () => {
    dispatch(deleteAccouont(userData._id)).then((result) => {
      if (result.type === "DELETE_ACCOUNT_SUCCESS") {
        toast.success(t("accountDeletedSuccessfully"));
        localStorage.clear();
        dispatch({ type: actionTypes.RESET_APP });
        navigate("/login", { replace: true });
      } else if (result.type === "DELETE_ACCOUNT_FAIL") {
        toast.error(t("failedToDelete"));
      }
    });
  };

  const deleteFormSubmitHandler = () => {
    setShowDeleteModal(true);
  };

  return (
    <div div className="relative px-6 sm:px-0 text-spekterDarkOne">
      <div>
        {userData !== null || userDataLoading ? (
          <div>
            <div
              className={`${
                isThemeDark
                  ? "bg-spekterDarkSix text-spekterWhite"
                  : "text-gray-700 bg-spekterWhite containerShadowLight"
              } flex flex-col gap-6 sm:gap-[69px] mb-4 sm:flex-row justify-between max-w-[100%] w-[930px]
              p-[39px] sm:p-[47px] rounded-[18px] duration-75`}
            >
              {userDataLoading ? (
                <div className="flex items-center justify-center w-full h-40">
                  <Spinner />
                </div>
              ) : (
                <>
                  <div className="sm:w-[40%]">
                    <div className="font-[700] text-[21px] sm:text-[24px]">
                      {t("personalInformation")}
                    </div>
                    <div className="py-[15px] text-[15px]">
                      {t("description")}
                    </div>
                  </div>
                  <div className="sm:w-[60%] text-[15px] font-normal">
                    <div className="py-2.5">
                      <div className="w-20 font-bold text-gray-600 dark:text-spekterWhite">
                        {t("name")}
                      </div>
                      <input
                        className={`bg-[#C9C9C9] dark:bg-[#333] rounded-[6px] text-[#333] dark:text-[#818181] py-[9px] w-full px-3 focus:outline-none 
                    ${!isNameValid ? "border-2 border-spekterRed" : ``}`}
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={nameChangeHandler}
                      />
                    </div>
                    <div className="py-2.5">
                      <div className="w-20 font-bold text-gray-600 dark:text-spekterWhite">
                        {t("mobile")}
                      </div>
                      <div
                        className={`bg-[#C9C9C9] dark:bg-[#333] rounded-[6px] text-[#333] dark:text-[#818181] py-[9px] w-full px-3 focus:outline-none 
                  ${!isMobileNumberValid ? "border-2 border-spekterRed" : ``}`}
                      >
                        <PhoneInput
                          className={`pt-1 ${
                            isThemeDark ? "phoneInputDark" : "phoneInput"
                          }`}
                          placeholder="Mobile Number"
                          onChange={setMobileNumber}
                          value={mobileNumber}
                          defaultCountry="DE"
                          international
                          countryCallingCodeEditable={false}
                          countries={["DE", "AT", "CH", "FR", "IT", "LU"]}
                        />
                      </div>
                    </div>
                    <div className="py-2.5">
                      <div className="w-20 font-bold text-gray-600 dark:text-spekterWhite">
                        {t("mail")}
                      </div>
                      <input
                        className={`bg-[#C9C9C9] dark:bg-[#333] rounded-[6px] text-[#333] dark:text-[#818181] py-[9px] w-full px-3 focus:outline-none 
                    ${!isEmailValid ? "border-2 border-spekterRed" : ``}`}
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="py-2.5">
                      <div className="w-20 font-bold text-gray-600 dark:text-spekterWhite">
                        {t("voiceCall")}
                      </div>
                      <div
                        className={`bg-[#C9C9C9] dark:bg-[#333] rounded-[6px] text-[#333] dark:text-[#818181] py-[9px] w-full px-3 focus:outline-none 
                    ${!isVoiceCallValid ? "border-2 border-spekterRed" : ``}`}
                      >
                        <PhoneInput
                          className={`pt-1 ${
                            isThemeDark ? "phoneInputDark" : "phoneInput"
                          }`}
                          placeholder="Voice Call"
                          onChange={setVoiceCall}
                          value={voiceCall}
                          defaultCountry="DE"
                          international
                          countryCallingCodeEditable={false}
                          countries={["DE", "AT", "CH", "FR", "IT", "LU"]}
                        />
                      </div>
                    </div>
                    <div className="py-2.5">
                      <div className="w-20 font-bold dark:text-spekterWhite">
                        {t("adress")}
                      </div>
                      <input
                        className={`bg-[#C9C9C9] dark:bg-[#333] rounded-[6px] text-[#333] dark:text-[#818181] py-[9px] w-full px-3 focus:outline-none 
                    ${!isAddressValid ? "border-2 border-spekterRed" : ``}`}
                        type="text"
                        placeholder="Address"
                        value={address}
                        onChange={addressChangeHandler}
                      />
                    </div>
                    <div className="flex justify-end">
                      <input
                        type="button"
                        value="Save"
                        className="bg-spekterGreenSuccess rounded-[6px] py-[15px] px-[36px] font-bold dark:text-spekterDarkSix text-spekterWhite mt-2 mb-2 cursor-pointer"
                        onClick={formSubmitHandler}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <div
              className={`${
                isThemeDark
                  ? "bg-spekterDarkSix text-spekterWhite"
                  : "text-gray-700 bg-spekterWhite containerShadowLight"
              } flex flex-col gap-6 sm:gap-[69px] mb-4 sm:flex-row justify-between max-w-[100%] w-[930px]
              p-[39px] sm:p-[47px] rounded-[18px] mt-3 duration-75`}
            >
              {userDataLoading ? (
                <div className="flex items-center justify-center w-full h-40">
                  <Spinner />
                </div>
              ) : (
                <>
                  <div className="sm:w-[40%]">
                    <div className="text-[21px] sm:text-[24px] font-bold">
                      Konto löschen
                    </div>
                    <div className="py-[15px] text-[15px] text-spekterRedOld">
                      {t("deletionMsg")}
                    </div>
                  </div>
                  <div className="sm:w-[60%] text-[15px] font-normal">
                    <div className="py-2.5">
                      <div className="w-20 font-bold text-gray-600 dark:text-spekterWhite">
                        {t("mail")}
                      </div>
                      <input
                        className={`bg-[#C9C9C9] dark:bg-[#333] rounded-[6px] text-[#333] dark:text-[#818181] py-[9px] w-full px-3 focus:outline-none 
                  `}
                        type="text"
                        placeholder="E-Mail"
                        value={email}
                        disabled
                      />
                    </div>
                    <div className="py-2.5">
                      <div className="w-20 font-bold text-gray-600 dark:text-spekterWhite">
                        {t("name")}
                      </div>
                      <input
                        className={`bg-[#C9C9C9] dark:bg-[#333] rounded-[6px] text-[#333] dark:text-[#818181] py-[9px] w-full px-3 focus:outline-none`}
                        type="text"
                        placeholder="Name"
                        value={name}
                        disabled
                      />
                    </div>
                    <div className="flex justify-end">
                      <input
                        type="button"
                        value={t("deleteAccount")}
                        className="bg-spekterRedOld rounded-[6px] py-[15px] px-[36px] font-bold dark:text-spekterDarkSix text-spekterWhite mt-2 mb-2 cursor-pointer"
                        onClick={deleteFormSubmitHandler}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <ProfileNotFound />
        )}
      </div>
      {showDeleteModal && (
        <ConfirmDeleteModal
          setShowDeleteModal={setShowDeleteModal}
          confirmClicked={confirmDeleteAccount}
          isDeleteLoading={isDeleteLoading}
        />
      )}
    </div>
  );
};

export default Account;
