import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import * as actions from "../../../redux/actions";
import mapDarkFeature from "../../../constants/mapDarkFeature.json";
import mapLightFeature from "../../../constants/mapLightFeature.json";
import ChartModal from "../../../components/ChartModal/ChartModal";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../Controls/Controls";
import AlarmInfoSideBar from "../CatchmentAlarmInfo/Sidebar";
import ReactTooltip from "react-tooltip";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { sensorTypes } from "../../../constants/sensorTypes";
import { useClickAway } from "@uidotdev/usehooks";
import { useSearchParams } from "react-router-dom";
import { calculateNewLatLng } from "../../../shared/Utility";
import useMobileView from "../../../hooks/useMobileView";
import useRole from "../../../hooks/useRole";
import useAgs from "../../../hooks/useAgs";
import { useSetState } from "react-use";
import Joyride, { STATUS } from "react-joyride";
import { mapWizardSteps } from "../mapWizardSteps";
import {
  wizardCustomStylesDark,
  wizardCustomStylesLight,
} from "../wizardCustomStyle";
import styles from "./NormalView.module.css";
import NormalOverview from "./NormalOverview";

// init. colors
const catchmentColors = {
  level1: "#24D160",
  level3: "#F3EA03",
  level4: "#FD1001",
  level5: "#8A03F3",
  inactive: "#A9A9A9",
};

const edgeColors = {
  level1: "#51FC42",
  level3: "#B9FC00",
  level4: "#FF0000",
  level5: "#8200FF",
  inactive: "#A9A9A9",
};
// states
let polygones = [];
let markers = [];

const NormalView = (props) => {
  const isMobileView = useMobileView();
  let [searchParams, setSearchParams] = useSearchParams();
  const [chartModalOpen, setChartModalOpen] = useState(false);
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const [isAlarmInfoOpen, setIsAlarmInfoOpen] = useState(false);
  const [alarmData, setAlarmData] = useState(undefined);
  const [addressCoordinate, setAddressCoordinate] = useState();
  const [existingMarker, setExistingMarker] = useState();

  const [map, setMap] = useState();
  const [maps, setMaps] = useState();

  const [latlng, setLatLng] = useState({});
  const [zoom, setZoom] = useState();

  // catchment
  const [onHoverPolygonName, setOnHoverPolygonName] = useState("");
  const [timeRange, setTimeRange] = useState(1440);
  const [sensorAttr, setSensorAttr] = useState(null);
  const dispatch = useDispatch();
  // const { sensorIdFromNotification } = useParams();

  const sensorIdFromNotification = searchParams.get("_id");
  const notificationType = searchParams.get("notificationType");
  const catchmentLat = searchParams.get("catchmentLat");
  const catchmentLng = searchParams.get("catchmentLng");

  const agsList = useAgs();
  const userRole = useRole();
  const customerData = useSelector((state) => state.customer.data);
  const catchmentData = useSelector((state) => state.catchment.data);
  const isLoading = useSelector((state) => state.catchment.isLoading);
  const hdcData = useSelector((state) => state.hdc.data);
  const gflData = useSelector((state) => state.gfl.data);
  const smartriverData = useSelector((state) => state.smartriver.data);
  const smartsewerData = useSelector((state) => state.smartsewer.data);

  const [showResetButton, setShowResetButton] = useState(false);

  const [showOnBoardWizard, setShowOnBoardWizard] = useState(false);

  const [{ run, steps }, setState] = useSetState({
    run: true,
    steps: mapWizardSteps,
  });

  // console.log(catchmentLat, catchmentLng);

  // TODO: this will be dynamic
  // const timeframe = "1440";

  const markerClicked = (marker, id, name, sensorType, location) => {
    marker.addListener("click", () => {
      let optionalData = {};
      // write thresholds logic

      // lookup smartRiver data
      if (sensorType === sensorTypes.SMARTRIVER) {
        smartriverData.forEach((data) => {
          if (data["_id"] === id) {
            optionalData.soleCorrectionValue = data["soleCorrectionValue"];
            optionalData.thresholds = data["thresholds"];
            optionalData.maintenance = data["maintenance"];
          }
        });
      }
      // lookup gfl data
      else if (sensorType === sensorTypes.GFL) {
        gflData.forEach((data) => {
          if (data["_id"] === id) {
            optionalData.thresholds = data["thresholds"];
            optionalData.maintenance = data["maintenance"];
          }
        });
      }

      // lookup smart-sewer data
      if (sensorType === sensorTypes.SMARTSEWER) {
        smartsewerData.forEach((data) => {
          if (data["_id"] === id) {
            optionalData.thresholds = data["thresholds"];
            optionalData.maintenance = data["maintenance"];
            optionalData.environment = data["environment"];
          }
        });
      }
      // TODO:here timeframe will be dynamic
      // dispatch(
      //   actions.chartAction(sensorType, id, timeRange, name, optionalData)
      // );
      setSensorAttr({ sensorType, id, name, optionalData });
      setChartModalOpen(true);
      // TODO: set setTimeRange to 1440
      setTimeRange(1440);
    });
  };

  const onGoogleApiLoaded = (map, maps) => {
    setMap(map);
    setMaps(maps);

    // Set maximum and minimum zoom levels
    map.setOptions({
      // maxZoom: 15,
      minZoom: 10,
    });
  };

  useEffect(() => {
    if (sensorAttr) {
      const { sensorType, id, name, optionalData } = sensorAttr;

      dispatch(
        actions.chartAction(sensorType, id, timeRange, name, optionalData)
      );
    }
    // return () => {
    //   // second;
    // };
  }, [timeRange, sensorAttr]);

  //--------------------------
  // Render catchments|polygons
  useEffect(() => {
    if (catchmentData && map && maps) {
      //------remove existing polygon-----
      // MOTO: fix catchment|polygon duplicate issue after catchment overview reload.
      if (polygones) {
        polygones.forEach((p) => {
          p.setMap(null);
        });
        polygones = [];
      }

      let showPods = false;
      catchmentData.forEach((element) => {
        let temptriangleCoords = [];

        element.shapeCoordinates.forEach((element) => {
          let temp = { lat: element[0], lng: element[1] };
          temptriangleCoords.push(temp);
        });

        let polygon = new maps.Polygon({
          paths: temptriangleCoords,
          strokeColor: element.inactive
            ? edgeColors.inactive
            : edgeColors[element.warningLevel],
          strokeOpacity: 1,
          strokeWeight: 3,
          fillColor: element.inactive
            ? catchmentColors.inactive
            : catchmentColors[element.warningLevel],
          fillOpacity: 0.3,
        });

        polygones.push(polygon);

        if (!element.inactive) {
          if (element.warningLevel !== "level1") {
            showPods = true;
          }
        }

        maps.event.addListener(polygon, "mouseover", function (e) {
          polygon.setOptions({ fillColor: "#ffffff", fillOpacity: 0.5 });
          setOnHoverPolygonName(element?.name);
        });

        maps.event.addListener(polygon, "mouseout", function (e) {
          polygon.setOptions({
            fillColor: element.inactive
              ? catchmentColors.inactive
              : catchmentColors[element.warningLevel],
            fillOpacity: 0.3,
          });
          setOnHoverPolygonName("");
        });

        //onclick
        maps.event.addListener(polygon, "click", function (e) {
          setIsAlarmInfoOpen(true);
          setAlarmData(element);
        });

        polygon.setMap(map);
      });

      if (showPods) {
        dispatch(actions.setShowPods(true));
      }
    }
  }, [catchmentData, map, maps]);

  // -----------
  // Render hdc marker
  useEffect(() => {
    if (hdcData && map && maps) {
      const hdcIconInactive = {
        url: "/images/Niederschlag_inaktiv.svg",
        // url: "/images/hdc-circle-idle.png",
        scaledSize: new maps.Size(25, 25),
      };
      const hdcIconActive = {
        url: "/images/Niederschlag_aktiv.svg",
        // url: "/images/hdc-circle-active.png",
        scaledSize: new maps.Size(25, 25),
      };

      Array.isArray(hdcData) &&
        hdcData.forEach((position) => {
          let isRaining;
          const differenceInMinutes = Math.floor(
            (new Date() - new Date(position.lastEmission)) / 1000 / 60
          );

          if (differenceInMinutes < 6) {
            isRaining = true;
          } else {
            isRaining = false;
          }

          const hdcMarker = new maps.Marker({
            position: new maps.LatLng(
              position.location.lat,
              position.location.lng
            ),
            // map: map,
            title: position.name ? `${position.name}` : "HDC",
            icon: isRaining ? hdcIconActive : hdcIconInactive,
          });
          markers.push(hdcMarker);
          markerClicked(
            hdcMarker,
            position._id,
            position.name,
            sensorTypes.HDC,
            position.location
          );

          hdcMarker.setMap(map);
        });
    }
  }, [hdcData, map, maps]);

  // -----------
  // Render smartRiver marker
  useEffect(() => {
    if (smartriverData && map && maps) {
      const smartriverIconInactive = {
        url: "/images/Pegel_inaktiv.svg",
        scaledSize: new maps.Size(25, 25),
      };
      const smartriverIconActive = {
        url: "/images/Pegel_aktiv.svg",
        scaledSize: new maps.Size(25, 25),
      };
      const smartriverIconWarning = {
        url: "/images/wartung_icon.svg",
        scaledSize: new maps.Size(30, 30),
      };

      Array.isArray(smartriverData) &&
        smartriverData.forEach((position) => {
          let timeDiffHours = 0;

          if (position.measurements?.length > 0) {
            const lastDate = new Date(
              position.measurements[position.measurements.length - 1].timestamp
            );

            const currentDate = new Date();

            const timeDiff = currentDate - lastDate;

            // timeDiffHours = Math.floor(timeDiff / (1000 * 60 * 60));
            timeDiffHours = timeDiff / (1000 * 60 * 60);
          }

          const smartriverMarker = new maps.Marker({
            position: new maps.LatLng(
              position.location.lat,
              position.location.lng
            ),

            // map: map,
            title: position.name ? `${position.name}` : "Smart-River",
            icon:
              position.maintenance || timeDiffHours > 6
                ? smartriverIconWarning
                : position.thresholds[0].active
                ? smartriverIconActive
                : smartriverIconInactive,
          });

          // console.log(position);

          smartriverMarker.setMap(map);
          markers.push(smartriverMarker);
          markerClicked(
            smartriverMarker,
            position._id,
            position.name,
            sensorTypes.SMARTRIVER,
            position.location
          );
        });
    }
  }, [smartriverData, map, maps]);

  // -----------
  // Render smartSewer marker
  useEffect(() => {
    if (smartsewerData && map && maps) {
      const smartsewerIconInactive = {
        url: "/images/Kanal_inaktiv.svg",
        // url: "/images/sewer-circle-inactive.png",
        scaledSize: new maps.Size(25, 25),
      };
      const smartsewerIconActive = {
        url: "/images/Kanal_aktiv.svg",
        // url: "/images/sewer-circle-active.png",
        scaledSize: new maps.Size(25, 25),
      };
      const smartsewerIconWarning = {
        url: "/images/wartung_icon.svg",
        scaledSize: new maps.Size(30, 30),
      };

      Array.isArray(smartsewerData) &&
        smartsewerData.forEach((position) => {
          const smartsewerMarker = new maps.Marker({
            position: new maps.LatLng(
              position.location.lat,
              position.location.lng
            ),
            // map: map,
            title: position.name ? `${position.name}` : "Smart-Sewer",
            icon: position.maintenance
              ? smartsewerIconWarning
              : position.thresholds[0].overflow
              ? smartsewerIconActive
              : smartsewerIconInactive,
          });
          smartsewerMarker.setMap(map);
          markers.push(smartsewerMarker);
          markerClicked(
            smartsewerMarker,
            position._id,
            position.name,
            sensorTypes.SMARTSEWER,
            position.location
          );
        });
    }
  }, [smartsewerData, map, maps]);

  // -----------
  // Render gfl marker
  useEffect(() => {
    if (gflData && map && maps) {
      const gflIconInactive = {
        url: "/images/Pegel_inaktiv.svg",
        // url: "/images/floodlevel-circle.png",
        scaledSize: new maps.Size(25, 25),
      };
      const gflIconActive = {
        url: "/images/Pegel_aktiv.svg",
        // url: "/images/floodlevel-circle-active.png",
        scaledSize: new maps.Size(25, 25),
      };
      const gflIconWarning = {
        url: "/images/wartung_icon.svg",
        scaledSize: new maps.Size(30, 30),
      };
      // icons8-loading-circle.gif
      Array.isArray(gflData) &&
        gflData.forEach((position) => {
          const gflMarker = new maps.Marker({
            position: new maps.LatLng(
              position.location.lat,
              position.location.lng
            ),
            // map: map,
            title: position.name ? `${position.name}` : "GFL",
            icon: position.maintenance
              ? gflIconWarning
              : position.thresholds[0].active
              ? gflIconActive
              : gflIconInactive,
          });
          gflMarker.setMap(map);
          markers.push(gflMarker);
          markerClicked(
            gflMarker,
            position._id,
            position.name,
            sensorTypes.GFL,
            position.location
          );
        });
    }
  }, [gflData, map, maps]);

  // customer effect
  useEffect(() => {
    if (customerData != null) {
      setLatLng({
        lat: customerData.coordinatesCenter[0],
        lng: customerData.coordinatesCenter[1],
      });
      setZoom(customerData.coordinatesCenter[2]);
    }
  }, [customerData]);

  // fetch all data
  useEffect(() => {
    // dispatch(actions.customerAction());

    const ags = agsList[0];

    dispatch(actions.catchment(ags));

    // hdc turn off for user
    userRole !== "users" && dispatch(actions.hdc({ ags }));
    dispatch(actions.gfl({ ags }));
    dispatch(actions.smartriver({ ags }));

    // smartsewer turn off for user
    userRole !== "users" && dispatch(actions.smartsewer({ ags }));
  }, [dispatch]);

  useEffect(() => {
    if (notificationType === "rain") {
      setLatLng({
        lat: parseFloat(catchmentLat),
        lng: parseFloat(catchmentLng),
      });
      setZoom(14);
      setTimeout(() => {
        setSearchParams({}); // clear search params
      }, 3000);
    }
    // smartriverdata
    if (
      notificationType === "sensor" &&
      smartriverData &&
      smartriverData.length > 0
    ) {
      let selectedSensor;
      // let optionalData = {};
      smartriverData &&
        smartriverData?.forEach((data) => {
          // console.log("data", data);
          if (data["_id"] === sensorIdFromNotification) {
            selectedSensor = data;
            // optionalData.soleCorrectionValue = data["soleCorrectionValue"];
            // optionalData.thresholds = data["thresholds"];
            // optionalData.maintenance = data["maintenance"];
          }
        });

      if (selectedSensor) {
        // setSensorAttr({
        //   sensorType: "smartriverdata",
        //   id: selectedSensor._id,
        //   name: selectedSensor.name,
        //   optionalData,
        // });
        // setTimeout(() => {
        //   setChartModalOpen(true);
        // }, 1000);
        // setTimeRange(1440);

        // setting center lat-lng 100 meters down to achieve better ux
        // const newLatLng = calculateNewLatLng(
        //   selectedSensor.location?.lat,
        //   selectedSensor.location?.lng
        // );
        setLatLng({
          lat: selectedSensor.location?.lat,
          lng: selectedSensor.location?.lng,
        });
        setZoom(17);
        setTimeout(() => {
          setSearchParams({}); // clear search params
        }, 3000);
      }
    }
  }, [notificationType, sensorIdFromNotification, smartriverData]);

  // Render address marker
  useEffect(() => {
    if (addressCoordinate && map && maps) {
      if (existingMarker) {
        markers
          .filter((item) => item.title === "Address-Marker")
          .forEach((marker) => marker.setMap(null));
      }

      const locationIcon = {
        url: "/images/address_marker.svg",
        scaledSize: new maps.Size(30, 30),
      };

      const addressMarker = new maps.Marker({
        position: new maps.LatLng(addressCoordinate[0], addressCoordinate[1]),
        title: "Address-Marker",
        icon: locationIcon,
      });
      addressMarker.setMap(map);
      markers.push(addressMarker);

      setLatLng({
        lat: addressCoordinate[0],
        lng: addressCoordinate[1],
      });
      setZoom(12);
      setExistingMarker(true);
    }
  }, [addressCoordinate, map, maps]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  // onboarding wizard logic
  useEffect(() => {
    const haveShowWizardFlag = localStorage.getItem("showOnBoardWizard");
    if (!haveShowWizardFlag) {
      setShowOnBoardWizard(true);
    }
    return () => {
      localStorage.setItem("showOnBoardWizard", "no");
    };
  }, []);

  const ref = useClickAway(() => {
    setChartModalOpen(false);
  });

  const handleMapChange = (e) => {
    setLatLng({ lat: e.center.lat, lng: e.center.lng });
    setZoom(e.zoom);
    let showMapResetButton;
    if (
      e.zoom !== customerData.coordinatesCenter[2] &&
      e.center.lat !== customerData.coordinatesCenter[0] &&
      e.center.lng !== customerData.coordinatesCenter[1]
    ) {
      showMapResetButton = true;
    } else {
      showMapResetButton = false;
    }

    setShowResetButton(showMapResetButton);
    // setZoom(e.zoom);
    // setLatLng({
    //   lat: e.center.lat,
    //   lng: e.center.lng,
    // });
  };

  const handleNormalMapReset = () => {
    setLatLng({
      lat: customerData.coordinatesCenter[0],
      lng: customerData.coordinatesCenter[1],
    });
    setZoom(customerData.coordinatesCenter[2]);
  };

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
      localStorage.setItem("showOnBoardWizard", "no");
    }
  };

  return (
    <div id="map" className={styles["gm-style"]}>
      {showOnBoardWizard && (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={run}
          showProgress={false}
          showSkipButton
          locale={{ back: "Zurück", last: "Fertig", next: "Weiter" }}
          steps={steps}
          styles={
            isThemeDark ? wizardCustomStylesDark : wizardCustomStylesLight
          }
        />
      )}
      <div>
        {/* <CatchmentsOverview
          items={catchmentData && catchmentData}
          isLoading={isLoading}
        /> */}
        <NormalOverview userAgs={agsList[0]} />
        <AlarmInfoSideBar
          open={isAlarmInfoOpen}
          setOpen={setIsAlarmInfoOpen}
          data={alarmData}
        />
        <Controls
          map={map}
          maps={maps}
          handleNormalMapReset={handleNormalMapReset}
          showResetButton={showResetButton}
          setAddressCoordinate={setAddressCoordinate}
        />
        <div
          data-tip=""
          id="realtime-map"
          style={{ zIndex: "100", height: "100vh", width: "100vw" }}
        >
          {customerData ? (
            <GoogleMapReact
              onChange={(e) => handleMapChange(e)}
              bootstrapURLKeys={{
                loadingElement: <Spinner />, // Set your custom loader component here
              }}
              options={{
                styles: isThemeDark ? mapDarkFeature : mapLightFeature,
                fullscreenControl: false,
                zoomControl: false,
                gestureHandling: "greedy",
              }}
              // defaultZoom={zoom}
              defaultCenter={{
                lat: 48.56692657664802,
                lng: 13.430500178529469,
              }}
              center={latlng}
              defaultZoom={8}
              zoom={zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) =>
                onGoogleApiLoaded(map, maps)
              }
            ></GoogleMapReact>
          ) : (
            <div className="flex items-center justify-center h-full">
              <Spinner />
            </div>
          )}
          {/* TODO: Will need this for future versions*/}
          {!isMobileView && <ReactTooltip>{onHoverPolygonName}</ReactTooltip>}
        </div>
      </div>
      <div ref={ref}>
        <ChartModal
          setTimeRange={setTimeRange}
          duration={timeRange}
          open={chartModalOpen}
          onCloseModal={() => setChartModalOpen(!chartModalOpen)}
        />
      </div>
    </div>
  );
};

export default NormalView;
