import React, { useState, useEffect } from "react";
import olms from "ol-mapbox-style";
import {
  chromaticThemeURL,
  satelliteThemeURL,
} from "../../../constants/mapboxThemeUrl";
import { useTranslation } from "react-i18next";
export const olThemeType = {
  SATELLITE_MODE: "SATELLITE_MODE",
  CHROMATIC_MODE: "CHROMATIC_MODE",
};

const isSafariAndIphone = () => {
  return (
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    /iPhone/.test(navigator.userAgent)
  );
};

const OLThemeSwitch = ({ map }) => {
  const { t } = useTranslation("webViewer");
  const [currentTheme, setCurrentTheme] = useState(chromaticThemeURL);
  const [themeMode, setThemeMode] = useState(olThemeType.CHROMATIC_MODE);

  const onThemeHandler = (themeType) => {
    const theme =
      themeType === olThemeType.SATELLITE_MODE
        ? satelliteThemeURL
        : chromaticThemeURL;
    setCurrentTheme(theme);
  };

  // theme switch side-effect
  useEffect(() => {
    if (map) {
      //------------------
      //remove old theme style
      const layers = map.getLayers().getArray();
      for (let i = layers.length - 1; i >= 0; --i) {
        if (layers[i].get("mapbox-source")) {
          map.removeLayer(layers[i]);
        }
      }

      //----------------
      //set theme style
      olms(map, currentTheme).then((map) => {});
    }
  }, [map, currentTheme]);

  const safariStyleIphone = isSafariAndIphone()
    ? { marginBottom: "10px" }
    : { marginBottom: "50px" };

  return (
    <div
      style={safariStyleIphone}
      className="box-border fixed bottom-0 flex items-center overflow-y-auto rounded-md z-100 bg-spekterDarkOne text-spekterWhite xl:ml-40 ml-6 mt-14"
    >
      <button
        className={`h-10 w-28  text-spekterWhite no-underline border-none hover:bg-gray-500
                 ${
                   themeMode === olThemeType.CHROMATIC_MODE
                     ? "bg-gray-500  font-bold"
                     : "bg-spekterDarkFour"
                 }`}
        onClick={() => {
          setThemeMode(olThemeType.CHROMATIC_MODE);
          onThemeHandler(olThemeType.CHROMATIC_MODE);
        }}
      >
        {t("chromatic")}
      </button>
      <div className="mx-2.5">|</div>
      <button
        className={`h-10 w-28  text-spekterWhite no-underline border-none hover:bg-gray-500
                 ${
                   themeMode === olThemeType.SATELLITE_MODE
                     ? "bg-gray-500  font-bold"
                     : "bg-spekterDarkFour"
                 }`}
        onClick={() => {
          setThemeMode(olThemeType.SATELLITE_MODE);
          onThemeHandler(olThemeType.SATELLITE_MODE);
        }}
      >
        {t("satellite")}
      </button>
    </div>
  );
};

export default OLThemeSwitch;
