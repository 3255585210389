import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwitchIcon from "../../../UI/Switch/Switch";
import { userAction } from "../../../../redux/actions";

const CatchmentSelectAdd = ({ setAlertCatchments, showSubtitle = true }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data);
  const [catchments, setCatchments] = useState(null);

  useEffect(() => {
    if (!userData) {
      dispatch(userAction());
    }
    if (userData && catchments === null) {
      const catchments = userData.catchments.map((catchment) => {
        return { ...catchment, alert: true };
      });

      const catchmentNumbersArray = catchments.map(
        (item) => item.catchmentNumber
      );
      setCatchments(catchments);
      setAlertCatchments(catchmentNumbersArray);
    }
  }, [userData, catchments, dispatch]);

  return (
    <div className="w-full p-2 rounded-xl lg:p-6 text-spekterWhite">
      <div className="flex flex-col items-center w-full gap-5">
        <div className="max-w-[100%] text-center text-gray-800 dark:text-gray-100">
          <p className="self-start text-2xl font-bold">Alarm-Einzugsgebiete</p>
          {showSubtitle && (
            <p>
              Bitte wählen Sie eines odere mehrer Einzugsgebiete aus in denen
              Sie alarmiert werden möchten. Wenn Sie KEIN Gebiet wählen, werden
              Sie NICHT alarmiert!
            </p>
          )}
        </div>
        <div
          className={`flex flex-col items-center w-full h-64 p-2
          overflow-y-auto text-black rounded-md dark:text-gray-200
           scrollbarLight dark:scrollbar bg-slate-100 dark:bg-spekterDarkTwo
           `}
        >
          {catchments &&
            catchments.map((catchment, index) => (
              <div
                key={index}
                className="flex justify-around items-center w-[300px] max-w-full h-16 gap-12"
              >
                <div className={` w-[50%]`}>
                  <SwitchIcon
                    bgColor="bg-[#ccc] opacity-40"
                    bgCheckedColor="checked:bg-spekterGreenLight checked:opacity-100"
                    value={catchment.alert}
                    onCheck={() => {
                      const newCatchments = [...catchments];
                      catchments[index].alert = !newCatchments[index].alert;
                      setCatchments(newCatchments);
                      const catchmentsWithAlert = newCatchments
                        .filter((catchment) => catchment.alert)
                        .map((catchment) => catchment.catchmentNumber);
                      setAlertCatchments(catchmentsWithAlert);
                    }}
                  />
                </div>
                <div className="relative bottom-[3px] font-[700] text-sm w-[50%]">
                  {catchment.name}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CatchmentSelectAdd;
