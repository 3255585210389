export const mapWizardSteps = [
  {
    content: (
      <div className="">
        <p className="text-[18px] md:text-[20px] font-bold text-left">☔️ Willkommen zum FAS v2 🚨</p>
        <p className="text-[12px] md:text-[15px] text-left font-normal">
          Die aktualisierte Version, entwickelt mit fortschrittlicher
          Technologie und Fachexpertise, bietet verbesserte Funktionen zur
          frühzeitigen Erkennung und Prävention von Überflutungsgefahren. <br /><br />Wir
          sind bestrebt, durch diese Innovation den Schutz von Menschen und
          Werten kontinuierlich zu optimieren.
        </p>
      </div>
    ),
    placement: "center",
    target: "#map",
    disableBeacon: true,
  },
  {
    content: (
      <div className="">
        <p className="text-[18px] md:text-[24px] font-bold text-left">Sidebar</p>
        <div className="h-[180px] 2xl:h-[250px] overflow-y-auto scrollbar mb-4">
          <p className="text-[15px] text-left font-normal">
            {/* Die neuen Features umfassen eine verbesserte Übersichtlichkeit mit
            klaren, strukturierten Abschnitten für schnelles Auffinden von
            Informationen und Funktionen, Schnellzugriff auf wichtige Tools wie
            Risikoanalysen, Echtzeit-Wetterdaten und personalisierte
            Einstellungen, erweiterte Filteroptionen für das schnelle Finden
            spezifischer Daten oder Berichte, sowie eine optimierte
            Benutzerinteraktion mit einem modernen, ansprechenden Design, das
            die Bedienung sowohl auf Desktop- als auch auf mobilen Geräten
            verbessert. */}

            Die Informationen und Funktionen sind jetzt in klare, strukturierte Abschnitte gegliedert. Dies ermöglicht es Ihnen, schnell zu finden, was Sie suchen.
            <br/>
            <br/>
            Ein weiteres Highlight sind die Schnellzugriffe. Wichtige Tools wie Risikoanalysen, Echtzeit-Wetterdaten und personalisierte Einstellungen sind nun direkt erreichbar. Diese Funktionen sind so konzipiert, dass sie Ihnen ohne Umwege zur Verfügung stehen.
            <br/>
            <br/>
            Außerdem haben wir unsere Analyseoptionen erweitert. Diese ermöglichen es Ihnen, spezifische Daten oder Berichte mit wenigen Klicks zu finden. Das macht die Suche nicht nur effizienter, sondern auch deutlich schneller.
            <br/>
            <br/>
            Zu guter Letzt haben wir die Benutzerinteraktion verbessert. Dank eines modernen, ansprechenden Designs ist die Bedienung unserer Plattform nun sowohl auf Desktop- als auch auf mobilen Geräten ein Vergnügen. Dieses Update verbessert nicht nur die Optik, sondern auch das Nutzererlebnis erheblich.
          </p>
        </div>

        <div className="mt-1 md:mt-4">
          <img
            src="/images/onboard/Image Wizard Sidebar.svg"
            alt="Sidebar"
            className="rounded-md"
          />
        </div>
      </div>
    ),
    placement: "right",
    target: "#sidebar",
    disableBeacon: true,
  },
  {
    content: (
      <div className="-my-5 md:-my-0">
        <p className="text-[18px] md:text-[24px] font-bold">Radar</p>
        <div className="h-[70px] md:h-auto overflow-y-auto scrollbar mb-1 md:mb-4">
          <p className="text-[12px] md:text-[15px] text-left font-normal">
            Diese Funktion bietet Ihnen Echtzeit-Visualisierungen von
            Niederschlagsmustern, ermöglicht es Ihnen, aufkommende
            Starkregenereignisse schnell zu erkennen und unterstützt Sie bei der
            präventiven Planung.
          </p>
        </div>
        <div className="mt-1 md:mt-4">
          <img
            src="/images/onboard/Image Wizard Radar.svg"
            alt="Radar"
            className="rounded-md"
          />
        </div>
      </div>
    ),
    placement: "left",
    target: "#radar-icon",
  },
  {
    content: (
      <div className="-my-5 md:-my-0">
        <p className="text-[18px] md:text-[24px] font-bold">POD</p>
        <div className="h-[70px] md:h-auto overflow-y-auto scrollbar mb-1 md:mb-4">
          <p className="text-[12px] md:text-[15px] text-left font-normal">
            Neu im Dashboard: Setzen Sie "Points of Danger" (POD) direkt auf der
            Karte. Diese Funktion ermöglicht es Ihnen, gefährdete Standorte
            festzulegen und zu überwachen. Einfach, effektiv und essentiell für
            proaktives Risikomanagement.
          </p>
        </div>
        <div className="mt-1 md:mt-4">
          <img
            src="/images/onboard/Image Wizard POD.svg"
            alt="POD"
            className="rounded-md"
          />
        </div>
      </div>
    ),
    placement: "left",
    target: "#pod-icon-map",
  },
];
