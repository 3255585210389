import React from "react";

const SwitchIcon = ({
  value,
  onCheck,
  bgColor,
  bgCheckedColor,
  disabled = false,
  labelClassName = "",
  inputHeight = "h-6",
}) => {
  return (
    <div
      htmlFor="toggle-switch"
      className={`${labelClassName ? labelClassName : "m-2"}`}
    >
      <input
        type="checkbox"
        id="toggle-switch"
        className={`cursor-pointer w-12 rounded-full appearance-none transition duration-200 relative disabled:opacity-40 
          ${bgColor ? bgColor : "bg-gray-300"} ${inputHeight} ${
          bgCheckedColor ? bgCheckedColor : "checked:bg-spekterGreen"
        }`}
        checked={value}
        onChange={onCheck}
        disabled={disabled}
      />
    </div>
  );
};

export default SwitchIcon;
