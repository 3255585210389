import { tr } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SensorItem = ({
  name,
  data,
  maintenance,
  warningStage,
  onDismissCard,
  top,
  zIndex,
  opacity,
  handleNotificationClick,
  notificationFlag,
  hasHeaderRendered,
  expandNotifications,
}) => {
  const { t, i18n } = useTranslation("sensorItem"); //language package
  const [showFull, setShowFull] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const navigate = useNavigate();

  useEffect(() => {
    setShowFull(false);
  }, [notificationFlag]);

  useEffect(() => {
    if (
      notificationFlag &&
      maintenance &&
      !hasHeaderRendered.current &&
      expandNotifications
    ) {
      setShowHeader(true);
      hasHeaderRendered.current = true;
    }
    if (hasHeaderRendered.current && !notificationFlag) {
      hasHeaderRendered.current = false;
    }
  }, [hasHeaderRendered, notificationFlag, expandNotifications]);

  return (
    <div
      onClick={() => handleNotificationClick(data)}
      style={{
        top: top,
        zIndex: zIndex,
        // opacity: opacity,
      }}
      className={` ${
        showFull ? "max-h-max" : "h-[90px]"
      } p-2.5 backdrop-blur-[61.5px] ${
        showHeader ? "mt-10" : "mt-2"
      } rounded-[15px]
      hover:cursor-pointer mb-2 sm:ml-2 relative transition-all duration-500 notificationShadow 
      notificationBg-light dark:notificationBg`}
    >
      {showHeader && (
        <div className="absolute left-0 px-3 font-semibold backdrop-blur-[61.5px] text-center text-black duration-500 rounded-lg notificationBg-light dark:notificationBg dark:text-white -top-7">
          {t("maintenanceHeader")}
        </div>
      )}
      <div className="flex justify-between w-full">
        <div className="w-[35px] h-[35px]">
          <img
            className="w-full"
            src={
              maintenance
                ? "/images/wartung_icon.svg"
                : "/images/Pegel_aktiv.svg"
            }
            alt="sensor status icon"
          />
        </div>
        <div className="w-[87%] px-2.5 text-[15px] font-semibold relative top-[2px] text-black dark:text-white">
          <p className={`${showFull ? "" : "truncate"} break-words`}>{name}</p>
        </div>
        <img
          className="h-3 duration-300 cursor-pointer hover:opacity-80"
          onClick={(e) => {
            onDismissCard();
            e.stopPropagation();
          }}
          src={`/images/notification/${
            isThemeDark ? "X Icon Bold.svg" : "X Icon Bold_000000.svg"
          }`}
          alt="cancel icon"
        />
      </div>
      {/* expand arrow */}
      {maintenance && name && name.length > 40 && (
        <div
          onClick={(e) => {
            setShowFull(!showFull);
            e.stopPropagation();
          }}
          className="absolute duration-300 text-spekterWhite hover:text-gray-300 right-2 bottom-2 hover:scale-125"
        >
          <img
            className={`w-4 ${showFull ? "rotate-180" : ""} duration-500`}
            src={`/images/notification/Opened Triangle Arrow for Infomenu_Bold_${
              isThemeDark ? "ffffff" : "000000"
            }.svg`}
            alt=""
          />
        </div>
      )}
      <div
        className={`pl-[45px] pr-6 text-xs text-black dark:text-white ${
          maintenance && "relative bottom-1"
        }`}
      >
        <p className={`w-[260px] ${showFull ? "" : "line-clamp-2"}`}>
          {maintenance
            ? `${t("theSensor")} ${name} ${t(
                "isCurrentlyInTheMaintenanceMode"
              )}`
            : `${t("theSensorHasThreshold")} ${warningStage} ${t("reached")}`}
        </p>
      </div>
    </div>
  );
};

export default SensorItem;
