import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Auxi from "../../../hoc/Auxi/Auxi";
import Backdrop from "../../UI/Backdrop/Backdrop";
import * as actions from "../../../redux/actions/index";
import * as actionTypes from "../../../redux/actions/actionTypes";
import "./ThemeButton.css";
import { VscAccount } from "react-icons/vsc";
import { RiLockPasswordLine } from "react-icons/ri";
import { FaRegBell } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import Menu from "./Menu/Menu";
import LogoutModal from "./Modals/LogoutModal";
import Notification from "./Notification/Notification";
import { Squash as Hamburger } from "hamburger-react";
import useMobileView from "../../../hooks/useMobileView";

const Header = (props) => {
  //language package
  const { t, i18n } = useTranslation("userSettings");

  let navigate = useNavigate();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const [notificationFlag, setNotificationFlag] = useState(true);
  const [expandNotifications, setExpandNotifications] = useState(false);
  const [bellIconPath, setBellIconPath] = useState(null);
  const [elseStatementHasRun, setElseStatementHasRun] = useState(false);

  const dispatch = useDispatch();
  const isMobileView = useMobileView();
  const [showDropdown, setShowDropdown] = useState(false);
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const observerData = useSelector((state) => state.observer.data);

  useEffect(() => {
    if (location.pathname !== "/realtimemap") {
      setNotificationFlag(false);
    } else {
      // else block should run only once
      if (!elseStatementHasRun) {
        setNotificationFlag(true);
        setElseStatementHasRun(true);
      }
    }
    if (!localStorage.getItem("showOnBoardWizard") && isMobileView) {
      setNotificationFlag(false);
    }
  }, [location.pathname, isMobileView]);

  const fasIcon = "/images/fas.png";
  const fasIcon_dark = "/images/fas_dark.png";

  const handleThemeSwitch = () => {
    dispatch(actions.toggleTheme());
  };

  const clicked = () => {
    setShowDropdown(!showDropdown);
  };
  const handleLogout = () => {
    setShowLogoutModal(true);
    setShowDropdown(false);
  };

  const logoutClicked = () => {
    // clear local storage without isThemeDark properties
    const isThemeDark = localStorage.getItem("isThemeDark");
    localStorage.clear();
    localStorage.setItem("isThemeDark", isThemeDark);
    localStorage.setItem("showOnBoardWizard", "no");

    // localStorage.clear();
    dispatch({ type: actionTypes.RESET_APP });
    navigate("/login", { replace: true });
    window.location.reload(); // reload the page to reset the app
  };

  const goToSettingPage = (tab) => {
    // props.onSettingsClick(page, tab);
    setShowDropdown(!showDropdown);
    navigate(`/setting?tabName=${tab}`);
  };

  const dropdownMenu = !observerData ? (
    <div
      className={`${
        showDropdown
          ? "opacity-100 scale-100"
          : "opacity-0 scale-75 pointer-events-none"
      } 
      ${isThemeDark ? "" : "headerShadowLight"}
            transition-all origin-top-right duration-100 dark:text-gray-100 text-gray-700 fixed text-sm w-[321px]
            top-[100px] right-10 rounded-[6px] overflow-hidden py-[15px] cursor-pointer z-1000 dark:bg-spekterDarkOne bg-spekterWhite
            bg-white/[.69] backdrop-blur-[61.5px] dark:bg-black/[.69]`}
    >
      <div
        className="flex items-center gap-4 font-bold text-[15px] py-2 px-[23px]"
        onClick={() => goToSettingPage("account")}
      >
        {/* <VscAccount size={20} /> */}
        <img
          className="w-[23px] h-[23px]"
          src={`/images/user_settings/user_${
            isThemeDark ? "light" : "dark"
          }.svg`}
          alt="avatar"
        />
        {t("acc")}
      </div>
      <div
        className="flex items-center gap-4 font-bold text-[15px] py-2 px-[23px]"
        onClick={() => goToSettingPage("password")}
      >
        <img
          className="w-[23px] h-[23px]"
          src={`/images/user_settings/key_${
            isThemeDark ? "light" : "dark"
          }.svg`}
          alt="avatar"
        />
        {t("pass")}
      </div>
      <div
        className="flex items-center gap-4 font-bold text-[15px] py-2 px-[23px]"
        onClick={() => goToSettingPage("notifications")}
      >
        <img
          className="w-[23px] h-[23px]"
          src={`/images/user_settings/bell_${
            isThemeDark ? "light" : "dark"
          }.svg`}
          alt="avatar"
        />
        {t("notify")}
      </div>
      <hr className="bg-[#c9c9c9] dark:bg[#333] mx-[2px] rounded-sm my-[9px]" />
      <div
        className="flex items-center gap-4 font-bold text-[15px] py-2 px-[23px]"
        onClick={handleLogout}
      >
        <img
          className="w-[23px] h-[23px]"
          src={`/images/user_settings/logout_${
            isThemeDark ? "light" : "dark"
          }.svg`}
          alt="avatar"
        />
        {t("logout")}
      </div>
    </div>
  ) : (
    <div
      className={`${
        showDropdown
          ? "opacity-100 scale-100"
          : "opacity-0 scale-75 pointer-events-none"
      } 
      ${isThemeDark ? "" : "headerShadowLight"}
            transition-all origin-top-right duration-100 dark:text-gray-100 text-gray-700 fixed text-sm
            top-[100px] right-10 rounded-[6px] max-w-max overflow-hidden py-[15px] cursor-pointer z-1000 dark:bg-spekterDarkOne bg-spekterWhite
            bg-white/[.69] backdrop-blur-[61.5px] dark:bg-black/[.69]`}
    >
      <div
        className="flex items-center gap-4 font-bold text-[15px] py-2 px-[23px]"
        onClick={handleLogout}
      >
        <img
          className="w-[23px] h-[23px]"
          src={`/images/user_settings/logout_${
            isThemeDark ? "light" : "dark"
          }.svg`}
          alt="avatar"
        />
        {t("logout")}
      </div>
    </div>
  );

  return (
    <Auxi>
      <Backdrop
        opacity="bg-opacity-0"
        show={showDropdown}
        clicked={() => setShowDropdown(false)}
      />
      <div className="background-layer-light dark:background-layer-dark headerShadowLight w-full h-14 fixed top-0 z-1000 py-1 px-[3%] min-w-max">
        <div className="flex items-center justify-between h-full gap-2">
          <div>
            <img
              className="h-10 duration-300 sm:h-14"
              src={`/images/Logo_Swisscom_${
                isThemeDark ? "Light" : "Dark"
              }.svg`}
              alt="Swiss Icon"
            />
          </div>

          <div className="flex items-center gap-3 duration-300 md:gap-7 2xl:gap-14">
            {/* <div className="items-center hidden gap-4 xl:flex">
              <input
                onChange={handleThemeSwitch}
                type="checkbox"
                className="checkbox"
                id="checkbox"
                checked={isThemeDark}
              />
              <label
                htmlFor="checkbox"
                className="border border-gray-500 label"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="white"
                  className="bi bi-moon-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="white"
                  className="bi bi-sun-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                </svg>
                <div className="ball" />
              </label>
            </div> */}

            <Notification
              setNotificationFlag={setNotificationFlag}
              notificationFlag={notificationFlag}
              setBellIconPath={setBellIconPath}
              setExpandNotifications={setExpandNotifications}
              expandNotifications={expandNotifications}
            />

            <div onClick={clicked} className="hidden cursor-pointer xl:block">
              <img
                className="w-8 h-8 rounded-full"
                src={
                  isThemeDark
                    ? "/images/user_settings/avatar_light.svg"
                    : "/images/user_settings/avatar_dark.svg"
                }
                alt="avatar"
              />
            </div>
            <div className="block cursor-pointer xl:hidden">
              <Hamburger
                toggled={openMenu}
                toggle={setOpenMenu}
                color={isThemeDark ? "white" : "black"}
              />
            </div>
          </div>
        </div>
        {dropdownMenu}
        {showLogoutModal && (
          <LogoutModal
            setShowLogoutModal={setShowLogoutModal}
            logoutClicked={logoutClicked}
          />
        )}
        <Menu
          open={openMenu}
          setOpen={setOpenMenu}
          setShowLogoutModal={setShowLogoutModal}
          setNotificationFlag={setNotificationFlag}
          notificationFlag={notificationFlag}
          bellIconPath={bellIconPath}
          setExpandNotifications={setExpandNotifications}
        />
      </div>
    </Auxi>
  );
};

export default Header;
