import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../redux/actions";
import PodViewModal from "../../../WebViewer/SideBar/pods/PodViewModal";
import useAgs from "../../../../hooks/useAgs";
import useObserver from "../../../../hooks/useObserver";
import { cn } from "../../../../shared/Utility";
import { useSearchParams } from "react-router-dom";

const selectPodIcon = (zoomValue, category) => {
  const fileName =
    zoomValue >= 15
      ? category === "firefighters"
        ? "Firefighters POD, State=Zoomed In"
        : category === "administration"
        ? "Administrative Service POD, State=Zoomed In"
        : category === "police"
        ? "Police POD, State=Zoomed In"
        : category === "thw"
        ? "THW POD, State=Zoomed In"
        : "POD Icon_aktiv"
      : category === "firefighters"
      ? "Firefighters POD, State=Zoomed Out"
      : category === "administration"
      ? "Administrative Service POD, State=Zoomed Out"
      : category === "police"
      ? "Police POD, State=Zoomed Out"
      : category === "thw"
      ? "THW POD, State=Zoomed Out"
      : "POD Icon_aktiv";
  return `/images/pod/${fileName}.svg`;
};

const POD = ({ map, maps, className }) => {
  const dispatch = useDispatch();
  const [zoomValue, setZoomValue] = useState(0); // [zoom, setZoom]
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  let [searchParams, setSearchParams] = useSearchParams();

  const [showPodsModal, setShowPodsModal] = useState(false);
  const [selectedPod, setSelectedPod] = useState(null);
  const [showPodViewModal, setShowPodViewModal] = useState(false);

  const [renderedMarkers, setRenderedMarkers] = useState([]);

  const agsList = useAgs();
  const showPods = useSelector((state) => state.pod.showPods);
  const selectedAgs = useSelector((state) => state.observer.selectedAgs);
  const isObserver = useObserver();

  const podsData = useSelector((state) => state.pod.pods.data);
  const selectedPodFromSidebar = searchParams.get("_id");
  const clickedItemType = searchParams.get("notificationType");

  useEffect(() => {
    renderedMarkers.forEach((marker) => {
      marker.setMap(null);
    });

    if (showPods) {
      if (podsData && map && maps) {
        // icons8-loading-circle.gif
        podsData.forEach((pod) => {
          const podMarker = new maps.Marker({
            position: new maps.LatLng(pod.location.lat, pod.location.lng),
            // map: map,
            title: pod.orderSheet ? `${pod.orderSheet}` : "No Order Sheet",
            icon: {
              url: selectPodIcon(zoomValue, pod?.category),
              scaledSize:
                zoomValue >= 15 ? new maps.Size(60, 60) : new maps.Size(25, 25),
              anchor: new maps.Point(27, 23),
            },
          });
          setRenderedMarkers((prev) => [...prev, podMarker]);
          podMarker.setMap(map);

          podMarker.addListener("click", () => {
            setSelectedPod(pod);
            setShowPodViewModal(true);
          });

          // markerClicked(gflMarker, position._id, position.name, sensorTypes.GFL);
        });
      }
    }
  }, [map, maps, podsData, showPods, zoomValue]);

  useEffect(() => {
    // clear all rendered markers if selectedAgs is null
    // when user switch from observer to normal view
    if (isObserver) {
      !selectedAgs &&
        renderedMarkers.forEach((marker) => {
          marker.setMap(null);
        });
    }
  }, [isObserver, selectedAgs, renderedMarkers]);

  useEffect(() => {
    if (!map) return;
    map.addListener("zoom_changed", function () {
      if (map.getZoom() <= 15) {
        setZoomValue(map.getZoom());
      }
    });
  }, [map]);

  useEffect(() => {
    // trigger action from 2nd layer of observer
    if (isObserver) {
      if (!selectedAgs) return;
      dispatch(actions.pods(selectedAgs));
    } else {
      // normal view trigger action
      if (agsList.length === 0) return;
      dispatch(actions.pods(agsList[0]));
    }
  }, [dispatch, selectedAgs, isObserver]);

  // selecting pod from sidebar (observer)
  useEffect(() => {
    if ((podsData, selectedPodFromSidebar && clickedItemType === "pod")) {
      const selectedPod = podsData.find(
        (pod) => pod._id === selectedPodFromSidebar
      );
      setSelectedPod(selectedPod);
      setTimeout(() => {
        setShowPodViewModal(true);
      }, 1000);
    }
  }, [selectedPodFromSidebar, clickedItemType, podsData]);

  return (
    <div className={cn("relative flex flex-col", className)}>
      {/* ON | OFF POD icon */}
      <section
        className={`flex items-center justify-center w-10 h-10 duration-300 backdrop-blur-sm rounded 
        cursor-pointer bg-spekterDarkOne hover:bg-gray-600`}
        onClick={() => {
          // setShowPodsMarker((prev) => !prev);
          dispatch(actions.setShowPods(!showPods));
        }}
        id="pod-icon-map"
      >
        <img
          src={
            showPods
              ? "/images/pod/POD Icon_aktiv.svg"
              : `/images/pod/POD Icon_ffffff.svg`
          }
          alt="pod icon"
          className="w-full scale-110"
          id="pod-icon"
        />
      </section>

      {showPodViewModal && (
        <PodViewModal
          setShow={setShowPodViewModal}
          selectedPod={selectedPod}
          setSelectedPod={setSelectedPod}
          setShowPodsModal={setShowPodsModal}
        />
      )}
    </div>
  );
};

export default POD;
