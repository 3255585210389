import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../../../components/UI/Backdrop/Backdrop";
import { useTranslation } from "react-i18next";
import { showDangerPagePopUp } from "../../../redux/actions/index";

const alarmsInfoDetail = [
  {
    description: [
      "Aktuell besteht keine akute Überflutungsgefahr",
      "Prüfen Sie Dachrinnen und Entwässerungssysteme",
      "Prüfen Sie vorhandene Rückstausysteme",
      "Material zur Sicherung der Zutrittwege vorhalten",
    ],
  },
  {
    description: [
      "Fenster, Türen und Dachluken schließen",
      "Rückstausicherungen prüfen oder aktivieren",
      "Dach- und Hofentwässerung beobachten oder in Gang setzen",
    ],
  },
  {
    description: [
      "Keller, Tiefgaragen, Unterführungen verlassen",
      "Überflutete Straßen und Uferbereiche meiden",
    ],
  },
  {
    description: [
      "Sofort höhere Räume oder Gelände aufsuchen",
      "Sofort überflutete Straßen und Uferbereiche verlassen",
      "Anweisungen der Rettungskräfte folgen",
    ],
  },
];

const Sidebar = ({ open, setOpen, data }) => {
  const { t } = useTranslation("legend");
  const dispatch = useDispatch();
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const [position, setPosition] = useState("");

  let bgColor = isThemeDark ? "bg-spekterDarkOne" : "bg-white";
  let textColor = isThemeDark ? "text-spekterWhite" : "text-spekterDarkOne";
  let cancel_icon = isThemeDark
    ? "/images/sidebar/close_light.svg"
    : "/images/sidebar/close_dark.svg";

  const {
    name,
    precipitationSum,
    warningLevel,
    lastUpdate,
    lastOneHourMaxPrecipitationSum,
    measurements,
  } = data || {};

  useEffect(() => {
    const getIndicatorPosition = () => {
      // reference doc is here:
      // https://spekter.atlassian.net/wiki/spaces/CLOUDBREAK/pages/831094785/Sidebar

      if (open) {
        const percentage = (lastOneHourMaxPrecipitationSum / 40) * 100;
        return percentage;
      }
    };
    const indicatorPosition =
      getIndicatorPosition() > 0
        ? `${Math.round(
            getIndicatorPosition() > 99 ? 99 : getIndicatorPosition()
          )}%`
        : "";
    setPosition(indicatorPosition);
  }, [lastOneHourMaxPrecipitationSum, warningLevel, open]);

  const onShowDangerPage = () => {
    dispatch(showDangerPagePopUp(true));
  };

  return (
    <div>
      <Backdrop
        show={open}
        clicked={() => setOpen(false)}
        opacity="bg-opacity-0"
      />
      <div
        className={`bg-white/[.69] backdrop-blur-[61px] dark:bg-black/[.69] fixed w-screen sm:w-[470px] h-[calc(100vh_-_56px)] right-0 top-0 ${textColor} py-8 px-4 box-border mt-14 transition-transform transform overflow-y-auto ${
          open ? "translate-x-0" : "translate-x-full"
        }`}
        style={{ zIndex: 1000 }}
      >
        <div>
          <div className="flex flex-row items-center justify-end px-3">
            <div onClick={() => setOpen(false)} className="cursor-pointer">
              <img
                className="m-1"
                height="20"
                width="20"
                src={cancel_icon}
                alt="cancel icon"
              />
            </div>
          </div>
          <section className="text-center">
            <h4 className="text-[27px] font-bold">{name}</h4>
            <h6 className="text-[15px] font-bold">
              ({moment().format("DD.MM.YY - HH:mm")} Uhr)
            </h6>
          </section>
          <main className="flex flex-col pt-5 sm:pr-2 sm:overflow-y-auto dark:scrollbar scrollbarLight">
            <section className="w-full">
              <span className="text-[21px] font-bold">
                Niederschlagssumme 1 Stunde
              </span>
              <div className="flex items-end justify-center">
                <div className="flex flex-row items-center justify-center p-0 m-0">
                  <span className="text-[47px] font-bold">
                    &nbsp;{lastOneHourMaxPrecipitationSum?.toFixed(1)}
                  </span>
                </div>
                <span className="relative font-bold bottom-3.5">MM</span>
              </div>
              <div
                className={`h-[27px] w-[95%] rounded-[18px] barBG relative mb-3`}
              >
                <div
                  style={{ left: position }}
                  className={`h-[39px] w-[6px] rounded-5 bg-white absolute -bottom-[6px] z-10 border`}
                ></div>

                <div className="dark:text-white/75 text-black/75 font-bold h-9 relative top-[100%] left-[3.5px] flex justify-evenly">
                  <div className="flex items-center justify-center w-[25%] h-full border-r-2 border-black/75 dark:border-white/75">
                    S0
                  </div>
                  <div className="flex items-center justify-center w-[25%] h-full border-r-2 border-black/75 dark:border-white/75">
                    S1
                  </div>
                  <div className="flex items-center justify-center w-[37.5%] h-full border-r-2 border-black/75 dark:border-white/75">
                    S2
                  </div>
                  <div className="flex items-center justify-center w-[12.5%] h-full">
                    S3
                  </div>
                </div>
              </div>
            </section>
            {measurements?.radolan_rq_60?.average_sum > 0.5 &&
            measurements?.radolan_rq_60?.average_sum >
              lastOneHourMaxPrecipitationSum ? (
              <div className="w-full mt-3 text-center">
                <p className="text-[15px] dark:text-white text-black mt-[18px]">
                  <span className="font-bold">{t("trend")}</span>:{" "}
                  <span>{t("precipitationIsIncreasing")}</span>
                </p>
              </div>
            ) : (
              <div className="w-full mt-3 text-center">
                <p className="text-[15px] dark:text-white text-black mt-[18px]">
                  <span className="font-bold">{t("trend")}</span>:{" "}
                  <span>{t("currentlyNoForecast")}</span>
                </p>
              </div>
            )}

            <section className="w-full pt-5 mt-5">
              <div className="flex items-center pb-2">
                <span className="text-[21px] font-bold">Alarmstufe</span>
                {warningLevel !== "level1" ? (
                  <img
                    className="w-10 h-10 ml-2"
                    src={`/images/${
                      warningLevel === "level3"
                        ? "S1 Icon"
                        : warningLevel === "level4"
                        ? "S2 Icon"
                        : warningLevel === "level5" && "S3 Icon"
                    }.svg`}
                    alt="Warning Level icon"
                  />
                ) : (
                  <div className="flex items-center justify-center w-10 h-10 ml-2 rounded-full bg-green-200/70">
                    <span className="text-[20px] relative top-[.5px] font-bold text-green-700">
                      S0
                    </span>
                  </div>
                )}
              </div>
              <span className="text-[15px] font-bold text-[#818181] mt-[18px]">
                Handlungsempfehlungen:{" "}
                {warningLevel === "level3"
                  ? "Intensiv"
                  : warningLevel === "level4"
                  ? "Außergewöhnlich"
                  : warningLevel === "level5" && "Extrem"}
              </span>
              <img
                className="mt-[15px]"
                src={`/images/sidebar/${
                  warningLevel === "level3"
                    ? `${isThemeDark ? "house s1" : "house s1 light"}`
                    : warningLevel === "level4"
                    ? `${isThemeDark ? "house s2" : "house s2 light"}`
                    : warningLevel === "level5"
                    ? `${isThemeDark ? "house s3" : "house s3 light"}`
                    : `${isThemeDark ? "house s0" : "house s0 light"}`
                }.svg`}
                alt="house"
              />
              <ul className="py-4">
                {alarmsInfoDetail[
                  warningLevel === "level1"
                    ? 0
                    : warningLevel === "level3"
                    ? 1
                    : warningLevel === "level4"
                    ? 2
                    : 3
                ].description.map((desciption, index) => (
                  <li key={index} className="flex gap-2 m-0">
                    <span className="relative text-2xl bottom-1">&#8226;</span>{" "}
                    <span>{desciption}</span>
                  </li>
                ))}
              </ul>
            </section>
            <section className="flex items-center justify-center mb-28">
              <button
                className="flex items-center justify-center h-10 font-bold text-gray-800 rounded cursor-pointer w-60 bg-neutral-200"
                onClick={() => onShowDangerPage()}
              >
                <img src="/i2/images/icons8-info-24.png" alt="info icon" />
                <p className="m-0 ml-2 text-xs text-gray-800">
                  {t("infoWarningStage")}
                </p>
              </button>
            </section>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
