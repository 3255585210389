import React, { useState, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import SwitchIcon from "../../../components/UI/Switch/Switch";
import { useDispatch, useSelector } from "react-redux";
import { fromLonLat } from "ol/proj";
import Vector from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OlStyle from "ol/style/Style";
import OlStyleIcon from "ol/style/Icon";
import OlFeature from "ol/Feature";
import OlPoint from "ol/geom/Point";
import * as actions from "../../../redux/actions/index";
import useAgs from "../../../hooks/useAgs";
import useObserver from "../../../hooks/useObserver";

const harmReportsLayer = new Vector({
  source: new VectorSource({
    features: [],
    wrapX: false,
  }),
  style: new OlStyle({
    image: new OlStyleIcon({
      anchor: [0.5, 0.5],
      anchorXUnits: "fraction",
      anchorYUnits: "fraction",
      scale: 0.05,
      src: "/images/Meldung, State=Active.svg",
    }),
  }),
  zIndex: 10005,
});

// let harmNotesLayer = new Vector({
//   source: new VectorSource({
//     features: [],
//     wrapX: false,
//   }),
//   style: new OlStyle({
//     image: new OlStyleIcon({
//       anchor: [0.5, 0.5],
//       anchorXUnits: "fraction",
//       anchorYUnits: "fraction",
//       src: "/images/harm-notes.png",
//     }),
//   }),
//   zIndex: 10005,
// });

const MelderSwitch = ({ map, ags }) => {
  const dispatch = useDispatch();
  const agsList = useAgs();
  const isObserver = useObserver();
  const [expanded, setExpanded] = useState(false);
  const [isHarmReportOn, setIsHarmReportOn] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState("Alle");
  const [isHarmNoteOn, setIsHarmNoteOn] = useState(false);
  const harmReports = useSelector((state) => state.harmReports.data);
  const harmNotes = useSelector((state) => state.harmNotes.data);
  const preserveState = useSelector((state) => state.webviewer.preserveState);
  const selectedObserverAgs = useSelector(
    (state) => state.observer.selectedAgs
  );

  //update the switch state when the map is loaded
  useEffect(() => {
    if (!map) return;
    if (preserveState) {
      setIsHarmReportOn(preserveState.harmReport?.flag);
      setSelectedDropdown(preserveState.harmReport?.option);
      setIsHarmNoteOn(preserveState.harmNote);
    }
  }, [map, preserveState]);

  // ---------------------
  // show | hide harm reports on map
  useEffect(() => {
    if (!map) return;
    if (!harmReports) return;

    harmReportsLayer.getSource().clear();

    if (isHarmReportOn) {
      // harmReportsLayer.getSource().clear();
      harmReports.forEach((feature) => {
        let coordinate = [feature.location.lng, feature.location.lat];
        let id = feature._id;

        let reports = new OlFeature({
          geometry: new OlPoint(fromLonLat(coordinate)),
          name: "harm reports",
        });

        reports.setId(id);

        if (selectedDropdown === "" || selectedDropdown === "Alle") {
          harmReportsLayer.getSource().addFeature(reports);
        } else if (selectedDropdown === feature.harmType[0]) {
          harmReportsLayer.getSource().addFeature(reports);
        }
      });
    }
  }, [map, harmReports, isHarmReportOn, selectedDropdown]);

  // ---------------------
  // show | hide harm notes on map
  // useEffect(() => {
  //   if (!map) return;
  //   if (!harmNotes) return;

  //   harmNotesLayer.getSource().clear();

  //   if (isHarmNoteOn) {
  //     harmNotes.forEach((feature) => {
  //       let coordinate = [feature.location.lng, feature.location.lat];
  //       let id = feature._id;

  //       let notes = new OlFeature({
  //         geometry: new OlPoint(fromLonLat(coordinate)),
  //         name: "harm notes",
  //       });

  //       notes.setId(id);

  //       harmNotesLayer.getSource().addFeature(notes);
  //     });
  //   }
  // }, [map, harmNotes, isHarmNoteOn]);

  useEffect(() => {
    if (!map) return;

    dispatch(actions.harmReports(ags));
    map.addLayer(harmReportsLayer);

    // dispatch(actions.harmNotes());
    // map.addLayer(harmNotesLayer);
  }, [map, ags, dispatch]);

  return (
    <div>
      <div className="py-8 px-3.5 mb-5 box-border block text-spekterWhite bg-spekterDarkThree shadow-container rounded-md">
        <div
          className="flex justify-between cursor-pointer hover:text-blue-500"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="font-bold text-lg mr-2.5">Starkregen Melder</div>
          <div className="mt-1">{<FaChevronDown />}</div>
        </div>

        {expanded && (
          <div className="p-1.5 sm:p-2.5 mt-3">
            {/* HarmReport switch */}
            <div className="flex py-1">
              <SwitchIcon
                value={isHarmReportOn}
                onCheck={() => {
                  setIsHarmReportOn((prev) => !prev);
                  dispatch(
                    actions.updateWebviewerStates({
                      harmReport: {
                        flag: !isHarmReportOn,
                        option: selectedDropdown,
                      },
                    })
                  );
                }}
              />
              <div className="mt-2 sm:ml-2 text-[13px] sm:text-sm">
                Schadensmeldungen anzeigen
              </div>
            </div>

            <div className="pt-1 pb-4">
              <select
                // defaultValue="Alle"
                onChange={(e) => {
                  setSelectedDropdown();
                  dispatch(
                    actions.updateWebviewerStates({
                      harmReport: {
                        flag: isHarmReportOn,
                        option: e.target.value,
                      },
                    })
                  );
                }}
                id="harmtypes"
                className="bg-spekterDarkThree border border-gray-300 text-spekterWhite text-xs sm:text-sm rounded-lg block w-full p-2.5"
              >
                <option selected={selectedDropdown === "Alle"} value="Alle">
                  Alle
                </option>
                <option
                  selected={selectedDropdown === "Undichtheit Dach"}
                  value="Undichtheit Dach"
                >
                  (1) Undichtheit Dach
                </option>
                <option
                  selected={selectedDropdown === "Überschwemmung Gewässer"}
                  value="Überschwemmung Gewässer"
                >
                  (2) Überschwemmung Gewässer
                </option>
                <option
                  selected={selectedDropdown === "Grundwasser"}
                  value="Grundwasser"
                >
                  (3) Grundwasser
                </option>
                <option
                  selected={selectedDropdown === "Rückstau Kanal"}
                  value="Rückstau Kanal"
                >
                  (4) Rückstau Kanal
                </option>
                <option
                  selected={selectedDropdown === "Überstau Kanal"}
                  value="Überstau Kanal"
                >
                  (5) Überstau Kanal
                </option>
                <option
                  selected={selectedDropdown === "Überflutung Oberfläche"}
                  value="Überflutung Oberfläche"
                >
                  (6) Überflutung Oberfläche
                </option>
              </select>
            </div>

            {/* HarmNote switch */}
            {/* <div className="flex py-1">
              <SwitchIcon
                value={isHarmNoteOn}
                onCheck={() => {
                  setIsHarmNoteOn((prev) => !prev);
                  dispatch(
                    actions.updateWebviewerStates({
                      harmNote: !isHarmNoteOn,
                    })
                  );
                }}
              />
              <div className="mt-2 ml-2 text-sm">Hinweismeldungen anzeigen</div>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default MelderSwitch;
