import { useSelector } from "react-redux";
import useAgs from "./useAgs";
import useObserver from "./useObserver";

// pick the valid ags based on below condition
// if user is observer, then pick the selected ags
// else pick the first ags from the ags list
const usePodAgs = () => {
  let ags;
  const agsList = useAgs();
  const selectedAgs = useSelector((state) => state.observer.selectedAgs);
  const isObserver = useObserver();

  if (isObserver) {
    ags = selectedAgs || agsList[0];
  } else {
    ags = agsList[0];
  }
  return ags;
};

export default usePodAgs;
