import React from "react";

const ZoomControls = ({ map }) => {
  const zoomIn = () => {
    if (map) {
      const currentZoom = map.getZoom();
      map.setZoom(currentZoom + 1);
    }
  };

  const zoomOut = () => {
    if (map) {
      const currentZoom = map.getZoom();
      map.setZoom(currentZoom - 1);
    }
  };
  return (
    <div className="flex flex-col justify-center font-bold rounded bg-spekterDarkOne text-spekterWhite">
      <button
        className="w-10 h-10 text-3xl duration-300 rounded hover:bg-gray-600"
        onClick={() => zoomIn()}
      >
        +
      </button>
      <div className="w-4/5 m-auto border border-white border-solid"></div>
      <button
        className="w-10 h-10 text-3xl duration-300 rounded hover:bg-gray-600"
        onClick={() => zoomOut()}
      >
        -
      </button>
    </div>
  );
};

export default ZoomControls;
