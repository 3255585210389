import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import EditModal from "./Modals/EditModal/EditModal";
import * as actions from "../../../redux/actions/index";
import Spinner from "../../UI/Spinner/Spinner";
import Pagination from "../../UI/Pagination/Pagination";
import AddSubscriberModal from "./Modals/AddSubscriber";
import ConfirmDelete from "./Modals/ConfirmDelete";
import EditSubscriberModal from "./Modals/EditCustomSubs";
import { useTranslation } from "react-i18next";
import EyeButton from "../../UI/EyeButton";
import ShowHideText from "../../UI/ShowHideText";
import SearchBar from "../../UI/SearchBar/SearchBar";
import { debounce } from "../../../shared/Utility";

const RescueGroup = ({ ags }) => {
  //language package
  const { t, i18n } = useTranslation("userManagement");
  const userType = "Rescue"; //case sensitive
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [subscriberId, setSubscriberId] = useState();
  const [subscriber, setSubscriber] = useState({});
  const [subscriberData, setSubscriberData] = useState({});
  const [searchText, setSearchText] = useState("");
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const allCustomSubs = useSelector(
    (state) => state.allCustomSubscribers?.data?.result
  );

  // pagination
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const retrivedTotalPage = useSelector(
    (state) => state.allCustomSubscribers.data?.totalPage
  );
  const paginate = (number) => setCurrentPage(number);

  const users = useSelector((state) => state.users.data?.result);
  // const isLoading = useSelector((state) => state.users.isLoading);
  const isLoading = useSelector(
    (state) => state.allCustomSubscribers.isLoading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!retrivedTotalPage) return;
    setTotalPage(retrivedTotalPage);
  }, [retrivedTotalPage]);

  useEffect(() => {
    dispatch(actions.ownRescueSubscriberAction());
    dispatch(
      actions.allCustomSubscribersAction(ags, {
        page: currentPage,
        limit: 10,
        sort: -1,
        searchTerm: searchText,
      })
    );
    // unmount the state
    return () => dispatch(actions.usersReset());
  }, [currentPage, searchText]);

  const searchInput = (event) => {
    setCurrentPage(1);
    setSearchText(event.target.value);
  };
  const debouncedChangeHandler = useCallback(debounce(searchInput, 700), []);

  const modalToggleHandler = (id, data) => {
    setSubscriberId(id);
    setShowModal(!showModal);
    setSubscriberData(data);
  };

  const editModalToggleHandler = (subscriber) => {
    setSubscriber(subscriber);
    setShowEditModal(!showEditModal);
  };
  const deleteModalToggleHandler = (subscriber) => {
    setSubscriber(subscriber);
    setShowDeleteModal(!showDeleteModal);
  };

  const modalClosedHandler = () => {
    setShowModal(false);
  };
  return (
    <div className="flex flex-col w-full mt-3 md:mt-12">
      {showModal ? (
        <EditModal
          ags={ags}
          open={showModal}
          closed={modalClosedHandler}
          subscriberId={subscriberId}
          subscriberData={subscriberData}
          currentPage={currentPage}
          userType={userType}
          searchText={searchText}
        />
      ) : null}
      {showAddModal ? (
        <AddSubscriberModal
          ags={ags}
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          paginate={paginate}
        />
      ) : null}
      {showEditModal ? (
        <EditSubscriberModal
          ags={ags}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          subscriber={subscriber}
          paginate={paginate}
        />
      ) : null}
      {showDeleteModal ? (
        <ConfirmDelete
          ags={ags}
          setShowDeleteModal={setShowDeleteModal}
          id={subscriber._id}
          paginate={paginate}
        />
      ) : null}

      <div className="flex flex-col justify-between gap-5 md:flex-row">
        <SearchBar
          placeholder={t("search")}
          searchInput={debouncedChangeHandler}
        />

        <button
          onClick={() => setShowAddModal(true)}
          className="bg-spekterGreenSuccess flex justify-center items-center gap-4 font-[700] text-[15pz] text-[#fff] dark:text-[#000] lg:text-[14px] rounded-5 h-[37px] max-h-[5vh] shadow-btn px-1.5 lg:px-4"
        >
          <img
            className="w-4 md:w-5"
            src={`/images/${isThemeDark ? "plus" : "plus_light"}.svg`}
            alt="plus"
          />
          {t("customSubscriberButton")}
        </button>
      </div>

      {/* <div className="grid grid-rows-2 bb"> */}
      <div className="h-[calc(80vh-260px)] 2xl:h-[calc(85vh-225px)] pl-1 overflow-y-auto md:scrollbarLight md:dark:scrollbar">
        {/* custom subscribers big screens */}
        <div className="hidden rounded-xl lg:block">
          <table className="w-full h-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="sticky top-0 z-20 text-xs text-gray-700 uppercase dark:text-gray-400 bg-[#ECECEC] dark:bg-[#1D1E21]">
              <tr>
                <th scope="col" className="px-12 py-3">
                  {t("name")}
                </th>
                <th scope="col" className="hidden px-12 py-3 lg:block">
                  <div className="flex items-center gap-10">
                    <span> {t("mail")}</span>
                    <EyeButton
                      open={showEmail}
                      onEyeClick={() => setShowEmail((prev) => !prev)}
                    />
                  </div>
                </th>
                <th scope="col" className="px-12 py-3">
                  <div className="flex items-center gap-10">
                    <span> {t("mobile")}</span>
                    <EyeButton
                      open={showPhoneNumber}
                      onEyeClick={() => setShowPhoneNumber((prev) => !prev)}
                    />
                  </div>
                </th>
                <th scope="col" className="px-12 py-3 text-center">
                  {t("options")}
                </th>
              </tr>
            </thead>
            <tbody className="relative h-full">
              {isLoading ? (
                <tr>
                  <div className="absolute z-50 flex items-end justify-center w-full h-56">
                    <Spinner />
                  </div>
                </tr>
              ) : allCustomSubs && allCustomSubs.length > 0 ? (
                allCustomSubs.map((subscriber, index) => {
                  return (
                    <tr
                      className={`${
                        isThemeDark
                          ? "odd:bg-[#282A2E] w-[100%]"
                          : "odd:bg-[#e3e4e6] w-[100%]"
                      }`}
                      key={subscriber._id}
                    >
                      <td className="px-12 py-4 rounded-l-10 w-[25%] ">
                        {subscriber.name ? subscriber.name : ""}
                      </td>
                      <td className="py-4 px-4 hidden lg:block lg:relative top-[5px] w-full ">
                        {/* {`${
                          subscriber.email
                            ? subscriber.email.split("@")[0].slice(0, -2) + "**"
                            : ""
                        }@********`} */}
                        <ShowHideText
                          text={subscriber.email || ""}
                          show={showEmail}
                        />
                      </td>
                      <td className="px-4 py-4 w-[18%] ">
                        {/* {subscriber.mobile &&
                          subscriber.mobile.slice(0, 6) + "*****"} */}
                        <ShowHideText
                          text={subscriber.mobile || ""}
                          show={showPhoneNumber}
                        />
                      </td>
                      <td className="px-12 py-4 cursor-pointer rounded-r-10 w-[27%] ">
                        <div className="flex justify-center">
                          <button
                            className="w-20 h-8 px-2 py-0 mx-1 text-xs font-bold no-underline border-0 rounded bg-spekterGreenSuccess dark:bg-spekterGreenSuccess text-spekterWhite dark:text-spekterDarkOne"
                            onClick={() => editModalToggleHandler(subscriber)}
                          >
                            {" "}
                            {t("edit")}
                          </button>
                          <button
                            className="w-20 h-8 px-2 py-0 mx-1 text-xs font-bold no-underline border-0 rounded bg-spekterRed text-spekterWhite dark:text-spekterDarkOne"
                            onClick={() => deleteModalToggleHandler(subscriber)}
                          >
                            {" "}
                            {t("delete")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="relative">
                  <div className="absolute mx-auto mt-40 transform -translate-x-1/2 bg-gray-400 rounded-sm left-[45%] max-w-max">
                    <h5 className="p-10 text-white">
                      {t("noIndividualUserFound")}
                    </h5>
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* custom subscribers mobile */}
        <div className="mb-2 overflow-x-auto rounded-xl lg:hidden">
          <div className="flex justify-end gap-3 pt-3 text-black dark:text-gray-400">
            <div className="flex items-center gap-2">
              <span>{t("mail")}:</span>
              <EyeButton
                open={showEmail}
                onEyeClick={() => setShowEmail((prev) => !prev)}
              />
            </div>
            <div className="flex items-center gap-2">
              <span>Phone:</span>
              <EyeButton
                open={showPhoneNumber}
                onEyeClick={() => setShowPhoneNumber((prev) => !prev)}
              />
            </div>
          </div>
          {isLoading ? (
            <div className="absolute flex items-center justify-center w-full h-[90%]">
              <Spinner />
            </div>
          ) : allCustomSubs && allCustomSubs.length > 0 ? (
            allCustomSubs.map((subscriber, index) => (
              <div
                key={subscriber._id}
                className="flex gap-2 flex-col even:bg-spekterLightGray dark:even:bg-spekterDarkOne text-gray-700 dark:text-gray-400 rounded-[18px] mt-3 p-3 text-[15px]"
              >
                <div className="flex flex-col w-full gap-2 overflow-x-auto ">
                  <div className="flex gap-3">
                    <div className="font-bold whitespace-nowrap">
                      {t("name")}:
                    </div>
                    <div>{subscriber.name ? subscriber.name : ""}</div>
                  </div>
                  <div className="flex gap-2">
                    <div className="font-bold whitespace-nowrap">
                      {t("mail")}:
                    </div>
                    <div>
                      {/* {`${
                      subscriber.email
                        ? subscriber.email.split("@")[0].slice(0, -2) + "**"
                        : ""
                    }@********`} */}
                      <ShowHideText
                        text={subscriber.email || ""}
                        show={showEmail}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2.5">
                    <div className="font-bold whitespace-nowrap">Phone:</div>
                    <div>
                      {/* {subscriber.mobile &&
                        subscriber.mobile.slice(0, 6) + "*****"} */}
                      <ShowHideText
                        text={subscriber.mobile || ""}
                        show={showPhoneNumber}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-end w-full">
                  <div className="flex items-end justify-center gap-2">
                    <button
                      // className="h-8 px-2 py-0 text-xs font-bold no-underline border-0 rounded w-28 bg-spekterGreenLight dark:bg-spekterGreen text-spekterDarkOne"
                      className="w-24 h-8 px-1 py-0 text-xs font-bold no-underline rounded bg-spekterGreenSuccess text-spekterWhite dark:text-spekterDarkOne"
                      onClick={() => editModalToggleHandler(subscriber)}
                    >
                      {t("edit")}
                    </button>
                    <button
                      // className="h-8 px-2 py-0 text-xs font-bold no-underline border-0 rounded w-28 bg-spekterRed text-spekterWhite"
                      className="w-24 h-8 px-1 py-0 text-xs font-bold no-underline border-0 rounded bg-spekterRed text-spekterWhite dark:text-gray-900"
                      onClick={() => deleteModalToggleHandler(subscriber)}
                    >
                      {t("delete")}
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="mx-auto mt-40 bg-gray-400 rounded-sm max-w-max">
              <h5 className="p-10 text-white">No individual user found</h5>
            </div>
          )}
        </div>
        {/* custom subscribers */}

        <div className="flex justify-center sticky top-[100vh]">
          {allCustomSubs && allCustomSubs.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              paginate={paginate}
            />
          )}
        </div>
      </div>

      {/* <div className="relative row-span-1 mb-3 overflow-auto rounded">
          <p className="pt-4 font-semibold text-gray-800 dark:text-gray-100">User Group Rescue</p>

          <div className="overflow-hidden rounded-xl">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-12 py-3">Name</th>
                  <th scope="col" className="hidden px-12 py-3 lg:block">Email</th>
                  <th scope="col" className="px-12 py-3">Phone</th>
                  <th scope="col" className="px-12 py-3 text-center">Options</th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ? (
                    <div className="flex h-full my-5 items-center justify-center relative left-[27vw]">
                      <Spinner />
                    </div>
                  ) : users ?
                    users.map((user) => {
                      return (
                        <tr className={`${isThemeDark ? "" : "odd:text-gray-400"} odd:bg-[#282A2E] `}>
                          <td className="px-12 py-4 rounded-l-10">{user.subscriber ? user.subscriber.name : "Empty"}</td>
                          <td className="py-4 px-12 hidden lg:block lg:relative top-[5px]">{user.subscriber ? user.subscriber.email : "Empty"}</td>
                          <td className="px-12 py-4">{user.subscriber ? user.subscriber.mobile : "Empty"}</td>
                          <td className="px-12 py-4 cursor-pointer rounded-r-10">
                            <div
                              onClick={() => modalToggleHandler(user.subscriber._id, user.subscriber)}
                              className="flex justify-center"
                            >
                              <button
                                className="w-20 h-8 px-2 py-0 mx-1 text-xs font-bold no-underline border-0 rounded bg-spekterGreen text-spekterWhite dark:text-gray-900"
                              >
                                Edit
                              </button>
                            </div>
                          </td>

                        </tr>
                      );
                    })
                    : null
                }
              </tbody>
            </table>
          </div>
          <div className="flex justify-center mt-4">
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              paginate={paginate} />
          </div>
        </div> */}
      {/* </div> */}
      {/* <ToastContainer transition={Slide} /> */}
    </div>
  );
};

export default RescueGroup;
