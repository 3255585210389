import { CgClose, CgArrowLeft } from "react-icons/cg";

function DatenschutzModal({
  setShowDatenschutz,
  setMainModal,
  setShowAboutModal,
}) {
  return (
    <div
      className="absolute sm:relative bottom-0 m-auto headerShadowLight dark:text-white text-black max-h-[80vh]
            w-[830px] max-w-[100%] sm:max-w-[70%] dark:bg-black/70 bg-white/70 backdrop-blur-[61.5px] rounded-t-2xl sm:rounded-[18px] p-8"
    >
      <section className="text-center">
        <div
          onClick={() => {
            setShowDatenschutz(false);
            setShowAboutModal(false);
            setMainModal(true);
          }}
          className="absolute cursor-pointer top-8 right-8"
        >
          <CgClose size={24} />
        </div>
        <div
          onClick={() => {
            setShowDatenschutz(false);
            setMainModal(true);
          }}
          className="absolute flex gap-1 cursor-pointer top-8 left-8"
        >
          <CgArrowLeft size={25} />
          <label className="hidden cursor-pointer md:block">Zurück</label>
        </div>
        <p className="text-xl md:text-[28px] pb-2 font-[700]">
          Datenschutzerklärung
        </p>
      </section>

      <section className="max-h-[60vh] overflow-y-auto text-base break-words scrollbarLight dark:scrollbar">
        {/* <div className="max-h-[90%] text-base overflow-y-auto break-words scrollbarLight dark:scrollbar"> */}
        <p>
          Wir freuen uns sehr &uuml;ber Ihr Interesse an unserem Unternehmen.
          Datenschutz hat einen besonders hohen Stellenwert f&uuml;r die
          Gesch&auml;ftsleitung der Spekter GmbH. Eine Nutzung der
          Internetseiten der Spekter GmbH ist grunds&auml;tzlich ohne jede
          Angabe personenbezogener Daten m&ouml;glich. Sofern eine betroffene
          Person besondere Services unseres Unternehmens &uuml;ber unsere
          Internetseite in Anspruch nehmen m&ouml;chte, k&ouml;nnte jedoch eine
          Verarbeitung personenbezogener Daten erforderlich werden. Ist die
          Verarbeitung personenbezogener Daten erforderlich und besteht f&uuml;r
          eine solche Verarbeitung keine gesetzliche Grundlage, holen wir
          generell eine Einwilligung der betroffenen Person ein.
        </p>
        <p>
          Die Verarbeitung personenbezogener Daten, beispielsweise des Namens,
          der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen
          Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung
          und in &Uuml;bereinstimmung mit den f&uuml;r die Spekter GmbH
          geltenden landesspezifischen Datenschutzbestimmungen. Mittels dieser
          Datenschutzerkl&auml;rung m&ouml;chte unser Unternehmen die
          &Ouml;ffentlichkeit &uuml;ber Art, Umfang und Zweck der von uns
          erhobenen, genutzten und verarbeiteten personenbezogenen Daten
          informieren. Ferner werden betroffene Personen mittels dieser
          Datenschutzerkl&auml;rung &uuml;ber die ihnen zustehenden Rechte
          aufgekl&auml;rt.
        </p>
        <p>
          Die Spekter GmbH hat als f&uuml;r die Verarbeitung Verantwortlicher
          zahlreiche technische und organisatorische Ma&szlig;nahmen umgesetzt,
          um einen m&ouml;glichst l&uuml;ckenlosen Schutz der &uuml;ber diese
          Internetseite verarbeiteten personenbezogenen Daten sicherzustellen.
          Dennoch k&ouml;nnen Internetbasierte Daten&uuml;bertragungen
          grunds&auml;tzlich Sicherheitsl&uuml;cken aufweisen, sodass ein
          absoluter Schutz nicht gew&auml;hrleistet werden kann. Aus diesem
          Grund steht es jeder betroffenen Person frei, personenbezogene Daten
          auch auf alternativen Wegen, beispielsweise telefonisch, an uns zu
          &uuml;bermitteln.
        </p>
        <h4>1. Begriffsbestimmungen</h4>
        <p>
          Die Datenschutzerkl&auml;rung der Spekter GmbH beruht auf den
          Begrifflichkeiten, die durch den Europ&auml;ischen Richtlinien- und
          Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DS-GVO)
          verwendet wurden. Unsere Datenschutzerkl&auml;rung soll sowohl
          f&uuml;r die &Ouml;ffentlichkeit als auch f&uuml;r unsere Kunden und
          Gesch&auml;ftspartner einfach lesbar und verst&auml;ndlich sein. Um
          dies zu gew&auml;hrleisten, m&ouml;chten wir vorab die verwendeten
          Begrifflichkeiten erl&auml;utern.
        </p>
        <p>
          Wir verwenden in dieser Datenschutzerkl&auml;rung unter anderem die
          folgenden Begriffe:
        </p>
        <ul>
          <li>
            <h4>a) &nbsp; &nbsp;personenbezogene Daten</h4>
            <p>
              Personenbezogene Daten sind alle Informationen, die sich auf eine
              identifizierte oder identifizierbare nat&uuml;rliche Person (im
              Folgenden &bdquo;betroffene Person&ldquo;) beziehen. Als
              identifizierbar wird eine nat&uuml;rliche Person angesehen, die
              direkt oder indirekt, insbesondere mittels Zuordnung zu einer
              Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu
              einer Online-Kennung oder zu einem oder mehreren besonderen
              Merkmalen, die Ausdruck der physischen, physiologischen,
              genetischen, psychischen, wirtschaftlichen, kulturellen oder
              sozialen Identit&auml;t dieser nat&uuml;rlichen Person sind,
              identifiziert werden kann.
            </p>
          </li>
          <li>
            <br></br>
            <h4>b) &nbsp; &nbsp;betroffene Person</h4>
            <p>
              Betroffene Person ist jede identifizierte oder identifizierbare
              nat&uuml;rliche Person, deren personenbezogene Daten von dem
              f&uuml;r die Verarbeitung Verantwortlichen verarbeitet werden.
            </p>
          </li>
          <li>
            <br></br>
            <h4>c) &nbsp; &nbsp;Verarbeitung</h4>
            <p>
              Verarbeitung ist jeder mit oder ohne Hilfe automatisierter
              Verfahren ausgef&uuml;hrte Vorgang oder jede solche Vorgangsreihe
              im Zusammenhang mit personenbezogenen Daten wie das Erheben, das
              Erfassen, die Organisation, das Ordnen, die Speicherung, die
              Anpassung oder Ver&auml;nderung, das Auslesen, das Abfragen, die
              Verwendung, die Offenlegung durch &Uuml;bermittlung, Verbreitung
              oder eine andere Form der Bereitstellung, den Abgleich oder die
              Verkn&uuml;pfung, die Einschr&auml;nkung, das L&ouml;schen oder
              die Vernichtung.
            </p>
          </li>
          <li>
            <br></br>
            <h4>d) &nbsp; &nbsp;Einschr&auml;nkung der Verarbeitung</h4>
            <p>
              Einschr&auml;nkung der Verarbeitung ist die Markierung
              gespeicherter personenbezogener Daten mit dem Ziel, ihre
              k&uuml;nftige Verarbeitung einzuschr&auml;nken.
            </p>
          </li>
          <li>
            <br></br>
            <h4>e) &nbsp; &nbsp;Profiling</h4>
            <p>
              Profiling ist jede Art der automatisierten Verarbeitung
              personenbezogener Daten, die darin besteht, dass diese
              personenbezogenen Daten verwendet werden, um bestimmte
              pers&ouml;nliche Aspekte, die sich auf eine nat&uuml;rliche Person
              beziehen, zu bewerten, insbesondere, um Aspekte bez&uuml;glich
              Arbeitsleistung, wirtschaftlicher Lage, Gesundheit,
              pers&ouml;nlicher Vorlieben, Interessen, Zuverl&auml;ssigkeit,
              Verhalten, Aufenthaltsort oder Ortswechsel dieser nat&uuml;rlichen
              Person zu analysieren oder vorherzusagen.
            </p>
          </li>
          <li>
            <br></br>
            <h4>f) &nbsp; &nbsp; Pseudonymisierung</h4>
            <p>
              Pseudonymisierung ist die Verarbeitung personenbezogener Daten in
              einer Weise, auf welche die personenbezogenen Daten ohne
              Hinzuziehung zus&auml;tzlicher Informationen nicht mehr einer
              spezifischen betroffenen Person zugeordnet werden k&ouml;nnen,
              sofern diese zus&auml;tzlichen Informationen gesondert aufbewahrt
              werden und technischen und organisatorischen Ma&szlig;nahmen
              unterliegen, die gew&auml;hrleisten, dass die personenbezogenen
              Daten nicht einer identifizierten oder identifizierbaren
              nat&uuml;rlichen Person zugewiesen werden.
            </p>
          </li>
          <li>
            <br></br>
            <h4>
              g) &nbsp; &nbsp;Verantwortlicher oder f&uuml;r die Verarbeitung
              Verantwortlicher
            </h4>
            <p>
              Verantwortlicher oder f&uuml;r die Verarbeitung Verantwortlicher
              ist die nat&uuml;rliche oder juristische Person, Beh&ouml;rde,
              Einrichtung oder andere Stelle, die allein oder gemeinsam mit
              anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von
              personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel
              dieser Verarbeitung durch das Unionsrecht oder das Recht der
              Mitgliedstaaten vorgegeben, so kann der Verantwortliche
              beziehungsweise k&ouml;nnen die bestimmten Kriterien seiner
              Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
              vorgesehen werden.
            </p>
          </li>
          <li>
            <br></br>
            <h4>h) &nbsp; &nbsp;Auftragsverarbeiter</h4>
            <p>
              Auftragsverarbeiter ist eine nat&uuml;rliche oder juristische
              Person, Beh&ouml;rde, Einrichtung oder andere Stelle, die
              personenbezogene Daten im Auftrag des Verantwortlichen
              verarbeitet.
            </p>
          </li>
          <li>
            <br></br>
            <h4>i) &nbsp; &nbsp; &nbsp;Empf&auml;nger</h4>
            <p>
              Empf&auml;nger ist eine nat&uuml;rliche oder juristische Person,
              Beh&ouml;rde, Einrichtung oder andere Stelle, der personenbezogene
              Daten offengelegt werden, unabh&auml;ngig davon, ob es sich bei
              ihr um einen Dritten handelt oder nicht. Beh&ouml;rden, die im
              Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht
              oder dem Recht der Mitgliedstaaten m&ouml;glicherweise
              personenbezogene Daten erhalten, gelten jedoch nicht als
              Empf&auml;nger.
            </p>
          </li>
          <li>
            <br></br>
            <h4>j) &nbsp; &nbsp; &nbsp;Dritter</h4>
            <p>
              Dritter ist eine nat&uuml;rliche oder juristische Person,
              Beh&ouml;rde, Einrichtung oder andere Stelle au&szlig;er der
              betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter
              und den Personen, die unter der unmittelbaren Verantwortung des
              Verantwortlichen oder des Auftragsverarbeiters befugt sind, die
              personenbezogenen Daten zu verarbeiten.
            </p>
          </li>
          <li>
            <br></br>
            <h4>k) &nbsp; &nbsp;Einwilligung</h4>
            <p>
              Einwilligung ist jede von der betroffenen Person freiwillig
              f&uuml;r den bestimmten Fall in informierter Weise und
              unmissverst&auml;ndlich abgegebene Willensbekundung in Form einer
              Erkl&auml;rung oder einer sonstigen eindeutigen best&auml;tigenden
              Handlung, mit der die betroffene Person zu verstehen gibt, dass
              sie mit der Verarbeitung der sie betreffenden personenbezogenen
              Daten einverstanden ist.
            </p>
          </li>
        </ul>
        <h4>
          2. Name und Anschrift des f&uuml;r die Verarbeitung Verantwortlichen
        </h4>
        <p>
          Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger
          in den Mitgliedstaaten der Europ&auml;ischen Union geltenden
          Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem
          Charakter ist die:
        </p>
        <p>Spekter GmbH</p>
        <p>Werner-Heisenberg-Stra&szlig;e, 9</p>
        <p>91074 Herzogenaurach</p>
        <p>Bayern</p>
        <p>Tel.: 091327538980</p>
        <p>E-Mail: info@spekter.de</p>
        <p>Website: www.starkregenalarm.de</p>
        <h4>3. Cookies</h4>
        <p>
          Die Internetseiten der Spekter GmbH verwenden Cookies. Cookies sind
          Textdateien, welche &uuml;ber einen Internetbrowser auf einem
          Computersystem abgelegt und gespeichert werden.
        </p>
        <p>
          Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies
          enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine
          eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge,
          durch welche Internetseiten und Server dem konkreten Internetbrowser
          zugeordnet werden k&ouml;nnen, in dem das Cookie gespeichert wurde.
          Dies erm&ouml;glicht es den besuchten Internetseiten und Servern, den
          individuellen Browser der betroffenen Person von anderen
          Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein
          bestimmter Internetbrowser kann &uuml;ber die eindeutige Cookie-ID
          wiedererkannt und identifiziert werden.
        </p>
        <p>
          Durch den Einsatz von Cookies kann die Spekter GmbH den Nutzern dieser
          Internetseite nutzerfreundlichere Services bereitstellen, die ohne die
          Cookie-Setzung nicht m&ouml;glich w&auml;ren.
        </p>
        <p>
          Mittels eines Cookies k&ouml;nnen die Informationen und Angebote auf
          unserer Internetseite im Sinne des Benutzers optimiert werden. Cookies
          erm&ouml;glichen uns, wie bereits erw&auml;hnt, die Benutzer unserer
          Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es,
          den Nutzern die Verwendung unserer Internetseite zu erleichtern. Der
          Benutzer einer Internetseite, die Cookies verwendet, muss
          beispielsweise nicht bei jedem Besuch der Internetseite erneut seine
          Zugangsdaten eingeben, weil dies von der Internetseite und dem auf dem
          Computersystem des Benutzers abgelegten Cookie &uuml;bernommen wird.
          Ein weiteres Beispiel ist das Cookie eines Warenkorbes im Online-Shop.
          Der Online-Shop merkt sich die Artikel, die ein Kunde in den
          virtuellen Warenkorb gelegt hat, &uuml;ber ein Cookie.
        </p>
        <p>
          Die betroffene Person kann die Setzung von Cookies durch unsere
          Internetseite jederzeit mittels einer entsprechenden Einstellung des
          genutzten Internetbrowsers verhindern und damit der Setzung von
          Cookies dauerhaft widersprechen. Ferner k&ouml;nnen bereits gesetzte
          Cookies jederzeit &uuml;ber einen Internetbrowser oder andere
          Softwareprogramme gel&ouml;scht werden. Dies ist in allen
          g&auml;ngigen Internetbrowsern m&ouml;glich. Deaktiviert die
          betroffene Person die Setzung von Cookies in dem genutzten
          Internetbrowser, sind unter Umst&auml;nden nicht alle Funktionen
          unserer Internetseite vollumf&auml;nglich nutzbar.
        </p>
        <h4>4. Erfassung von allgemeinen Daten und Informationen</h4>
        <p>
          Die Internetseite der Spekter GmbH erfasst mit jedem Aufruf der
          Internetseite durch eine betroffene Person oder ein automatisiertes
          System eine Reihe von allgemeinen Daten und Informationen. Diese
          allgemeinen Daten und Informationen werden in den Logfiles des Servers
          gespeichert. Erfasst werden k&ouml;nnen die (1) verwendeten
          Browsertypen und Versionen, (2) das vom zugreifenden System verwendete
          Betriebssystem, (3) die Internetseite, von welcher ein zugreifendes
          System auf unsere Internetseite gelangt (sogenannte Referrer), (4) die
          Unterwebseiten, welche &uuml;ber ein zugreifendes System auf unserer
          Internetseite angesteuert werden, (5) das Datum und die Uhrzeit eines
          Zugriffs auf die Internetseite, (6) eine Internet-Protokoll-Adresse
          (IP-Adresse), (7) der Internet-Service-Provider des zugreifenden
          Systems und (8) sonstige &auml;hnliche Daten und Informationen, die
          der Gefahrenabwehr im Falle von Angriffen auf unsere
          informationstechnologischen Systeme dienen.
        </p>
        <p>
          Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die
          Spekter GmbH keine R&uuml;ckschl&uuml;sse auf die betroffene Person.
          Diese Informationen werden vielmehr ben&ouml;tigt, um (1) die Inhalte
          unserer Internetseite korrekt auszuliefern, (2) die Inhalte unserer
          Internetseite sowie die Werbung f&uuml;r diese zu optimieren, (3) die
          dauerhafte Funktionsf&auml;higkeit unserer informationstechnologischen
          Systeme und der Technik unserer Internetseite zu gew&auml;hrleisten
          sowie (4) um Strafverfolgungsbeh&ouml;rden im Falle eines
          Cyberangriffes die zur Strafverfolgung notwendigen Informationen
          bereitzustellen. Diese anonym erhobenen Daten und Informationen werden
          durch die Spekter GmbH daher einerseits statistisch und ferner mit dem
          Ziel ausgewertet, den Datenschutz und die Datensicherheit in unserem
          Unternehmen zu erh&ouml;hen, um letztlich ein optimales Schutzniveau
          f&uuml;r die von uns verarbeiteten personenbezogenen Daten
          sicherzustellen. Die anonymen Daten der Server-Logfiles werden
          getrennt von allen durch eine betroffene Person angegebenen
          personenbezogenen Daten gespeichert.
        </p>
        <h4>5. Registrierung auf unserer Internetseite</h4>
        <p>
          Die betroffene Person hat die M&ouml;glichkeit, sich auf der
          Internetseite des f&uuml;r die Verarbeitung Verantwortlichen unter
          Angabe von personenbezogenen Daten zu registrieren. Welche
          personenbezogenen Daten dabei an den f&uuml;r die Verarbeitung
          Verantwortlichen &uuml;bermittelt werden, ergibt sich aus der
          jeweiligen Eingabemaske, die f&uuml;r die Registrierung verwendet
          wird. Die von der betroffenen Person eingegebenen personenbezogenen
          Daten werden ausschlie&szlig;lich f&uuml;r die interne Verwendung bei
          dem f&uuml;r die Verarbeitung Verantwortlichen und f&uuml;r eigene
          Zwecke erhoben und gespeichert. Der f&uuml;r die Verarbeitung
          Verantwortliche kann die Weitergabe an einen oder mehrere
          Auftragsverarbeiter, beispielsweise einen Paketdienstleister,
          veranlassen, der die personenbezogenen Daten ebenfalls
          ausschlie&szlig;lich f&uuml;r eine interne Verwendung, die dem
          f&uuml;r die Verarbeitung Verantwortlichen zuzurechnen ist, nutzt.
        </p>
        <p>
          Durch eine Registrierung auf der Internetseite des f&uuml;r die
          Verarbeitung Verantwortlichen wird ferner die vom
          Internet-Service-Provider (ISP) der betroffenen Person vergebene
          IP-Adresse, das Datum sowie die Uhrzeit der Registrierung gespeichert.
          Die Speicherung dieser Daten erfolgt vor dem Hintergrund, dass nur so
          der Missbrauch unserer Dienste verhindert werden kann, und diese Daten
          im Bedarfsfall erm&ouml;glichen, begangene Straftaten
          aufzukl&auml;ren. Insofern ist die Speicherung dieser Daten zur
          Absicherung des f&uuml;r die Verarbeitung Verantwortlichen
          erforderlich. Eine Weitergabe dieser Daten an Dritte erfolgt
          grunds&auml;tzlich nicht, sofern keine gesetzliche Pflicht zur
          Weitergabe besteht oder die Weitergabe der Strafverfolgung dient.
        </p>
        <p>
          Die Registrierung der betroffenen Person unter freiwilliger Angabe
          personenbezogener Daten dient dem f&uuml;r die Verarbeitung
          Verantwortlichen dazu, der betroffenen Person Inhalte oder Leistungen
          anzubieten, die aufgrund der Natur der Sache nur registrierten
          Benutzern angeboten werden k&ouml;nnen. Registrierten Personen steht
          die M&ouml;glichkeit frei, die bei der Registrierung angegebenen
          personenbezogenen Daten jederzeit abzu&auml;ndern oder
          vollst&auml;ndig aus dem Datenbestand des f&uuml;r die Verarbeitung
          Verantwortlichen l&ouml;schen zu lassen.
        </p>
        <p>
          Der f&uuml;r die Verarbeitung Verantwortliche erteilt jeder
          betroffenen Person jederzeit auf Anfrage Auskunft dar&uuml;ber, welche
          personenbezogenen Daten &uuml;ber die betroffene Person gespeichert
          sind. Ferner berichtigt oder l&ouml;scht der f&uuml;r die Verarbeitung
          Verantwortliche personenbezogene Daten auf Wunsch oder Hinweis der
          betroffenen Person, soweit dem keine gesetzlichen
          Aufbewahrungspflichten entgegenstehen. Die Gesamtheit der Mitarbeiter
          des f&uuml;r die Verarbeitung Verantwortlichen stehen der betroffenen
          Person in diesem Zusammenhang als Ansprechpartner zur Verf&uuml;gung.
        </p>
        <h4>6. Abonnement unseres Newsletters</h4>
        <p>
          Auf der Internetseite der Spekter GmbH wird den Benutzern die
          M&ouml;glichkeit einger&auml;umt, den Newsletter unseres Unternehmens
          zu abonnieren. Welche personenbezogenen Daten bei der Bestellung des
          Newsletters an den f&uuml;r die Verarbeitung Verantwortlichen
          &uuml;bermittelt werden, ergibt sich aus der hierzu verwendeten
          Eingabemaske.
        </p>
        <p>
          Die Spekter GmbH informiert ihre Kunden und Gesch&auml;ftspartner in
          regelm&auml;&szlig;igen Abst&auml;nden im Wege eines Newsletters
          &uuml;ber Angebote des Unternehmens. Der Newsletter unseres
          Unternehmens kann von der betroffenen Person grunds&auml;tzlich nur
          dann empfangen werden, wenn (1) die betroffene Person &uuml;ber eine
          g&uuml;ltige E-Mail-Adresse verf&uuml;gt und (2) die betroffene Person
          sich f&uuml;r den Newsletterversand registriert. An die von einer
          betroffenen Person erstmalig f&uuml;r den Newsletterversand
          eingetragene E-Mail-Adresse wird aus rechtlichen Gr&uuml;nden eine
          Best&auml;tigungsmail im Double-Opt-In-Verfahren versendet. Diese
          Best&auml;tigungsmail dient der &Uuml;berpr&uuml;fung, ob der Inhaber
          der E-Mail-Adresse als betroffene Person den Empfang des Newsletters
          autorisiert hat.
        </p>
        <p>
          Bei der Anmeldung zum Newsletter speichern wir ferner die vom
          Internet-Service-Provider (ISP) vergebene IP-Adresse des von der
          betroffenen Person zum Zeitpunkt der Anmeldung verwendeten
          Computersystems sowie das Datum und die Uhrzeit der Anmeldung. Die
          Erhebung dieser Daten ist erforderlich, um den(m&ouml;glichen)
          Missbrauch der E-Mail-Adresse einer betroffenen Person zu einem
          sp&auml;teren Zeitpunkt nachvollziehen zu k&ouml;nnen und dient
          deshalb der rechtlichen Absicherung des f&uuml;r die Verarbeitung
          Verantwortlichen.
        </p>
        <p>
          Die im Rahmen einer Anmeldung zum Newsletter erhobenen
          personenbezogenen Daten werden ausschlie&szlig;lich zum Versand
          unseres Newsletters verwendet. Ferner k&ouml;nnten Abonnenten des
          Newsletters per E-Mail informiert werden, sofern dies f&uuml;r den
          Betrieb des Newsletter-Dienstes oder eine diesbez&uuml;gliche
          Registrierung erforderlich ist, wie dies im Falle von &Auml;nderungen
          am Newsletterangebot oder bei der Ver&auml;nderung der technischen
          Gegebenheiten der Fall sein k&ouml;nnte. Es erfolgt keine Weitergabe
          der im Rahmen des Newsletter-Dienstes erhobenen personenbezogenen
          Daten an Dritte. Das Abonnement unseres Newsletters kann durch die
          betroffene Person jederzeit gek&uuml;ndigt werden. Die Einwilligung in
          die Speicherung personenbezogener Daten, die die betroffene Person uns
          f&uuml;r den Newsletterversand erteilt hat, kann jederzeit widerrufen
          werden. Zum Zwecke des Widerrufs der Einwilligung findet sich in jedem
          Newsletter ein entsprechender Link. Ferner besteht die
          M&ouml;glichkeit, sich jederzeit auch direkt auf der Internetseite des
          f&uuml;r die Verarbeitung Verantwortlichen vom Newsletterversand
          abzumelden oder dies dem f&uuml;r die Verarbeitung Verantwortlichen
          auf andere Weise mitzuteilen.
        </p>
        <h4>7. Newsletter-Tracking</h4>
        <p>
          Die Newsletter der Spekter GmbH enthalten sogenannte Z&auml;hlpixel.
          Ein Z&auml;hlpixel ist eine Miniaturgrafik, die in solche E-Mails
          eingebettet wird, welche im HTML-Format versendet werden, um eine
          Logdatei-Aufzeichnung und eine Logdatei-Analyse zu erm&ouml;glichen.
          Dadurch kann eine statistische Auswertung des Erfolges oder
          Misserfolges von Online-Marketing-Kampagnen durchgef&uuml;hrt werden.
          Anhand des eingebetteten Z&auml;hlpixels kann die Spekter GmbH
          erkennen, ob und wann eine E-Mail von einer betroffenen Person
          ge&ouml;ffnet wurde und welche in der E-Mail befindlichen Links von
          der betroffenen Person aufgerufen wurden.
        </p>
        <p>
          Solche &uuml;ber die in den Newslettern enthaltenen Z&auml;hlpixel
          erhobenen personenbezogenen Daten, werden von dem f&uuml;r die
          Verarbeitung Verantwortlichen gespeichert und ausgewertet, um den
          Newsletterversand zu optimieren und den Inhalt zuk&uuml;nftiger
          Newsletter noch besser den Interessen der betroffenen Person
          anzupassen. Diese personenbezogenen Daten werden nicht an Dritte
          weitergegeben. Betroffene Personen sind jederzeit berechtigt, die
          diesbez&uuml;gliche gesonderte, &uuml;ber das Double-Opt-In-Verfahren
          abgegebene Einwilligungserkl&auml;rung zu widerrufen. Nach einem
          Widerruf werden diese personenbezogenen Daten von dem f&uuml;r die
          Verarbeitung Verantwortlichen gel&ouml;scht. Eine Abmeldung vom Erhalt
          des Newsletters deutet die Spekter GmbH automatisch als Widerruf.
        </p>
        <h4>8. Kontaktm&ouml;glichkeit &uuml;ber die Internetseite</h4>
        <p>
          Die Internetseite der Spekter GmbH enth&auml;lt aufgrund von
          gesetzlichen Vorschriften Angaben, die eine schnelle elektronische
          Kontaktaufnahme zu unserem Unternehmen sowie eine unmittelbare
          Kommunikation mit uns erm&ouml;glichen, was ebenfalls eine allgemeine
          Adresse der sogenannten elektronischen Post (E-Mail-Adresse) umfasst.
          Sofern eine betroffene Person per E-Mail oder &uuml;ber ein
          Kontaktformular den Kontakt mit dem f&uuml;r die Verarbeitung
          Verantwortlichen aufnimmt, werden die von der betroffenen Person
          &uuml;bermittelten personenbezogenen Daten automatisch gespeichert.
          Solche auf freiwilliger Basis von einer betroffenen Person an den
          f&uuml;r die Verarbeitung Verantwortlichen &uuml;bermittelten
          personenbezogenen Daten werden f&uuml;r Zwecke der Bearbeitung oder
          der Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt
          keine Weitergabe dieser personenbezogenen Daten an Dritte.
        </p>
        <h4>
          9. Routinem&auml;&szlig;ige L&ouml;schung und Sperrung von
          personenbezogenen Daten
        </h4>
        <p>
          Der f&uuml;r die Verarbeitung Verantwortliche verarbeitet und
          speichert personenbezogene Daten der betroffenen Person nur f&uuml;r
          den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich
          ist oder sofern dies durch den Europ&auml;ischen Richtlinien- und
          Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder
          Vorschriften, welchen der f&uuml;r die Verarbeitung Verantwortliche
          unterliegt, vorgesehen wurde.
        </p>
        <p>
          Entf&auml;llt der Speicherungszweck oder l&auml;uft eine vom
          Europ&auml;ischen Richtlinien- und Verordnungsgeber oder einem anderen
          zust&auml;ndigen Gesetzgeber vorgeschriebene Speicherfrist ab, werden
          die personenbezogenen Daten routinem&auml;&szlig;ig und entsprechend
          den gesetzlichen Vorschriften gesperrt oder gel&ouml;scht.
        </p>
        <h4>10. Rechte der betroffenen Person</h4>
        <ul>
          <li>
            <br></br>
            <h4>a) &nbsp; &nbsp;Recht auf Best&auml;tigung</h4>
            <p>
              Jede betroffene Person hat das vom Europ&auml;ischen Richtlinien-
              und Verordnungsgeber einger&auml;umte Recht, von dem f&uuml;r die
              Verarbeitung Verantwortlichen eine Best&auml;tigung dar&uuml;ber
              zu verlangen, ob sie betreffende personenbezogene Daten
              verarbeitet werden. M&ouml;chte eine betroffene Person dieses
              Best&auml;tigungsrecht in Anspruch nehmen, kann sie sich hierzu
              jederzeit an einen Mitarbeiter des f&uuml;r die Verarbeitung
              Verantwortlichen wenden.
            </p>
          </li>
          <li>
            <br></br>
            <h4>b) &nbsp; &nbsp;Recht auf Auskunft</h4>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europ&auml;ischen Richtlinien- und
              Verordnungsgeber gew&auml;hrte Recht, jederzeit von dem f&uuml;r
              die Verarbeitung Verantwortlichen unentgeltliche Auskunft
              &uuml;ber die zu seiner Person gespeicherten personenbezogenen
              Daten und eine Kopie dieser Auskunft zu erhalten. Ferner hat der
              Europ&auml;ische Richtlinien- und Verordnungsgeber der betroffenen
              Person Auskunft &uuml;ber folgende Informationen zugestanden:
            </p>
            <ul>
              <li>die Verarbeitungszwecke</li>
              <li>
                die Kategorien personenbezogener Daten, die verarbeitet werden
              </li>
              <li>
                die Empf&auml;nger oder Kategorien von Empf&auml;ngern,
                gegen&uuml;ber denen die personenbezogenen Daten offengelegt
                worden sind oder noch offengelegt werden, insbesondere bei
                Empf&auml;ngern in Drittl&auml;ndern oder bei internationalen
                Organisationen
              </li>
              <li>
                falls m&ouml;glich die geplante Dauer, f&uuml;r die die
                personenbezogenen Daten gespeichert werden, oder, falls dies
                nicht m&ouml;glich ist, die Kriterien f&uuml;r die Festlegung
                dieser Dauer
              </li>
              <li>
                das Bestehen eines Rechts auf Berichtigung oder L&ouml;schung
                der sie betreffenden personenbezogenen Daten oder auf
                Einschr&auml;nkung der Verarbeitung durch den Verantwortlichen
                oder eines Widerspruchsrechts gegen diese Verarbeitung
              </li>
              <li>
                das Bestehen eines Beschwerderechts bei einer
                Aufsichtsbeh&ouml;rde
              </li>
              <li>
                wenn die personenbezogenen Daten nicht bei der betroffenen
                Person erhoben werden: Alle verf&uuml;gbaren Informationen
                &uuml;ber die Herkunft der Daten
              </li>
              <li>
                das Bestehen einer automatisierten Entscheidungsfindung
                einschlie&szlig;lich Profiling gem&auml;&szlig; Artikel 22 Abs.1
                und 4 DS-GVO und &mdash; zumindest in diesen F&auml;llen &mdash;
                aussagekr&auml;ftige Informationen &uuml;ber die involvierte
                Logik sowie die Tragweite und die angestrebten Auswirkungen
                einer derartigen Verarbeitung f&uuml;r die betroffene Person
              </li>
              Ferner steht der betroffenen Person ein Auskunftsrecht
              dar&uuml;ber zu, ob personenbezogene Daten an ein Drittland oder
              an eine internationale Organisation &uuml;bermittelt wurden.
              Sofern dies der Fall ist, so steht der betroffenen Person im
              &Uuml;brigen das Recht zu, Auskunft &uuml;ber die geeigneten
              Garantien im Zusammenhang mit der &Uuml;bermittlung zu erhalten.
            </ul>
            <p></p>
            <p>
              M&ouml;chte eine betroffene Person dieses Auskunftsrecht in
              Anspruch nehmen, kann sie sich hierzu jederzeit an einen
              Mitarbeiter des f&uuml;r die Verarbeitung Verantwortlichen wenden.
            </p>
          </li>
          <li>
            <br></br>
            <h4>c) &nbsp; &nbsp;Recht auf Berichtigung</h4>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europ&auml;ischen Richtlinien- und
              Verordnungsgeber gew&auml;hrte Recht, die unverz&uuml;gliche
              Berichtigung sie betreffender unrichtiger personenbezogener Daten
              zu verlangen. Ferner steht der betroffenen Person das Recht zu,
              unter Ber&uuml;cksichtigung der Zwecke der Verarbeitung, die
              Vervollst&auml;ndigung unvollst&auml;ndiger personenbezogener
              Daten &mdash; auch mittels einer erg&auml;nzenden Erkl&auml;rung
              &mdash; zu verlangen.
            </p>
            <p>
              M&ouml;chte eine betroffene Person dieses Berichtigungsrecht in
              Anspruch nehmen, kann sie sich hierzu jederzeit an einen
              Mitarbeiter des f&uuml;r die Verarbeitung Verantwortlichen wenden.
            </p>
          </li>
          <li>
            <br></br>
            <h4>
              d) &nbsp; &nbsp;Recht auf L&ouml;schung (Recht auf Vergessen
              werden)
            </h4>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europ&auml;ischen Richtlinien- und
              Verordnungsgeber gew&auml;hrte Recht, von dem Verantwortlichen zu
              verlangen, dass die sie betreffenden personenbezogenen Daten
              unverz&uuml;glich gel&ouml;scht werden, sofern einer der folgenden
              Gr&uuml;nde zutrifft und soweit die Verarbeitung nicht
              erforderlich ist:
            </p>
            <ul>
              <li>
                Die personenbezogenen Daten wurden f&uuml;r solche Zwecke
                erhoben oder auf sonstige Weise verarbeitet, f&uuml;r welche sie
                nicht mehr notwendig sind.
              </li>
              <li>
                Die betroffene Person widerruft ihre Einwilligung, auf die sich
                die Verarbeitung gem&auml;&szlig; Art. 6 Abs. 1 Buchstabe a
                DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO st&uuml;tzte, und
                es fehlt an einer anderweitigen Rechtsgrundlage f&uuml;r die
                Verarbeitung.
              </li>
              <li>
                Die betroffene Person legt gem&auml;&szlig; Art. 21 Abs. 1
                DS-GVO Widerspruch gegen die Verarbeitung ein, und es liegen
                keine vorrangigen berechtigten Gr&uuml;nde f&uuml;r die
                Verarbeitung vor, oder die betroffene Person legt
                gem&auml;&szlig; Art. 21 Abs. 2 DS-GVO Widerspruch gegen die
                Verarbeitung ein.
              </li>
              <li>
                Die personenbezogenen Daten wurden unrechtm&auml;&szlig;ig
                verarbeitet.
              </li>
              <li>
                Die L&ouml;schung der personenbezogenen Daten ist zur
                Erf&uuml;llung einer rechtlichen Verpflichtung nach dem
                Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem
                der Verantwortliche unterliegt.
              </li>
              <li>
                Die personenbezogenen Daten wurden in Bezug auf angebotene
                Dienste der Informationsgesellschaft gem&auml;&szlig; Art. 8
                Abs. 1 DS-GVO erhoben.
              </li>
              Sofern einer der oben genannten Gr&uuml;nde zutrifft und eine
              betroffene Person die L&ouml;schung von personenbezogenen Daten,
              die bei der Spekter GmbH gespeichert sind, veranlassen
              m&ouml;chte, kann sie sich hierzu jederzeit an einen Mitarbeiter
              des f&uuml;r die Verarbeitung Verantwortlichen wenden. Der
              Mitarbeiter der Spekter GmbH wird veranlassen, dass dem
              L&ouml;schverlangen unverz&uuml;glich nachgekommen wird.
            </ul>
            <p></p>
            <p>
              Wurden die personenbezogenen Daten von der Spekter GmbH
              &ouml;ffentlich gemacht und ist unser Unternehmen als
              Verantwortlicher gem&auml;&szlig; Art. 17 Abs. 1 DS-GVO zur
              L&ouml;schung der personenbezogenen Daten verpflichtet, so trifft
              die Spekter GmbH unter Ber&uuml;cksichtigung der verf&uuml;gbaren
              Technologie und der Implementierungskosten angemessene
              Ma&szlig;nahmen, auch technischer Art, um andere f&uuml;r die
              Datenverarbeitung Verantwortliche, welche die
              ver&ouml;ffentlichten personenbezogenen Daten verarbeiten,
              dar&uuml;ber in Kenntnis zu setzen, dass die betroffene Person von
              diesen anderen f&uuml;r die Datenverarbeitung Verantwortlichen die
              L&ouml;schung s&auml;mtlicher Links zu diesen personenbezogenen
              Daten oder von Kopien oder Replikationen dieser personenbezogenen
              Daten verlangt hat, soweit die Verarbeitung nicht erforderlich
              ist. Der Mitarbeiter der Spekter GmbH wird im Einzelfall das
              Notwendige veranlassen.
            </p>
          </li>
          <li>
            <br></br>
            <h4>
              e) &nbsp; &nbsp;Recht auf Einschr&auml;nkung der Verarbeitung
            </h4>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europ&auml;ischen Richtlinien- und
              Verordnungsgeber gew&auml;hrte Recht, von dem Verantwortlichen die
              Einschr&auml;nkung der Verarbeitung zu verlangen, wenn eine der
              folgenden Voraussetzungen gegeben ist:
            </p>
            <ul>
              <li>
                Die Richtigkeit der personenbezogenen Daten wird von der
                betroffenen Person bestritten, und zwar f&uuml;r eine Dauer, die
                es dem Verantwortlichen erm&ouml;glicht, die Richtigkeit der
                personenbezogenen Daten zu &uuml;berpr&uuml;fen.
              </li>
              <li>
                Die Verarbeitung ist unrechtm&auml;&szlig;ig, die betroffene
                Person lehnt die L&ouml;schung der personenbezogenen Daten ab
                und verlangt stattdessen die Einschr&auml;nkung der Nutzung der
                personenbezogenen Daten.
              </li>
              <li>
                Der Verantwortliche ben&ouml;tigt die personenbezogenen Daten
                f&uuml;r die Zwecke der Verarbeitung nicht l&auml;nger, die
                betroffene Person ben&ouml;tigt sie jedoch zur Geltendmachung,
                Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.
              </li>
              <li>
                Die betroffene Person hat Widerspruch gegen die Verarbeitung
                gem. Art. 21 Abs. 1 DS-GVO eingelegt und es steht noch nicht
                fest, ob die berechtigten Gr&uuml;nde des Verantwortlichen
                gegen&uuml;ber denen der betroffenen Person &uuml;berwiegen.
              </li>
              Sofern eine der oben genannten Voraussetzungen gegeben ist und
              eine betroffene Person die Einschr&auml;nkung von
              personenbezogenen Daten, die bei der Spekter GmbH gespeichert
              sind, verlangen m&ouml;chte, kann sie sich hierzu jederzeit an
              einen Mitarbeiter des f&uuml;r die Verarbeitung Verantwortlichen
              wenden. Der Mitarbeiter der Spekter GmbH wird die
              Einschr&auml;nkung der Verarbeitung veranlassen.
            </ul>
            <p></p>
          </li>
          <li>
            <br></br>
            <h4>f) &nbsp; &nbsp; Recht auf Daten&uuml;bertragbarkeit</h4>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europ&auml;ischen Richtlinien- und
              Verordnungsgeber gew&auml;hrte Recht, die sie betreffenden
              personenbezogenen Daten, welche durch die betroffene Person einem
              Verantwortlichen bereitgestellt wurden, in einem strukturierten,
              g&auml;ngigen und maschinenlesbaren Format zu erhalten. Sie hat
              au&szlig;erdem das Recht, diese Daten einem anderen
              Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem
              die personenbezogenen Daten bereitgestellt wurden, zu
              &uuml;bermitteln, sofern die Verarbeitung auf der Einwilligung
              gem&auml;&szlig; Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs.
              2 Buchstabe a DS-GVO oder auf einem Vertrag gem&auml;&szlig; Art.
              6 Abs. 1 Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe
              automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht
              f&uuml;r die Wahrnehmung einer Aufgabe erforderlich ist, die im
              &ouml;ffentlichen Interesse liegt oder in Aus&uuml;bung
              &ouml;ffentlicher Gewalt erfolgt, welche dem Verantwortlichen
              &uuml;bertragen wurde.
            </p>
            <p>
              Ferner hat die betroffene Person bei der Aus&uuml;bung ihres
              Rechts auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20 Abs.
              1 DS-GVO das Recht, zu erwirken, dass die personenbezogenen Daten
              direkt von einem Verantwortlichen an einen anderen
              Verantwortlichen &uuml;bermittelt werden, soweit dies technisch
              machbar ist und sofern hiervon nicht die Rechte und Freiheiten
              anderer Personen beeintr&auml;chtigt werden.
            </p>
            <p>
              Zur Geltendmachung des Rechts auf Daten&uuml;bertragbarkeit kann
              sich die betroffene Person jederzeit an einen Mitarbeiter der
              Spekter GmbH wenden.
            </p>
          </li>
          <li>
            <br></br>
            <h4>g) &nbsp; &nbsp;Recht auf Widerspruch</h4>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europ&auml;ischen Richtlinien- und
              Verordnungsgeber gew&auml;hrte Recht, aus Gr&uuml;nden, die sich
              aus ihrer besonderen Situation ergeben, jederzeit gegen die
              Verarbeitung sie betreffender personenbezogener Daten, die
              aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DS-GVO erfolgt,
              Widerspruch einzulegen. Dies gilt auch f&uuml;r ein auf diese
              Bestimmungen gest&uuml;tztes Profiling.
            </p>
            <p>
              Die Spekter GmbH verarbeitet die personenbezogenen Daten im Falle
              des Widerspruchs nicht mehr, es sei denn, wir k&ouml;nnen
              zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung
              nachweisen, die den Interessen, Rechten und Freiheiten der
              betroffenen Person &uuml;berwiegen, oder die Verarbeitung dient
              der Geltendmachung, Aus&uuml;bung oder Verteidigung von
              Rechtsanspr&uuml;chen.
            </p>
            <p>
              Verarbeitet die Spekter GmbH personenbezogene Daten, um
              Direktwerbung zu betreiben, so hat die betroffene Person das
              Recht, jederzeit Widerspruch gegen die Verarbeitung der
              personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen.
              Dies gilt auch f&uuml;r das Profiling, soweit es mit solcher
              Direktwerbung in Verbindung steht. Widerspricht die betroffene
              Person gegen&uuml;ber der Spekter GmbH der Verarbeitung f&uuml;r
              Zwecke der Direktwerbung, so wird die Spekter GmbH die
              personenbezogenen Daten nicht mehr f&uuml;r diese Zwecke
              verarbeiten.
            </p>
            <p>
              Zudem hat die betroffene Person das Recht, aus Gr&uuml;nden, die
              sich aus ihrer besonderen Situation ergeben, gegen die sie
              betreffende Verarbeitung personenbezogener Daten, die bei der
              Spekter GmbH zu wissenschaftlichen oder historischen
              Forschungszwecken oder zu statistischen Zwecken gem&auml;&szlig;
              Art. 89 Abs. 1 DS-GVO erfolgen, Widerspruch einzulegen, es sei
              denn, eine solche Verarbeitung ist zur Erf&uuml;llung einer im
              &ouml;ffentlichen Interesse liegenden Aufgabe erforderlich.
            </p>
            <p>
              Zur Aus&uuml;bung des Rechts auf Widerspruch kann sich die
              betroffene Person direkt jeden Mitarbeiter der Spekter GmbH oder
              einen anderen Mitarbeiter wenden. Der betroffenen Person steht es
              ferner frei, im Zusammenhang mit der Nutzung von Diensten der
              Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG,
              ihr Widerspruchsrecht mittels automatisierter Verfahren
              auszu&uuml;ben, bei denen technische Spezifikationen verwendet
              werden.
            </p>
          </li>
          <li>
            <br></br>
            <h4>
              h) &nbsp; &nbsp;Automatisierte Entscheidungen im Einzelfall
              einschlie&szlig;lich Profiling
            </h4>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europ&auml;ischen Richtlinien- und
              Verordnungsgeber gew&auml;hrte Recht, nicht einer
              ausschlie&szlig;lich auf einer automatisierten Verarbeitung
              &mdash; einschlie&szlig;lich Profiling &mdash; beruhenden
              Entscheidung unterworfen zu werden, die ihr gegen&uuml;ber
              rechtliche Wirkung entfaltet oder sie in &auml;hnlicher Weise
              erheblich beeintr&auml;chtigt, sofern die Entscheidung (1) nicht
              f&uuml;r den Abschluss oder die Erf&uuml;llung eines Vertrags
              zwischen der betroffenen Person und dem Verantwortlichen
              erforderlich ist, oder (2) aufgrund von Rechtsvorschriften der
              Union oder der Mitgliedstaaten, denen der Verantwortliche
              unterliegt, zul&auml;ssig ist und diese Rechtsvorschriften
              angemessene Ma&szlig;nahmen zur Wahrung der Rechte und Freiheiten
              sowie der berechtigten Interessen der betroffenen Person enthalten
              oder (3) mit ausdr&uuml;cklicher Einwilligung der betroffenen
              Person erfolgt.
            </p>
            <p>
              Ist die Entscheidung (1) f&uuml;r den Abschluss oder die
              Erf&uuml;llung eines Vertrags zwischen der betroffenen Person und
              dem Verantwortlichen erforderlich oder (2) erfolgt sie mit
              ausdr&uuml;cklicher Einwilligung der betroffenen Person, trifft
              die Spekter GmbH angemessene Ma&szlig;nahmen, um die Rechte und
              Freiheiten sowie die berechtigten Interessen der betroffenen
              Person zu wahren, wozu mindestens das Recht auf Erwirkung des
              Eingreifens einer Person seitens des Verantwortlichen, auf
              Darlegung des eigenen Standpunkts und auf Anfechtung der
              Entscheidung geh&ouml;rt.
            </p>
            <p>
              M&ouml;chte die betroffene Person Rechte mit Bezug auf
              automatisierte Entscheidungen geltend machen, kann sie sich hierzu
              jederzeit an einen Mitarbeiter des f&uuml;r die Verarbeitung
              Verantwortlichen wenden.
            </p>
          </li>
          <li>
            <br></br>
            <h4>
              i) &nbsp; &nbsp; &nbsp;Recht auf Widerruf einer
              datenschutzrechtlichen Einwilligung
            </h4>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene
              Person hat das vom Europ&auml;ischen Richtlinien- und
              Verordnungsgeber gew&auml;hrte Recht, eine Einwilligung zur
              Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
            </p>
            <p>
              M&ouml;chte die betroffene Person ihr Recht auf Widerruf einer
              Einwilligung geltend machen, kann sie sich hierzu jederzeit an
              einen Mitarbeiter des f&uuml;r die Verarbeitung Verantwortlichen
              wenden.
            </p>
          </li>
        </ul>
        <h4>
          11. Datenschutzbestimmungen zu Einsatz und Verwendung von Google
          Analytics (mit Anonymisierungsfunktion)
        </h4>
        <p>
          Der f&uuml;r die Verarbeitung Verantwortliche hat auf dieser
          Internetseite die Komponente Google Analytics (mit
          Anonymisierungsfunktion) integriert. Google Analytics ist ein
          Web-Analyse-Dienst. Web-Analyse ist die Erhebung, Sammlung und
          Auswertung von Daten &uuml;ber das Verhalten von Besuchern von
          Internetseiten. Ein Web-Analyse-Dienst erfasst unter anderem Daten
          dar&uuml;ber, von welcher Internetseite eine betroffene Person auf
          eine Internetseite gekommen ist (sogenannte Referrer), auf welche
          Unterseiten der Internetseite zugegriffen oder wie oft und f&uuml;r
          welche Verweildauer eine Unterseite betrachtet wurde. Eine Web-Analyse
          wird &uuml;berwiegend zur Optimierung einer Internetseite und zur
          Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.
        </p>
        <p>
          Betreibergesellschaft der Google-Analytics-Komponente ist die Google
          Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
        </p>
        <p>
          Der f&uuml;r die Verarbeitung Verantwortliche verwendet f&uuml;r die
          Web-Analyse &uuml;ber Google Analytics den Zusatz
          &quot;_gat._anonymizeIp&quot;. Mittels dieses Zusatzes wird die
          IP-Adresse des Internetanschlusses der betroffenen Person von Google
          gek&uuml;rzt und anonymisiert, wenn der Zugriff auf unsere
          Internetseiten aus einem Mitgliedstaat der Europ&auml;ischen Union
          oder aus einem anderen Vertragsstaat des Abkommens &uuml;ber den
          Europ&auml;ischen Wirtschaftsraum erfolgt.
        </p>
        <p>
          Der Zweck der Google-Analytics-Komponente ist die Analyse der
          Besucherstr&ouml;me auf unserer Internetseite. Google nutzt die
          gewonnenen Daten und Informationen unter anderem dazu, die Nutzung
          unserer Internetseite auszuwerten, um f&uuml;r uns Online-Reports,
          welche die Aktivit&auml;ten auf unseren Internetseiten aufzeigen,
          zusammenzustellen, und um weitere mit der Nutzung unserer
          Internetseite in Verbindung stehende Dienstleistungen zu erbringen.
        </p>
        <p>
          Google Analytics setzt ein Cookie auf dem informationstechnologischen
          System der betroffenen Person. Was Cookies sind, wurde oben bereits
          erl&auml;utert. Mit Setzung des Cookies wird Google eine Analyse der
          Benutzung unserer Internetseite erm&ouml;glicht. Durch jeden Aufruf
          einer der Einzelseiten dieser Internetseite, die durch den f&uuml;r
          die Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
          Google-Analytics-Komponente integriert wurde, wird der Internetbrowser
          auf dem informationstechnologischen System der betroffenen Person
          automatisch durch die jeweilige Google-Analytics-Komponente
          veranlasst, Daten zum Zwecke der Online-Analyse an Google zu
          &uuml;bermitteln. Im Rahmen dieses technischen Verfahrens erh&auml;lt
          Google Kenntnis &uuml;ber personenbezogene Daten, wie der IP-Adresse
          der betroffenen Person, die Google unter anderem dazu dienen, die
          Herkunft der Besucher und Klicks nachzuvollziehen und in der Folge
          Provisionsabrechnungen zu erm&ouml;glichen.
        </p>
        <p>
          Mittels des Cookies werden personenbezogene Informationen,
          beispielsweise die Zugriffszeit, der Ort, von welchem ein Zugriff
          ausging und die H&auml;ufigkeit der Besuche unserer Internetseite
          durch die betroffene Person, gespeichert. Bei jedem Besuch unserer
          Internetseiten werden diese personenbezogenen Daten,
          einschlie&szlig;lich der IP-Adresse des von der betroffenen Person
          genutzten Internetanschlusses, an Google in den Vereinigten Staaten
          von Amerika &uuml;bertragen. Diese personenbezogenen Daten werden
          durch Google in den Vereinigten Staaten von Amerika gespeichert.
          Google gibt diese &uuml;ber das technische Verfahren erhobenen
          personenbezogenen Daten unter Umst&auml;nden an Dritte weiter.
        </p>
        <p>
          Die betroffene Person kann die Setzung von Cookies durch unsere
          Internetseite, wie oben bereits dargestellt, jederzeit mittels einer
          entsprechenden Einstellung des genutzten Internetbrowsers verhindern
          und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche
          Einstellung des genutzten Internetbrowsers w&uuml;rde auch verhindern,
          dass Google ein Cookie auf dem informationstechnologischen System der
          betroffenen Person setzt. Zudem kann ein von Google Analytics bereits
          gesetzter Cookie jederzeit &uuml;ber den Internetbrowser oder andere
          Softwareprogramme gel&ouml;scht werden.
        </p>
        <p>
          Ferner besteht f&uuml;r die betroffene Person die M&ouml;glichkeit,
          einer Erfassung der durch Google Analytics erzeugten, auf eine Nutzung
          dieser Internetseite bezogenen Daten sowie der Verarbeitung dieser
          Daten durch Google zu widersprechen und eine solche zu verhindern.
          Hierzu muss die betroffene Person ein Browser-Add-On unter dem Link
          https://tools.google.com/dlpage/gaoptout herunterladen und
          installieren. Dieses Browser-Add-On teilt Google Analytics &uuml;ber
          JavaScript mit, dass keine Daten und Informationen zu den Besuchen von
          Internetseiten an Google Analytics &uuml;bermittelt werden
          d&uuml;rfen. Die Installation des Browser-Add-Ons wird von Google als
          Widerspruch gewertet. Wird das informationstechnologische System der
          betroffenen Person zu einem sp&auml;teren Zeitpunkt gel&ouml;scht,
          formatiert oder neu installiert, muss durch die betroffene Person eine
          erneute Installation des Browser-Add-Ons erfolgen, um Google Analytics
          zu deaktivieren. Sofern das Browser-Add-On durch die betroffene Person
          oder einer anderen Person, die ihrem Machtbereich zuzurechnen ist,
          deinstalliert oder deaktiviert wird, besteht die M&ouml;glichkeit der
          Neuinstallation oder der erneuten Aktivierung des Browser-Add-Ons.
        </p>
        <p>
          Weitere Informationen und die geltenden Datenschutzbestimmungen von
          Google k&ouml;nnen unter
          https://www.google.de/intl/de/policies/privacy/ und unter
          http://www.google.com/analytics/terms/de.html abgerufen werden. Google
          Analytics wird unter diesem Link
          https://www.google.com/intl/de_de/analytics/ genauer erl&auml;utert.
        </p>
        <h4>12. jsdeliver.net / jsdeliver.com/ cdn.jsdelivr.net</h4>
        <p>
          Auf unserer Seite wird Java-Script Code des Unternehmens Prospectone
          Sp. z o.o., ul. Krolweska 65A, 30-081, Krakow, Poland (nachfolgend:
          jsdeliver.net / jsdeliver.com / cdn.jsdelivr.net) nachgeladen. Der
          Java-Script Code gstatic.com ist ein Teil von Google zur Auswertung
          der Webseiten Statistik. Wenn Sie in Ihrem Browser Java-Script
          aktiviert und keinen Java-Script-Blocker installiert haben, wird Ihr
          Browser ggf. personenbezogene Daten an jsdeliver.net / jsdeliver.com
          übermitteln. Die Nutzung von cdn.jsdelivr.net erfolgt im Interesse
          technisch einwandfreien Darstellung unserer Online-Angebote. Dies
          stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
          DSGVO dar. Weitere Informationen hierzu finden Sie in der
          Datenschutzerklärung von jsdeliver.net / jsdeliver.com:
          https://www.jsdelivr.com/about. Um die Ausführung von Java-Script Code
          von jsdeliver.net / jsdeliver.com / cdn.jsdelivr.net / gstatic.com
          insgesamt zu verhindern, können Sie einen Java-Script-Blocker
          installieren (z.B. www.noscript.net).
        </p>
        <h4>13. Nutzung der Google-Api Schnittschnelle für Javaskripte</h4>
        <p>
          Diese Seite nutzt Java-Script Code des Unternehmens Google Inc., 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend:
          Google). Wir verwenden dabei Ajax und jQuery Technologien, wodurch
          eine Optimierung der Ladegeschwindigkeiten erzielt wird. Diesbezüglich
          werden Programmbibliotheken von Google aufgerufen. Dies stellt ein
          berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Es
          wird das CDN (Content delivery network) von Google verwendet. Sollten
          Sie jQuery vorher auf einer anderen Seite vom Google CDN gebraucht
          haben, wird Ihr Browser auf die im Cache abgelegte Kopie
          zurückgreifen. Ist dies nicht zutreffend, erfordert dies ein
          Herunterladen, wobei Daten von Ihrem Browser an Google gelangen.
          Weitere Informationen hierzu finden Sie in der Datenschutzerklärung
          von Google: http://www.google.de/intl/de/policies/privacy/ Um die
          Ausführung von Java-Script Code von Google insgesamt zu verhindern,
          können Sie einen Java-Script-Blocker installieren (z.B.
          www.noscript.net).
        </p>
        <h4>14. Google Maps</h4>
        <p>
          Diese Webseite nutzt Google Maps zur Darstellung interaktiver Karten
          und zur Routenplanung. Google Maps ist ein Kartendienst von der Google
          Inc., 1600 Amphitheatre Parkway, Mountain View, California 94043, USA.
          Durch die Nutzung von Google Maps können Informationen über die
          Benutzung dieser Webseite einschließlich Ihrer IP-Adresse und der im
          Rahmen der Routenplanerfunktion eingegebenen Start- und Zieladresse an
          Google in den USA übertragen werden. Wenn Sie eine Webseite unseres
          Internetauftritts aufrufen, die Google Maps enthält, baut Ihr Browser
          eine direkte Verbindung mit den Servern von Google auf. Der
          Karteninhalt wird von Google direkt an Ihren Browser übermittelt und
          von diesem in die Webseite eingebunden. Daher haben wir keinen
          Einfluss auf den Umfang der auf diese Weise von Google erhobenen
          Daten. Entsprechend unserem Kenntnisstand sind dies zumindest folgende
          Daten: Datum und Uhrzeit des Besuchs auf der betreffenden Webseite
          Internetadresse oder URL der aufgerufenen Webseite IP-Adresse Die
          Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote und an einer leichten
          Auffindbarkeit der von uns auf der Webseite angegebenen Orte. Dies
          stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
          DSGVO dar. Auf die weitere Verarbeitung und Nutzung der Daten durch
          Google haben wir keinen Einfluss und können daher hierfür keine
          Verantwortung übernehmen. Wenn Sie nicht möchten, dass Google über
          unseren Internetauftritt Daten über Sie erhebt, verarbeitet oder
          nutzt, können Sie in Ihrem Browsereinstellungen JavaScript
          deaktivieren. In diesem Fall können Sie die Kartenanzeige jedoch nicht
          nutzen. Zweck und Umfang der Datenerhebung und die weitere
          Verarbeitung und Nutzung der Daten durch Google sowie Ihre
          diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer
          Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Google:
          https://policies.google.com/privacy?hl=de Google verarbeitet Ihre
          personenbezogenen Daten in den USA und hat sich dem EU-US Privacy
          Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.
        </p>
        <h4>15. Rechtsgrundlage der Verarbeitung</h4>
        <p>
          Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage
          f&uuml;r Verarbeitungsvorg&auml;nge, bei denen wir eine Einwilligung
          f&uuml;r einen bestimmten Verarbeitungszweck einholen. Ist die
          Verarbeitung personenbezogener Daten zur Erf&uuml;llung eines
          Vertrags, dessen Vertragspartei die betroffene Person ist,
          erforderlich, wie dies beispielsweise bei Verarbeitungsvorg&auml;ngen
          der Fall ist, die f&uuml;r eine Lieferung von Waren oder die
          Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind,
          so beruht die Verarbeitung auf Art. 6 I lit. b DS-GVO. Gleiches gilt
          f&uuml;r solche Verarbeitungsvorg&auml;nge die zur Durchf&uuml;hrung
          vorvertraglicher Ma&szlig;nahmen erforderlich sind, etwa in
          F&auml;llen von Anfragen zur unseren Produkten oder Leistungen.
          Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch
          welche eine Verarbeitung von personenbezogenen Daten erforderlich
          wird, wie beispielsweise zur Erf&uuml;llung steuerlicher Pflichten, so
          basiert die Verarbeitung auf Art. 6 I lit. c DS-GVO. In seltenen
          F&auml;llen k&ouml;nnte die Verarbeitung von personenbezogenen Daten
          erforderlich werden, um lebenswichtige Interessen der betroffenen
          Person oder einer anderen nat&uuml;rlichen Person zu sch&uuml;tzen.
          Dies w&auml;re beispielsweise der Fall, wenn ein Besucher in unserem
          Betrieb verletzt werden w&uuml;rde und daraufhin sein Name, sein
          Alter, seine Krankenkassendaten oder sonstige lebenswichtige
          Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte
          weitergegeben werden m&uuml;ssten. Dann w&uuml;rde die Verarbeitung
          auf Art. 6 I lit. d DS-GVO beruhen. Letztlich k&ouml;nnten
          Verarbeitungsvorg&auml;nge auf Art. 6 I lit. f DS-GVO beruhen. Auf
          dieser Rechtsgrundlage basieren Verarbeitungsvorg&auml;nge, die von
          keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die
          Verarbeitung zur Wahrung eines berechtigten Interesses unseres
          Unternehmens oder eines Dritten erforderlich ist, sofern die
          Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht
          &uuml;berwiegen. Solche Verarbeitungsvorg&auml;nge sind uns
          insbesondere deshalb gestattet, weil sie durch den Europ&auml;ischen
          Gesetzgeber besonders erw&auml;hnt wurden. Er vertrat insoweit die
          Auffassung, dass ein berechtigtes Interesse anzunehmen sein
          k&ouml;nnte, wenn die betroffene Person ein Kunde des Verantwortlichen
          ist (Erw&auml;gungsgrund 47 Satz 2 DS-GVO).
        </p>
        <h4>
          16. Berechtigte Interessen an der Verarbeitung, die von dem
          Verantwortlichen oder einem Dritten verfolgt werden
        </h4>
        <p>
          Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit.
          f DS-GVO ist unser berechtigtes Interesse die Durchf&uuml;hrung
          unserer Gesch&auml;ftst&auml;tigkeit zugunsten des Wohlergehens all
          unserer Mitarbeiter und unserer Anteilseigner.
        </p>
        <h4>
          17. Dauer, f&uuml;r die die personenbezogenen Daten gespeichert werden
        </h4>
        <p>
          Das Kriterium f&uuml;r die Dauer der Speicherung von personenbezogenen
          Daten ist die jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf
          der Frist werden die entsprechenden Daten routinem&auml;&szlig;ig
          gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder
          Vertragsanbahnung erforderlich sind.
        </p>
        <h4>
          18. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der
          personenbezogenen Daten; Erforderlichkeit f&uuml;r den
          Vertragsabschluss; Verpflichtung der betroffenen Person, die
          personenbezogenen Daten bereitzustellen; m&ouml;gliche Folgen der
          Nichtbereitstellung
        </h4>
        <p>
          Wir kl&auml;ren Sie dar&uuml;ber auf, dass die Bereitstellung
          personenbezogener Daten zum Teil gesetzlich vorgeschrieben ist (z.B.
          Steuervorschriften) oder sich auch aus vertraglichen Regelungen (z.B.
          Angaben zum Vertragspartner) ergeben kann. Mitunter kann es zu einem
          Vertragsschluss erforderlich sein, dass eine betroffene Person uns
          personenbezogene Daten zur Verf&uuml;gung stellt, die in der Folge
          durch uns verarbeitet werden m&uuml;ssen. Die betroffene Person ist
          beispielsweise verpflichtet uns personenbezogene Daten
          bereitzustellen, wenn unser Unternehmen mit ihr einen Vertrag
          abschlie&szlig;t. Eine Nichtbereitstellung der personenbezogenen Daten
          h&auml;tte zur Folge, dass der Vertrag mit dem Betroffenen nicht
          geschlossen werden k&ouml;nnte. Vor einer Bereitstellung
          personenbezogener Daten durch den Betroffenen muss sich der Betroffene
          an einen unserer Mitarbeiter wenden. Unser Mitarbeiter kl&auml;rt den
          Betroffenen einzelfallbezogen dar&uuml;ber auf, ob die Bereitstellung
          der personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben
          oder f&uuml;r den Vertragsabschluss erforderlich ist, ob eine
          Verpflichtung besteht, die personenbezogenen Daten bereitzustellen,
          und welche Folgen die Nichtbereitstellung der personenbezogenen Daten
          h&auml;tte.
        </p>
        <h4>19. Bestehen einer automatisierten Entscheidungsfindung</h4>
        <p>
          Als verantwortungsbewusstes Unternehmen verzichten wir auf eine
          automatische Entscheidungsfindung oder ein Profiling.
        </p>
        <p>
          Diese Datenschutzerkl&auml;rung wurde durch den
          Datenschutzerkl&auml;rungs-Generator der DGD Deutsche Gesellschaft
          f&uuml;r Datenschutz GmbH, die als&nbsp;
          <a href="https://dg-datenschutz.de/datenschutz-dienstleistungen/externer-datenschutzbeauftragter/">
            Externer Datenschutzbeauftragter Frankfurt am Main
          </a>{" "}
          t&auml;tig ist, in Kooperation mit dem&nbsp;
          <a href="https://www.wbs-law.de/">
            K&ouml;lner Datenschutz Anwalt Christian Solmecke
          </a>{" "}
          erstellt.
        </p>
      </section>
    </div>
  );
}

export default DatenschutzModal;
