import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../redux/actions/index";
import * as toast from "../../../UI/Toaster";
import { useTranslation } from "react-i18next";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import SwitchIcon from "../../../UI/Switch/Switch";

const EditUserModal = ({
  ags,
  open,
  closed,
  userdata,
  currentPage,
  userType,
  searchText,
}) => {
  //language package
  const { t, i18n } = useTranslation("userManagementPopUp");

  const id = userdata._id;
  const [name, setName] = useState(userdata.name);
  const [email, setEmail] = useState(userdata.email);
  const [mobile, setMobile] = useState(userdata.mobile);
  const [role, setRole] = useState(
    userdata.user.roles[ags][1] || userdata.user.roles[ags][0]
  );
  const [notify, setNotify] = useState({
    fas: true,
    sas: true,
  });
  const [isNameValid, setIsNameValid] = useState(true);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.subscriber.isLoading);
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);

  useEffect(() => {
    if (role === "managers" && userdata?.user?.notify) {
      setNotify({
        fas: userdata?.user?.notify?.fas,
        sas: userdata?.user?.notify?.sas,
      });
    }
  }, [role]);

  const closeModal = () => {
    closed();
    setName("");
    setEmail("");
    setMobile("");
    setIsNameValid(true);
    setIsMobileValid(true);
    setNotify({
      fas: true,
      sas: true,
    });
  };

  const nameChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsNameValid(true);
    }
    setName(event.target.value);
  };

  const mobileChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsMobileValid(true);
    }
    setMobile(event.target.value);
  };

  const roleChangeHandler = (event) => {
    setRole(event.target.value);
  };

  const modalSubmitHandler = (event) => {
    event.preventDefault();

    if (name.trim().length === 0) {
      setIsNameValid(false);
      toast.error(t("enterName"));
      return;
    }

    // if (mobile && mobile.length > 0 && isValidPhoneNumber(mobile)) {
    //   setIsMobileValid(true);
    // } else {
    //   toast.error("Please enter a valid phone number");
    //   setIsMobileValid(false);
    //   return;
    // }

    const arg = {
      name: name,
      mobile: mobile,
      role: role,
    };

    if (role === "managers") {
      arg.notify = notify;
    }

    const response = dispatch(actions.updateSubsciberAction(ags, id, arg));
    response.then((result) => {
      if (result.type === "SUBSCRIBER_SUCCESS") {
        // refresh the user list
        dispatch(actions.usersAction(ags, currentPage, userType, searchText));
        toast.success(t("userUpdatedSuccessfully"));
        closeModal();
      }
    });
  };

  return (
    <div>
      {open ? (
        <div
          className="fixed top-0 left-0 z-30 flex items-center justify-center w-full h-full 
        rounded-xl overflow-auto dark:bg-black/70  bg-white/70 !backdrop-blur-[61.5px]"
        >
          <div
            className={`relative bg-spekterWhite dark:bg-boxBG m-auto p-0 rounded-xl max-w-[95%] w-[650px]
          ${isThemeDark ? "" : "shadow-modal"}
          `}
          >
            <div className="relative md:pl-4 bg-barBG flex items-center justify-center xl:justify-start dark:bg-transparent rounded-t-xl h-[64px]">
              <div className="font-semibold text-center text-gray-800 dark:text-spekterWhite lg:text-left">
                {t("popUpTitle")}
              </div>

              <div className="absolute flex justify-center items-center w-full h-80 top-[30%] right-1 pointer-events-none">
                {isLoading && <Spinner />}
              </div>
            </div>
            <hr className="w-full m-0 border-gray-200 dark:border-gray-800" />
            <div className="p-2.5 flex flex-col md:flex-row gap-2 lg:gap-6 items-center justify-evenly w-full">
              <div className="self-start w-20 text-gray-600 md:self-auto dark:text-spekterWhite">
                {t("name")}
              </div>
              <input
                className={`bg-spekterWhite dark:bg-spekterDarkFour rounded-sm text-gray-600 dark:text-gray-400 h-12 w-full md:w-60 px-3
                    ${
                      !isNameValid
                        ? "border border-spekterRed"
                        : `${isThemeDark ? "border-0" : "border"}`
                    }
                    focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2
                    `}
                type="text"
                placeholder="Name"
                value={name}
                onChange={nameChangeHandler}
              />
            </div>
            <div className="p-2.5 flex flex-col md:flex-row gap-2 lg:gap-6 items-center justify-evenly w-full">
              <div className="self-start w-20 text-gray-600 md:self-auto dark:text-spekterWhite">
                {t("email")}
              </div>
              <input
                className={`bg-gray-200 dark:bg-spekterDarkFour rounded-sm text-gray-600 dark:text-gray-400 h-12 w-full md:w-60 px-3
                    ${isThemeDark ? "border-0" : "border border-borderGary"}`}
                type="text"
                placeholder="Email"
                value={email}
                disabled
              />
            </div>
            <div className="p-2.5 flex flex-col md:flex-row gap-2 lg:gap-6 items-center justify-evenly w-full">
              <div className="self-start w-20 text-gray-600 md:self-auto dark:text-spekterWhite">
                {t("mobile")}
              </div>
              <div
                className={`bg-spekterWhite dark:bg-spekterDarkFour rounded-sm text-gray-600 dark:text-gray-400 h-12 w-full md:w-60 px-3
                    ${
                      !isMobileValid
                        ? "border border-spekterRed"
                        : `${
                            isThemeDark
                              ? "border-0"
                              : "border border-borderGary"
                          }`
                    }
                    focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2
                    `}
              >
                <PhoneInput
                  className="pt-2.5 phoneInputOld dark:phoneInputDarkOld"
                  placeholder="Mobile"
                  onChange={(value) => {
                    setMobile(value || "");
                  }}
                  value={mobile}
                  defaultCountry="DE"
                  international
                  countryCallingCodeEditable={false}
                  countries={["DE", "AT", "CH", "FR", "IT", "LU"]}
                />
              </div>
            </div>
            <div className="p-2.5 flex flex-col md:flex-row gap-2 lg:gap-6 items-center justify-evenly w-full">
              <div className="self-start w-20 text-gray-600 md:self-auto dark:text-spekterWhite">
                {t("role")}
              </div>
              <select
                onChange={roleChangeHandler}
                defaultValue={role}
                className={`${
                  isThemeDark ? "" : "border border-borderGary"
                } bg-spekterWhite dark:bg-spekterDarkFour rounded-sm
                capitalize text-gray-600 dark:text-gray-400 h-12 w-full md:w-60 px-3
                focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2`}
              >
                {/* <option disabled value={role} selected>{role}</option> */}
                <option disabled value={role}>
                  {role}
                </option>
                <option value="users">{t("users")}</option>
                <option value="managers">{t("managers")}</option>
                <option value="rescue">{t("rescue")}</option>
              </select>
            </div>
            {role && role === "managers" && (
              <div className="p-2.5 flex flex-col md:flex-row gap-2 lg:gap-6 justify-evenly w-full">
                <div className="self-start w-20 pt-2.5 text-gray-600 md:self-auto dark:text-spekterWhite">
                  Benachrichtigung
                </div>
                <div
                  className={` text-gray-600 dark:text-gray-400 w-full md:w-60 px-3`}
                >
                  <div className="flex items-center justify-between w-full">
                    <span className="">FAS</span>
                    <div>
                      <SwitchIcon
                        bgColor={`${
                          isThemeDark ? "bg-gray-900" : "bg-gray-500"
                        }`}
                        bgCheckedColor="checked:bg-spekterGreen"
                        labelClassName="pt-2.5"
                        value={notify.fas}
                        onCheck={() => {
                          setNotify((prevState) => ({
                            ...prevState,
                            fas: !prevState.fas,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <span className="">SAS</span>
                    <div>
                      <SwitchIcon
                        bgColor={`${
                          isThemeDark ? "bg-gray-700" : "bg-gray-500"
                        }`}
                        bgCheckedColor="checked:bg-spekterGreen"
                        labelClassName="pt-2.5"
                        value={notify.sas}
                        onCheck={() => {
                          setNotify((prevState) => ({
                            ...prevState,
                            sas: !prevState.sas,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              onClick={closeModal}
              className="absolute text-gray-800 duration-300 cursor-pointer right-2 top-2 hover:opacity-75 dark:text-gray-100"
            >
              <CgClose size={24} />
            </div>
            <hr className="w-full m-0 border-gray-200 dark:border-gray-800" />
            <div className="flex justify-end p-3 bg-barBG dark:bg-transparent rounded-b-xl">
              {/* <button onClick={closeModal}
                className="border-[1px] border-gray-400 w-24 rounded-5 py-1 text-base text-gray-600 dark:text-gray-400"
              >
                Cancel
              </button> */}
              <strong>
                <input
                  type="button"
                  value={t("save")}
                  className="px-10 py-2 ml-3 font-semibold border-0 rounded-md bg-spekterGreenLight dark:bg-spekterGreen shadow-btn text-spekterDarkOne dark:text-gray-800"
                  onClick={modalSubmitHandler}
                />
              </strong>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default EditUserModal;
