import React from "react";
import Backdrop from "../../../../components/UI/Backdrop/Backdrop";
import { useTranslation } from "react-i18next";

const ConfirmationDialog = ({
  setShowDeleteConfirmModal,
  onDelete,
  selectedPod,
}) => {
  const { t } = useTranslation("realtimeMap"); // i18n namespace for translation
  return (
    <>
      <Backdrop show={true} />
      <div
        id="popup-modal"
        className="fixed flex justify-center items-center backdrop-blur-[4.5px] top-0 left-0 right-0 z-1000 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div class="relative w-full max-w-md max-h-full">
          <div className="relative rounded-[18px] shadow bg-[#fff]/70 dark:bg-[#000]/70 backdrop-blur-[61.5px]">
            <div className="p-6 text-center">
              <h2 className="mb-4 text-2xl font-bold text-gray-900 dark:text-white">
              {t("delete")} POD
              </h2>
              <h3 className="mb-5 text-[15px] font-normal text-[#000] dark:text-[#fff] ">
              {t("areYouSure")}{" "}
                <span className="font-semibold">{selectedPod?.title}</span> ?{" "}
                <br />
                {t("thisAction")}
              </h3>
              <div className="flex items-center justify-center w-full gap-6 ml-2 scale-[80%] sm:w-96 sm:scale-[100%]">
                <button
                  type="button"
                  className="focus:outline-none rounded-[6px] border-2 border-[#c9c9c9] dark:border-[#333] text-[15px]
                   font-bold px-[46px] py-[11.5px]  focus:z-10 text-[#000] dark:text-[#fff] hover:opacity-70 duration-100"
                  onClick={() => setShowDeleteConfirmModal(false)}
                >
                  {t("cancel")}
                </button>
                <button
                  type="button"
                  className="text-[#fff] dark:text-[#000] justify-center font-bold bg-[#ff0000] rounded-[6px]
                   text-[16px] inline-flex items-center px-[47px] py-[12px] text-center hover:opacity-70 duration-100"
                  onClick={() => {
                    onDelete();
                    setShowDeleteConfirmModal(false);
                  }}
                >
                  {t("delete")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationDialog;
