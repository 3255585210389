import { useDispatch, useSelector } from "react-redux";
import { openAboutModal } from "../redux/actions/index";
function useAboutModal() {
  const dispatch = useDispatch();
  const showAboutModal = useSelector((state) => state.appPopUp.showAboutModal);
  const setShowAboutModal = (flag) => {
    dispatch(openAboutModal(flag));
  };

  return { showAboutModal, setShowAboutModal };
}

export default useAboutModal;
