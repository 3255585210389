import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const updateUserActionPending = () => {
  return {
    type: actionTypes.UPDATE_USER_PENDING,
  };
};

export const updateUserActionSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    data: data,
  };
};

export const updateUserActionFail = (error) => {
  return {
    type: actionTypes.UPDATE_USER_FAIL,
    error: error,
  };
};

export const updateUserAction = (ags, id, arg) => {
  let urlEndPoint = `/users/verify/${id}?ags=${ags}`;
  return async (dispatch) => {
    try {
      dispatch(updateUserActionPending());

      const response = await Axios.patch(urlEndPoint, arg, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      return dispatch(updateUserActionSuccess(response.data));
    } catch (error) {
      dispatch(updateUserActionFail(error));
    }
  };
};
