import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaRegBell } from "react-icons/fa";
import * as actions from "../../../../redux/actions/index";
import CatchmentItem from "./CatchmentItem";
import { toast } from "sonner";
import DwdItem from "./DwdItem";
import SensorItem from "./SensorItem";
import { setOpacityByIndex } from "../../../../shared/Utility";
import { el, is } from "date-fns/locale";
import useAgs from "../../../../hooks/useAgs";
import useMobileView from "../../../../hooks/useMobileView";

const dwdEventTypes = [
  "GEWITTER",
  "STARKES GEWITTER",
  "SCHWERES GEWITTER mit ORKANBÖEN",
  "SCHWERES GEWITTER mit EXTREMEN ORKANBÖEN",
  "SCHWERES GEWITTER mit HEFTIGEM STARKREGEN",
  "SCHWERES GEWITTER mit ORKANBÖEN und HEFTIGEM STARKREGEN",
  "SCHWERES GEWITTER mit EXTREMEN ORKANBÖEN und HEFTIGEM STARKREGEN",
  "SCHWERES GEWITTER mit HEFTIGEM STARKREGEN und HAGEL",
  "SCHWERES GEWITTER mit ORKANBÖEN, HEFTIGEM STARKREGEN und HAGEL",
  "SCHWERES GEWITTER mit EXTREMEN ORKANBÖEN, HEFTIGEM STARKREGEN und HAGEL",
  "STARKREGEN",
  "HEFTIGER STARKREGEN",
  "DAUERREGEN",
  "ERGIEBIGER DAUERREGEN",
  "EXTREM ERGIEBIGER DAUERREGEN",
  "EXTREM HEFTIGER STARKREGEN",
  "SCHWERES GEWITTER mit EXTREM HEFTIGEM STARKREGEN und HAGEL",
  "EXTREMES GEWITTER mit ORKANBÖEN, EXTREM HEFTIGEM STARKREGEN und HAGEL",
  "VORABINFORMATION SCHWERES GEWITTER",
  "VORABINFORMATION HEFTIGER / ERGIEBIGER REGEN",
  "VORABINFORMATION STARKES TAUWETTER",
];

const iconPath = "/images/notification/";

const Notification = ({
  setNotificationFlag,
  notificationFlag,
  setBellIconPath,
  setExpandNotifications,
  expandNotifications,
}) => {
  const { t, i18n } = useTranslation("userSettings"); //language package
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobileView = useMobileView();
  const agsList = useAgs();
  const hasHeaderRendered = useRef(false);
  const [notifications, setNotifications] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const catchmentData = useSelector((state) => state.catchment.data);
  const dwdMessages = useSelector((state) => state.dwd?.data);
  const sensorNotification = useSelector(
    (state) => state.sensorNotification.data
  );
  const catchmentNotification = useSelector(
    (state) => state.catchmentNotification.data?.data
  );
  const [isBellIconHovered, setIsBellIconHovered] = useState(false);

  //loading state
  const isCatchmentNotificationLoading = useSelector(
    (state) => state.catchmentNotification.isLoading
  );
  const isDwdNotificationLoading = useSelector((state) => state.dwd.isLoading);
  const isSensorNotificationLoading = useSelector(
    (state) => state.sensorNotification.isLoading
  );

  const isNotificationLoading =
    isDwdNotificationLoading ||
    isSensorNotificationLoading ||
    isCatchmentNotificationLoading;

  const isThemeDark = useSelector((state) => state.theme.isThemeDark);

  const showNotification = () => {
    if (!isNotificationLoading && notifications && notifications.length === 0) {
      toast.message(t("noNotifications"));
    } else {
      setNotificationFlag(!notificationFlag);
      setExpandNotifications(false);
    }
  };

  // click to dismiss notification item
  const dismissNotification = (id) => {
    setNotifications(
      notifications.filter((notification) => notification._id !== id)
    );
  };

  useEffect(() => {
    async function callFunc() {
      // Filter active DWD messages based on event types and notification type
      const activeDwdMessages =
        dwdMessages &&
        dwdMessages.filter(
          (dwd) =>
            dwdEventTypes.includes(dwd.info?.event) &&
            (dwd.notificationType = "dwd")
        );

      // Filter modified catchment notifications by notification type
      const modCatchmentNotification =
        catchmentNotification &&
        catchmentNotification.filter(
          (catchment) => (catchment.notificationType = "rain")
        );

      // Filter modified sensor notifications by notification type
      const modSensorNotification =
        sensorNotification &&
        sensorNotification.filter(
          (sensor) => (sensor.notificationType = "sensor")
        );

      if (
        // activeDwdMessages &&
        modCatchmentNotification &&
        modSensorNotification
      ) {
        // Combine all filtered notifications into a single array
        const tempNotificationArray = [
          ...modCatchmentNotification,
          // ...activeDwdMessages,
          ...modSensorNotification,
        ];

        // Check if all notifications are maintenance
        const hasAllMaintenanceTrue = tempNotificationArray.every(
          (item) => item.maintenance
        );

        // Filter out maintenance notifications for notification preview
        const filteredData = tempNotificationArray.filter(
          (item) => !item.maintenance
        );

        const reversedFilteredData = filteredData.reverse();

        if (!expandNotifications) {
          if (hasAllMaintenanceTrue) {
            if (isFirstRender) {
              setNotificationFlag(false);
            }
            setTimeout(() => {
              if (tempNotificationArray.length > 3) {
                setNotifications(
                  tempNotificationArray.slice(
                    tempNotificationArray.length - 3,
                    tempNotificationArray.length
                  )
                );
              } else {
                setNotifications(tempNotificationArray);
              }
            }, 1000); // Delay execution by 1 second for better user experience

            setIsFirstRender(false);
          } else {
            // If not all notifications are maintenance notifications
            if (reversedFilteredData && reversedFilteredData.length > 3) {
              setNotifications(
                reversedFilteredData.slice(
                  reversedFilteredData.length - 3,
                  reversedFilteredData.length
                )
              );
            } else {
              setNotifications(reversedFilteredData);
            }
          }
        } else {
          // If notifications are expanded, set notifications to tempNotificationArray
          setNotifications(tempNotificationArray);
        }
      }
    }
    callFunc();
  }, [
    catchmentData,
    // dwdMessages,
    sensorNotification,
    catchmentNotification,
    notificationFlag,
    expandNotifications,
  ]);

  useEffect(() => {
    const ags = agsList[0];

    dispatch(actions.sensorNotification());
    dispatch(actions.catchmentNotificationAction());
    dispatch(actions.dwdAction());
    !catchmentData && dispatch(actions.catchment(ags));
    dispatch(actions.catchment(ags));
  }, [dispatch]);

  const handleNotificationClick = (data) => {
    // console.log("sensor in noti", data);
    if (!expandNotifications) {
      setExpandNotifications(true);
    } else if (data?.ags) {
      navigate({
        pathname: "/realtimemap",
        search: `?${createSearchParams({
          _id: data._id,
          ags: data.ags,
          notificationType: data.notificationType,
          catchmentLat: data.coordinatesCenter
            ? data.coordinatesCenter[0]
            : null,
          catchmentLng: data.coordinatesCenter
            ? data.coordinatesCenter[1]
            : null,
        })}`,
      });
      if (isMobileView) {
        setExpandNotifications(false);
        hasHeaderRendered.current = false;
      }
    }
  };

  const notificationBellIconPath = isThemeDark
    ? notifications && notifications.length === 0 && !notificationFlag
      ? `${iconPath}Notification Icon crossed out_${
          isBellIconHovered ? "c9c9c9" : "ffffff"
        }_fill.svg`
      : notifications && notifications.length > 0 && !notificationFlag
      ? `${iconPath}Notification Icon 2 crossed out_${
          isBellIconHovered ? "c9c9c9" : "ffffff"
        }_fill.svg`
      : notifications && notifications.length > 0
      ? `${iconPath}Notification Icon 2_${
          isBellIconHovered ? "c9c9c9" : "ffffff"
        }_fill.svg`
      : `${iconPath}Notification Icon_${
          isBellIconHovered ? "c9c9c9" : "ffffff"
        }_fill.svg`
    : notifications && notifications.length === 0 && !notificationFlag
    ? `${iconPath}Notification Icon crossed out_${
        isBellIconHovered ? "818181" : "000000"
      }_fill.svg`
    : notifications && notifications.length > 0 && !notificationFlag
    ? `${iconPath}Notification Icon 2 crossed out_${
        isBellIconHovered ? "818181" : "000000"
      }_fill.svg`
    : notifications && notifications.length > 0
    ? `${iconPath}Notification Icon 2_${
        isBellIconHovered ? "818181" : "000000"
      }_fill.svg`
    : `${iconPath}Notification Icon_${
        isBellIconHovered ? "818181" : "000000"
      }_fill.svg`;

  useEffect(() => {
    setBellIconPath(notificationBellIconPath);
  }, [notificationBellIconPath]);

  // console.log("notifications", notifications);

  return (
    <>
      <div
        onClick={() => showNotification()}
        onMouseEnter={() => setIsBellIconHovered(true)}
        onMouseLeave={() => setIsBellIconHovered(false)}
        className={`${
          isNotificationLoading && "animate-pulse"
        } flex w-10 justify-center items-center relative mr-2 text-gray-900 duration-300 cursor-pointer sm:mr-0 dark:text-gray-100`}
      >
        <img
          className="h-7"
          src={notificationBellIconPath}
          alt="bell-icon-dark"
        />
      </div>

      {/* Non-sticky notification */}
      <div
        className={`${
          notificationFlag ? "" : "translate-x-[120%] pointer-events-none"
        }
        ${
          expandNotifications
            ? "max-h-[calc(100vh_-_56px)] overflow-y-auto"
            : "h-0"
        }
    transition-all duration-100 pb-28 md:pb-4
    sm:w-[432px] sm:px-8 sm:pt-8 max-w-[97vw] fixed right-1.5 sm:right-16 mt-14 top-[0px] z-30 hide-scrollbar`}
      >
        {notifications &&
          notifications.map((notification, index) => {
            // console.log(notification);
            if (notification.notificationType === "rain") {
              // console.log(notification);
              return (
                <CatchmentItem
                  onDismissCard={() => dismissNotification(notification._id)}
                  data={notification}
                  name={notification?.name}
                  warningLevel={notification?.warningLevel}
                  key={index}
                  top={!expandNotifications && `${index * -75}px`}
                  zIndex={index}
                  opacity={!expandNotifications && setOpacityByIndex(index)}
                  handleNotificationClick={handleNotificationClick}
                />
              );
            } else if (notification.notificationType === "dwd") {
              return (
                <DwdItem
                  onDismissCard={() => dismissNotification(notification._id)}
                  data={notification}
                  key={index}
                  top={!expandNotifications && `${index * -75}px`}
                  zIndex={index}
                  opacity={!expandNotifications && setOpacityByIndex(index)}
                  expandNotifications={expandNotifications}
                  setExpandNotifications={setExpandNotifications}
                  notificationFlag={notificationFlag}
                />
              );
            } else if (notification.notificationType === "sensor") {
              return (
                <SensorItem
                  onDismissCard={() => dismissNotification(notification._id)}
                  data={notification}
                  name={notification?.name}
                  key={index}
                  maintenance={notification?.maintenance}
                  warningStage={notification?.warningStage}
                  top={!expandNotifications && `${index * -75}px`}
                  zIndex={index}
                  opacity={!expandNotifications && setOpacityByIndex(index)}
                  handleNotificationClick={handleNotificationClick}
                  notificationFlag={notificationFlag}
                  hasHeaderRendered={hasHeaderRendered}
                  expandNotifications={expandNotifications}
                />
              );
            } else {
              return null;
            }
          })}
      </div>
    </>
  );
};

export default Notification;
