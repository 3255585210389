import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "../../components/Analytics/DatePicker/DatePicker";
import { mixChartAction, mixChartReset } from "../../redux/actions/index";
import { sensorTypes } from "../../constants/sensorTypes";
import Spinner from "../../components/UI/Spinner/Spinner";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SingleSmartRiver from "../../components/Analytics/SingleCharts/SingleSmartRiver";
import SingleHdc from "../../components/Analytics/SingleCharts/SingleHdc";
import SingleGfl from "../../components/Analytics/SingleCharts/SingleGfl";
import * as actions from "../../redux/actions/index";
import * as toast from "../../components/UI/Toaster";
import AnalyticsModal from "../../components/Analytics/Modal/AnalyticsModal";
import { utcToGermanTime } from "../../shared/Utility";
import useAgs from "../../hooks/useAgs";
import { useSearchParams } from "react-router-dom";

const animatedComponents = makeAnimated();

const customStylesDark = {
  control: (base, state) => ({
    ...base,
    //from tailwind.config.js: spekterDarkOne color is used
    background: "#141517",
    border: 0,
    boxShadow: "none",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    right: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    padding: 0,
    background: "#1d1e21",
    color: "gray",
  }),
};

const customStylesLight = {
  control: (base, state) => ({
    ...base,
    background: "#F9FAFB",
    border: 0,
    boxShadow: "none",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    padding: 0,
    background: "#F9FAFB",
    color: "gray",
  }),
};

const Analytics = () => {
  //language package
  const { t, i18n } = useTranslation("analytics");

  const [searchParams, setSearchParams] = useSearchParams();
  const agsList = useAgs();
  const selectedObserverAgs = useSelector(
    (state) => state.observer.selectedAgs
  );
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const [showExplanationModal, setShowExplanationModal] = useState(false);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [smartriver, setSmartRiver] = useState([]);
  const [hdc, setHdc] = useState([]);
  const [gfl, setGfl] = useState([]);
  ///csv
  const [csvData, setCsvData] = useState([]);
  /* ---------------------------------------
    ---> state for smartRiver and gfl<---
    # those state are holds thresholds of sensor.
    # hdc sensor have not any thresholds.
  ------------------------------------------*/
  const [smartRiverOptionalData, setsmartRiverOptionalData] = useState({});
  const [gflOptionalData, setgflOptionalData] = useState({});

  //Holds sensor info. We use that for fetching indivitual sensor chart Data by id
  const smartRiverSensorData = useSelector((state) => state.smartriver.data);
  const hdcSensorData = useSelector((state) => state.hdc.data);
  const gflSensorData = useSelector((state) => state.gfl.data);

  const [allSensorChartData, setAllSensorChartData] = useState([]);
  const [singleSmartRivers, setSingleSmartRivers] = useState([]);
  const [singleGfls, setSingleGfls] = useState([]);
  const [singleHdcs, setSingleHdcs] = useState([]);

  // date picker
  const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(new Date());

  // mix chart loader
  const isLoading = useSelector((state) => state.mixChart.isLoading);
  const smartRiverSelectedSensorsData = useSelector(
    (state) => state.mixChart.smartRiverData
  );
  const hdcSelectedSensorsData = useSelector((state) => state.mixChart.hdcData);
  const gflSelectedSensorsData = useSelector((state) => state.mixChart.gflData);

  useEffect(() => {
    if (
      smartRiverSelectedSensorsData ||
      hdcSelectedSensorsData ||
      gflSelectedSensorsData
    ) {
      let tempMixChartData = [];
      let tempSmartRiverData = [];
      let tempHdcData = [];
      let tempGflData = [];

      smartRiverSelectedSensorsData.map((sensorData) => {
        let tempdates = [];
        let tempdatesSingle = [];
        let tempValues = [];
        let sensorName = sensorData.sensorName;

        // for CSV export
        if (sensorData.values) {
          for (let i = 0; i < sensorData.values.length; i++) {
            tempdates.push(sensorData.values[i]._time);
            tempdatesSingle.push(sensorData.values[i]._time);
            tempValues.push(
              sensorData.soleCorrectionValue - sensorData.values[i]._value < 0
                ? 0
                : sensorData.soleCorrectionValue - sensorData.values[i]._value
            );
          }
        }

        // for CSV export
        tempMixChartData.push({
          dates: tempdates,
          data: tempValues,
          name: sensorName,
          type: "smartriver",
        });

        tempSmartRiverData.push({
          ...sensorData,
        });
      });

      hdcSelectedSensorsData.map((sensorData) => {
        let tempdates = [];
        let tempValues = [];
        let sensorName = sensorData.sensorName;

        // for CSV export
        if (sensorData.values) {
          for (let i = 0; i < sensorData.values.length; i++) {
            tempdates.push(sensorData.values[i].start);
            tempValues.push(
              parseFloat(sensorData.values[i].value.toPrecision(4))
            );
          }
        }

        // for CSV export
        tempMixChartData.push({
          dates: tempdates,
          data: tempValues,
          name: sensorName,
          type: "hdc",
        });

        // used for single chart
        tempHdcData.push({
          ...sensorData,
        });
      });

      gflSelectedSensorsData.map((sensorData) => {
        let tempdates = [];
        let tempValues = [];
        let tempdatesSingle = [];
        let sensorName = sensorData.sensorName;

        // for CSV export
        if (sensorData.values) {
          for (let i = 0; i < sensorData.values.length; i++) {
            tempdates.push(sensorData.values[i].start);
            tempdatesSingle.push(sensorData.values[i].start);
            tempValues.push(sensorData.values[i].value);
          }
        }

        // for CSV export
        tempMixChartData.push({
          dates: tempdates,
          data: tempValues,
          name: sensorName,
          type: "gfl",
        });

        // used for single chart
        tempGflData.push({
          ...sensorData,
        });
      });

      setSingleSmartRivers(tempSmartRiverData);
      setSingleHdcs(tempHdcData);
      setSingleGfls(tempGflData);
      setAllSensorChartData(tempMixChartData);
    }
  }, [
    smartRiverSelectedSensorsData,
    hdcSelectedSensorsData,
    gflSelectedSensorsData,
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mixChartReset());
  }, []);

  const onPressedSelectButton = () => {
    if (selectedSensors.length < 1 || selectedSensors.length > 6) {
      toast.error(t("selectionError"));
      return;
    }

    const roundedStartDate = (date) => {
      // used for presislely hour timetamp
      // if minutes is less than 30 then round to 0. if minutes is greater than 30 then round to 1
      let roundedDate;
      if (date.getMinutes() >= 30) {
        roundedDate = moment(date).startOf("hour").add(1, "hour");
      } else {
        roundedDate = moment(date).startOf("hour").add(0, "hour");
      }
      return roundedDate;
    };

    const roundedEndDate = (date) => {
      const roundedDate = moment(date).startOf("hour").add(0, "hour");
      return roundedDate;
    };

    if (selectedSensors.length !== 0) {
      if (startDate && endDate) {
        // const start = roundedStartDate(startDate);
        // const end = roundedEndDate(endDate);

        dispatch(
          mixChartAction(
            // start.toISOString(),
            // end.toISOString(),
            startDate.toISOString(),
            endDate.toISOString(),
            selectedSensors
          )
        );
      } else {
        toast.error(t("selectStartEndDate"));
      }
    }
  };

  // preparing selected sensors. It will used for fetching sensors chart data.
  useEffect(() => {
    if (smartRiverSensorData === null) return;
    if (smartRiverSensorData.length !== 0) {
      let newData = [];
      let tempSmartRiverOptionalData = {};
      Array.isArray(smartRiverSensorData) &&
        smartRiverSensorData.forEach((sensor) => {
          if (sensor.maintenance) return;
          const tempSensor = {
            label: sensor.name,
            value: sensor._id,
            sensorType: sensorTypes.SMARTRIVER,
            soleCorrectionValue: sensor.soleCorrectionValue,
            thresholds: sensor.thresholds,
          };
          tempSmartRiverOptionalData[sensor._id] = {
            soleCorrectionValue: sensor.soleCorrectionValue,
            thresholds: sensor.thresholds,
          };

          newData.push(tempSensor);
        });
      setSmartRiver(newData);
      setsmartRiverOptionalData(tempSmartRiverOptionalData);
    }
    return () => {
      setSmartRiver([]);
      setsmartRiverOptionalData({});
    };
  }, [smartRiverSensorData]);

  useEffect(() => {
    if (hdcSensorData === null) return;
    if (hdcSensorData.length !== 0) {
      let newData = [];
      Array.isArray(hdcSensorData) &&
        hdcSensorData.forEach((sensor) => {
          const tempSensor = {
            label: sensor.name,
            value: sensor._id,
            sensorType: sensorTypes.HDC,
          };
          newData.push(tempSensor);
        });
      setHdc(newData);
    }
    return () => {
      setHdc([]);
    };
  }, [hdcSensorData]);

  useEffect(() => {
    if (gflSensorData === null) return;
    if (gflSensorData.length !== 0) {
      let newData = [];
      let tempGflOptionalData = {};
      Array.isArray(gflSensorData) &&
        gflSensorData.forEach((sensor) => {
          if (sensor.maintenance) return;
          const tempSensor = {
            label: sensor.name,
            value: sensor._id,
            // sensorType: "gfldata",
            sensorType: sensorTypes.GFL,
            thresholds: sensor.thresholds,
          };

          // console.log(sensor);

          tempGflOptionalData[sensor._id] = {
            thresholds: sensor.thresholds,
          };

          newData.push(tempSensor);
        });
      setGfl(newData);
      setgflOptionalData(tempGflOptionalData);
    }
    return () => {
      setGfl([]);
      setgflOptionalData({});
    };
  }, [gflSensorData]);

  useEffect(() => {
    if (selectedSensors.length >= 6) {
      {
        toast.message(t("maximumSelectionOfSensors"));
      }
    }
  }, [selectedSensors]);

  //on mount call
  // mount onCatchment

  const hdcLoading = useSelector((state) => state.hdc.isLoading);
  const smartRiverLoading = useSelector((state) => state.smartriver.isLoading);
  const gflLoading = useSelector((state) => state.gfl.isLoading);

  useEffect(() => {
    let ags = "";
    if (searchParams.get("ags")) {
      ags = searchParams.get("ags");
    } else {
      ags = agsList && agsList[0];
    }
    dispatch(actions.hdc({ ags }));
    dispatch(actions.gfl({ ags }));
    dispatch(actions.smartriver({ ags }));
  }, [dispatch]);

  // Download csv function
  const downloadCsv = () => {
    let nestedDataList = []; //[[],[],[]]
    let tempList = [];
    const sensorsLength = []; // store sensor length

    allSensorChartData.forEach((sensor, index) => {
      /* gerenate this format csv header
            date - pagel - date - pagel2
      */
      if (sensor.type === "hdc") {
        tempList.push("date");
        tempList.push(sensor.name.toString() + " " + t("hdcHour"));
        tempList.push(sensor.name.toString() + " " + t("hdcHourlySum"));
      } else {
        tempList.push("date");
        tempList.push(sensor.name.toString());
      }

      // put sensor length
      sensorsLength.push(sensor.data.length);
    });
    nestedDataList.push(tempList); //insert label [date,sensor-name]

    // find max value
    let maxValue = Math.max(...sensorsLength);

    // calculate cumulative sum
    let cumulativeData = [];
    allSensorChartData.forEach((sensor, index) => {
      if (sensor.type === "hdc") {
        let cumulativeSum = 0;
        let temp = [];
        for (let i = 0; i < sensor.data.length; i++) {
          const value = sensor.data[i];
          const sumvalue = cumulativeSum + value;
          cumulativeSum = sumvalue;

          // pick 1 digit after decimal without rounding by Math.trunc
          // const cumulativeValue = Math.trunc(cumulativeSum * 10) / 10;

          //pick 2 digit after decimal point
          const cumulativeValue = Math.round(cumulativeSum * 100) / 100;
          temp.push(cumulativeValue);
        }
        cumulativeData.push(temp);
      }
    });

    for (let i = 0; i < maxValue; i++) {
      let temp = [];

      // create arrary and fill up with 0 to leng of cumulativeData array. looks like [0,1,2,3,4... length]
      let indexArry = Array.from(
        { length: cumulativeData.length },
        (_, i) => i
      );

      for (let j = 0; j < allSensorChartData.length; j++) {
        if (allSensorChartData[j].type === "hdc") {
          const date =
            allSensorChartData[j].dates[i] &&
            utcToGermanTime(allSensorChartData[j].dates[i]);
          const value = allSensorChartData[j].data[i];
          // const sumvalue = cumulativeSum + value;
          // cumulativeSum = sumvalue;

          // pick 1 digit after decimal without rounding by Math.trunc
          // const cumulativeValue = Math.trunc(cumulativeSum * 10) / 10;
          temp.push(date);
          temp.push(value);

          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/shift
          temp.push(cumulativeData[indexArry.shift()][i]);
        } else {
          const date =
            allSensorChartData[j].dates[i] &&
            utcToGermanTime(allSensorChartData[j].dates[i]);
          const value = allSensorChartData[j].data[i];
          temp.push(date);
          temp.push(value);
        }
      }

      nestedDataList.push(temp);
    }

    setCsvData(nestedDataList);
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    const now = new Date();
    if (Date.parse(start) === Date.parse(end)) {
      const midnight = new Date(start);
      midnight.setHours(0, 0, 0, 0);
      const endOfDay = new Date(start);
      endOfDay.setHours(23, 59, 59, 999);

      const maxHourOfToday = endOfDay > now ? now : endOfDay;

      setStartDate(midnight);
      setEndDate(maxHourOfToday);
    } else {
      setStartDate(start);
      setEndDate(end);
    }
  };

  const darkInfoIcon = "images/menu_icons/about-icon-dark.svg";
  const lightInfoIcon = "images/menu_icons/about-icon-light.svg";

  let full_screen_loader = hdcLoading && smartRiverLoading && gflLoading && (
    <div className="flex h-full w-full justify-center fixed items-center backdrop-blur-sm z-[1000]">
      <Spinner />
    </div>
  );

  return (
    <div className="h-full min-h-screen pt-12 text-gray-700 dark:text-gray-100 dark:bg-spekterDarkTwo md:pt-20 md:pl-36 sm:pr-8 md:pb-auto">
      {full_screen_loader}
      <div className="flex flex-col items-center sm:rounded-10 mt-[10px] sm:mt-[25px] px-2 sm:h-[82vh] 2xl:h-[85vh] sm:shadow-container dark:shadow-slate-700">
        <div
          className={`${
            selectedSensors.length === 2
              ? "pb-20"
              : selectedSensors.length === 3
              ? "pb-28"
              : "pb-32"
          } sm:pb-0 w-full sticky top-0 z-20 dark:bg-spekterDarkTwo sm:dark:bg-spekterDarkTwo bg-spekterLight`}
        >
          <div className="flex md:self-start">
            <p className="text-gray-700 dark:text-gray-100 font-bold pl-[26px] pr-[15px] py-[6px] sm:py-[24px] text-2xl">
              {t("title")}
            </p>
            <img
              onClick={() => setShowExplanationModal(!showExplanationModal)}
              className="h-6 mt-[11px] cursor-pointer md:mt-[29px]"
              alt="sensor"
              src={isThemeDark ? lightInfoIcon : darkInfoIcon}
            />
          </div>
          <section
            className={`${
              selectedSensors.length > 2
                ? "h-[108px] md:h-[166px] lg:h-[140px] xl:h-[108px]"
                : "h-[108px] md:h-[166px] lg:h-[108px]"
            } flex flex-col sm:items-center lg:flex-row lg:justify-between lg:p-2 gap-5 w-full duration-300`}
          >
            {/* left portion */}
            <div
              className={`${
                isThemeDark ? "" : "shadow-input"
              } lg:select sm:w-4/5 lg:w-4/12 bg-spekterDarkOne text-gray-700 rounded-5 basis-[50%] lg:basis-[40%] 2xl:basis-[33%] flex items-center`}
            >
              <Select
                isMulti
                closeMenuOnSelect={selectedSensors.length === 5}
                components={animatedComponents}
                styles={customStylesDark}
                options={smartriver.concat(hdc, gfl)}
                className="w-full p-1 basic-multi-select"
                classNamePrefix="select"
                isOptionDisabled={() => selectedSensors.length >= 6}
                onChange={(value) => {
                  setSelectedSensors(value);
                }}
              />
            </div>

            <div className="flex justify-between sm:justify-around sm:w-11/12 lg:w-8/12">
              {/* middle portion */}
              <div className="flex basis-[25%] lg:basis-[30%] 2xl:basis-[33%] justify-center sm:items-center">
                <DatePicker
                  styles={customStylesDark}
                  startDate={startDate}
                  endDate={endDate}
                  onDateChange={onDateChange}
                  setEndDate={setEndDate}
                />
              </div>

              {/* right portion */}
              <div className="basis-[25%] lg:basis-[30%] 2xl:basis-[33%] flex w-full items-end gap-2 flex-col lg:relative left-[8%]">
                <button
                  className="h-10 px-2 py-0 mx-1 text-xs font-bold no-underline border-0 rounded w-28 sm:w-40 bg-spekterGreen text-spekterDarkOne dark:text-SpekterDarkOne"
                  onClick={onPressedSelectButton}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : t("show")}
                </button>
                {endDate && (
                  <CSVLink
                    className={allSensorChartData.length !== 0 ? "" : "hidden"}
                    onClick={downloadCsv}
                    data={csvData}
                    filename={`Analyse_${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}.csv`}
                    separator={","}
                    target="_blank"
                  >
                    <button
                      className="h-10 px-2 py-0 mx-1 text-xs font-bold no-underline border-0 rounded w-28 sm:w-40 bg-spekterDarkOne disabled:bg-gray-200 disabled:dark:bg-opacity-5 text-spekterWhite dark:text-spekterDarkOne dark:bg-neutral-200"
                      disabled={isLoading}
                    >
                      {t("csv")}
                    </button>
                  </CSVLink>
                )}
              </div>
            </div>
          </section>
          <hr className="hidden w-full m-0 mt-2 mb-3 border-b border-spekterDarkThree sm:block" />
        </div>
        {/* mix chart */}

        <div className="flex flex-col items-center w-full mt-2 overflow-x-hidden overflow-y-auto sm:scrollbarLight dark:sm:scrollbar">
          {/* Mix chart code */}
          {/* {isLoading ? (
            <div className="grid h-96 place-content-center">
              <Spinner />{" "}
            </div>
          ) : allSensorChartData.length !== 0 ? (
            <MixChart allSensorChartData={allSensorChartData} />
          ) : (
            <div className="grid h-96 place-content-center">
              <SensorSelectMessage isThemeDark={isThemeDark} />
            </div>
          )} */}

          {/* ---Single charts--- */}
          {/* <div className={styles.SingleChart__Grid}> */}
          <div className="grid grid-cols-6 md:grid-cols-12 xl:gap-x-20 gap-y-6 w-full sm:p-2.5 my-2 mx-2">
            {singleSmartRivers.map((sensorData) => (
              <div
                className="w-full col-span-12 py-4 text-spekterWhite bg-spekterDarkOne rounded-xl xl:col-span-6"
                key={sensorData.sensorId}
              >
                <SingleSmartRiver
                  data={sensorData}
                  optionalData={smartRiverOptionalData[sensorData.sensorId]}
                />
              </div>
            ))}

            {singleHdcs.map((sensorData) => (
              <div
                className="w-full col-span-12 py-4 text-spekterWhite bg-spekterDarkOne rounded-xl xl:col-span-6"
                key={sensorData.sensorId}
              >
                <SingleHdc sensorData={sensorData} />
              </div>
            ))}

            {singleGfls.map((sensorData) => (
              <div
                className="w-full col-span-12 py-4 text-spekterWhite bg-spekterDarkOne rounded-xl xl:col-span-6"
                key={sensorData.sensorId}
              >
                <SingleGfl
                  data={sensorData}
                  optionalData={gflOptionalData[sensorData.sensorId]}
                />
              </div>
            ))}
          </div>
        </div>
        {showExplanationModal && (
          <AnalyticsModal setShowExplanationModal={setShowExplanationModal} />
        )}
      </div>
    </div>
  );
};

export default Analytics;
