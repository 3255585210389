import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { useImmer } from "use-immer";
import * as actions from "../../../../redux/actions/";
import CustomBackdrop from "./CustomBackdrop";
import useObserver from "../../../../hooks/useObserver";
import useRole from "../../../../hooks/useRole";
import useAboutModal from "../../../../hooks/useAboutModal";
import useAgs from "../../../../hooks/useAgs";
import { useSearchParams } from "react-router-dom";
import useRoles from "../../../../hooks/useRoles";

const pages = {
  REALTIME_MAP: "realtime_map",
  SENSOR_MANAGER: "senosr_manager",
  WEBVIEWER_MAP: "webviewer_map",
  USERMANAGEMENT: "user_management",
  ANALYTICS: "analytics",
  SETTINGS: "settings",
  NOTIFICATION: "notification",
  ABOUT: "about",
  // LOGOUT: "logout",
};

const Menu = ({
  open,
  setOpen,
  setShowLogoutModal,
  setNotificationFlag,
  notificationFlag,
  bellIconPath,
  setExpandNotifications,
}) => {
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  let textColor = isThemeDark ? "text-spekterWhite" : "text-spekterDarkOne";

  const { t, i18n } = useTranslation("navigation");
  const [currentPage, setcurrentPage] = useState(pages.REALTIME_MAP);
  const [searchParams, setSearchParams] = useSearchParams();

  // const [role, setRole] = useState();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { showAboutModal, setShowAboutModal } = useAboutModal();
  const agsList = useAgs();
  const userRole = useRole();
  const userRoles = useRoles();
  let appMode = useObserver() ? "observer" : "non-observer";
  const activeModules = useSelector(
    (state) => state.customer?.data?.activeModules
  );
  const selectedObserverAgs = useSelector(
    (state) => state.observer.selectedAgs
  );

  useEffect(() => {
    if (!pathname) return;
    switch (pathname) {
      case "/realtimemap":
        setcurrentPage(pages.REALTIME_MAP);
        break;
      case "/sensormanager":
        setcurrentPage(pages.SENSOR_MANAGER);
        break;
      case "/usermanagement":
        setcurrentPage(pages.USERMANAGEMENT);
        break;
      case "/analytics":
        setcurrentPage(pages.ANALYTICS);
        break;
      case "/webviewer":
        setcurrentPage(pages.WEBVIEWER_MAP);
        break;
      case "/setting":
        setcurrentPage(pages.SETTINGS);
        break;

      case "/notifications":
        setcurrentPage(pages.NOTIFICATION);
        break;
      default:
        setcurrentPage(pages.REALTIME_MAP);
    }
  }, [pathname]);

  const pageOnClick = (page) => {
    switch (page) {
      case pages.REALTIME_MAP:
        setNotificationFlag(false);
        dispatch(actions.smartRiverSensorReset());
        dispatch(actions.gflSensorReset());
        dispatch(actions.hdcSensorReset());
        navigate("/");
        break;
      case pages.SENSOR_MANAGER:
        setNotificationFlag(false);
        navigate("/sensormanager");
        break;
      case pages.WEBVIEWER_MAP:
        setNotificationFlag(false);
        dispatch(actions.smartRiverSensorReset());
        dispatch(actions.gflSensorReset());
        dispatch(actions.hdcSensorReset());

        const ags = selectedObserverAgs || agsList[0];
        navigate("/webviewer?ags=" + ags);

        break;
      case pages.USERMANAGEMENT:
        setNotificationFlag(false);
        dispatch(actions.smartRiverSensorReset());
        dispatch(actions.gflSensorReset());
        dispatch(actions.hdcSensorReset());
        navigate("/usermanagement");
        break;
      case pages.ANALYTICS:
        setNotificationFlag(false);
        dispatch(actions.smartRiverSensorReset());
        dispatch(actions.gflSensorReset());
        dispatch(actions.hdcSensorReset());
        navigate("/analytics");
        break;
      case pages.SETTINGS:
        setNotificationFlag(false);
        navigate("/setting?tabName=notifications");
        break;

      case pages.NOTIFICATION:
        setNotificationFlag(false);
        navigate("/notifications");
        break;

      // case pages.ABOUT:
      //   setShowAboutModal(!showAboutModal);
      //   break;

      // case pages.LOGOUT:
      //   logoutClicked();
      //   break;
      default:
        break;
    }
    page !== pages.ABOUT && setOpen(false);
  };

  const itemList = [
    {
      title: `${t("map")}`,
      imgUrlLight: "/images/menu_icons/map_light.svg",
      imgUrlDark: "/images/menu_icons/map_dark.svg",
      allowedUser: ["users", "rescue", "managers", "observer"],
      id: pages.REALTIME_MAP,
      isActive: false,
    },
    {
      title: `${t("sensorMgmt")}`,
      imgUrlLight: "images/menu_icons/sensor_manager_light.svg",
      imgUrlDark: "images/menu_icons/sensor_manager_dark.svg",
      allowedUser: ["rescue", "managers", "observer"],
      id: pages.SENSOR_MANAGER,
      isActive: false,
    },
    {
      title: `${t("userMgmt")}`,
      imgUrlLight: "images/menu_icons/user_icon_light.svg",
      imgUrlDark: "images/menu_icons/user_icon_dark.svg",
      allowedUser: ["managers", "observer"],
      id: pages.USERMANAGEMENT,
      isActive: false,
    },
    {
      title: `${t("analytics")}`,
      imgUrlLight: "images/menu_icons/analytics_icon_light.svg",
      imgUrlDark: "images/menu_icons/analytics_icon_dark.svg",
      allowedUser: ["managers", "observer"],
      id: pages.ANALYTICS,
      isActive: false,
    },
    {
      title: `${t("webViewer")}`,
      imgUrlLight: "images/menu_icons/web_view_icon_light.svg",
      imgUrlDark: "images/menu_icons/web_view_icon_dark.svg",
      allowedUser: ["managers", "observer"],
      id: pages.WEBVIEWER_MAP,
      isActive: false,
    },
    {
      title: `${t("settings")}`,
      imgUrlLight: "images/menu_icons/icons8-settings-100-light.svg",
      imgUrlDark: "images/menu_icons/icons8-settings-100-dark.svg",
      allowedUser: ["users", "rescue", "managers"],
      id: pages.SETTINGS,
      isActive: false,
    },
    // {
    //   title: "Risk Profile",
    //   imgUrlLight: "/images/menu_icons/risk_profile_light.png",
    //   imgUrlDark: "/images/menu_icons/risk_profile_dark.png",
    //   allowedUser: ["managers"],
    //   id: pages.RISK_PROFILE,
    //   isActive: false,
    // },
    // {
    //   title: "Notifications",
    //   imgUrlLight: "/images/menu_icons/icons8-notification-96-light.png",
    //   imgUrlDark: "/images/menu_icons/icons8-notification-96-dark.png",
    //   allowedUser: ["users", "rescue", "managers", "observer", "non-observer"],
    //   id: pages.NOTIFICATION,
    //   isActive: true,
    // },
    // {
    //   title: "Logout",
    //   imgUrlLight: "images/menu_icons/icons8-logout-96-light.png",
    //   imgUrlDark: "images/menu_icons/icons8-logout-96-dark.png",
    //   allowedUser: ["users", "rescue", "managers", "observer", "non-observer"],
    //   id: pages.LOGOUT,
    //   isActive: true,
    // },
    // {
    //   title: `${t("about")}`,
    //   imgUrlLight: "images/menu_icons/about-icon-light.png",
    //   imgUrlDark: "images/menu_icons/about-icon-dark.png",
    //   allowedUser: ["users", "rescue", "managers", "observer"],
    //   id: pages.ABOUT,
    //   isActive: true,
    // },
  ];

  const logoutClicked = () => {
    setNotificationFlag(false);
    setOpen(false);
    setShowLogoutModal(true);
  };

  const handleThemeSwitch = () => {
    setNotificationFlag(false);
    dispatch(actions.toggleTheme());
  };

  const [menuItems, setMenuItems] = useImmer([]);

  useEffect(() => {
    setMenuItems(itemList);
    if (!activeModules) return;
    let keys = Object.keys(activeModules);
    setMenuItems((draft) => {
      // swap position of realtime map and webviewer, if realtime map is not active
      if (
        activeModules["realtimeMap"] === false &&
        activeModules["webViewer"] === true
      ) {
        let temp = draft[0];
        draft[0] = draft[4];
        draft[4] = temp;
      }

      keys.forEach((key) => {
        if (key === "realtimeMap") {
          //realtime map
          draft[0].id === pages.REALTIME_MAP
            ? (draft[0].isActive = activeModules[key])
            : (draft[4].isActive = activeModules[key]);
        } else if (key === "sensorManager") {
          //sensor manager
          draft[1].isActive = activeModules[key];
        } else if (key === "userManagement") {
          // user management
          draft[2].isActive = activeModules[key];
        } else if (key === "analytics") {
          //analytics
          draft[3].isActive = activeModules[key];
        } else if (key === "webViewer") {
          //webviewer
          draft[4].id === pages.WEBVIEWER_MAP
            ? (draft[4].isActive = activeModules[key])
            : (draft[0].isActive = activeModules[key]);
        } else if (key === "userSettings") {
          draft[5].isActive = activeModules[key];
        }
        // else if (key === "riskReport") {
        //   // riskReport profile
        //   draft[5].isActive = activeModules[key];
        // }
      });
    });
  }, [activeModules]);

  let themeSwitch = (
    <div
      onClick={handleThemeSwitch}
      className="box-border items-center gap-3 scale-75"
    >
      <input
        type="checkbox"
        className="checkbox"
        id="checkbox"
        checked={isThemeDark}
      />
      <label htmlFor="checkbox" className="border border-gray-500 label">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="white"
          className="bi bi-moon-fill"
          viewBox="0 0 16 16"
        >
          <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="white"
          className="bi bi-sun-fill"
          viewBox="0 0 16 16"
        >
          <path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
        </svg>
        <div className="ball" />
      </label>
    </div>
  );

  return (
    <div className="flex xl:hidden">
      <div
        className={`fixed w-[350px] h-[100vh] right-0 top-0 bg-white/[.69] backdrop-blur-[61.5px] dark:bg-black/[.69]
         dark:text-spekterWhite text-spekterDarkOne box-border mt-[56px] transition-transform transform overflow-auto duration-300 ${
           open ? "translate-x-0" : "translate-x-full"
         }`}
        style={{ zIndex: 500 }}
      >
        <main className="flex flex-col items-center font-bold max-h-[85%]">
          <div className="text-[#818181]  text-[20px] self-start px-8 mt-5">
            Funktionen
          </div>
          <nav className="relative w-full h-full px-4 py-8 overflow-y-auto">
            {menuItems.map((items, index) => {
              if (appMode === "observer") {
                // ---------------
                //No need to check isActive (activeModule) for realtime-map and sensor-manager
                if (
                  items.id === pages.REALTIME_MAP ||
                  items.id === pages.SENSOR_MANAGER
                ) {
                  return (
                    <>
                      {items.allowedUser.includes(appMode) && (
                        <div
                          key={items.id}
                          className="flex items-center gap-3 m-1"
                          // className="flex items-center gap-3 m-1 hover:cursor-pointer hover:bg-spekterRed hover:text-white hover:rounded"
                          onClick={() => pageOnClick(items.id)}
                        >
                          <div
                            className={`flex h-10 w-10 p-2 flex-col justify-center items-center rounded-xl text-white text-center cursor-pointer hover:bg-spekterRed 
                  ${currentPage === items.id ? "bg-spekterRed" : ""}`}
                          >
                            <div>
                              <img
                                className="w-full"
                                alt={items.id}
                                src={
                                  isThemeDark
                                    ? items.imgUrlLight
                                    : items.imgUrlDark
                                }
                              />
                            </div>
                          </div>
                          <span>{items.title}</span>
                        </div>
                      )}
                      {index === 3 && (
                        <hr className="my-4 border-b border-gray-700" />
                      )}
                    </>
                  );
                }

                // -------------------------
                // show Webviewer, if particular observer area is selected
                if (items.id === pages.WEBVIEWER_MAP) {
                  if (!searchParams.get("ags") && !selectedObserverAgs)
                    return null;

                  return (
                    <>
                      {items.allowedUser.includes(appMode) &&
                        items.isActive && (
                          <div
                            key={items.id}
                            className="flex items-center gap-3 m-1"
                            // className="flex items-center gap-3 m-1 hover:cursor-pointer hover:bg-spekterRed hover:text-white hover:rounded"
                            onClick={() => pageOnClick(items.id)}
                          >
                            <div
                              className={`flex h-10 w-10 p-2 flex-col justify-center items-center rounded-xl text-white text-center cursor-pointer hover:bg-spekterRed 
                      ${currentPage === items.id ? "bg-spekterRed" : ""}`}
                            >
                              <div>
                                <img
                                  className="w-full"
                                  alt={items.id}
                                  src={
                                    isThemeDark
                                      ? items.imgUrlLight
                                      : items.imgUrlDark
                                  }
                                />
                              </div>
                            </div>
                            <span>{items.title}</span>
                          </div>
                        )}
                      {index === 3 && (
                        <hr className="my-4 border-b border-gray-700" />
                      )}
                    </>
                  );
                }
                // ---------------
                //check isActive (activeModule) for sensor-manager, user-management, analytics

                return (
                  <>
                    {items.allowedUser.includes(appMode) &&
                      selectedObserverAgs &&
                      (userRoles[selectedObserverAgs] || []).includes(
                        "managers"
                      ) && (
                        <div
                          key={items.id}
                          className="flex items-center gap-3 m-1"
                          // className="flex items-center gap-3 m-1 hover:cursor-pointer hover:bg-spekterRed hover:text-white hover:rounded"
                          onClick={() => pageOnClick(items.id)}
                        >
                          <div
                            className={`flex h-10 w-10 p-2 flex-col justify-center items-center rounded-xl text-white text-center cursor-pointer hover:bg-spekterRed 
                  ${currentPage === items.id ? "bg-spekterRed" : ""}`}
                          >
                            <div>
                              <img
                                className="w-full"
                                alt={items.id}
                                src={
                                  isThemeDark
                                    ? items.imgUrlLight
                                    : items.imgUrlDark
                                }
                              />
                            </div>
                          </div>
                          <span>{items.title}</span>
                        </div>
                      )}
                    {/* {index === 3 && (
                      <hr className="my-4 border-b border-gray-700" />
                    )} */}
                  </>
                );
              } else {
                return (
                  <>
                    {items.allowedUser.includes(userRole) && items.isActive && (
                      <div
                        key={items.id}
                        className="flex items-center gap-3 m-1"
                        // className="flex items-center gap-3 m-1 hover:cursor-pointer hover:bg-spekterRed hover:text-white hover:rounded"
                        onClick={() => pageOnClick(items.id)}
                      >
                        <div
                          className={`flex h-10 w-10 p-2 flex-col justify-center items-center rounded-xl text-white text-center cursor-pointer hover:bg-spekterRed 
                    ${currentPage === items.id ? "bg-spekterRed" : ""}`}
                        >
                          <div>
                            <img
                              className="w-full"
                              alt={items.id}
                              src={
                                isThemeDark
                                  ? items.imgUrlLight
                                  : items.imgUrlDark
                              }
                            />
                          </div>
                        </div>
                        <span>{items.title}</span>
                      </div>
                    )}
                    {index === 4 && (
                      <hr className="my-4 border-b border-gray-700" />
                    )}
                  </>
                );
              }
            })}
            {/* <div
              onClick={() => {
                setExpandNotifications(false);
                setOpen(false);
                open && !notificationFlag
                  ? setTimeout(() => {
                      setNotificationFlag((prev) => !prev);
                    }, 500)
                  : setNotificationFlag((prev) => !prev);
              }}
              className="flex items-center gap-3 p-2 m-1"
            >
              <img className="w-6" alt="Notification" src={bellIconPath} />
              <span className="pl-2">
                {`${t("notification")} : ${notificationFlag ? "On" : "Off"}`}
              </span>
              {/* <span>{notificationFlag ? "On" : "Off"}</span> 
              </div>*/}

            {/* <div
            {appMode === "observer" && (
              <hr className="my-4 border-b border-gray-700" />
            )}
            <div
              onClick={() => setShowAboutModal(!showAboutModal)}
              className="flex items-center gap-3 p-2 m-1"
            >
              <img
                className="w-6"
                alt="about icon"
                src={
                  isThemeDark
                    ? "images/menu_icons/about-icon-light.svg"
                    : "images/menu_icons/about-icon-dark.svg"
                }
              />
              <span className="pl-2">{t("about")}</span>
            </div>

            <div
              onClick={handleThemeSwitch}
              className="flex items-center gap-3 m-1"
            >
              <div
                className={`flex h-10 w-10 p-2 flex-col justify-center items-center rounded-xl text-white text-center cursor-pointer
                  `}
              >
                <div>{themeSwitch}</div>
              </div>
              <span>{t("mode")}</span>
            </div> */}
            <div
              onClick={logoutClicked}
              className="flex items-center gap-3 p-2 m-1"
            >
              <img
                className="w-6"
                alt="Logout"
                src={
                  isThemeDark
                    ? "images/menu_icons/icons8-logout-96-light.svg"
                    : "images/menu_icons/icons8-logout-96-dark.svg"
                }
              />
              <span className="pl-2">{t("logout")}</span>
            </div>
          </nav>
        </main>
      </div>
    </div>
  );
};

export default Menu;
