import { toast } from "sonner";

export const message = (message) => {
  return toast.message(message);
};

export const error = (message) => {
  return toast.error(message);
};

export const success = (message) => {
  return toast.success(message);
};
