import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as toast from "../../../UI/Toaster";
import * as actions from "../../../../redux/actions/index";
import { deleteCustomSubscribersAction } from "../../../../redux/actions/userManagerActions/deleteCustomSubscriber";
import { useTranslation } from "react-i18next";

const ConfirmDelete = ({ ags, setShowDeleteModal, id, paginate }) => {
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const dispatch = useDispatch();
  const { t } = useTranslation("modal");
  const successToast = (notifyText) =>
    toast.success(<div>{notifyText}</div>, {
      position: "top-center",
      style: { background: "#07bc0c" },
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });

  const errorToast = (notifyText) =>
    toast.error(<div>{notifyText}</div>, {
      position: "top-center",
      style: { background: "#FF0000" },
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: 0,
    });

  const handleDelete = () => {
    const deleteResponse = dispatch(deleteCustomSubscribersAction(ags, id));

    deleteResponse.then(function (result) {
      if (result?.type === "DELETE_CUSTOM_SUBSCRIBER_SUCCESS") {
        toast.success(t("subscriberDeletedSuccessfully"));
        dispatch(
          actions.allCustomSubscribersAction(ags, {
            page: 1,
            limit: 10,
            sort: -1,
            searchTerm: "",
          })
        );
        paginate(1);
        setShowDeleteModal(false);
      }

      if (result?.error && result.error?.message === "Network Error") {
        toast.error(t("serverIsNotResponding"));
      } else if (result?.type === "DELETE_CUSTOM_SUBSCRIBER_FAIL") {
        toast.error(t("failed"));
      }
    });
  };

  return (
    <div>
      <div className="fixed top-0 left-0 z-30 flex items-center justify-center w-full h-full py-24 overflow-auto backdrop-blur-sm">
        <div
          className={`relative bg-spekterWhite dark:bg-boxBG m-auto  flex flex-col justify-between rounded-10
                          ${
                            isThemeDark ? "" : "shadow-modal"
                          } p-0 w-[95%] md:w-[70%] lg:w-[50%] xl:w-[35%] 2xl:w-[550px] bg-blue-100`}
        >
          <p className="py-3 pl-8 font-semibold text-gray-700 dark:text-gray-100 bg-spekterWhite dark:bg-boxBG rounded-t-10">
            {t("deleteCustomSubscriberQuestion")}
          </p>
          <p className="py-3 pl-8 text-gray-700 dark:text-gray-100 text-md">
            {t("deleteCustomSubscriberMessage")}
          </p>
          <div className="flex justify-end gap-5 py-3 pr-10 bg-spekterWhite dark:bg-boxBG rounded-b-10">
            <button
              onClick={() => setShowDeleteModal(false)}
              className="border-[1px] border-gray-400 w-24 rounded-5 py-1 text-base text-gray-600 dark:text-gray-400"
            >
              {t("deleteCustomSubscriberButtonCancel")}
            </button>
            <button
              onClick={handleDelete}
              className="w-24 py-1 text-base bg-spekterRed rounded-5 text-spekterWhite dark:text-gray-900"
            >
              {t("deleteCustomSubscriberButtonConfirm")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDelete;
