import React, { useEffect, useState } from "react";
import AttachmentField from "./AttachmentField";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { toast } from "sonner";
import * as actions from "../../../../redux/actions";
import Axios from "../../../../services/NetworkService";
import axios from "axios";
import ConfirmationDialog from "./ConfirmationDialog";
import { useTranslation } from "react-i18next";
import usePodAgs from "../../../../hooks/usePodAgs";
import CreatableSelect from "react-select/creatable";
import DeleteOptionConfirmModal from "./DeleteOptionConfirmModal";
import { customStylesDark, customStylesLight } from "./DropdownStyles";
import {
  CustomCategoryOption,
  CustomMenu,
  CustomOption,
} from "./CustomDropdownComponents";
// import { cn } from "../../../../shared/Utility";
// import { set } from "date-fns";
// import { el } from "date-fns/locale";
// import { DevTool } from "@hookform/devtools";
// import { ErrorMessage } from "@hookform/error-message";
// import PlacesAutocomplete from "react-places-autocomplete";
// import makeAnimated from "react-select/animated";
// import Loader from "react-loader-spinner";

const fileUploadUrl = `/pods/getUploadUrl`;
const DESCRIPTION_TEXT =
  "Bei Auslösung des o.g. Alarm- und Einsatzplans erledigen die o.g. Einheiten/Organisationen folgende Aufträge:";

const uploadAttachment = async (files) => {
  let uploadedFileNames = [];
  let fileNames = [];

  if (files.length > 0) {
    for (let i = 0; i < files.length; i++) {
      fileNames.push(files[i].name);
    }

    await Axios.post(
      fileUploadUrl,
      {
        fileNames: fileNames,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(async (response) => {
        for (let i = 0; i < files.length; i++) {
          await axios.put(response.data[i].uploadUrl, files[i]);
          uploadedFileNames.push(response.data[i].fileName);
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  return uploadedFileNames;
};

const deleteAttachment = async (podId, fileName) => {
  const url = `/pods/removeFile?podId=${podId}&fileName=${fileName}`;
  try {
    const response = await Axios.delete(url);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

const postNewPod = async (ags, payload, files) => {
  // setIsLoadingNewPod(true);
  const uploadedFileUrl = await uploadAttachment(files);

  payload["files"] = uploadedFileUrl;

  try {
    const response = await Axios.post(`/pods/createPod?ags=${ags}`, payload);
    // setIsLoadingNewPod(false);
    return response;
  } catch (error) {
    // setIsLoadingNewPod(false);
    throw error;
  }
};

const patchPod = async (id, payload, newFiles) => {
  const existingFileNames = payload["files"];

  const uploadedFileNames = await uploadAttachment(newFiles);
  payload["files"] = [...existingFileNames, ...uploadedFileNames];

  // setIsLoadingNewPod(true);
  try {
    const response = await Axios.patch(`/pods/${id}`, payload);
    // setIsLoadingNewPod(false);
    return response;
  } catch (error) {
    // setIsLoadingNewPod(false);
    throw error;
  }
};

const deletePod = async (id) => {
  try {
    const response = await Axios.delete(`/pods/remove/${id}`);
    // setIsLoadingNewPod(false);
    return response;
  } catch (error) {
    // setIsLoadingNewPod(false);
    throw error;
  }
};

const getPodMetaData = async (ags) => {
  try {
    const response = await Axios.get(`/podmetadata/getAll?ags=${ags}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const createPodMetaData = async (ags, type, value) => {
  try {
    const response = await Axios.post(`/podmetadata/create?ags=${ags}`, {
      metadataname: type,
      value: value,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
const deletePodMetaData = async (ags, type, value) => {
  try {
    const response = await Axios.delete(`/podmetadata/remove?ags=${ags}`, {
      data: {
        metadataname: type,
        value: value,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const CreateAndEditModal = ({
  selectedPod,
  setSelectedPod,
  setShowPodsModal,
  setIsAddNewPod,
  newLatLng = {},
  setNewMarkerInstruction,
}) => {
  //language package
  const { t, i18n } = useTranslation("realtimeMap");
  const dispatch = useDispatch();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  // const newPodMarker = useSelector((state) => state.pod.newMarker);
  const [newFiles, setNewFiles] = useState([]);

  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const podAgs = usePodAgs();

  // pods metadata
  const [aepTypes, setAepTypes] = useState([]);
  const [orderSheetTypes, setOrderSheetTypes] = useState([]);
  // const [forTypes, setForTypes] = useState([]);

  const [isLoadingAepType, setIsLoadingAepType] = useState(false);
  const [isLoadingOrderSheetType, setIsLoadingOrderSheetType] = useState(false);
  // const [isLoadingForType, setIsLoadingForType] = useState(false);

  const [showDeleteOptionModal, setShowDeleteOptionModal] = useState(false);
  const [optionData, setOptionData] = useState(null);

  // get pods metadata
  useEffect(() => {
    const fetchPodMetaData = async () => {
      setIsLoadingAepType(true);
      setIsLoadingOrderSheetType(true);
      // setIsLoadingForType(true);

      const response = await getPodMetaData(podAgs);

      const podsMetaData = response.data;
      const aepTypes = podsMetaData?.aepTypes?.map((type) => {
        return {
          label: type,
          value: type,
        };
      });
      setAepTypes(aepTypes);

      const orderSheets = podsMetaData?.orderSheetOptions?.map((type) => {
        return {
          label: type,
          value: type,
        };
      });
      setOrderSheetTypes(orderSheets);

      // const forTypes = podsMetaData?.forOptions?.map((type) => {
      //   return {
      //     label: type,
      //     value: type,
      //   };
      // });
      // setForTypes(forTypes);

      setIsLoadingAepType(false);
      setIsLoadingOrderSheetType(false);
      // setIsLoadingForType(false);
    };

    fetchPodMetaData();
  }, [podAgs]);

  //category options
  const categoryOptions = [
    {
      label: t("firefighers"),
      value: "firefighters",
      color: "#A72920",
    },
    {
      label: t("administrationalService"),
      value: "administration",
      color: "#F9A800",
    },
    {
      label: t("thw"),
      value: "thw",
      color: "#20603D",
    },
    {
      label: t("police"),
      value: "police",
      color: "#00387B",
    },
  ];

  const deletePodHandler = (id) => {
    toast.promise(deletePod(id), {
      loading: t("loading"),
      success: (data) => {
        dispatch(actions.pods(podAgs));
        reset({ title: "", description: "", category: [] });
        setShowPodsModal(false);
        setIsAddNewPod(false);
        setSelectedPod(null);
        return `${t("PODDeleted")}`;
      },
      error: t("somethingWentWrong"),
    });
  };

  const deleteAttachmentHandler = (podId, fileName) => {
    toast.promise(deleteAttachment(podId, fileName), {
      loading: t("loading"),
      success: (data) => {
        dispatch(actions.pods(podAgs));

        // update local file
        selectedPod.files = selectedPod.files.filter(
          (file) => file.fileName !== fileName
        );
        return `${t("attachmentDeleted")}`;
      },
      error: t("somethingWentWrong"),
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    // return;
    const fileArray = [];
    if (selectedPod) {
      fileArray.push(...selectedPod.files.map((file) => file.fileName));
    }

    const payload = {
      aepName: data?.aepName,
      type: data?.type ? data?.type?.value : "",
      orderSheet: data?.orderSheet ? data?.orderSheet?.value : "",
      for: data?.for ? data?.for?.value : "",
      commandAssemblyPoint: data?.commandAssemblyPoint,
      descriptionText: data?.description,
      location: newLatLng,
      category: data?.category?.value,
      address: {
        street: data?.street,
        streetNr: data?.streetNr,
        zipCode: data?.zipCode,
        municipality: data?.municipality,
      },
      contact: {
        phoneNumber: data?.phoneNumber,
        faxNumber: data?.faxNumber,
      },
      files: fileArray,
    };

    // when editing, if the value is not on the list, removing the key from the payload
    if (selectedPod) {
      const orderSheetValue = orderSheetTypes.find(
        (type) => type.value === payload.orderSheet
      );
      if (!orderSheetValue) {
        payload.orderSheet = "";
      }

      // const forValue = forTypes.find((type) => type.value === payload.for);
      // if (!forValue) {
      //   payload.for = "";
      // }

      const typeValue = aepTypes.find((type) => type.value === payload.type);
      if (!typeValue) {
        payload.type = "";
      }
    }

    // check if new files are more than 5
    if (newFiles.length + fileArray.length > 5) {
      toast.error(t("canUpload5Files"));
      return;
    }

    let selectedPromise;
    if (selectedPod) {
      selectedPromise = patchPod(selectedPod._id, payload, newFiles);
    } else {
      selectedPromise = postNewPod(podAgs, payload, newFiles);
    }

    toast.promise(selectedPromise, {
      loading: t("loading"),
      success: (data) => {
        // reset({ title: "", description: "", category: [] });

        dispatch(actions.pods(podAgs));
        setShowPodsModal(false);
        setIsAddNewPod(false);
        setSelectedPod(null);
        return `${t("PODHasBeen")} ${
          selectedPod ? `${t("updated")}` : `${t("added")}`
        }.`;
      },
      error: (err) => {
        return t("somethingWentWrong");
      },
    });
  };

  // handle pod marker click
  useEffect(() => {
    if (selectedPod) {
      reset({
        aepName: selectedPod?.aepName,
        type: selectedPod?.type && {
          label: selectedPod?.type,
          value: selectedPod?.type,
        },
        orderSheet: selectedPod?.orderSheet && {
          label: selectedPod?.orderSheet,
          value: selectedPod?.orderSheet,
        },
        // for: selectedPod?.for && {
        //   label: selectedPod?.for,
        //   value: selectedPod?.for,
        // },
        category:
          selectedPod?.category && selectedPod?.category === "administration"
            ? {
                label: t("administrationalService"),
                value: selectedPod?.category,
              }
            : selectedPod?.category && selectedPod?.category === "firefighters"
            ? {
                label: t("firefighers"),
                value: selectedPod?.category,
              }
            : selectedPod?.category && selectedPod?.category === "police"
            ? {
                label: t("police"),
                value: selectedPod?.category,
              }
            : {
                label: t("thw"),
                value: selectedPod?.category,
              },
        commandAssemblyPoint: selectedPod?.commandAssemblyPoint,
        description: selectedPod?.descriptionText,
        street: selectedPod?.address?.street,
        streetNr: selectedPod?.address?.streetNr,
        zipCode: selectedPod?.address?.zipCode,
        municipality: selectedPod?.address?.municipality,
        phoneNumber: selectedPod?.contact?.phoneNumber,
        faxNumber: selectedPod?.contact?.faxNumber,
      });
    } else {
      reset({
        aepName: "",
        type: "",
        orderSheet: "",
        // for: "",
        category: "",
        commandAssemblyPoint: "",
        description: "",
        street: "",
        streetNr: "",
        zipCode: "",
        municipality: "",
        phoneNumber: "",
        faxNumber: "",
      });
    }
  }, [selectedPod, reset]);

  const deletePodMetaDataHandler = async () => {
    const setFunction =
      optionData.metadataname === "aepTypes"
        ? setAepTypes
        : // : optionData.metadataname === "forOptions"
          // ? setForTypes
          optionData.metadataname === "orderSheetOptions" && setOrderSheetTypes;

    const loaderFunction =
      optionData.metadataname === "aepTypes"
        ? setIsLoadingAepType
        : // : optionData.metadataname === "forOptions"
          // ? setIsLoadingForType
          optionData.metadataname === "orderSheetOptions" &&
          setIsLoadingOrderSheetType;

    loaderFunction && loaderFunction(true);

    const response = await deletePodMetaData(
      podAgs,
      optionData.metadataname,
      optionData.label
    );

    if (response.status === 200) {
      loaderFunction(false);
      setFunction((prev) =>
        prev.filter((opt) => opt.value !== optionData?.label)
      );
      toast.success(t("optionDeletedSuccessfully"));
    } else {
      loaderFunction(false);
      toast.error(t("somethingWentWrong"));
    }
    setShowDeleteOptionModal(false);
  };

  return (
    <div className="fixed right-0 flex flex-col items-center justify-center w-full h-[calc(100vh_-_420px)] sm:h-[calc(100vh_-_320px)]">
      {showDeleteConfirmModal && (
        <ConfirmationDialog
          setShowDeleteConfirmModal={setShowDeleteConfirmModal}
          onDelete={() => deletePodHandler(selectedPod._id)}
          selectedPod={selectedPod}
        />
      )}
      {showDeleteOptionModal && (
        <DeleteOptionConfirmModal
          setShowDeleteOptionModal={setShowDeleteOptionModal}
          optionData={optionData}
          deletePodMetaDataHandler={deletePodMetaDataHandler}
        />
      )}
      <section
        className="relative flex flex-col gap-5 p-[23px] md:p-[36px] headerShadowLight dark:text-white text-black max-h-[65vh] sm:max-h-[80vh]
          w-[900px] max-w-[90%] md:max-w-[70%] dark:bg-black/70 bg-white/70  backdrop-blur-[61px] rounded-[18px]"
      >
        <div className="flex justify-between">
          <div className="flex items-center gap-3">
            <img
              src={`/images/pod/POD Pin Icon_${
                isThemeDark ? "ffffff" : "000000"
              }.svg`}
              alt="pod icon"
              className="h-[33px]"
            />
            <span className="text-xl font-bold">
              {`${!selectedPod ? `${t("create")}` : `${t("edit")}`} ${t(
                "createNewAlarm"
              )}`}
            </span>
          </div>

          <img
            onClick={() => {
              setShowPodsModal((prev) => !prev);
              setSelectedPod(null);
              // setIsAddNewPod(false);
              setNewMarkerInstruction(true);
            }}
            src={`/images/pod/X Icon Bold_${
              isThemeDark ? "ffffff" : "000000"
            }.svg`}
            alt="pod icon"
            className="self-start w-3 duration-300 cursor-pointer hover:opacity-70"
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col w-full gap-8 h-[calc(100%_-_60px)] lg:h-[calc(100%_-_36px)]"
        >
          <div className="h-full pr-1 overflow-y-auto scrollbarLight dark:scrollbar">
            <div className="flex flex-col items-start justify-center gap-3">
              <div className="flex flex-col items-start justify-between w-full gap-7 md:flex-row">
                {/* Left section */}
                <section className="flex flex-col w-full gap-5">
                  <div className="w-full mb-2">
                    <input
                      className="w-full px-3 py-2 mt-1 dark:text-[#fff] text-[#333] text-[15px] font-normal rounded-[6px] bg-[#C9C9C9] dark:bg-[#333333] focus:outline-none"
                      placeholder={t("addAepName")}
                      {...register("aepName", {})}
                    />
                  </div>
                  <div>
                    <span className="text-[15px] font-bold">
                      {t("orderSheet")}
                    </span>
                    <Controller
                      control={control}
                      name="orderSheet"
                      rules={{}}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <>
                          <div className="flex">
                            <CreatableSelect
                              components={{
                                Option: (optionProps) => (
                                  <CustomOption
                                    {...optionProps}
                                    setShowDeleteOptionModal={
                                      setShowDeleteOptionModal
                                    }
                                    setOptionData={setOptionData}
                                    metadataname="orderSheetOptions"
                                    isThemeDark
                                  />
                                ),
                                Menu: CustomMenu,
                              }}
                              ref={ref}
                              styles={
                                isThemeDark
                                  ? customStylesDark
                                  : customStylesLight
                              }
                              className="w-full font-normal"
                              // classNamePrefix="select"
                              value={value}
                              name={name}
                              isMulti={false}
                              isClearable
                              isDisabled={isLoadingOrderSheetType}
                              isLoading={isLoadingOrderSheetType}
                              placeholder={t("selectionOptional")}
                              onChange={onChange}
                              options={orderSheetTypes}
                              onCreateOption={async (inputValue) => {
                                setIsLoadingOrderSheetType(true);
                                const response = await createPodMetaData(
                                  podAgs,
                                  "orderSheetOptions",
                                  inputValue
                                );

                                if (response.status === 201) {
                                  const newOption = {
                                    label: inputValue,
                                    value: inputValue,
                                  };
                                  setIsLoadingOrderSheetType(false);
                                  setOrderSheetTypes((prev) => [
                                    ...prev,
                                    newOption,
                                  ]);
                                }
                              }}
                            />
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="w-full">
                    <span className="text-[15px] font-bold">
                      {t("commandAndAssemblyPoint")}
                    </span>
                    <input
                      className="w-full px-3 py-2 mt-1 dark:text-[#fff] text-[#333] text-[15px] font-normal rounded-[6px] bg-[#C9C9C9] dark:bg-[#333333] focus:outline-none"
                      placeholder={t("where")}
                      // onChange={(e) => setTitle(e.target.value)}
                      {...register("commandAssemblyPoint")}
                    />
                  </div>
                  <div className="w-full">
                    <span className="text-[15px] font-bold">{t("telNr")}</span>
                    <input
                      className="w-full px-3 py-2 mt-1 dark:text-[#fff] text-[#333] text-[15px] font-normal rounded-[6px] bg-[#C9C9C9] dark:bg-[#333333] focus:outline-none"
                      placeholder={t("phoneNumber")}
                      // onChange={(e) => setTitle(e.target.value)}
                      {...register("phoneNumber")}
                    />
                  </div>
                  <div className="w-full">
                    <span className="text-[15px] font-bold">{t("faxNr")}</span>
                    <input
                      className="w-full px-3 py-2 mt-1 dark:text-[#fff] text-[#333] text-[15px] font-normal rounded-[6px] bg-[#C9C9C9] dark:bg-[#333333] focus:outline-none"
                      placeholder={t("faxNumber")}
                      {...register("faxNumber")}
                    />
                  </div>
                </section>

                {/* Right section */}
                <section className="flex flex-col w-full gap-5 mt-1">
                  <div className="mb-2">
                    <Controller
                      control={control}
                      name="type"
                      rules={{}}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <>
                          <div className="flex">
                            <CreatableSelect
                              components={{
                                Option: (optionProps) => (
                                  <CustomOption
                                    {...optionProps}
                                    setShowDeleteOptionModal={
                                      setShowDeleteOptionModal
                                    }
                                    setOptionData={setOptionData}
                                    metadataname="aepTypes"
                                    isThemeDark
                                  />
                                ),
                                Menu: CustomMenu,
                              }}
                              ref={ref}
                              styles={
                                isThemeDark
                                  ? customStylesDark
                                  : customStylesLight
                              }
                              className="w-full font-normal"
                              classNamePrefix="select"
                              value={value}
                              name={name}
                              isMulti={false}
                              isClearable
                              isDisabled={isLoadingAepType}
                              isLoading={isLoadingAepType}
                              placeholder={t("selectionOptional")}
                              onChange={onChange}
                              options={aepTypes}
                              onCreateOption={async (inputValue) => {
                                setIsLoadingAepType(true);
                                const response = await createPodMetaData(
                                  podAgs,
                                  "aepTypes",
                                  inputValue
                                );

                                if (response.status === 201) {
                                  const newOption = {
                                    label: inputValue,
                                    value: inputValue,
                                  };
                                  setIsLoadingAepType(false);
                                  setAepTypes((prev) => [...prev, newOption]);
                                }
                              }}
                            />
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <span className="text-[15px] font-bold">{t("for")}</span>
                    <Controller
                      control={control}
                      name="category"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value, name, ref },
                        fieldState: { error },
                      }) => {
                        return (
                          <>
                            <div className="relative">
                              <Select
                                components={{
                                  Option: (optionProps) => (
                                    <CustomCategoryOption
                                      {...optionProps}
                                      isThemeDark
                                    />
                                  ),
                                  Menu: CustomMenu,
                                }}
                                ref={ref}
                                styles={
                                  isThemeDark
                                    ? customStylesDark
                                    : customStylesLight
                                }
                                className="w-full mt-1 font-normal capitalize"
                                classNamePrefix="select"
                                value={value}
                                name={name}
                                placeholder={t("selectCategory")}
                                onChange={onChange}
                                options={categoryOptions}
                              />
                              {error && (
                                <p className="absolute text-red-500 text-md">
                                  {t("categoryIsRequired")}
                                </p>
                              )}
                            </div>
                          </>
                        );
                      }}
                    />

                    {/* Previous code */}
                    {/* <Controller
                      control={control}
                      name="for"
                      rules={{}}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <>
                          <div className="flex">
                            <CreatableSelect
                              components={{
                                Option: (optionProps) => (
                                  <CustomOption
                                    {...optionProps}
                                    setShowDeleteOptionModal={
                                      setShowDeleteOptionModal
                                    }
                                    setOptionData={setOptionData}
                                    metadataname="forOptions"
                                    isThemeDark
                                  />
                                ),
                                Menu: CustomMenu,
                              }}
                              ref={ref}
                              styles={
                                isThemeDark
                                  ? customStylesDark
                                  : customStylesLight
                              }
                              className="w-full font-normal"
                              classNamePrefix="select"
                              value={value}
                              name={name}
                              isMulti={false}
                              isClearable
                              isDisabled={isLoadingForType}
                              isLoading={isLoadingForType}
                              placeholder={t("selectionOptional")}
                              onChange={onChange}
                              options={forTypes}
                              onCreateOption={async (inputValue) => {
                                setIsLoadingForType(true);
                                const response = await createPodMetaData(
                                  podAgs,
                                  "forOptions",
                                  inputValue
                                );

                                if (response.status === 201) {
                                  const newOption = {
                                    label: inputValue,
                                    value: inputValue,
                                  };
                                  setIsLoadingForType(false);
                                  setForTypes((prev) => [...prev, newOption]);
                                }
                              }}
                            />
                          </div>
                        </>
                      )}
                    /> */}
                  </div>
                  <div className="flex flex-col gap-2 md:flex-row">
                    <div className="w-full">
                      {/* <Controller
                        control={control}
                        name="street"
                        rules={{ required: "This is required." }}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <PlacesAutocomplete
                            value={value}
                            onChange={onChange}
                            ref={ref}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div className="relative">
                                <div className="relative">
                                  <input
                                    {...getInputProps({
                                      type: "text",
                                    })}
                                    className={cn(
                                      "w-full px-3 py-2 mt-1 dark:text-[#fff] text-[#333] text-[15px] font-normal rounded-[6px] bg-[#C9C9C9] dark:bg-[#333333] focus:outline-none",
                                      {
                                        "border-2 border-red-500":
                                          errors.street,
                                      }
                                    )}
                                    // onFocus={removeAdressePlaceholder}
                                    // onBlur={handleAdresseBlur}
                                    placeholder="Street"
                                  />
                                </div>
                                {suggestions && suggestions.length > 0 && (
                                  <div
                                    className="absolute flex px-1 flex-col 
            justify-end overflow-y-auto w-[100%] scrollbar mx-auto"
                                  >
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const style = {
                                        backgroundColor: suggestion.active
                                          ? "#41b6e6"
                                          : "#2d2d2d",
                                        color: "white",
                                        cursor: "pointer",
                                      };
                                      return (
                                        <div
                                          className={`p-2`}
                                          key={index}
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              style,
                                            }
                                          )}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            )}
                          </PlacesAutocomplete>
                        )}
                      /> */}
                      <span className="text-[15px] font-bold">
                        {t("street")}
                      </span>
                      <input
                        className="w-full px-3 py-2 mt-1 dark:text-[#fff] text-[#333] text-[15px] font-normal rounded-[6px] bg-[#C9C9C9] dark:bg-[#333333] focus:outline-none"
                        placeholder={t("street")}
                        {...register("street")}
                      />
                    </div>
                    <div className="w-full md:w-2/5">
                      <span className="text-[15px] font-bold">
                        {t("streetNr")}
                      </span>
                      <input
                        className="w-full px-3 py-2 mt-1 dark:text-[#fff] text-[#333] text-[15px] font-normal rounded-[6px] bg-[#C9C9C9] dark:bg-[#333333] focus:outline-none"
                        placeholder={t("streetNr")}
                        {...register("streetNr")}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 md:flex-row">
                    <div className="">
                      <span className="text-[15px] font-bold">
                        {t("postalCode")}
                      </span>
                      <input
                        className="w-full px-3 py-2 mt-1 dark:text-[#fff] text-[#333] text-[15px] font-normal rounded-[6px] bg-[#C9C9C9] dark:bg-[#333333] focus:outline-none"
                        placeholder={t("zipCode")}
                        {...register("zipCode")}
                      />
                    </div>
                    <div className="w-full md:w-5/6">
                      <span className="text-[15px] font-bold">{t("city")}</span>
                      <input
                        className="w-full px-3 py-2 mt-1 dark:text-[#fff] text-[#333] text-[15px] font-normal rounded-[6px] bg-[#C9C9C9] dark:bg-[#333333] focus:outline-none"
                        placeholder={t("municipality")}
                        {...register("municipality")}
                      />
                    </div>
                  </div>
                  {/* <div>
                    <span className="text-[15px] font-bold">
                      {t("category")}
                    </span>
                    <Controller
                      control={control}
                      name="category"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value, name, ref },
                        fieldState: { error },
                      }) => {
                        return (
                          <>
                            <div className="relative">
                              <Select
                                components={{
                                  Option: (optionProps) => (
                                    <CustomCategoryOption
                                      {...optionProps}
                                      isThemeDark
                                    />
                                  ),
                                  Menu: CustomMenu,
                                }}
                                ref={ref}
                                styles={
                                  isThemeDark
                                    ? customStylesDark
                                    : customStylesLight
                                }
                                className="w-full mt-1 font-normal capitalize"
                                classNamePrefix="select"
                                value={value}
                                name={name}
                                placeholder={t("selectCategory")}
                                onChange={onChange}
                                options={categoryOptions}
                              />
                              {error && (
                                <p className="absolute text-red-500 text-md">
                                  {t("categoryIsRequired")}
                                </p>
                              )}
                            </div>
                          </>
                        );
                      }}
                    />
                  </div> */}
                </section>
              </div>
              <div className="flex flex-col w-full gap-1 mt-2">
                <span className="text-[15px] font-bold m-0">
                  {t("description")}
                </span>
                <span className="mt-1 text-sm opacity-70">
                  {DESCRIPTION_TEXT}
                </span>
                <textarea
                  className="w-full h-40 px-3 py-2 mt-1 overflow-auto hide-scrollbar max-h-max
                 dark:text-[#fff] text-[#333] text-[15px] font-normal rounded-[6px] bg-[#C9C9C9]
                  dark:bg-[#333333] scrollbar focus:outline-none"
                  placeholder={t("descriptionOptional")}
                  cols="30"
                  rows="10"
                  {...register("description")}
                />
              </div>
              {/* <div className="ml-2 -mt-4 text-sm italic font-normal text-red-500">
                <ErrorMessage errors={errors} name="description" />
              </div> */}
              {selectedPod && selectedPod?.files?.length <= 0 && (
                <p className="font-normal opacity-50">
                  Dateien: Keine Dateien vorhanden
                </p>
              )}
              {selectedPod && selectedPod?.files?.length !== 0 && (
                <div className="w-full">
                  <div className="w-full">
                    <span className="rounded ">Dateien</span>
                    <div className="flex flex-wrap gap-3">
                      {selectedPod &&
                        selectedPod?.files?.map((file) => (
                          <div key={file.name}>
                            <div
                              className="flex justify-between gap-3 items-center font-normal w-[100px] md:w-[150px] text-[.75rem]
                          h-7 px-2 my-1 text-[#000] dark:text-[#fff] bg-[#C9C9C9] dark:bg-[#333333] rounded-[9px]"
                            >
                              <div
                                style={{
                                  width: "max-content",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                className="flex items-center gap-2"
                              >
                                <img
                                  src={`/images/pod/${
                                    isThemeDark
                                      ? "File Icon_c9c9c9_fill.svg"
                                      : "File Icon_000000_fill.svg"
                                  }`}
                                  alt="pod icon"
                                  className="w-[15px]"
                                />

                                <span className="w-[70%] overflow-hidden text-ellipsis whitespace-nowrap">
                                  {file.showName}
                                </span>
                                <div
                                  onClick={() =>
                                    deleteAttachmentHandler(
                                      selectedPod._id,
                                      file.fileName
                                    )
                                  }
                                  className="cursor-pointer"
                                >
                                  <img
                                    src={`/images/pod/X Icon Bold_${
                                      isThemeDark ? "ffffff" : "000000"
                                    }.svg`}
                                    alt="pod icon"
                                    className="self-start w-[12px] duration-300 cursor-pointer hover:opacity-70"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
              <AttachmentField newFiles={newFiles} setNewFiles={setNewFiles} />
            </div>
          </div>
          <div
            className={`flex flex-row items-center justify-end gap-5 w-full`}
          >
            {selectedPod && (
              <button
                type="button"
                onClick={() => {
                  setShowDeleteConfirmModal(true);
                }}
                className="my-1 text-red-500 text-[15px] font-bold disabled:bg-gray-500"
              >
                {t("deleteThisPOD")}
              </button>
            )}
            <button
              disabled={isSubmitting}
              type="submit"
              className=" disabled:bg-gray-500 px-[23px] py-[6px] self-center sm:self-end font-semibold
               text-[#fff] dark:text-[#000] rounded-[6px] bg-[#04CF00]"
            >
              {t("save")}
            </button>
          </div>
        </form>
        {/* <DevTool control={control} /> */}
      </section>
    </div>
  );
};

export default CreateAndEditModal;
