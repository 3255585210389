import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CgClose } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import packageJsonData from "./../../../package.json";
import DatenschutzModal from "../../components/About/DatenschutzModal";
import ImpressumModal from "../../components/About/ImpressumModal";
import FeedbackModal from "../../components/About/FeedbackModal";
import useAboutModal from "../../hooks/useAboutModal";
import useObserver from "../../hooks/useObserver";

const AboutModal = () => {
  const [showImpressum, setShowImpressum] = useState(false);
  const [showDatenschutz, setShowDatenschutz] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [mainModal, setMainModal] = useState(true);

  const isObserver = useObserver();
  const { showAboutModal, setShowAboutModal } = useAboutModal();

  //Language package
  const { t, i18n } = useTranslation("about");
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const ags = useSelector((state) => state?.customer?.data?.ags);
  const customerName = useSelector((state) => state?.customer?.data?.name);

  // secondary solution from .env
  const secondary_version_from_env = `${process.env.REACT_APP_VERSION}`;

  //import version from package.json
  const version = packageJsonData.version || secondary_version_from_env;

  const currentYear = new Date().getFullYear();

  //   console.log("secondary version: " + secondary_version);
  return (
    <div>
      {showAboutModal ? (
        <div className="fixed z-[20000] flex left-0 top-0 w-full h-full background-about-layer-light dark:background-about-layer-dark duration-300">
          {mainModal && (
            <div
              className={`absolute bottom-0 sm:relative m-auto gap-5 headerShadowLight dark:text-white text-black max-h-[85vh] overflow-auto scrollbarLight dark:scrollbar
            w-[830px] max-w-[100%] sm:max-w-[70%] dark:bg-black/70 bg-white/70 backdrop-blur-[61.5px] rounded-t-2xl sm:rounded-[18px] text-center`}
            >
              <div
                onClick={() => setShowAboutModal(false)}
                className="absolute duration-300 cursor-pointer right-7 top-7 hover:opacity-75"
              >
                <CgClose size={24} />
              </div>
              <section className="p-[23px] -mt-2 md:-mt-0 md:p-[50px]">
                <div className="flex items-center justify-center gap-2 md:gap-4">
                  <div
                    onClick={() => setShowAboutModal(false)}
                    className="absolute duration-300 cursor-pointer right-7 top-7 hover:opacity-75"
                  >
                    <CgClose size={24} />
                  </div>
                  <div className="h-[50px] flex items-center">
                    <img
                      className="w-[200px] sm:w-[250px] mx-auto"
                      src={`/images/Logo_Swisscom_${
                        isThemeDark ? "Light" : "Dark"
                      }.svg`}
                      alt="Logo_Swisscom"
                    />
                  </div>

                  {!isObserver && (
                    <>
                      <div className="h-8 border-l-2 border-[#C9C9C9] dark:border-[#333]"></div>
                      <img
                        src={`https://cloudbreak-assets.s3.eu-central-1.amazonaws.com/coa/COA+Kunden/${ags}.png`}
                        alt="customer logo"
                        className="w-8 h-8"
                      />
                    </>
                  )}
                </div>
                <p className="pt-3">
                  {isObserver
                    ? t("aboutText1") + t("aboutText2")
                    : t("aboutText1") + ` ${customerName}` + t("aboutText2")}
                </p>

                <p className="py-1">{t("legalText")}</p>
                <div className="h-[calc(100%-48px)] aspect-video lg:w-2/3 max-w-full self-center m-auto">
                  <iframe
                    className="rounded-xl"
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/tboBtIgHUQo"
                    title="Starkregen Frühalarmsystem"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen={true}
                  />
                </div>
              </section>

              {/* <p className="">
              {t("companyName")} <br /> {t("streetAdress")} <br />{" "}
              {t("cityAdress")}
            </p>
            <p>{t("emailAdress")}</p>
            <p>
              {t("copyright")} <br />
              {t("website")}
            </p> */}

              {/* <button
              onClick={() => setShowLearnMore(true)}
              className="px-6 py-2.5 mx-auto my-3 duration-300 w-max bg-spekterRed focus:outline-none hover:opacity-90 rounded-5 text-white"
            >
              Mehr erfahren
            </button> */}
              <div className="flex flex-col items-center justify-between w-full px-8 pb-6 md:flex-row">
                <span>© {currentYear} Spekter GmbH</span>
                <div className="flex gap-6 m-auto">
                  <span
                    onClick={() => {
                      setShowImpressum(true);
                      setMainModal(false);
                    }}
                    className="cursor-pointer"
                  >
                    {t("imprint")}
                  </span>
                  <span
                    onClick={() => {
                      setShowDatenschutz(true);
                      setMainModal(false);
                    }}
                    className="relative cursor-pointer"
                  >
                    {t("privacy")}
                  </span>
                  <span
                    onClick={() => {
                      setShowFeedback(true);
                      setMainModal(false);
                    }}
                    className="cursor-pointer"
                  >
                    {t("feedback")}
                  </span>
                </div>
                <span className="text-gray-500">
                  {t("version")} {version}
                </span>
              </div>
            </div>
          )}

          {showImpressum && (
            <ImpressumModal
              setShowImpressum={setShowImpressum}
              setMainModal={setMainModal}
              setShowAboutModal={setShowAboutModal}
            />
          )}
          {showDatenschutz && (
            <DatenschutzModal
              setShowDatenschutz={setShowDatenschutz}
              setMainModal={setMainModal}
              setShowAboutModal={setShowAboutModal}
            />
          )}

          {showFeedback && (
            <FeedbackModal
              setShowFeedback={setShowFeedback}
              setMainModal={setMainModal}
              setShowAboutModal={setShowAboutModal}
            />
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default AboutModal;
