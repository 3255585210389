import * as actionType from "../actionTypes";
import Axios from "../../../services/NetworkService";
import { sensorTypes } from "../../../constants/sensorTypes";
import { getStartEndDateByTimeFrame } from "../../../shared/Utility";

//  sensorType, deviceId, timeframe
export function chartAction(
  sensorType,
  deviceId,
  timeFrame,
  sensorName,
  optionalData
) {
  let deviceIds = [deviceId];

  return async function (dispatch) {
    try {
      dispatch({ type: actionType.CHART_PENDING, payload: "loading..." });

      const { start, end } = getStartEndDateByTimeFrame(timeFrame);

      let response;
      if (sensorType === sensorTypes.SMARTRIVER) {
        response = await Axios.post("/smartriverdata", {
          // timeFrame,
          start: start.toISOString(),
          end: end.toISOString(),
          deviceIds,
          filter: true,
          // resolution: 5
          // resolution: timeFrame === 1440 ? 5 : 60,
        });
      } else if (sensorType === sensorTypes.SMARTSEWER) {
        response = await Axios.post("/smartsewerdata", {
          timeFrame,
          deviceIds,
          resolution: 300,
        });
      } else if (sensorType === sensorTypes.GFL) {
        response = await Axios.post("/gfldata", {
          start: start.toISOString(),
          end: end.toISOString(),
          // timeFrame,
          deviceIds,
          resolution: 60,
        });
      } else if (sensorType === sensorTypes.HDC) {
        response = await Axios.post("/hdcdata", {
          // timeFrame,
          start: start.toISOString(),
          end: end.toISOString(),
          deviceIds,
          resolution: 5,
        });
      }

      return dispatch({
        type: actionType.CHART_SUCCESS,
        payload: response.data.data[deviceId] || [],
        sensorName,
        sensorType,
        optionalData,
      });
    } catch (error) {
      return dispatch({
        type: actionType.CHART_FAIL,
        error: error,
      });
    }
  };
}
