import React, { useState } from "react";
import Sidebar from "./Sidebar/Sidebar";
import ReactTooltip from "react-tooltip";
import useMobileView from "../../../../hooks/useMobileView";

const Legend = () => {
  const [open, setOpen] = useState(false);
  const isMobileView = useMobileView();
  return (
    <>
      <Sidebar open={open} setOpen={setOpen} />
      <div
        data-tip="Alarm Info"
        data-for="tooltip"
        className="flex items-center justify-center w-10 h-10 duration-300 rounded cursor-pointer bg-spekterDarkOne hover:bg-gray-600"
        onClick={() => setOpen(!open)}
      >
        <img
          className="w-5 h-5"
          src="/images/menu_icons/about-icon-light.svg"
          alt="alarm info icon"
        />
      </div>
      {!isMobileView && <ReactTooltip id="tooltip" place="top" />}
    </>
  );
};

export default Legend;
