import React, { useEffect, useState } from "react";
import BarToggle from "../../../components/UI/BarToggle/BarToggle";
import { useSelector } from "react-redux";
import HazardSwitch from "./hazardSwitch";
import HeatSwitch from "./HeatSwitch.js";
import RiskSwitch from "./RiskSwitch";
import MelderSwitch from "./MelderSwitch";
import Comments from "./comments.js";
import AddressSearch from "./AddressSearch";
import "./SideBar.css";
import Pods from "./pods/pods.js";
import useRole from "../../../hooks/useRole.js";
import PodViewModal from "./pods/PodViewModal";
import CreateAndEditModal from "../SideBar/pods/CreateAndEditModal.js";
import PodIndicator from "../SideBar/pods/PodIndicator";
import PodAddInstruction from "./pods/PodAddInstruction.js";
import useObserver from "../../../hooks/useObserver.js";

const SideBar = ({ map, ags, setOpen, open }) => {
  const userRole = useRole();
  const isObserver = useObserver();
  const isDarkTheme = useSelector((state) => state.theme.isThemeDark);
  const activeModules = useSelector(
    (state) => state.customer?.data?.activeModules
  );
  const activeLayers = useSelector((state) => state.customer?.data?.mapLayers);

  // pods
  const [showPodViewModal, setShowPodViewModal] = useState(false);
  const [showCreateAndEditModal, setShowCreateAndEditModal] = useState(false);
  const [newMarkerInstruction, setNewMarkerInstruction] = useState(false);
  const [selectedPod, setSelectedPod] = useState(null);
  const [addNewPod, setAddNewPod] = useState(false);
  const [newLatLng, setNewLatLng] = useState(null);

  // close sidebar when clicked add new pod
  useEffect(() => {
    if (addNewPod) {
      setOpen(false);
    }
  }, [addNewPod, setOpen]);

  return (
    <>
      <BarToggle
        open={open}
        clicked={() => setOpen(!open)}
        importer="webviewer"
      />
      <div
        className={`overflow-auto fixed w-4/5 sm:w-[450px] h-full right-0 top-0 z-200 ${
          isDarkTheme
            ? "bg-spekterDarkOne text-spekterWhite"
            : "bg-white text-spekterDarkOne"
        } py-8 px-4 box-border mt-14 transition-transform transform ${
          open ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {activeLayers?.strgkMapLayersActive &&
          activeModules &&
          activeModules["dangerMap"] && <HazardSwitch map={map} />}
        {activeLayers?.hydroMapLayersActive && <HeatSwitch map={map} />}
        {activeModules && activeModules["riskMap"] && <RiskSwitch map={map} />}
        {activeModules && activeModules["melder"] && (
          <MelderSwitch map={map} ags={ags} />
        )}
        {activeModules && activeModules["comments"] && <Comments />}
        {activeModules && activeModules["pod"] ? (
          isObserver ? (
            <Pods
              map={map}
              ags={ags}
              setShowPodViewModal={setShowPodViewModal}
              setShowCreateAndEditModal={setShowCreateAndEditModal}
              setSelectedPod={setSelectedPod}
              addNewPod={addNewPod}
              setAddNewPod={setAddNewPod}
              newLatLng={newLatLng}
              setNewLatLng={setNewLatLng}
              newMarkerInstruction={newMarkerInstruction}
              setNewMarkerInstruction={setNewMarkerInstruction}
            />
          ) : (
            useRole !== "users" && (
              <Pods
                map={map}
                ags={ags}
                setShowPodViewModal={setShowPodViewModal}
                setShowCreateAndEditModal={setShowCreateAndEditModal}
                setSelectedPod={setSelectedPod}
                addNewPod={addNewPod}
                setAddNewPod={setAddNewPod}
                newLatLng={newLatLng}
                setNewLatLng={setNewLatLng}
                newMarkerInstruction={newMarkerInstruction}
                setNewMarkerInstruction={setNewMarkerInstruction}
              />
            )
          )
        ) : null}
        <AddressSearch map={map} />
      </div>
      {showPodViewModal && (
        <div className="absolute z-[1000] flex items-center justify-center w-screen h-screen">
          <PodViewModal
            setShow={setShowPodViewModal}
            selectedPod={selectedPod}
            setSelectedPod={setSelectedPod}
            setShowPodsModal={setShowCreateAndEditModal}
          />
        </div>
      )}
      {showCreateAndEditModal && (
        <div className="absolute z-[1000] flex items-center justify-center w-screen h-screen">
          <CreateAndEditModal
            selectedPod={selectedPod}
            setSelectedPod={setSelectedPod}
            setShowPodsModal={setShowCreateAndEditModal}
            setIsAddNewPod={setAddNewPod}
            newLatLng={newLatLng}
            setNewMarkerInstruction={setNewMarkerInstruction}
          />
        </div>
      )}
      {addNewPod && newMarkerInstruction && !showCreateAndEditModal && (
        <div className="absolute z-[1000] flex items-center justify-center w-screen">
          <PodIndicator />
          <PodAddInstruction
            setOpen={setOpen}
            setAddNewPod={setAddNewPod}
            setNewMarkerInstruction={setNewMarkerInstruction}
          />
        </div>
      )}
    </>
  );
};

export default SideBar;
