import { useEffect, useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import * as toast from "../../../../components/UI/Toaster";

const PlaceSearch = ({ setAddressCoordinate }) => {
  const inputRef = useRef(null);
  const [showSearchbar, setShowSearchbar] = useState(false);
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [showSearchbar]);

  const handleChange = (value) => {
    setAddress(value);
  };

  const handleSelect = (value) => {
    setAddress(value);
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => setAddressCoordinate(Object.values(latLng)))
      .catch((error) => toast.error("Something went wrong"));
  };

  return (
    <div
      className={`flex my-1 w-10 h-10 relative
      duration-300 rounded cursor-pointer bg-spekterDarkOne hover:bg-gray-600 `}
    >
      <div
        onClick={() => {
          setShowSearchbar(!showSearchbar);
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        className="flex items-center justify-center w-full h-full"
      >
        <img width={20} src="/images/searchIcon.svg" alt="normal back icon" />
      </div>

      {showSearchbar && (
        <div className="absolute w-80 max-w-[75vw] h-10 right-[110%]">
          <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="relative">
                <input
                  ref={inputRef}
                  {...getInputProps({
                    placeholder: "Adresse suchen...",
                    type: "text",
                  })}
                  className="w-[100%] p-2 text-white rounded-lg bg-spekterDarkOne focus:outline-none "
                />
                {suggestions && suggestions.length > 0 && (
                  <div className="h-24 md:h-40 rounded-lg overflow-x-auto text-sm bg-[#333] autocomplete-dropdown-container scrollbar">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion, index) => {
                      const style = {
                        backgroundColor: suggestion.active
                          ? "#41b6e6"
                          : "#2d2d2d",
                        color: "white",
                        cursor: "pointer",
                      };
                      return (
                        <div
                          className={`p-2`}
                          // className={`p-2 ${
                          //   index + 1 == suggestions.length ? "rounded-b-md" : ""
                          // }`}
                          key={index}
                          {...getSuggestionItemProps(suggestion, { style })}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      )}
    </div>
  );
};

export default PlaceSearch;
