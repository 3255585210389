//reset all state
export const RESET_APP = "RESET_APP";

// theme action types
export const THEME_TYPE = "THEME_TYPE";

// login action types
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_RESET = "LOGIN_RESET";
export const LOGIN_FAIL = "LOGIN_FAIL";

// register action types
export const REG = "REG";
export const REG_PENDING = "REG_PENDING";
export const REG_SUCCESS = "REG_SUCCESS";
export const REG_FAIL = "REG_FAIL";
export const SET_REG_REDIRECT_PATH = "SET_REG_REDIRECT_PATH";

// notifications
export const SENSOR_NOTIFICATIONS_PENDING = "SENSOR_NOTIFICATIONS_PENDING";
export const SENSOR_NOTIFICATIONS_SUCCESS = "SENSOR_NOTIFICATIONS_SUCCESS";
export const SENSOR_NOTIFICATIONS_FAIL = "SENSOR_NOTIFICATIONS_FAIL";

export const CATCHMENT_NOTIFICATIONS_PENDING =
  "CATCHMENT_NOTIFICATIONS_PENDING";
export const CATCHMENT_NOTIFICATIONS_SUCCESS =
  "CATCHMENT_NOTIFICATIONS_SUCCESS";
export const CATCHMENT_NOTIFICATIONS_FAIL = "CATCHMENT_NOTIFICATIONS_FAIL";

export const DWD_PENDING = "DWD_PENDING";
export const DWD_SUCCESS = "DWD_SUCCESS";
export const DWD_FAIL = "DWD_FAIL";

export const ADD_COLLAPSED_NOTIFICATION = "ADD_COLLAPSED_NOTIFICATION";

// smart river action types
export const HDC_INIT = "HDC_INIT";
export const HDC_SUCCESS = "HDC_SUCCESS";
export const HDC_FAIL = "HDC_FAIL";

// gfl action types
export const GFL_INIT = "GFL_INIT";
export const GFL_SUCCESS = "GFL_SUCCESS";
export const GFL_FAIL = "GFL_FAIL";

// HDC action types
export const SMARTRIVER_INIT = "SMARTRIVER_INIT";
export const SMARTRIVER_SUCCESS = "SMARTRIVER_SUCCESS";
export const SMARTRIVER_FAIL = "SMARTRIVER_FAIL";

// SMARTSEWER action types
export const SMARTSEWER_INIT = "SMARTSEWER_INIT";
export const SMARTSEWER_SUCCESS = "SMARTSEWER_SUCCESS";
export const SMARTSEWER_FAIL = "SMARTSEWER_FAIL";

// forget password action types
export const FORGETPASS_INIT = "FORGETPASS_INIT";
export const FORGETPASS_OTP_SUCCESS = "FORGETPASS_OTP_SUCCESS";
export const FORGETPASS_SUBMIT_SUCCESS = "FORGETPASS_SUBMIT_SUCCESS";
export const FORGETPASS_FAIL = "FORGETPASS_FAIL";
export const FORGETPASS_SEND_MAIL = "FORGETPASS_SEND_MAIL";
export const FORGETPASS_SUBMIT = "FORGETPASS_SUBMIT";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";

// observer action types
export const OBSERVER_INIT = "OBSERVER_INIT";
export const OBSERVER_SUCCESS = "OBSERVER_SUCCESS";
export const OBSERVER_FAIL = "OBSERVER_FAIL";
export const OBSERVER_SELECT_AGS = "OBSERVER_SELECT_AGS";
export const OBSERVER_SELECT_NAME = "OBSERVER_SELECT_NAME";
export const OBSERVER_CURRENT_MAP_POSITION = "OBSERVER_CURRENT_MAP_POSITION";

//danger page action
export const SHOW_DANGER_PAGE_POPUP = "SHOW_DANGER_PAGE_POPUP";

// catchment action types
export const CATCHMENT_INIT = "CATCHMENT_INIT";
export const CATCHMENT_SUCCESS = "CATCHMENT_SUCCESS";
export const CATCHMENT_FAIL = "CATCHMENT_FAIL";

// customer action types
export const CUSTOMER_PENDING = "CUSTOMER_PENDING";
export const CUSTOMER_SUCCESS = "CUSTOMER_SUCCESS";
export const CUSTOMER_FAIL = "CUSTOMER_FAIL";

// chart action types
export const CHART_PENDING = "CHART_PENDING";
export const CHART_SUCCESS = "CHART_SUCCESS";
export const CHART_FAIL = "CHART_FAIL";

// risk report action types
export const RISK_REPORTS_PENDING = "RISK_REPORTS_PENDING";
export const RISK_REPORTS_SUCCESS = "RISK_REPORTS_SUCCESS";
export const RISK_REPORTS_FAIL = "RISK_REPORTS_FAIL";

// risk report authorized action types
export const RISK_REPORTS_PROCESSED_PENDING = "RISK_REPORTS_PROCESSED_PENDING";
export const RISK_REPORTS_PROCESSED_SUCCESS = "RISK_REPORTS_PROCESSED_SUCCESS";
export const RISK_REPORTS_PROCESSED_FAIL = "RISK_REPORTS_PROCESSED_FAIL";

// risk submit action types
export const RISK_SUBMIT_PENDING = "RISK_SUBMIT_PENDING";
export const RISK_SUBMIT_SUCCESS = "RISK_SUBMIT_SUCCESS";
export const RISK_SUBMIT_FAIL = "RISK_SUBMIT_FAIL";

// risk handle action types
export const RISK_HANDLE_PENDING = "RISK_HANDLE_PENDING";
export const RISK_HANDLE_SUCCESS = "RISK_HANDLE_SUCCESS";
export const RISK_HANDLE_FAIL = "RISK_HANDLE_FAIL";

// users action types
export const USERS_PENDING = "USERS_PENDING";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_FAIL = "USERS_FAIL";
export const USERS_RESET = "USERS_RESET";

// user action types
export const USER_PENDING = "USER_PENDING";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";

// update user action types
export const UPDATE_USER_PENDING = "UPDATE_USER_PENDING";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

// update user action types
export const ADD_SPECIAL_USER_PENDING = "ADD_SPECIAL_USER_PENDING";
export const ADD_SPECIAL_USER_SUCCESS = "ADD_SPECIAL_USER_SUCCESS";
export const ADD_SPECIAL_USER_FAIL = "ADD_SPECIAL_USER_FAIL";

// subscibers action types
export const SUBSCRIBERS_PENDING = "SUBSCRIBERS_PENDING";
export const SUBSCRIBERS_SUCCESS = "SUBSCRIBERS_SUCCESS";
export const SUBSCRIBERS_FAIL = "SUBSCRIBERS_FAIL";
export const SUBSCRIBERS_RESET = "SUBSCRIBERS_RESET";

// add subscriber action types
export const ADD_SUBSCRIBER_PENDING = "ADD_SUBSCRIBER_PENDING";
export const ADD_SUBSCRIBER_SUCCESS = "ADD_SUBSCRIBER_SUCCESS";
export const ADD_SUBSCRIBER_FAIL = "ADD_SUBSCRIBER_FAIL";

// edit subscriber action types
export const EDIT_CUSTOM_SUBSCRIBER_PENDING = "EDIT_CUSTOM_SUBSCRIBER_PENDING";
export const EDIT_CUSTOM_SUBSCRIBER_SUCCESS = "EDIT_CUSTOM_SUBSCRIBER_SUCCESS";
export const EDIT_CUSTOM_SUBSCRIBER_FAIL = "EDIT_CUSTOM_SUBSCRIBER_FAIL";

// delete custom subscriber action types
export const DELETE_CUSTOM_SUBSCRIBER_PENDING =
  "DELETE_CUSTOM_SUBSCRIBER_PENDING";
export const DELETE_CUSTOM_SUBSCRIBER_SUCCESS =
  "DELETE_CUSTOM_SUBSCRIBER_SUCCESS";
export const DELETE_CUSTOM_SUBSCRIBER_FAIL = "DELETE_CUSTOM_SUBSCRIBER_FAIL";

// get all custom subscribers action types
export const ALL_CUSTOM_SUBSCRIBERS_PENDING = "ALL_CUSTOM_SUBSCRIBERS_PENDING";
export const ALL_CUSTOM_SUBSCRIBERS_SUCCESS = "ALL_CUSTOM_SUBSCRIBERS_SUCCESS";
export const ALL_CUSTOM_SUBSCRIBERS_FAIL = "ALL_CUSTOM_SUBSCRIBERS_FAIL";

// subsciber action types
export const SUBSCRIBER_PENDING = "SUBSCRIBER_PENDING";
export const SUBSCRIBER_SUCCESS = "SUBSCRIBER_SUCCESS";
export const SUBSCRIBER_FAIL = "SUBSCRIBER_FAIL";

// update subscriber action types
export const OWN_RESCUE_SUBSCRIBER_PENDING = "OWN_RESCUE_SUBSCRIBER_PENDING";
export const OWN_RESCUE_SUBSCRIBER_SUCCESS = "OWN_RESCUE_SUBSCRIBER_SUCCESS";
export const OWN_RESCUE_SUBSCRIBER_FAIL = "OWN_RESCUE_SUBSCRIBER_FAIL";

// analytics chart -smartRiver
export const ANALYTICS_MIXCHART_PENDING = "ANALYTICS_MIXCHART_PENDING";
export const ANALYTICS_MIXCHART_SUCCESS = "ANALYTICS_MIXCHART_SUCCESS";
export const ANALYTICS_MIXCHART_FAIL = "ANALYTICS_MIXCHART_FAIL";
export const ANALYTICS_MIXCHART_RESET = "ANALYTICS_MIXCHART_RESET";
export const ANALYTICS_MIXCHART_LOADED = "ANALYTICS_MIXCHART_LOADED";

// sensor manager
// smart river sensor
export const SMART_RIVER_PENDING = "SMART_RIVER_PENDING";
export const SMART_RIVER_SUCCESS = "SMART_RIVER_SUCCESS";
export const SMART_RIVER_COMPLETE = "SMART_RIVER_COMPLETE";
export const SMART_RIVER_FAIL = "SMART_RIVER_FAIL";
export const SMART_RIVER_RESET = "SMART_RIVER_RESET";

// gfl sensor
export const GFL_SENSOR_PENDING = "GFL_SENSOR_PENDING";
export const GFL_SENSOR_SUCCESS = "GFL_SENSOR_SUCCESS";
export const GFL_SENSOR_COMPLETE = "GFL_SENSOR_COMPLETE";
export const GFL_SENSOR_FAIL = "GFL_SENSOR_FAIL";
export const GFL_SENSOR_RESET = "GFL_SENSOR_RESET";

// hdc sensor
export const HDC_SENSOR_PENDING = "HDC_SENSOR_PENDING";
export const HDC_SENSOR_SUCCESS = "HDC_SENSOR_SUCCESS";
export const HDC_SENSOR_COMPLETE = "HDC_SENSOR_COMPLETE";
export const HDC_SENSOR_FAIL = "HDC_SENSOR_FAIL";
export const HDC_SENSOR_RESET = "HDC_SENSOR_RESET";

// smart sewer sensor
export const SMART_SEWER_PENDING = "SMART_SEWER_PENDING";
export const SMART_SEWER_SUCCESS = "SMART_SEWER_SUCCESS";
export const SMART_SEWER_COMPLETE = "SMART_SEWER_COMPLETE";
export const SMART_SEWER_FAIL = "SMART_SEWER_FAIL";
export const SMART_SEWER_RESET = "SMART_SEWER_RESET";

// delete account
export const DELETE_ACCOUNT_PENDING = "DELETE_ACCOUNT_PENDING";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL";

// WebView comments
export const WEBVIEW_COMMENTS_PENDING = "WEBVIEW_COMMENTS_PENDING";
export const WEBVIEW_COMMENTS_SUCCESS = "WEBVIEW_COMMENTS_SUCCESS";
export const WEBVIEW_COMMENTS_REMOVE_SUCCESS =
  "WEBVIEW_COMMENTS_REMOVE_SUCCESS";
export const WEBVIEW_COMMENTS_FAIL = "WEBVIEW_COMMENTS_FAIL";
export const WEBVIEW_COMMENTS_STATUS = "WEBVIEW_COMMENTS_STATUS";
export const WEBVIEW_ADD_NEW_COMMENTS_STATUS =
  "WEBVIEW_ADD_NEW_COMMENTS_STATUS";
export const UPDATE_WEBVIEW_COMMENTS_SUCCESS =
  "UPDATE_WEBVIEW_COMMENTS_SUCCESS";
export const UPDATE_WEBVIEW_COMMENTS_FAIL = "UPDATE_WEBVIEW_COMMENTS_FAIL";
export const WEBVIEW_UPDATE_META_DATA = "WEBVIEW_UPDATE_META_DATA";

export const ADD_LOCAL_OL_FEATURE = "ADD_LOCAL_OL_FEATURE";
export const REMOVE_LOCAL_OL_FEATURE = "REMOVE_LOCAL_OL_FEATURE";

// Melder harm reports
export const HARM_REPORTS_PENDING = "HARM_REPORTS_PENDING";
export const HARM_REPORTS_SUCCESS = "HARM_REPORTS_SUCCESS";
export const HARM_REPORTS_FAIL = "HARM_REPORTS_FAIL";
// export const HARM_REPORTS_STATUS = "HARM_REPORTS_STATUS";
export const HARM_REPORTS_OFF = "HARM_REPORTS_OFF";

// Melder harm notes
export const HARM_NOTES_PENDING = "HARM_NOTES_PENDING";
export const HARM_NOTES_SUCCESS = "HARM_NOTES_SUCCESS";
export const HARM_NOTES_FAIL = "HARM_NOTES_FAIL";
// export const HARM_NOTES_STATUS = "HARM_NOTES_STATUS";
export const HARM_NOTES_OFF = "HARM_NOTES_OFF";

// Fetch Municipality
export const MUNICIPALITY_PENDING = "MUNICIPALITY_PENDING";
export const MUNICIPALITY_SUCCESS = "MUNICIPALITY_SUCCESS";
export const MUNICIPALITY_FAIL = "MUNICIPALITY_FAIL";

// Fetch all Communities
export const ALL_COMMUNITIES_PENDING = "ALL_COMMUNITIES_PENDING";
export const ALL_COMMUNITIES_SUCCESS = "ALL_COMMUNITIES_SUCCESS";
export const ALL_COMMUNITIES_FAIL = "ALL_COMMUNITIES_FAIL";

// Point of danger (POD)

export const SHOW_PODS = "SHOW_PODS";
export const POD_INIT = "POD_INIT";
export const POD_SUCCESS = "POD_SUCCESS";
export const POD_FAIL = "POD_FAIL";

// Change Email
export const CHANGE_EMAIL_PENDING = "CHANGE_EMAIL_PENDING";
export const CHANGE_EMAIL_SUCCESS = "CHANGE_EMAIL_SUCCESS";
export const CHANGE_EMAIL_FAIL = "CHANGE_EMAIL_FAIL";

//app popUp action
export const SHOW_ABOUT_MODAL = "SHOW_ABOUT_MODAL";

// Riverscape action types
export const RIVERSCAPE_INIT = "RIVERSCAPE_INIT";
export const RIVERSCAPE_SUCCESS = "RIVERSCAPE_SUCCESS";
export const RIVERSCAPE_FAIL = "RIVERSCAPE_FAIL";
