import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import OtpInputField from "./OtpInput";
import { forgetPassSentOTP } from "../../../redux/actions/index";
import { STEPS } from "./STEPS";
import { verifyOTP } from "../../../redux/actions/auth/forgetPassword";
import { useTranslation } from "react-i18next";
import * as toast from "../../UI/Toaster";

const CodeVerification = ({
  email,
  otp,
  setOtp,
  resendCounter,
  setResendCounter,
  setCurrentStep,
}) => {
  //Language package
  const { t, i18n } = useTranslation("settings");
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const [isOtpInvalid, setIsOtpInvalid] = useState(otp.length < 6);

  const dispatch = useDispatch();

  const onResendOTP = () => {
    if (!email) {
      return setCurrentStep(STEPS.REQUEST_OTP);
    }
    dispatch(forgetPassSentOTP(email)).then((response) => {
      if (response.error) {
        if (response.error === 404) {
          toast.error(t("emailDoesNotExist"));
        } else {
          toast.error(response?.data?.message || t("somethingWentWrong"));
        }
      } else if (response.data) {
        toast.success(response?.data?.message);
        setResendCounter(response.data.timer);
      }
    });
  };

  const onVerifyCode = () => {
    dispatch(verifyOTP(otp)).then((response) => {
      if (response.error) {
        toast.error(response?.data?.message || t("somethingWentWrong"));
      } else if (response.data) {
        toast.success(response?.data?.message);
        setCurrentStep(STEPS.NEW_CREDENTIAL);
      }
    });
  };

  return (
    <div
      className={`py-10 rounded-[18px] min-h-[480px] duration-300 p-[39px] sm:p-[47px] w-full lg:w-[500px]
    ${
      isThemeDark
        ? "bg-spekterDarkSix text-gray-100"
        : "text-gray-700 bg-spekterWhite containerShadowLight"
    }  `}
    >
      <p className="text-[21px] sm:text-[24px] pb-2 font-bold sm:text-center lg:text-left duration-300">
        {t("codeVerification")}
      </p>
      <p className="text-[15px] sm:text-center lg:text-left duration-300">
        {t("enterOTP")}
        {email}
      </p>

      <div className="pt-16">
        <OtpInputField
          otp={otp}
          setOtp={setOtp}
          isOtpInvalid={isOtpInvalid}
          setIsOtpInvalid={setIsOtpInvalid}
          isThemeDark={isThemeDark}
        />

        <div className="h-6">
          {otp && isOtpInvalid && (
            <div className="flex justify-between">
              <p className={`text-[11px] pt-1 text-spekterRed`}>
                {t("invalidOTP")}
              </p>
            </div>
          )}
        </div>
        <button
          className="bg-spekterGreenSuccess rounded-[6px] w-full py-[15px] font-bold dark:text-spekterDarkSix text-spekterWhite mt-2 mb-2 cursor-pointer"
          disabled={isOtpInvalid}
          onClick={onVerifyCode}
        >
          {t("verifyCode")}
        </button>
        <button
          className="rounded-[4px] w-full h-9 mt-2 cursor-pointer test-sm dark:text-spekterGreen"
          disabled={resendCounter > 0}
          onClick={onResendOTP}
        >
          {t("resendCodeShortText")}
          {resendCounter > 0 && resendCounter}
        </button>
      </div>
    </div>
  );
};

export default CodeVerification;
