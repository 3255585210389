import { useEffect, useState } from "react";
import useMousePosition from "../../../../hooks/useMousePosition";

const PodIndicator = () => {
  const { x, y } = useMousePosition();

  // State to store the adjusted boundaries
  const [boundaries, setBoundaries] = useState({
    minX: 0,
    maxX: window.innerWidth * 0.9,
    minY: 0,
    maxY: window.innerHeight * 0.9,
  });

  useEffect(() => {
    const handleResize = () => {
      // Calculate the dead zone dimensions (8% of the screen on each side)
      const deadZoneWidth = window.innerWidth * 0.08;
      const deadZoneHeight = window.innerHeight * 0.08;

      setBoundaries({
        minX: deadZoneWidth,
        maxX: window.innerWidth - deadZoneWidth, // The mouse can move from 8% to 92% of the viewport width
        minY: deadZoneHeight,
        maxY: window.innerHeight - deadZoneHeight, // The mouse can move from 8% to 92% of the viewport height
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    // Clean up event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  if (!x || !y) return null;

  // Ensure the component (or mouse) stays within the horizontal boundaries
  const limitedX = Math.min(Math.max(x, boundaries.minX), boundaries.maxX);

  // Ensure the component (or mouse) stays within the vertical boundaries
  const limitedY = Math.min(Math.max(y, boundaries.minY), boundaries.maxY);

  const draggableStyle = {
    position: "absolute",
    left: `${limitedX}px`,
    top: `${limitedY}px`,
    transform: "translate(-50%, -50%)", // Center the component on the cursor
  };

  return (
    <div className="hidden pointer-events-none md:block" style={draggableStyle}>
      {/* draw center to top vertical line */}
      <div className="absolute w-[1px] h-screen bg-[#000000] left-1/2 bottom-1/2 z-100"></div>

      {/* draw center to bottom vertical line */}
      <div className="absolute w-[1px] h-screen bg-[#000000] left-1/2 top-1/2 z-100"></div>

      {/* draw center to left horizontal line */}
      <div className="absolute w-screen h-[1px] bg-[#000000] left-1/2 top-1/2 z-100"></div>

      {/* draw center to right horizontal line */}
      <div className="absolute w-screen h-[1px] bg-[#000000] -right-1/2 top-1/2 z-100"></div>

      {/* draw center circle */}
      <div className="absolute w-[15px] h-[15px] rounded-full -right-[10px] -top-[5px] scale-[3] !border border-solid border-[#000000] z-100 opacity-30"></div>
      {/* 2nd center circle */}
      <div className="absolute w-[15px] h-[15px] rounded-full -right-[10px] -top-[5px] scale-[2.5] !border border-solid !border-[#000000] z-100 opacity-70"></div>
      {/* 2nd center circle */}
      <div className="absolute w-[15px] h-[15px] rounded-full -right-[10px] -top-[5px] scale-[2] !border border-solid !border-[#000000] z-100 opacity-100"></div>
    </div>
  );
};

export default PodIndicator;
