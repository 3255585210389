import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import styles from "./EditModal.module.css";
import MessagingOption from "../../MessageOption/MessagingOption";
import * as actions from "../../../../../redux/actions/index";
import Spinner from "../../../../UI/Spinner/Spinner";

const EditModal = ({
  ags,
  open,
  closed,
  subscriberId,
  subscriberData,
  currentPage,
  userType,
  searchText,
}) => {
  const [name, setName] = useState(subscriberData.name);
  const [mail, setMail] = useState(subscriberData.email);
  const [mobile, setMobile] = useState(subscriberData.mobile);
  const [voiceCall, setVoiceCall] = useState(subscriberData.voicecall);
  const isloading = useSelector((state) => state.subscriber.isLoading);
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const dispatch = useDispatch();

  const updateSubscriber = () => {
    const payload = {
      name: name,
      mobile: mobile,
      email: mail,
      voicecall: voiceCall,
    };

    dispatch(actions.updateSubsciberAction(ags, subscriberId, payload)).then(
      (result) => {
        if (result.type === "SUBSCRIBER_SUCCESS") {
          dispatch(actions.usersAction(ags, currentPage, userType, searchText));
          closed();
        }
      }
    );
  };

  return (
    <div>
      {open ? (
        <div className="fixed top-0 left-0 z-30 flex w-full h-full overflow-auto xl:block xl:py-24">
          <div
            className={`relative bg-spekterWhite dark:bg-boxBG m-auto 
                          ${
                            isThemeDark ? "" : "shadow-modal"
                          } p-0 sm:w-[65%]  lg:w-2/3 rounded-xl`}
          >
            <div className="p-4 text-spekterDarkOne">
              <p className="text-xl font-bold text-gray-700 sm:text-center xl:text-left dark:text-gray-100">
                Edit Special User
              </p>
            </div>
            {/* Loader */}
            {isloading ? (
              <div className="flex justify-center">
                <Spinner />
              </div>
            ) : null}
            <div className="flex flex-col items-center xl:px-4 xl:items-start">
              {/* Name */}
              <div className="p-2.5 flex gap-6 items-center">
                <div className="w-20 text-gray-600 dark:text-gray-400">
                  Name
                </div>
                <input
                  // className={`bg-spekterWhite dark:bg-inputBoxBG rounded-lg text-gray-600 dark:text-gray-400 h-9 w-full md:w-60 px-3 focus:outline-none
                  //   ${isThemeDark ? 'border-0' : 'border-2 border-borderGary'}`}
                  className={`bg-spekterWhite dark:bg-inputBoxBG rounded-lg text-gray-600 dark:text-gray-200 
                                h-9 w-full md:w-80 px-3 focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2
                                  ${
                                    isThemeDark
                                      ? "border-0"
                                      : "border-2 border-borderGary"
                                  } 
                              `}
                  type="text"
                  value={name}
                  onChange={(event) => {
                    event.preventDefault();
                    setName(event.target.value);
                  }}
                />
              </div>

              {/* Email */}
              <div className="p-2.5 flex gap-6 items-center">
                <div className="w-20 text-gray-600 dark:text-gray-400">
                  Email
                </div>
                <input
                  className={`bg-spekterWhite dark:bg-inputBoxBG rounded-lg text-gray-600 dark:text-gray-200 
                  h-9 w-full md:w-80 px-3 focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2
                    ${isThemeDark ? "border-0" : "border-2 border-borderGary"} 
                `}
                  type="text"
                  value={mail}
                  onChange={(event) => {
                    event.preventDefault();
                    setMail(event.target.value);
                  }}
                  disabled
                />
              </div>

              {/* Mobile */}
              <div className="p-2.5 flex gap-6 items-center">
                <div className="w-20 text-gray-600 dark:text-gray-400">
                  Mobile
                </div>
                <input
                  className={`bg-spekterWhite dark:bg-inputBoxBG rounded-lg text-gray-600 dark:text-gray-200 
                  h-9 w-full md:w-80 px-3 focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2
                    ${isThemeDark ? "border-0" : "border-2 border-borderGary"} 
                `}
                  type="text"
                  value={mobile}
                  onChange={(event) => {
                    event.preventDefault();
                    setMobile(event.target.value);
                  }}
                />
              </div>

              {/* VoiceCall */}
              <div className="p-2.5 flex gap-6 items-center">
                <div className="w-20 text-gray-600 dark:text-gray-400">
                  Voice Call
                </div>
                <input
                  className={`bg-spekterWhite dark:bg-inputBoxBG rounded-lg text-gray-600 dark:text-gray-200 
                  h-9 w-full md:w-80 px-3 focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2
                    ${isThemeDark ? "border-0" : "border-2 border-borderGary"} 
                `}
                  type="text"
                  value={voiceCall}
                  onChange={(event) => {
                    event.preventDefault();
                    setVoiceCall(event.target.value);
                  }}
                />
              </div>
              {/* Telephone */}
              <div className="p-2">
                <MessagingOption subscriberId={subscriberId} />
              </div>
            </div>
            <div className="flex justify-end p-3">
              <input
                type="button"
                value="Cancel"
                className="px-4 py-1 ml-2 font-semibold bg-gray-400 border-0 rounded text-spekterWhite dark:text-gray-900"
                onClick={closed}
              />
              <input
                type="button"
                value="Save"
                onClick={updateSubscriber}
                className="px-4 py-1 ml-2 font-semibold border-0 rounded bg-spekterGreenLight dark:bg-spekterGreen text-spekterWhite dark:text-gray-900"
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EditModal;
