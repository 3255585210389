import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as toast from "../../../UI/Toaster";
import { CgClose } from "react-icons/cg";
import SwitchIcon from "../../../UI/Switch/Switch";
import validator from "validator";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import * as actions from "../../../../redux/actions/index";
import { editCustomSubscriberAction } from "../../../../redux/actions/userManagerActions/editCustomSubscriber";
import { useTranslation } from "react-i18next";
import CatchmentSelectEdit from "./CatchmentSelectEdit";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const EditSubscriberModal = ({
  ags,
  showEditModal,
  setShowEditModal,
  subscriber,
  paginate,
}) => {
  //language package
  const { t, i18n } = useTranslation("userManagement");

  const isThemeDark = useSelector((state) => state.theme.isThemeDark);

  const { width } = useWindowDimensions();

  const [name, setName] = useState(subscriber.name);
  const [email, setEmail] = useState(subscriber.email);
  const [phone, setPhone] = useState(subscriber.mobile);
  const [voice, setVoice] = useState(subscriber.voicecall);

  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isVoiceValid, setIsVoiceValid] = useState(true);

  // waring stage s1
  const [s1smsSwitch, sets1smsSwitch] = useState(
    subscriber?.alertSettings?.s1?.sms
  );
  const [s1mailSwitch, sets1mailSwitch] = useState(
    subscriber?.alertSettings?.s1?.email
  );
  const [s1voiceCallSwitch, sets1VoiceCallSwitch] = useState(
    subscriber?.alertSettings?.s1?.voice
  );

  // waring stage s2
  const [s2smsSwitch, sets2smsSwitch] = useState(
    subscriber?.alertSettings?.s2?.sms
  );
  const [s2mailSwitch, sets2mailSwitch] = useState(
    subscriber?.alertSettings?.s2?.email
  );
  const [s2voiceCallSwitch, sets2VoiceCallSwitch] = useState(
    subscriber?.alertSettings?.s2?.voice
  );

  // waring stage s3
  const [s3smsSwitch, sets3smsSwitch] = useState(
    subscriber?.alertSettings?.s3?.sms
  );
  const [s3mailSwitch, sets3mailSwitch] = useState(
    subscriber?.alertSettings?.s3?.email
  );
  const [s3voiceCallSwitch, sets3VoiceCallSwitch] = useState(
    subscriber?.alertSettings?.s3?.voice
  );

  // enable/disable catchments
  const [alertCatchments, setAlertCatchments] = useState([]);

  const dispatch = useDispatch();

  const nameChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsNameValid(true);
    }
    if (event.target.value.trim().length === 0) {
      setIsNameValid(false);
    }
    setName(event.target.value);
  };

  const emailChangeHandler = (event) => {
    setEmail(event.target.value);

    if (event.target.value.trim().length === 0) {
      setIsEmailValid(false);
    } else if (validator.isEmail(email) === false) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  };

  const phoneChangeHandler = (value) => {
    setPhone(value);
    if (value && isValidPhoneNumber(value)) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  };

  const voiceChangeHandler = (value) => {
    setVoice(value);
    if (value && isValidPhoneNumber(value)) {
      setIsVoiceValid(true);
    } else {
      setIsVoiceValid(false);
    }
  };

  const customSubscriberInfo = {
    name: name,
    email: email,
    mobile: phone,
    voicecall: voice,
    alertCatchments: alertCatchments,
    alertSettings: {
      s1: {
        sms: s1smsSwitch,
        email: s1mailSwitch,
        voice: s1voiceCallSwitch,
      },
      s2: {
        sms: s2smsSwitch,
        email: s2mailSwitch,
        voice: s2voiceCallSwitch,
      },
      s3: {
        sms: s3smsSwitch,
        email: s3mailSwitch,
        voice: s3voiceCallSwitch,
      },
    },
  };

  const submitEditedCustomSubscriber = () => {
    if (validator.isEmail(email) === false) {
      toast.error(t("enterEmail"));
    } else if (!isPhoneValid) {
      toast.error(t("enterPhoneNumber"));
    } else if (!isVoiceValid) {
      toast.error(t("enterCellNumber"));
    } else if (isNameValid && isEmailValid && isPhoneValid && isVoiceValid) {
      const editResponse = dispatch(
        editCustomSubscriberAction(ags, subscriber._id, customSubscriberInfo)
      );

      editResponse.then(function (result) {
        if (result.type === "EDIT_CUSTOM_SUBSCRIBER_SUCCESS") {
          toast.success(t("subscriberUpdatedSuccessfully"));
          dispatch(
            actions.allCustomSubscribersAction(ags, {
              page: 1,
              limit: 10,
              sort: -1,
              searchTerm: "",
            })
          );
          paginate(1);
          setShowEditModal(false);
        }

        if (result.error && result.error.message === "Network Error") {
          toast.error(t("serverNotResponding"));
        } else if (result.type === "EDIT_CUSTOM_SUBSCRIBER_FAIL") {
          toast.error(t("failed"));
        }
      });
    } else {
      toast.error(t("allTheFieldsRequired"));
    }
  };

  return (
    <div>
      {showEditModal ? (
        // container
        <div className="fixed left-0 z-[30] flex w-full h-full overflow-auto top-2 md:top-0 user-modal-bg-light dark:user-modal-bg-dark">
          {/* modal */}
          <div
            className={`relative max-h-[70vh] pr-2 md:max-h-[80vh] 2xl:max-h-[85vh] bg-spekterWhite dark:bg-spekterDarkSix m-auto flex flex-col justify-between rounded-10
                           p-0 w-full md:w-[95%] max-w-[930px]`}
          >
            {/* header */}
            <div className="flex h-20 pt-3 pl-[10px] md:pl-[40px] gap-4 items-center">
              <img
                className="w-5 md:w-7"
                src={`/images/${!isThemeDark ? "plus" : "plus_light"}.svg`}
                alt="plus"
              />
              <span className="text-xl font-bold text-gray-700 dark:text-spekterWhite sm:text-center lg:text-left">
                {t("customSubscriberEdit")}
              </span>
            </div>
            {/* content */}
            <div className="max-h-[70vh] overflow-y-auto md:scrollbarLight md:dark:scrollbar">
              <div className="flex justify-start w-full h-full md:px-8">
                <div className="flex flex-col justify-center w-full mt-2">
                  <div className="p-2.5">
                    <div className="font-bold text-gray-600 dark:text-spekterWhite">
                      {t("name")}
                    </div>
                    <input
                      className={`bg-[#c9c9c9] dark:bg-[#333333] placeholder:opacity-50 rounded-sm text-gray-600 dark:text-gray-200 
                                  h-12 w-full   px-3 focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2
                                    ${
                                      !isNameValid
                                        ? "border-2 border-red-600"
                                        : "border-0"
                                    }`}
                      type="text"
                      placeholder="user name"
                      onChange={nameChangeHandler}
                      value={name}
                    />
                  </div>
                  <div className="p-2.5">
                    <div className="font-bold text-gray-600 dark:text-spekterWhite">
                      {t("mail")}
                    </div>
                    <input
                      className={`bg-[#c9c9c9] dark:bg-[#333333] placeholder:opacity-50 rounded-sm text-gray-600 dark:text-gray-200 
                                h-12 w-full   px-3 focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2
                                  ${
                                    !isEmailValid
                                      ? "border-2 border-red-600"
                                      : "border-0"
                                  } `}
                      type="email"
                      placeholder="user e-mail"
                      onChange={emailChangeHandler}
                      value={email}
                    />
                  </div>
                  <div className="p-2.5">
                    <div className="font-bold text-gray-600 dark:text-spekterWhite">
                      {t("mobile")}
                    </div>
                    <div
                      className={`bg-[#c9c9c9] dark:bg-[#333333] placeholder:opacity-50 rounded-sm text-[#333] dark:text-[#818181]
                  h-12  px-3 focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2
                    ${!isPhoneValid ? "border-2 border-red-600" : "border-0"}`}
                    >
                      <PhoneInput
                        className={`pt-2.5 ${
                          isThemeDark ? "phoneInputDarkSubs" : "phoneInputSubs"
                        }`}
                        placeholder="user phone number"
                        onChange={phoneChangeHandler}
                        value={phone}
                        defaultCountry="DE"
                        international
                        countryCallingCodeEditable={false}
                        countries={["DE", "AT", "CH", "FR", "IT", "LU"]}
                      />
                    </div>
                  </div>
                  <div className="p-2.5">
                    <div className="font-bold text-gray-600 dark:text-spekterWhite">
                      {t("voiceCall")}
                    </div>
                    <div
                      className={`bg-[#c9c9c9] dark:bg-[#333333] placeholder:opacity-50 rounded-sm text-[#333] dark:text-[#818181]
                  h-12 px-3 focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2
                    ${!isVoiceValid ? "border-2 border-red-600" : "border-0"}`}
                    >
                      <PhoneInput
                        className={`pt-2.5 ${
                          isThemeDark ? "phoneInputDarkSubs" : "phoneInputSubs"
                        }`}
                        placeholder="user voice call number"
                        onChange={voiceChangeHandler}
                        value={voice}
                        defaultCountry="DE"
                        international
                        countryCallingCodeEditable={false}
                        countries={["DE", "AT", "CH", "FR", "IT", "LU"]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-3 xl:flex-row md:px-8">
                <CatchmentSelectEdit
                  setAlertCatchments={setAlertCatchments}
                  catchmentsWithAlert={subscriber?.alertCatchments}
                  showSubtitle={false}
                />
                {/* switch container */}
                <div className="">
                  <p className="self-start py-2 text-2xl font-bold text-center text-black dark:text-white">
                    Alarm-Einstellungen
                  </p>
                  {width > 640 ? (
                    // non mobile ui
                    <div className="flex gap-4 w-full sm:w-[460px] sm:mx-8 sm:pr-2.5 pt-2 sm:pt-4 pb-2">
                      <div className="w-full flex-col flex justify-end gap-2 mx-2.5">
                        <div className="w-20">
                          <p className="relative text-gray-800 dark:text-spekterWhite">
                            {t("SMS")}
                          </p>
                          <div className="w-20">
                            <p className="text-gray-800 dark:text-spekterWhite">
                              {t("mail")}
                            </p>
                          </div>
                        </div>
                        <div className="w-20">
                          <p className="text-gray-800 dark:text-spekterWhite">
                            {t("voiceCall")}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center pt-2 rounded-lg sm:px-2 bg-spekterYellow">
                        <p className="text-spekterDarkOne">S1</p>
                        <div>
                          <SwitchIcon
                            bgColor={`${
                              isThemeDark ? "bg-gray-900" : "bg-gray-500"
                            }`}
                            bgCheckedColor="checked:bg-spekterGreen"
                            value={s1smsSwitch}
                            onCheck={() => sets1smsSwitch(!s1smsSwitch)}
                          />
                        </div>
                        <div>
                          <SwitchIcon
                            bgColor={`${
                              isThemeDark ? "bg-gray-900" : "bg-gray-500"
                            }`}
                            bgCheckedColor="checked:bg-spekterGreen"
                            value={s1mailSwitch}
                            onCheck={() => sets1mailSwitch(!s1mailSwitch)}
                          />
                        </div>
                        <div>
                          <SwitchIcon
                            bgColor={`${
                              isThemeDark ? "bg-gray-900" : "bg-gray-500"
                            }`}
                            bgCheckedColor="checked:bg-spekterGreen"
                            value={s1voiceCallSwitch}
                            onCheck={() =>
                              sets1VoiceCallSwitch(!s1voiceCallSwitch)
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="flex flex-col items-center pt-2 rounded-lg sm:px-2 bg-spekterRed">
                        <p>S2</p>
                        <div>
                          <SwitchIcon
                            bgColor={`${
                              isThemeDark ? "bg-gray-900" : "bg-gray-500"
                            }`}
                            bgCheckedColor="checked:bg-spekterGreen"
                            value={s2smsSwitch}
                            onCheck={() => sets2smsSwitch(!s2smsSwitch)}
                          />
                        </div>
                        <div>
                          <SwitchIcon
                            bgColor={`${
                              isThemeDark ? "bg-gray-900" : "bg-gray-500"
                            }`}
                            bgCheckedColor="checked:bg-spekterGreen"
                            value={s2mailSwitch}
                            onCheck={() => sets2mailSwitch(!s2mailSwitch)}
                          />
                        </div>
                        <div>
                          <SwitchIcon
                            bgColor={`${
                              isThemeDark ? "bg-gray-900" : "bg-gray-500"
                            }`}
                            bgCheckedColor="checked:bg-spekterGreen"
                            value={s2voiceCallSwitch}
                            onCheck={() =>
                              sets2VoiceCallSwitch(!s2voiceCallSwitch)
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="flex flex-col items-center pt-2 mr-3 rounded-lg sm:mr-0 sm:px-2 bg-spekterPurple">
                        <p>S3</p>
                        <div>
                          <SwitchIcon
                            bgColor={`${
                              isThemeDark ? "bg-gray-900" : "bg-gray-500"
                            }`}
                            bgCheckedColor="checked:bg-spekterGreen"
                            value={s3smsSwitch}
                            onCheck={() => sets3smsSwitch(!s3smsSwitch)}
                          />
                        </div>
                        <div>
                          <SwitchIcon
                            bgColor={`${
                              isThemeDark ? "bg-gray-900" : "bg-gray-500"
                            }`}
                            bgCheckedColor="checked:bg-spekterGreen"
                            value={s3mailSwitch}
                            onCheck={() => sets3mailSwitch(!s3mailSwitch)}
                          />
                        </div>
                        <div>
                          <SwitchIcon
                            bgColor={`${
                              isThemeDark ? "bg-gray-900" : "bg-gray-500"
                            }`}
                            bgCheckedColor="checked:bg-spekterGreen"
                            value={s3voiceCallSwitch}
                            onCheck={() =>
                              sets3VoiceCallSwitch(!s3voiceCallSwitch)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    // mobile ui
                    <div
                      className={`flex px-2 flex-col justify-between w-full lg:w-[60%] gap-2 sm:gap-10 duration-200`}
                    >
                      <div className="flex justify-between p-[15px]">
                        <div className="flex items-center"></div>
                        <div className="flex items-center justify-center">
                          <img
                            src={
                              isThemeDark
                                ? "/images/sms_light.svg"
                                : "/images/sms_dark.svg"
                            }
                            alt="sms"
                            className="h-4 sm:block"
                          />
                        </div>
                        <div className="flex items-center justify-center">
                          <img
                            src={
                              isThemeDark
                                ? "/images/mail_light.svg"
                                : "/images/mail_dark.svg"
                            }
                            alt="mail"
                            className=" h-[14px] sm:block"
                          />
                        </div>
                        <div className="flex items-center justify-center pr-2.5">
                          <img
                            src={
                              isThemeDark
                                ? "/images/phone_light.svg"
                                : "/images/phone_dark.svg"
                            }
                            alt="phone"
                            className="h-4 sm:block"
                          />
                        </div>
                        {/* <div className="flex items-center justify-center">WP</div> */}
                      </div>
                      <div className="w-full p-[15px] flex items-center justify-between bg-[#F2CB53] rounded-[9px] text-[#FFFFFF]">
                        <div className="font-bold text-[15px]">{t("S1")}</div>
                        <div className="flex items-center justify-center">
                          <SwitchIcon
                            value={s1smsSwitch}
                            labelClassName="m-0 pt-1.5"
                            bgColor="bg-[#BA9C40] opacity-40"
                            bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                            onCheck={() => sets1smsSwitch(!s1smsSwitch)}
                            // disabled={userRole === "users" && true}
                          />
                        </div>
                        <div>
                          <SwitchIcon
                            value={s1mailSwitch}
                            labelClassName="m-0 pt-1.5"
                            bgColor="bg-[#BA9C40] opacity-40"
                            bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                            onCheck={() => sets1mailSwitch(!s1mailSwitch)}
                          />
                        </div>
                        {/* dummy switch for ui alignment */}
                        <div className="flex items-center justify-center invisible">
                          <SwitchIcon
                            value={false}
                            labelClassName="m-0 pt-1.5"
                            bgColor="bg-[#BA9C40] opacity-40"
                            bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                            onCheck={() => {}}
                          />
                        </div>
                        {/* switch for webpush */}
                        {/* <div className="flex items-center justify-center">
                  <SwitchIcon
                    value={false}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {}}
                  />
                </div> */}
                      </div>
                      <div className="w-full p-[15px] flex items-center justify-between bg-[#E41931] rounded-[9px] text-[#FFFFFF]">
                        {/* <div className="w-20 py-3 flex flex-col items-center bg-[#E41931] rounded-[9px] text-[#FFFFFF]"> */}
                        <div className="font-bold text-[15px]">{t("S2")}</div>
                        <div>
                          <SwitchIcon
                            value={s2smsSwitch}
                            labelClassName="m-0 pt-1.5"
                            bgColor="bg-[#b01326] opacity-40"
                            bgCheckedColor="checked:bg-[#b01326] checked:opacity-100"
                            onCheck={() => sets2smsSwitch(!s2smsSwitch)}
                          />
                        </div>

                        <div>
                          <SwitchIcon
                            value={s2mailSwitch}
                            labelClassName="m-0 pt-1.5"
                            bgColor="bg-[#b01326] opacity-40"
                            bgCheckedColor="checked:bg-[#b01326] checked:opacity-100"
                            onCheck={() => sets2mailSwitch(!s2mailSwitch)}
                          />
                        </div>
                        {/* dummy switch for ui alignment */}
                        <div className="flex items-center justify-center invisible">
                          <SwitchIcon
                            value={s1smsSwitch}
                            labelClassName="m-0 pt-1.5"
                            bgColor="bg-[#BA9C40] opacity-40"
                            bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                            onCheck={() => {}}
                          />
                        </div>
                        {/* <div className="flex items-center justify-center">
                  <SwitchIcon
                    value={false}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {}}
                  />
                </div> */}
                        {/* )} */}
                      </div>
                      <div className="w-full p-[15px] flex items-center justify-between bg-[#9F33E5] rounded-[9px] text-[#FFFFFF]">
                        {/* <div className="w-20 py-3 flex flex-col items-center bg-[#9F33E5] rounded-[9px] text-[#FFFFFF]"> */}
                        <div className="font-bold text-[15px]">{t("S3")}</div>
                        <div>
                          <SwitchIcon
                            value={s3smsSwitch}
                            labelClassName="m-0 pt-1.5"
                            bgColor="bg-[#7a27b0] opacity-40"
                            bgCheckedColor="checked:bg-[#7a27b0] checked:opacity-100"
                            onCheck={() => sets3smsSwitch(!s3smsSwitch)}
                          />
                        </div>
                        <div>
                          <SwitchIcon
                            value={s3mailSwitch}
                            labelClassName="m-0 pt-1.5"
                            bgColor="bg-[#7a27b0] opacity-40"
                            bgCheckedColor="checked:bg-[#7a27b0] checked:opacity-100"
                            onCheck={() => sets3mailSwitch(!s3mailSwitch)}
                          />
                        </div>
                        <div>
                          <SwitchIcon
                            value={s3voiceCallSwitch}
                            labelClassName="m-0 pt-1.5"
                            bgColor="bg-[#7a27b0] opacity-40"
                            bgCheckedColor="checked:bg-[#7a27b0] checked:opacity-100"
                            onCheck={() =>
                              sets3VoiceCallSwitch(!s3voiceCallSwitch)
                            }
                          />
                        </div>
                        {/* <div className="flex items-center justify-center">
                  <SwitchIcon
                    value={false}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {}}
                  />
                </div> */}
                      </div>
                      {/* <div className="w-full p-[15px] flex items-center justify-between bg-[#3733E5] rounded-[9px] text-[#FFFFFF]">
                <div className="font-bold text-[15px]">Flood Level</div>

                <div>
                  <SwitchIcon
                    value={false}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {}}
                  />
                </div>
              </div>
              <div className="w-full p-[15px] flex items-center justify-between bg-[#33E590] rounded-[9px] text-[#FFFFFF]">
                <div className="font-bold text-[15px]">CAP</div>

                <div>
                  <SwitchIcon
                    value={false}
                    labelClassName="m-0 pt-1.5"
                    bgColor="bg-[#BA9C40] opacity-40"
                    bgCheckedColor="checked:bg-[#BA9C40] checked:opacity-100"
                    onCheck={() => {}}
                  />
                </div>
              </div> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="flex justify-end gap-5 py-3 pr-3 sm:pr-10 rounded-b-10">
              {/* <button onClick={() => setShowAddModal(false)} className="border-[1px] border-gray-400 w-24 rounded-5 py-1 text-base text-gray-600 dark:text-gray-400">Cancel</button> */}
              <div
                onClick={() => setShowEditModal(false)}
                className="absolute text-gray-800 duration-300 cursor-pointer right-2 top-2 hover:opacity-75 dark:text-gray-100"
              >
                <CgClose size={24} />
              </div>
              <button
                onClick={submitEditedCustomSubscriber}
                className="px-10 py-2 text-base bg-spekterGreenLight dark:bg-spekterGreen rounded-5 text-spekterWhite dark:text-spekterDarkOne"
              >
                <strong className="text-spekterWhite dark:text-spekterDarkOne">
                  Save
                </strong>
              </button>
              {/* <button onClick={() => setShowAddModal(false)} className="w-24 py-1 text-base text-gray-600 bg-btnGray rounded-5">{t("cancel")}</button> */}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default EditSubscriberModal;
