import React, { useState, useEffect } from "react";
import { sensorTypes } from "../../../constants/sensorTypes";
// import './Info.css';
import { useTranslation } from "react-i18next";
import useMobileView from "../../../hooks/useMobileView";
import { cn } from "../../../shared/Utility";
import ReactTooltip from "react-tooltip";

const Info = ({
  information,
  data,
  sensorType,
  maintenanceMode,
  isCumulative,
  setIsCumulative,
}) => {
  const [currentValue, setCurrentValue] = useState("");
  //language package
  const { t, i18n } = useTranslation("sensorManager");
  const { t: tSensors } = useTranslation("sensors");
  const isMobileView = useMobileView();

  useEffect(() => {
    if (data && sensorType === sensorTypes.HDC) {
      //total rain amount
      setCurrentValue(data + " mm");
    } else {
      const value = data > 0 ? data : 0;
      setCurrentValue(`${value} cm`);
    }
  }, [data, sensorType]);

  const cumulativeSwitch = (
    <div
      data-tip={isMobileView ? "" : tSensors("cumulativeRainfall")}
      data-for="tooltip"
      className={"flex bg-[#343A40] p-1 rounded-5 cursor-pointer"}
      onClick={() => {
        setIsCumulative(!isCumulative);
      }}
    >
      <img
        src="images/chart_modal/icons8-gefülltes-plus-96.svg"
        alt="cumulative chart icon"
        className={cn(
          { "bg-spekterDarkOne rounded-5": isCumulative },
          { "bg-transparent": !isCumulative }
        )}
        height={18}
        width={18}
      />
    </div>
  );

  return (
    <div className="flex justify-between w-full px-4 pt-4 max-h-[102px]">
      <div className="w-[80%]">
        <p
          className={`mb-2 text-sm font-bold ${
            information.length < 30 ? "md:text-xl" : "md:text-base"
          }`}
        >
          {information}
        </p>
        <div className="text-sm md:text-lg">
          {sensorType === sensorTypes.SMARTRIVER ? (
            <p>{t("floodLevel")}</p>
          ) : sensorType === sensorTypes.GFL ? (
            <p>{t("gfl")}</p>
          ) : sensorType === sensorTypes.HDC ? (
            <p>{t("hdc")}</p>
          ) : (
            <p>{t("sewer")}</p>
          )}
        </div>
      </div>
      {sensorType === sensorTypes.SMARTSEWER ? (
        <span></span>
      ) : (
        <div className="w-[70%] text-right">
          <div className="flex items-start justify-end gap-2 text-center">
            {sensorType === sensorTypes.HDC && cumulativeSwitch}
            <h5>{maintenanceMode ? "--" : currentValue}</h5>
          </div>
          {sensorType === sensorTypes.HDC && (
            <p className="text-sm text-gray-500 md:text-lg">
              {" "}
              {t("rainAmountText")}
            </p>
          )}
        </div>
      )}
      <ReactTooltip id="tooltip" place="top" />
    </div>
  );
};

export default Info;
