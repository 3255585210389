import React, { useState, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { fromLonLat } from "ol/proj";
import Vector from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OlStyle from "ol/style/Style";
import OlStyleIcon from "ol/style/Icon";
import OlFeature from "ol/Feature";
import OlPoint from "ol/geom/Point";

let addressSearchLayer = new Vector({
  source: new VectorSource({
    features: [],
    wrapX: false,
  }),
  style: new OlStyle({
    image: new OlStyleIcon({
      anchor: [0.5, 98],
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      scale: 0.5,
      src: "/images/address_marker.svg",
    }),
  }),
  zIndex: 10005,
});

const AddressSearch = ({ map }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedAddress, setSelectedAddress] = useState();

  const handleChange = (value) => {
    setSearchText(value);
  };

  const handleSelect = (value) => {
    setSearchText(value);
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => setSelectedAddress(Object.values(latLng)))
      .catch((error) => console.error("Error", error));
  };

  // ---------------------
  // search address on map
  useEffect(() => {
    if (!selectedAddress) return;

    addressSearchLayer.getSource().clear();
    let coordinate = [selectedAddress[1], selectedAddress[0]];

    let id = selectedAddress.join("");

    let imagePath = `/images/address_marker.svg`;
    let iconStyle = new OlStyle({
      image: new OlStyleIcon({
        anchor: [0.5, 1],
        scale: 0.4,
        src: imagePath,
      }),
    });

    let marker = new OlFeature({
      geometry: new OlPoint(fromLonLat(coordinate)),
      name: "address marker",
    });

    // Set the custom style for the marker
    marker.setStyle(iconStyle);
    marker.setId(id);

    addressSearchLayer.getSource().addFeature(marker);

    map.getView().setCenter(fromLonLat(coordinate));
    map.getView().setZoom(13);
  }, [map, selectedAddress]);

  useEffect(() => {
    if (!map) return;
    map.addLayer(addressSearchLayer);
  }, [map]);

  return (
    <div className="mb-5">
      <PlacesAutocomplete
        value={searchText}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <div className="relative">
              <span>
                <img
                  className="absolute pt-2 ml-1"
                  height="25"
                  width="25"
                  src="images/location_search.svg"
                  alt="search icon"
                />
              </span>
              <input
                {...getInputProps({
                  placeholder: "Adresse suchen...",
                  type: "text",
                })}
                className="w-full py-2 pl-5 text-white rounded-lg bg-spekterDarkFour focus:outline-none focus:border-btnBlue focus:btnBlue focus:ring-2"
              />
            </div>
            <div>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#2d2d2d",
                  color: "white",
                  cursor: "pointer",
                };
                return (
                  <div
                    className={`px-2 ${
                      index + 1 == suggestions.length ? "rounded-b-md" : ""
                    }`}
                    {...getSuggestionItemProps(suggestion, { style })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default AddressSearch;
