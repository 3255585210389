//i18next framework for translation to different languages throughout the whole app 
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

//English language files
import analyticsEN from './locales/en/analytics/analytics.json';
import settingsEN from './locales/en/settings/settings.json';
import userSettingsEN from './locales/en/settings/userSettings.json';
import navigationEN from './locales/en/navigation/sideMenu.json';
import realtimeMapEN from './locales/en/realtimeMap/realtimeMap.json';
import riskProfileEN from './locales/en/riskProfile/riskProfile.json';
import sensorsEN from './locales/de/sensorManager/sensors.json';
import sensorManagerEN from './locales/en/sensorManager/sensorManager.json';
import userManagementEN from './locales/en/userManagement/userManagement.json';
import userProfilePopUpEN from './locales/en/userManagement/userProfilePopUp.json';
import webViewerEN from './locales/en/webViewer/webViewer.json';
import loginEN from './locales/en/settings/login.json';
import headerEN from './locales/en/navigation/header.json';
import aboutEN from './locales/en/settings/about.json';
import legendEN from './locales/en/realtimeMap/legend.json';
import modalEN from './locales/en/settings/modals.json';
import catchmentItemEN from './locales/en/catchmentItem/catchmentItem.json';
import gflChartEN from './locales/en/gflChart/gflChart.json';
import sensorItemEN from './locales/en/sensorItem/sensorItem.json';
import paginationEN from './locales/en/settings/pagination.json';
import addToHomeScreenEN from './locales/en/banner/addToHomeScreen.json';

//German language files
import analyticsDE from './locales/de/analytics/analytics.json';
import settingsDE from './locales/de/settings/settings.json';
import userSettingsDE from './locales/de/settings/userSettings.json';
import navigationDE from './locales/de/navigation/sideMenu.json';
import realtimeMapDE from './locales/de/realtimeMap/realtimeMap.json';
import riskProfileDE from './locales/de/riskProfile/riskProfile.json';
import sensorsDE from './locales/de/sensorManager/sensors.json';
import sensorManagerDE from './locales/de/sensorManager/sensorManager.json';
import userManagementDE from './locales/de/userManagement/userManagement.json';
import userProfilePopUpDE from './locales/de/userManagement/userProfilePopUp.json';
import webViewerDE from './locales/de/webViewer/webViewer.json';
import loginDE from './locales/de/settings/login.json';
import headerDE from './locales/de/navigation/header.json';
import aboutDE from './locales/de/settings/about.json';
import legendDE from './locales/de/realtimeMap/legend.json';
import modalDE from './locales/de/settings/modals.json';
import catchmentItemDE from './locales/de/catchmentItem/catchmentItem.json';
import gflChartDE from './locales/de/gflChart/gflChart.json';
import sensorItemDE from './locales/de/sensorItem/sensorItem.json';
import paginationDE from './locales/de/settings/pagination.json';
import addToHomeScreenDE from './locales/de/banner/addToHomeScreen.json';


//assignment of the language files (from above) to names that are available in the different classes
const resources = {
    en: {
        analytics: analyticsEN,
        navigation: navigationEN,
        realtimeMap: realtimeMapEN,
        legend: legendEN,
        riskProfile: riskProfileEN,
        sensors: sensorsEN,
        sensorManager: sensorManagerEN,
        settings: settingsEN,
        about: aboutEN,
        userSettings: userSettingsEN,
        userManagement: userManagementEN,
        userManagementPopUp: userProfilePopUpEN,
        webViewer: webViewerEN,
        login: loginEN,
        header: headerEN,
        modal: modalEN,
        catchmentItem: catchmentItemEN,
        gflChart: gflChartEN,
        sensorItem: sensorItemEN,
        pagination: paginationEN,
        addToHomeScreen: addToHomeScreenEN,
    },
    de: {
        analytics: analyticsDE,
        navigation: navigationDE,
        realtimeMap: realtimeMapDE,
        legend: legendDE,
        riskProfile: riskProfileDE,
        sensors: sensorsDE,
        sensorManager: sensorManagerDE,
        settings: settingsDE,
        about: aboutDE,
        userSettings: userSettingsDE,
        userManagement: userManagementDE,
        userManagementPopUp: userProfilePopUpDE,
        webViewer: webViewerDE,
        login: loginDE,
        header: headerDE,
        modal: modalDE,
        catchmentItem: catchmentItemDE,
        gflChart: gflChartDE,
        sensorItem: sensorItemDE,
        pagination: paginationDE,
        addToHomeScreen: addToHomeScreenDE,
    }
};

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: 'de',
        whitelist: ['en', 'de'],
        debug: true,
        interpolation: {
            // not needed for react as it escapes by default 
            escapeValue: false,
        },
        react: {
            useSuspense: false,
            wait: false,
        },
    });

export default i18n;   