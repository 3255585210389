import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import validator from "validator";
import { login } from "../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styles from "./Login.module.css";
import { useTranslation } from "react-i18next";
import * as toast from "../UI/Toaster";
import { customErrorMessages } from "../../shared/locales/CustomErrorMessages";
import Spinner from "../UI/Spinner/Spinner";
import * as actions from "../../redux/actions/index";
import DownloadSection from "../Auth/DownloadSection/DownloadSection";
import useMobileView from "../../hooks/useMobileView";
import { is } from "date-fns/locale";

function Login() {
  //Language package
  const { t, i18n } = useTranslation("settings");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showIcon, setShowIcon] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [mailPlaceholder, setMailPlaceholder] = useState("Email");
  const [passwordPlaceholder, setPasswordPlaceholder] = useState("Passwort");
  const [showMailTitle, setShowMailTitle] = useState(false);
  const [showPasswordTitle, setShowPasswordTitle] = useState(false);
  const dispatch = useDispatch();

  const isMobileView = useMobileView();
  const isLoading = useSelector((state) => state.login.isLoading);

  // Carousel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "/images/fas_slide_1.svg",
    "/images/fas_slide_2.svg",
    "/images/fas_slide_3.svg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  const currentImage = images[currentImageIndex];

  useEffect(() => {
    dispatch(actions.municipality());
  }, []);

  const emailChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsEmailValid(true);
    }

    setEmail(event.target.value);
  };

  const passwordChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsPasswordValid(true);
      setShowIcon(true);
    }

    if (event.target.value.trim().length === 0) {
      setShowIcon(false);
    }
    setPassword(event.target.value);
  };

  const removeMailPlaceholder = () => {
    setMailPlaceholder("");
    setShowMailTitle(true);
  };

  const removePasswordPlaceholder = () => {
    setPasswordPlaceholder("");
    setShowPasswordTitle(true);
  };

  const handleMailBlur = () => {
    if (email === "") {
      setMailPlaceholder("Email");
      setShowMailTitle(false);
    }
  };

  const handlePasswordBlur = () => {
    if (password === "") {
      setPasswordPlaceholder("Passwort");
      setShowPasswordTitle(false);
    }
  };

  const toggleIcon = () => {
    setShowPassword(!showPassword);
  };

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  const navigate = useNavigate();

  const formSubmitHandler = (event) => {
    event.preventDefault();

    if (email.trim().length === 0 && password.trim().length === 0) {
      setIsEmailValid(false);
      setIsPasswordValid(false);
      return;
    }

    if (email.trim().length === 0) {
      setIsEmailValid(false);
      return;
    }

    if (password.trim().length === 0) {
      setIsPasswordValid(false);
      return;
    }

    if (validator.isEmail(email) === false) {
      setIsEmailValid(false);
      return;
    }

    const loginData = {
      strategy: "local",
      email: email.trim().toLowerCase(),
      password: password.trim(),
    };

    const loginResponse = dispatch(login(loginData));

    loginResponse.then(function (result) {
      console.log(result.type);
      if (result.type === "LOGIN_SUCCESS") {
        setRedirect(true);
        return <Navigate replace to="/realtimemap" />;
      }

      if (result.error && result.error.message === "Network Error") {
        toast.error("Server not responding please try later");
      } else if (result.type === "LOGIN_FAIL") {
        toast.error(
          customErrorMessages[result.error.message] ?? result.error.message
        );
      }
    });
  };

  if (redirect) {
    return <Navigate replace to="/" />;
  }

  const isIOS = /iPhone/.test(navigator.userAgent);

  let form = (
    <form
      onSubmit={formSubmitHandler}
      className={`${styles.LoginForm} bg-boxBG w-full sm:w-[50%] xl:w-[40%] h-full bg-opacity-95 relative`}
    >
      <div className="mx-[20px] sm:mx-[30px] xl:mx-[47px]">
        <div className="mx-[20px] sm:mx-[30px] xl:mx-[47px] h-full">
          <div
            className={`flex justify-start ${
              isIOS && isMobileView ? "mt-8" : ""
            }`}
          >
            <img
              src="/images/Logo_Swisscom_Light.svg"
              alt="fas"
              className="h-20 mt-4 mb-3"
            />
          </div>
          <div className="flex pt-[2vh] xl:pt-[10vh] 3xl:pt-[20vh] flex-col duration-300 h-full">
            {/* <div className="flex pt-[60px] sm:pt-[150px] flex-col duration-300 h-full"> */}
            <span className="text-white mr-4 cursor-pointer text-[18px] sm:text-[29px] font-bold">
              {!isMobileView && "Login"} Kommune / Landkreis
            </span>
            <div
              className={`text-white text-[15px] w-full ${
                isMobileView ? "mt-1 mb-2.5" : "mt-2 mb-3.5"
              }`}
            >
              Hier können sie sich in ihren FAS-Account einloggen.
            </div>
            <div className="">
              <div
                className={`${
                  showMailTitle ? "opacity-100" : "opacity-0"
                } text-white w-full font-bold pl-[12px]`}
              >
                Email
              </div>

              <input
                type="email"
                placeholder={mailPlaceholder}
                className={` ${
                  styles.login__input
                } w-full xl:w-[425px] h-[41px] bg-[#333333] border-2 rounded-md text-spekterWhite
             py-3.5 px-3 focus:outline-none placeholder:capitalize
             ${isEmailValid ? "border-boxBG" : "border-red-600"}`}
                onChange={emailChangeHandler}
                value={email}
                onFocus={removeMailPlaceholder}
                onBlur={handleMailBlur}
              />

              <div
                className={`${
                  showPasswordTitle ? "opacity-100" : "opacity-0"
                } text-white w-full font-bold pl-[12px] ${
                  isMobileView ? "mt-2" : "mt-3"
                }`}
              >
                Passwort
              </div>
              <div>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder={passwordPlaceholder}
                  className={`w-full xl:w-[425px] h-[41px] border-2 bg-[#333333] rounded-md text-spekterWhite py-3.5 px-3 focus:outline-none
             ${isPasswordValid ? "border-boxBG" : "border-red-600"}`}
                  onChange={passwordChangeHandler}
                  value={password}
                  onFocus={removePasswordPlaceholder}
                  onBlur={handlePasswordBlur}
                />

                {/* <FontAwesomeIcon
                  className={`text-spekterWhite relative top-0 right-14 cursor-pointer ${
                    showIcon ? "opacity-1" : "opacity-0"
                  }`}
                  icon={showPassword ? faEye : faEyeSlash}
                  onClick={toggleIcon}
                /> */}
              </div>
              <div
                className="w-full mt-4 text-white no-underline cursor-pointer text-md sm:mt-14"
                onClick={() => navigate("/forgetpassword")}
              >
                Passwort vergessen?
              </div>

              <button
                className="w-full xl:w-[425px] mt-4 h-[53px] text-base text-center no-underline border-0 cursor-pointer bg-[#04CF00] shadow-btn text-spekterWhite dark:text-gray-800 rounded-5 hover:opacity-80 duration-300"
                type="submit"
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <div className="font-semibold text-[#000000]">
                    {t("loginText")}
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Sticky Button */}
      <div
        id="communitySelectButton"
        className="relative sm:absolute -bottom-6 md:bottom-0 left-0 w-full px-[20px] sm:px-[30px] xl:px-[47px] py-4"
      >
        <div className="ml-[10px] sm:mx-[30px] xl:mx-[47px] pb-4 text-center">
          <span
            onClick={() => navigate("/")} // Adjust the route as needed
            className="font-semibold text-[#04CF00] hover:opacity-80 cursor-pointer"
          >
            Zurück zur Kommunenauswahl
          </span>
        </div>
      </div>
    </form>
  );

  return (
    <div
      className="h-screen overflow-hidden bg-center bg-cover"
      style={{ backgroundImage: `url(${currentImage})` }}
    >
      <div className="flex flex-col items-center justify-center w-full h-full sm:flex-row">
        {form}
        <DownloadSection
          images={images}
          currentImageIndex={currentImageIndex}
          handleDotClick={handleDotClick}
        />
      </div>
    </div>
  );
}

export default Login;
