import React, { forwardRef, useEffect } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
registerLocale("de", de);

const DatePicker = ({ startDate, endDate, onDateChange, setEndDate }) => {
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);

  const now = new Date();
  const calcEndDate = new Date(
    new Date(startDate).setDate(startDate.getDate() + 30)
  );
  const maxEndDate = calcEndDate > now ? now : calcEndDate;

  useEffect(() => {
    if (calcEndDate < endDate) {
      setEndDate(calcEndDate);
    }
  }, [startDate, endDate]);

  const DatePickerFrom = forwardRef(({ value, onClick }, ref) => (
    <div className="flex items-center h-10 gap-2" onClick={onClick} ref={ref}>
      <img
        src={
          !isThemeDark
            ? "/images/icon-calendar.svg"
            : "/images/icon-calendar-light.svg"
        }
        className={`hidden lg:block ${
          isThemeDark ? "opacity-80" : "opacity-60"
        }`}
        alt="calendar_images"
        height="30px"
        width="30px"
      />
      <div
        className={`m-0 flex items-center gap-6 lg:gap-0 xl:gap-5 w-[200px] sm:w-[280px] xl:w-[300px] py-2 bg-spekterDarkOne text-gray-100
                rounded-5 justify-center px-2`}
      >
        <span>{value}</span>
      </div>
    </div>
  ));

  return (
    <div className="flex flex-col gap-2 text-spekterWhite">
      <ReactDatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={(dates) => onDateChange(dates)}
        selectsRange
        dateFormat="dd.MM.yyyy"
        minDate={new Date("1-1-2022")}
        maxDate={maxEndDate}
        locale={"de"}
        customInput={<DatePickerFrom />}
      />
    </div>
  );
};

export default DatePicker;
