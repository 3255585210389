import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CgClose } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { timeStampToGermanTime, utcToGermanTime } from "../../shared/Utility";
// ----test----
import SmartRiverChart from "./smartRiver_chart/smartRiver_chart";
import HdcBarChart from "./hdc_barchart/hdc_barchart";
import GflChart from "./gfl_chart/gfl_chart";
import Spinner from "../UI/Spinner/Spinner";
import SmartsewerChart from "./smartsewer_chart/smartsewer_chart";
import { sensorTypes } from "../../constants/sensorTypes";

const ChartModal = (props) => {
  const { t, i18n } = useTranslation("charts");
  const [name, setName] = useState("");
  const [chartDates, setChartDates] = useState([]);
  const [chartValues, setChartValues] = useState([]);
  const [chartType, setChartType] = useState("");
  const [smartsewerInfos, setSmartsewerInfos] = useState();
  // const [timeRange, setTimeRange] = useState(1440);

  useEffect(() => {
    if (props.data) {
      let tempDates = [];
      // let tempHdcDates = [];
      // let tempHdcValues = [];
      let tempValues = [];

      if (props.sensorType === sensorTypes.SMARTSEWER) {
        props.data.forEach((data) => {
          const timeStamp = data?.start;

          // Convert UTC to German local time
          const germanTime = timeStampToGermanTime(timeStamp);

          tempDates.push(germanTime);

          tempValues.push(data.value);
        });
        setChartDates(tempDates);
        setChartValues(tempValues);
      }
    }

    return () => {
      setChartDates([]);
      // setHdcChartDates([]);
      setChartValues([]);
      setName("");
    };
  }, [props.data, props.sensorName]);

  useEffect(() => {
    if (props.sensorType) {
      setChartType(props.sensorType);
    }
    if (props.sensorName) {
      setName(props.sensorName, props.sensorName);
    }
  }, [props.sensorType]);

  const loader = (
    <div className="flex items-center justify-center w-full h-full">
      <Spinner />
    </div>
  );

  let chart = loader;
  if (chartType === sensorTypes.HDC) {
    chart = (
      <HdcBarChart
        sensorName={name}
        rawData={props.data}
        setTimeRange={props.setTimeRange}
        duration={props.duration}
      />
    );
  } else if (chartType === sensorTypes.SMARTRIVER) {
    chart = (
      <SmartRiverChart
        // dates={chartDates}
        // data={chartValues}
        rawData={props.data}
        sensorName={name}
        setTimeRange={props.setTimeRange}
        duration={props.duration}
        optionalData={{ ...props.optionalData }}
      />
    );
  } else if (chartType === sensorTypes.GFL) {
    chart = (
      <GflChart
        rawData={props.data}
        setTimeRange={props.setTimeRange}
        duration={props.duration}
        // dates={chartDates}
        // data={chartValues}
        sensorName={name}
        optionalData={{ ...props.optionalData }}
      />
    );
  } else if (chartType === sensorTypes.SMARTSEWER) {
    chart = (
      <SmartsewerChart
        dates={chartDates}
        data={chartValues}
        sensorName={name}
        setTimeRange={props.setTimeRange}
        duration={props.duration}
        loading={props.loading}
        optionalData={{ ...props.optionalData }}
      />
    );
  }

  const handleCloseChartModal = () => {
    setChartType("");
    props.onCloseModal();
  };
  return (
    <div className="flex justify-center">
      <div
        className={`flex fixed justify-center h-[450px] sm:h-96 w-[100%] xl:w-4/6 bottom-0 z-200 sm:z-1001 bg-spekterDarkOne py-8 sm:px-20 xl:mb-10 
        rounded-t-xl xl:rounded-xl box-border shadow ease-out duration-300 transform transition-all overflow-x-hidden
        ${props.open ? "translate-y-0" : "hidden"}`}
      >
        {/* <div
        className={`flex fixed justify-center h-[450px] sm:h-96 w-[100%] xl:w-4/6 bottom-0 z-200 sm:z-100 bg-spekterDarkOne py-8 sm:px-20 xl:mb-10 
        rounded-t-xl xl:rounded-xl box-border shadow ease-out duration-300 transform transition-all overflow-x-hidden
        ${props.open ? "translate-y-0" : "hidden"}`}
      > */}
        <CgClose
          onClick={handleCloseChartModal}
          size={24}
          className="absolute text-gray-100 duration-300 cursor-pointer right-3 top-3 hover:opacity-75"
        />
        <div className="w-full">{chart}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.chart.isLoading,
    data: state.chart.data,
    sensorName: state.chart.sensorName,
    sensorType: state.chart.sensorType,
    optionalData: state.chart.optionalData,
    error: state.chart.error,
  };
};
export default connect(mapStateToProps)(ChartModal);
