import { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../../components/Navigation/Header/Header";
import LeftSidebar from "../../components/Navigation/LeftSidebar/LeftSidebar";

const AppLayout = ({ isAuthenticated = true }) => {
  const location = useLocation();
  const [currentPage, setcurrentPage] = useState();
  const [settingsTab, setSettingsTab] = useState();
  const loading = useSelector((state) => state.login.isLoading);

  const onSettingsClick = (page, tab) => {
    setcurrentPage(page);
    setSettingsTab(tab);
  };

  const setcurrentPageNull = (page) => {
    setcurrentPage();
  };

  if (location.pathname.split("/")[1] === "change-email") {
    return null;
  }

  return isAuthenticated ? (
    //   return (
    <>
      <Header onSettingsClick={onSettingsClick} />

      <LeftSidebar
        currentPage={currentPage}
        settingsTab={settingsTab}
        setcurrentPageNull={setcurrentPageNull}
      />

      <Outlet />
    </>
  ) : //   );
  null;
};
export default AppLayout;
