import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const baseStyle = {
  // flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // height: '100%',
  justifyContent: "center",
  padding: "20px",
  // borderWidth: 2,
  // borderRadius: 2,
  // borderColor: "#0d0d0f",
  // backgroundColor: "#0d0d0f",
  // color: "#bdbdbd",
  outline: "none",
  border: "none",
  // transition: "border .24s ease-in-out",
  // height: '60px',
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "0.5rem",
  padding: "10px 0",
  overflowX: "auto",
};

function AttachmentField({ newFiles, setNewFiles }) {
  // const [filesLength, setFilesLength] = useState();
  const isThemeDark = useSelector((state) => state.theme.isThemeDark);
  const [showToolTip, setShowToolTip] = useState(false);

  const { t } = useTranslation("webViewer");

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ["image/*", "application/pdf"],
    onDrop: (acceptedFiles) => {
      let files = newFiles.concat(acceptedFiles);
      setNewFiles(
        files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const removeFile = (file) => () => {
    const files = [...newFiles];
    files.splice(files.indexOf(file), 1);
    setNewFiles(files);
  };

  // const removeAll = () => {
  //   setNewFiles([]);
  // };
  const thumbs = newFiles.map((file) => (
    <div key={file.name}>
      <div
        className="flex justify-between gap-3 items-center font-normal w-[100px] md:w-[150px] text-[.75rem]
        h-7 px-2 my-1 text-[#000] dark:text-[#fff] bg-[#C9C9C9] dark:bg-[#333333] rounded-[9px]"
      >
        <div
          style={{
            width: "max-content",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          className="flex items-center gap-2"
        >
          <img
            src={`/images/pod/${
              isThemeDark
                ? "File Icon_c9c9c9_fill.svg"
                : "File Icon_000000_fill.svg"
            }`}
            alt="pod icon"
            className="w-[15px]"
          />

          <span className="w-[70%] overflow-hidden text-ellipsis whitespace-nowrap">
            {file.name}
          </span>
          <div onClick={removeFile(file)} className="cursor-pointer">
            <img
              src={`/images/pod/X Icon Bold_${
                isThemeDark ? "ffffff" : "000000"
              }.svg`}
              alt="pod icon"
              className="self-start w-[12px] duration-300 cursor-pointer hover:opacity-70"
            />
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="flex flex-col w-full gap-3 rounded border-grey">
      <div className="flex gap-5">
        <div className="w-[calc(100%_-_53px)] relative flex p-1 border-2 border-dashed border-[#333333] dark:border-[#818181] rounded-[6px]">
          <div
            className="border w-full h-20 flex justify-center border-dashed bg-[#C9C9C9] dark:bg-[#333333] dark:text-[#fff] text-[#333] text-[15px] font-normal rounded-[6px]"
            {...getRootProps()}
          >
            <input style={{ border: "0px" }} {...getInputProps()} />
            <div className="flex items-center justify-center gap-3">
              <img
                src={`/images/pod/Upload File Icon_${
                  isThemeDark ? "c9c9c9" : "333333"
                }_fill.svg`}
                alt="Upload icon"
                className="w-[21px]"
              />
              <span className="hidden text-sm text-center cursor-pointer md:block">
                {t("dropFile")}
              </span>
            </div>
          </div>
        </div>
        <div
          onMouseEnter={() => setShowToolTip(true)}
          onMouseLeave={() => setShowToolTip(false)}
          className="w-[33px] relative h-[33px] p-2 rounded-full bg-[#C9C9C9] dark:bg-[#333333]"
        >
          <img
            src={`/images/pod/${
              !isThemeDark
                ? "Info Icon_333333_fill.svg"
                : "Info Icon_c9c9c9_fill.svg"
            }`}
            alt="info icon"
            className={`${showToolTip && "duration-300 opacity-70"}`}
          />
          {showToolTip && (
            <span
              className=" rounded-[6px] whitespace-nowrap p-3 -top-[160%] right-0 absolute font-normal
         bg-[#fff]/70 dark:bg-[#000]/70 backdrop-blur-[61.5px] dark:text-white text-[12px]"
            >
              {t("accept")}: jpeg, jpg, png, gif and pdf
            </span>
          )}
        </div>
      </div>

      <div className="flex flex-col flex-1">
        <aside className="flex justify-start" style={thumbsContainer}>
          {thumbs}
        </aside>
        {/* <div className="flex self-start justify-center">
          {thumbs.length > 1 && (
            <button
              className="px-4 bg-red-500 rounded hover:bg-red-700 text-spekterWhite"
              type="button"
              onClick={() => removeAll()}
            >
              {t("removeAll")}
            </button>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default AttachmentField;
