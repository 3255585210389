import * as actionTypes from "../actionTypes";

export const hideNotification = (id) => {
  return {
    type: actionTypes.ADD_COLLAPSED_NOTIFICATION,
    id: id,
  };
};

// export const removeNotificationId = (id) => {
//   return {
//     type: actionTypes.REMOVE_COLLAPSED_NOTIFICATION,
//     id: id,
//   };
// };
