import React from 'react';

// import './Modal.css';
import Backdrop from '../Backdrop/Backdrop';

const Modal = (props) => {
    return (
        <>
            <Backdrop show={props.show} clicked={props.modalClosed} />
            <div
                className="fixed z-100 top-1/4 md:top-1/3 max-w-md bg-white w-4/6 left-10 md:left-1/3 border box-border shadow ml-4 ml:auto md:p-4 modalStyle"
                style={{
                    transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
                    opacity: props.show ? '1' : '0'
                }}>
                {props.children}
            </div>
        </>
    )
};

const ModalPropsAreEqual = (prevModal, nextModal) => {
    return prevModal.show === nextModal.show || prevModal.children === nextModal.children;
}

export default React.memo(Modal, ModalPropsAreEqual);